<div class="row p-3">
  <div class="col-lg-3 col-md-1 col-sm-1"></div>
  <div class="col-lg-6 col-md-10 col-sm-10 Card pb-1">
    <form
      [formGroup]="approvalLeadTimeForm"
      id="approvalLeadTImeForm"
      (ngSubmit)="save()"
    >
      <h4>
        {{
          !isNewApprovalLeadTime
            ? ("config.management.ApprovalLeadTime.Edit" | translate) +
              " " +
              approvalLeadTimeModel.name
            : ("config.management.ApprovalLeadTime.New" | translate)
        }}
      </h4>
      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>
      <fieldset class="nested-fieldset">
        <div *ngIf="isDuplicateApprovalLeadTime" class="error-message">
          <app-error-badge
            [thinBar]="true"
            message="{{
              'config.management.ApprovalLeadTime.Editor.DuplicateEntry'
                | translate
            }}"
            class="warning-badge"
          ></app-error-badge>
        </div>

        <div class="form-group mb-3">
          <label for="name">{{
            "config.management.ApprovalLeadTime.KpiName" | translate
          }}</label>
          <input
            formControlName="name"
            type="text"
            class="form-control"
            id="name"
            placeholder="{{
              'config.management.ApprovalLeadTime.NamePlaceHolder' | translate
            }}"
            [ngClass]="{
              error: isInvalidFormControl('name')
            }"
          />
          <small
            *ngIf="isInvalidFormControl('name')"
            class="form-text text-danger"
          >
            {{
              "config.management.ApprovalLeadTime.Editor.KpiNameRequired"
                | translate
            }}
          </small>
        </div>

        <div class="form-group mb-3">
          <label for="leadTime">{{
            "config.management.ApprovalLeadTime.LeadTime" | translate
          }}</label>
          <input
            formControlName="leadTime"
            type="number"
            class="form-control"
            id="leadTime"
            placeholder="{{
              'config.management.ApprovalLeadTime.LeadTimePlaceHolder'
                | translate
            }}"
            [ngClass]="{
              error: isInvalidFormControl('leadTime')
            }"
            appOnlyDigitsDirective
            min="1"
          />

          <small  *ngIf="isInvalidFormControl('leadTime')" class="form-text text-danger">
            {{
              "config.management.ApprovalLeadTime.Editor.LeadTimeRequired"
                | translate
            }}
          </small>
        </div>

        <div class="row">
          <div class="col-6 form-group">
            <label for="startTrigger">
              {{
                "config.management.ApprovalLeadTime.StartTrigger" | translate
              }}</label
            >
            <select
              class="form-control"
              formControlName="startTriggerId"
              id="startTriggerId"
              (change)="onChangingStartTrigger()"
              [ngClass]="{
                 error: isInvalidFormControl('startTriggerId')
               }"
            >
              <option [value]="null" disabled selected>
                {{
                  "config.management.ApprovalLeadTime.StartTriggerPlaceHolder"
                    | translate
                }}
              </option>
              <option *ngFor="let item of activities" [value]="item.id">{{
                item.name
              }}</option>
            </select>
          </div>

          <div class="col-6 form-group">
            <label for="endTrigger"
              >{{ "config.management.ApprovalLeadTime.EndTrigger" | translate }}
            </label>
            <select
              class="form-control"
              formControlName="endTriggerId"
              id="endTriggerId"
              [ngClass]="{
                error: isInvalidFormControl('endTriggerId')
              }"
            >
              <option [value]="null"  disabled selected>
                {{
                  "config.management.ApprovalLeadTime.EndTriggerPlaceHolder"
                    | translate
                }}
              </option>
              <option *ngFor="let item of endTriggers" [value]="item.id">
                {{ item.name }}</option
              >
            </select>
          </div>
        </div>
      </fieldset>

      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <div class="col-sm-12 form-group">
        <div ngPreserveWhitespaces class="float-right">
          <button (click)="cancel()" class="btn btn-danger">
            <i class="fa fa-times"></i>
            {{ "config.management.ApprovalLeadTime.Editor.Cancel" | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="isSaving || !approvalLeadTimeForm.valid"
          >
            <i *ngIf="!isSaving" class="fa fa-save"></i>
            <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
            {{
              isSaving
                ? ("config.management.ApprovalLeadTime.Editor.Saving"
                  | translate)
                : ("config.management.ApprovalLeadTime.Editor.Save" | translate)
            }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
