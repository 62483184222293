import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/company/models/company.model';
import { CompanyService } from 'src/app/company/services/company.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ServiceTypes } from 'src/app/shared/models/shipment/service-types.enum';
import { CompanyTabs } from 'src/app/company2/enum/company-tabs.enum';
import { Company2Service } from 'src/app/company2/services/company2.service';
import { Tab } from '../../models/tab.model';
import { CustomerServicesService } from 'src/app/customer/services/customer-services.service';
import { CustomerServicesHelperService } from 'src/app/customer/services/customer-services-helper.service';
import { CompanySideNavBarSubTabs } from '../../models/company-side-nav-bar-sub-tabs.model';
import { OperationalKPIsEnum } from '../../models/enums/operational-kpis.enum';
import { SubTabTypeEnum } from '../../models/enums/sub-tab-type.enum';

@Component({
  selector: 'app-company-side-nav-bar',
  templateUrl: './company-side-nav-bar.component.html',
  styleUrls: ['./company-side-nav-bar.component.scss']
})
export class CompanySideNavBarComponent implements OnInit {
  //TODO when implementing the related page, ensure to replace 'canView' value with the correct value
  companyTabsWithIcons: Tab[];
  company: Company;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private company2Service: Company2Service,
    private companyHttpService: CompanyService,
    private authService: AuthService,
    private customerServicesService: CustomerServicesService,
    private customerServicesHelperService: CustomerServicesHelperService
  ) {}

  ngAfterContentChecked() {
    this.company = this.company2Service.company;
  }

  ngOnInit(): void {
    this.companyTabsWithIcons = this.setCompanyTabsWithIcons();
    this.setServicesSubTabsValue();
    if (this.isCustomerModule) {
      this.customerServicesService.selectedServiceChanged.subscribe(() => {
        this.getCustomerServicesSubTabs();
      });
    }
  }

  get currentCompanyId(): number {
    let splittedUrls = this.router.url.split('/');
    let companyId = splittedUrls[2];
    return +companyId;
  }

  isSelectedTab(key: string): boolean {
    if (key == CompanyTabs.Details && this.router.url.includes('edit')) {
      key = 'edit';
    }
    return this.router.url.includes('/' + key.replace(' ', '-').toLowerCase());
  }

  isSelectedSubTab(pageName: string, subPageName: string): boolean {
    var splitedUrl = this.router.url.split('/');
    let pageNameWrd = pageName.toLocaleLowerCase().slice(0, -1);
    let subPageNameWrd = subPageName
      .split(' ')
      .join('-')
      .toLocaleLowerCase();
    let currentTabWrd = splitedUrl[3];
    let currentSubTabWrd = splitedUrl[5]?.includes('-details')
      ? splitedUrl[5]?.split('?')[0].replace('-details', '')
      : splitedUrl[5]?.split('?')[0];
    return pageNameWrd == currentTabWrd && subPageNameWrd == currentSubTabWrd;
  }

  navigateToPage(pageName: string) {
    var companyTab = this.companyTabsWithIcons.find(x => x.name == pageName);

    if (this.isCustomerModule) {
      pageName = pageName?.toLowerCase().replace(/\s+/g, '-');
    }

    if (
      (this.isCustomerModule || companyTab.subTabs == null) &&
      companyTab.clickable != false
    ) {
      this.router.navigate(
        [`${this.company?.id}/${pageName.toLocaleLowerCase()}`],
        {
          relativeTo: this.route
        }
      );
    }
  }

  expandSubmenu(tab: any) {
    tab.isExpanded = !tab.isExpanded;
  }

  get canViewEmployees(): boolean {
    return this.authService.canViewEmployees;
  }

  setServicesSubTabsValue(): void {
    if (this.isCustomerModule) {
      this.getCustomerServicesSubTabs();
    } else {
      this.companyHttpService
        .getCompanyServices(this.currentCompanyId)
        .subscribe(res => {
          let subTabs = this.orderServices(res.map(data => data.freePlService));
          this.processServicesSubTabs(subTabs);
        });
    }
  }

  getCustomerServicesSubTabs() {
    this.customerServicesService
      .getSelectedServicesForCustomer(this.currentCompanyId)
      .subscribe(res => {
        let subTabs = this.customerServicesHelperService.processCustomerSelectedServicesOnSideNavBar(
          res
        );

        this.processServicesSubTabs(subTabs);
        this.changeTruckingToPortTrucking(subTabs);
      });
  }

  processServicesSubTabs(subTabsServices: CompanySideNavBarSubTabs[]) {
    var servicesTab = this.companyTabsWithIcons.find(
      x => x.name == CompanyTabs.Services
    );
    servicesTab.subTabs = subTabsServices;
    servicesTab.isExpanded = false;
    servicesTab.subTabs.forEach(subTab => {
      subTab.type = SubTabTypeEnum.Service;
    });
  }
  changeTruckingToPortTrucking(
    subTabsServices: CompanySideNavBarSubTabs[]
  ): void {
    var service = subTabsServices.find(x => x.name == ServiceTypes.Trucking);
    service.name = 'Port Trucking';
  }

  orderServices(
    res: Array<CompanySideNavBarSubTabs>
  ): Array<CompanySideNavBarSubTabs> {
    var service = res.find(x => x.name == ServiceTypes.Trucking);
    service.name = 'Port Trucking';

    // [TODO] need to remove 'warehousing' steps when it added to db , it's static now
    if (!this.isCustomerModule) {
      let tempEmptyElement = { ...service };
      tempEmptyElement.name = 'Warehousing';
      tempEmptyElement.id = 0;
      tempEmptyElement.serviceId = 9;
      res.push(tempEmptyElement);
    }

    res.sort((a, b) => a.serviceId - b.serviceId);

    return res;
  }

  getSubTabUrl(
    serviceId: number,
    subTabName: string,
    subTabType: string
  ): string {
    let serviceUrl = `${this.company?.id}/service/${serviceId}/`;

    let operationalKpisUrl = `${this.company?.id}/`;

    subTabName = subTabName?.toLowerCase().replace(/\s+/g, '-');

    if (subTabType == SubTabTypeEnum.KPI) {
      return operationalKpisUrl + subTabName + '-details';
    }

    return serviceUrl + subTabName + '-details';
  }

  get isCustomerModule(): boolean {
    return this.router.url.includes('customer');
  }

  setCompanyTabsWithIcons(): Tab[] {
    if (this.isCustomerModule) {
      var tabs = [
        {
          name: CompanyTabs.Details,
          icon: 'details',
          canView: true,
          subTabs: null,
          isExpanded: null
        },
        {
          name: CompanyTabs.Documents,
          icon: 'company-details-documents',
          canView: true,
          subTabs: null,
          isExpanded: null
        },
        {
          name: CompanyTabs.Services,
          icon: 'company-details-services',
          canView: true,
          subTabs: null,
          isExpanded: null
        },
        {
          name: CompanyTabs.Employees,
          icon: 'company-details-employees',
          canView: this.canViewEmployees,
          subTabs: null,
          isExpanded: null
        },
        {
          name: CompanyTabs.AssignedBPS,
          icon: 'assigned-bps',
          canView: true,
          subTabs: null,
          isExpanded: null
        },
        {
          name: CompanyTabs.Network,
          icon: 'company-network',
          canView: true,
          subTabs: null,
          isExpanded: null
        },
        {
          name: CompanyTabs.OperationalKpis,
          icon: 'company-details-operational-kpis',
          canView: true,
          subTabs: this.operationalKpisSubTabs,
          isExpanded: false,
          clickable: false
        },
        {
          name: CompanyTabs.ContractualTerms,
          icon: 'contractual-terms',
          canView: true,
          subTabs: null,
          isExpanded: null
        }
      ] as Tab[];

      return tabs;
    }
    var tabs = [
      {
        name: CompanyTabs.Details,
        icon: 'details',
        canView: true,
        subTabs: null,
        isExpanded: null
      },
      {
        name: CompanyTabs.Documents,
        icon: 'company-details-documents',
        canView: true,
        subTabs: null,
        isExpanded: null
      },
      {
        name: CompanyTabs.Services,
        icon: 'company-details-services',
        canView: true,
        subTabs: null,
        isExpanded: null
      },
      {
        name: CompanyTabs.Employees,
        icon: 'company-details-employees',
        canView: this.canViewEmployees,
        subTabs: null,
        isExpanded: null
      }
    ] as Tab[];

    return tabs;
  }

  get operationalKpisSubTabs(): CompanySideNavBarSubTabs[] {
    let shipmentKpiTab: CompanySideNavBarSubTabs = {
      name: OperationalKPIsEnum.ShipmentKPIs,
      isActive: true,
      type: SubTabTypeEnum.KPI
    };

    let registrationKpiTab: CompanySideNavBarSubTabs = {
      name: OperationalKPIsEnum.RegistrationKPIs,
      isActive: true,
      type: SubTabTypeEnum.KPI
    };

    let approvalsKpiTab: CompanySideNavBarSubTabs = {
      name: OperationalKPIsEnum.ApprovalsKPIs,
      isActive: true,
      type: SubTabTypeEnum.KPI
    };

    let subTabs = [shipmentKpiTab, registrationKpiTab, approvalsKpiTab];
    return subTabs;
  }
}
