export enum ShipmentProgressStatus {
  FutureActive = 'FutureActive',
  Current = 'Current',
  Complete = 'Complete',
  InActive = 'InActive'
}

export enum ShipmentStatusEnum {
  Draft = 'Draft',
  Active = 'Active',
  New = 'New',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Archived = 'Archived',
  OnHold = 'On hold',
  UserDraft = 'User draft'
}
