import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Carrier } from '../../shared/models/carrier.model';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {
  baseURL = environment.apiUrl + 'carrier';

  constructor(private http: HttpClient) {}

  getAll(): Observable<Carrier[]> {
    return this.http.get<Carrier[]>(`${this.baseURL}`);
  }

  getById(id: number): Observable<Carrier> {
    return this.http.get<Carrier>(`${this.baseURL}/${id}`);
  }

  deleteCarrier(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseURL}/${id}`);
  }

  addNewCarrier(carrier: any): Observable<Carrier> {
    return this.http.post<Carrier>(`${this.baseURL}`, carrier);
  }

  updateCarrier(id: number, carrier: any): Observable<Carrier> {
    return this.http.put<Carrier>(`${this.baseURL}/${id}`, carrier);
  }
  getCarrierByTransportType(freightReference: string): Observable<Carrier[]> {
    return this.http.get<Carrier[]>(`${this.baseURL}/freightReference/${freightReference}`);
  }
}
