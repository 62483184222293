import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateExpired'
})
export class DateExpired implements PipeTransform {
  transform(value: Date): any {
    if (value) {
      var today = new Date();

      if (value >= today) {
        return '';
      } else {
        return 'Expired';
      }
    }
    return 'Invalid';
  }
}
