import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TruckTypeWithSelectedLoadType } from 'src/app/admin-portal-configs/models/truck-type-with-load-type';
import { TruckTypeService } from 'src/app/admin-portal-configs/services/truck-type.service';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { Utilities } from 'src/app/shared/services/utilities';

@Component({
  selector: 'app-truck-type-list',
  templateUrl: './truck-type-list.component.html',
  styleUrls: ['./truck-type-list.component.scss']
})
export class TruckTypeListComponent implements OnInit {
  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;
  @ViewChild('loadTypeTemplate',{ static: true }) 
  loadTypeTemplate!: TemplateRef<any>;

  columns: any[] = [];
  rows: TruckTypeWithSelectedLoadType[] = [];
  rowsCache: TruckTypeWithSelectedLoadType[] = [];
  loadingIndicator: boolean;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private truckTypeService: TruckTypeService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      var truckTypes = data['truckTypes'];
      this.rowsCache = [...truckTypes];
      this.rows = truckTypes;
      truckTypes = this.getIndecies(truckTypes);
    });

    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      {
        prop: 'name',
        name: gT('config.management.TruckType.TruckType'),
        width: 100
      },
      {
        prop: 'loadTypes',
        name: gT('config.management.TruckType.LoadType'),
        width: 100,
        cellTemplate: this.loadTypeTemplate,

      },

      {
        prop: 'options',
        name: gT('config.management.TruckType.Manage'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      }
    ];
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  deleteTruckType(row: TruckTypeWithSelectedLoadType) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' + row.name + '" truck type?',
      DialogType.confirm,
      () => this.deleteTruckTypeHelper(row)
    );
  }

  deleteTruckTypeHelper(row: TruckTypeWithSelectedLoadType) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.truckTypeService.deleteTruckType(row.id).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter(item => item !== row);
        this.rows = this.rows.filter(item => item !== row);

        this.rowsCache = this.getIndecies(this.rowsCache);

        this.rows = this.rowsCache;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the truck type.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }
  
  getIndecies(array: any) {
    array.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }
}
