<div class="header-margin">
  <app-page-header
    name="card-replaceProductHeader"
    [title]="'Replace Document'"
  ></app-page-header>
</div>

<div class="row mx-0 replace-linked-document-container">
  <div class="col-7">
    <div *ngIf="isLinkExistingDocument" [formGroup]="form">
      <div class="field-label" name="lbl-documentType">
        Document Type
      </div>
      <ng-select
        [items]="[selectedDocumentType]"
        bindValue="id"
        bindLabel="name"
        formControlName="documentTypeId"
        class="document-type-field ddl-field"
        readonly="true"
        name="ddl-documentType"
      ></ng-select>

      <div class="add-file-section">
        <div class="add-title" name="lbl-linkExistingDocument">
          Link Existing Document
        </div>
        <div class="select-type-ddl">
          <ng-select
            class="link-existing-document-drop-down ddl-field"
            placeholder="Select Product"
            formControlName="existingDocument"
            [ngClass]="{
              'err-border': isInvalid('existingDocument')
            }"
            [items]="existingDocuments"
            bindLabel="partNumber"
            bindValue="selectedDocument"
            [loading]="isLoading"
            [typeahead]="existingDocuments$"
            name="ddl-existingDocument"
          >
            <ng-template ng-option-tmp let-item="item">
              <div
                class="drop-down-element d-flex"
                (mouseenter)="onExistingDocumentHover(item)"
                (mouseleave)="onExistingDocumentOutHover()"
              >
                <div class="circle drop-down-select-circle"></div>
                <div>
                  {{ item?.partNumber }}
                </div>
              </div>
              <span
                *ngIf="hoveredItem == item"
                class="hover-item"
                name="lbl-exitingDocumentOptionHover"
                >{{ item?.selectedDocument?.fileName }}</span
              >
            </ng-template>
          </ng-select>
        </div>

        <div>
          Or
          <a
            class="text-underline cursor-pointer hyper-link"
            (click)="onChangeFileUploadMethod(false)"
            name="lnk-addFile"
            >Add File</a
          >
        </div>

        <div>
          <h4 class="additional-notes">
            Additional Notes <span class="optional"><i> (optional)</i></span>
          </h4>

          <app-input
            name="input-additionalNotes"
            [classList]="'field-bg'"
            type="text"
            placeholder="Type notes here…"
            class="note"
            formControlName="notes"
            [disabled]="isReplacingDocument"
            [maxLength]="180"
          >
          </app-input>
        </div>
      </div>
    </div>

    <app-upload-document
      *ngIf="!isLinkExistingDocument"
      [parentTypeId]="parentId"
      [parentType]="documentParentType.Product"
      [selectedSingleDocumentTypeId]="selectedSingleDocumentTypeId"
      [popUpPurpose]="uploadDocumentPopUpPurpose.ReplaceExistingDocument"
      [documentToReplaceSqlId]="documentToReplaceSqlId"
      [selectedSingleDocumentTypeNames]="selectedSingleDocumentTypeNames"
      (linkExistingDocument)="onChangeFileUploadMethod(true)"
      [isMultipleProductsSelected]="selectedProducts?.length > 1"
      (uploadedDocument)="onUploadingDocumentToMultipleProducts($event)"
      name="card-uploadDocument"
    ></app-upload-document>
  </div>
  <div class="col-5">
    <div class="bulk-replace-container">
      <app-documents-bulk-product-list
        [title]="'Bulk Replace'"
        [subTitle]="'Select Products to apply this replacement'"
        [parentId]="parentId"
        [documentPath]="documentPath"
        [isOlderVersion]="false"
        (onSelectingProducts)="onSelectingProducts($event)"
      ></app-documents-bulk-product-list>
    </div>
  </div>
</div>

<div
  *ngIf="isLinkExistingDocument"
  class="d-flex align-items-center justify-content-between buttons-container"
>
  <button
    name="btn-cancelUploadDocument"
    class="secondary-button-outline w-50"
    (click)="closeDialog()"
  >
    Cancel
  </button>
  <span class="mx-2"></span>
  <button
    name="btn-addUploadDocument"
    class="primary-button w-50"
    (click)="replaceDocument()"
    [disabled]="isReplacingDocument"
  >
    Replace
  </button>
</div>
