import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApprovalActivity } from '../models/approval-activity.model';
import { ApprovalActivityService } from '../services/approval-activity.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApprovalActivitiesListResolver
  implements Resolve<ApprovalActivity[]> {
  constructor(private approvalActivityService: ApprovalActivityService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ApprovalActivity[]> {
    return this.approvalActivityService.getAll().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
