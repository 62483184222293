import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NumericValidators } from 'src/app/shared/services/validators/numeric-validators.service';
import { QuoteCargoContainer } from '../../quote/models/quote-cargo-container.model';

@Component({
  selector: 'app-container-details-form-1',
  templateUrl: './container-details-form-1.component.html',
  styleUrls: ['./container-details-form-1.component.scss']
})
export class ContainerDetailsForm1Component implements OnInit, OnDestroy {
  @Input() form: UntypedFormArray;
  @Input() data: QuoteCargoContainer[];
  @Input() title: string;

  constructor(private readonly fb: UntypedFormBuilder) {}

  ngOnDestroy(): void {
    this.form.clear();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    if (
      this.data?.length > 0 &&
      this.data[0] != ({} as QuoteCargoContainer) &&
      this.form?.length === 0
    ) {
      this.data.forEach((value: QuoteCargoContainer) => {
        this.addContainer(value);
      });
    }

    if (
      !this.data ||
      this.data.length <= 0 ||
      this.data[0] == ({} as QuoteCargoContainer)
    ) {
      this.form?.removeAt(0);
      this.addContainer();
    }
  }

  addContainer(data?: QuoteCargoContainer): void {
    const containerForm = this.fb.group({
      weight: [data?.weight, [NumericValidators.positiveValue]],
      quantity: [
        data?.quantity,
        [Validators.required, NumericValidators.positiveValue]
      ]
    });

    this.form.push(containerForm);
  }

  deleteContainer(containerIndex: number): void {
    this.form.removeAt(containerIndex);
  }

  getControl(index: number, controlName: string) {
    const formGroup = this.form.controls[index] as UntypedFormGroup;
    return formGroup.controls[controlName];
  }

  get addContainerButtonTitle(): string {
    let addContainerButtonTitle = this.title?.replace('Container', 'container');
    return addContainerButtonTitle;
  }
}
