<div class="activity-log" [class]="isDisabled ? 'disabled' : ''">
  <div class="timelineCon">
    <div
      class="line"
      [ngStyle]="{ display: isDisabletrackingLine ? 'none' : '' }"
    >
      <div
        class="subLine"
        [class]="!isDisabled && !isCurrentProgress ? 'active' : ''"
      ></div>
    </div>
    <div class="timelineGroup" [class]="isCurrentProgress ? 'active' : ''">
      <div class="pointGroup">
        <div class="timelinePoint">
          <div
            class="point"
            [class]="!isDisabled && !isCurrentProgress ? 'previous-point' : ''"
          >
            <div class="subPoint"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="activity-dates action">
    <div class="actual-date date">
      Actual:
      <div
        class="date-inner-cont"
        *ngIf="isDateDisplay; else elseBlockActualDate"
      >
        {{ progressActualDate | date: "dd/MM/YYYY" }}
      </div>
      <ng-template #elseBlockActualDate>
        <div class="date-inner-cont">dd/mm/yy</div>
      </ng-template>
    </div>
  </div>

  <app-progress
    *ngIf="progressAndActivityLogs"
    [isDisabled]="isDisabled"
    [isCurrentProgress]="isCurrentProgress"
    [progressPoint]="progressAndActivityLogs.progress"
    [activityLogs]="progressAndActivityLogs.activityLogs"
    [productRegistrationComments]="productRegistrationComments"
    [productId]="productId"
  ></app-progress>
</div>
