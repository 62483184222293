<div class="lead-times">
  <div class="section-title">
    <svg-icon name="shipment-lead-times"></svg-icon>
    Lead Times
  </div>
  <div *ngIf="leadTimes && leadTimes?.length > 0; else noLeadTimeTemplate">
    <div class="lead-times-header row">
      <div class="col-3">
        <span class="header-cell">Lead Time Name</span>
      </div>
      <div class="col-3">
        <span class="header-cell">Expected Lead Time</span>
      </div>
      <div class="col-3">
        <span class="header-cell">Actual Lead Time</span>
      </div>
      <div class="col-3">
        <span class="header-cell">Notes</span>
      </div>
    </div>

    <div class="lead-times-body row">
      <div
        *ngFor="let item of leadTimes; let i = index"
        class="lead-time-row row w-100"
      >
        <div class="col-3">
          <span class="body-cell">{{ item.name }}</span>
        </div>
        <div class="col-3">
          <span class="body-cell"
            >{{ item.expectedLeadTime }} day{{
              item.expectedLeadTime > 1 ? "s" : ""
            }}</span
          >
        </div>
        <div class="col-3">
          <span
            *ngIf="!item.isOngoing"
            class="body-cell"
            [ngClass]="{
              exceeded: item.isExceeded,
              'within-range': item.isExceeded == false
            }"
            >{{ item.netDays }} day{{ item.netDays > 1 ? "s" : "" }}</span
          >
          <span
            class="body-cell-on-going"
            [ngClass]="{
              exceeded: item.expectedLeadTime < item.netDays,
              'within-range': item.expectedLeadTime >= item.netDays
            }"
            *ngIf="item.isOngoing"
          >
            {{ item.netDays }} day{{ item.netDays > 1 ? "s" : "" }}...
          </span>
        </div>
        <div class="col-3">
          <div (click)="toggleShowInput(i)" *ngIf="showInput[i] != true">
            <div
              *ngIf="item?.notes; else NoNotesAddedBlock"
              [placement]="'bottom-left'"
              [ngbPopover]="
                item?.notes?.length > leadTimeLogNoteLimit ? noteTemplate : ''
              "
              triggers="hover:hover"
            >
              {{ item?.notes | slice: 0:leadTimeLogNoteLimit }}
              {{ item?.notes?.length > leadTimeLogNoteLimit ? "..." : "" }}
            </div>
            <ng-template #NoNotesAddedBlock>
              <div class="body-dimed-cell">
                No notes yet.
              </div>
            </ng-template>
            <ng-template #noteTemplate>
              <div>{{ item?.notes }}</div>
            </ng-template>
          </div>
          <input
            (keydown)="$event.stopPropagation()"
            *ngIf="showInput[i] === true"
            (change)="onNotesChange(i)"
            [(ngModel)]="insertedNotes[i]"
            maxlength="100"
            [placement]="'bottom-left'"
            [ngbPopover]="
              insertedNotes[i]?.length > leadTimeLogNoteLimit
                ? insertedNotes[i]
                : ''
            "
            [triggers]="'hover'"
          />
        </div>
      </div>
    </div>
  </div>

  <ng-template #noLeadTimeTemplate>
    <div class="no-lead-times">
      <svg-icon name="noItemsIcon"></svg-icon>
      <span>No lead time started yet</span>
    </div>
  </ng-template>
</div>
