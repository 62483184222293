import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProductCategory } from 'src/app/shared/models/product-category.model';
import { ProductCategoryService } from '../services/product-category.service';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoryListResolver implements Resolve<ProductCategory[]> {
  constructor(public productCategoryService: ProductCategoryService) {}

  resolve(): Observable<ProductCategory[]> {
    return this.productCategoryService.getAll().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
