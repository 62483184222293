export enum ProductInformationSummary {
  ProductName = 'Product Name',
  ProductDescription = 'Product Description',
  ProductCategory = 'Product Category',
  ActualManufacturer = 'Actual Manufacturer',
  LegalManufacturer = 'Legal Manufacturer',
  ArticleNumber = 'Part Number',
  GTIN = 'GTIN',
  HSCode = 'HS Code(s)',
  COO = 'Country of Origin',
  DangerousGoods = 'Dangerous Goods (DG)',
  Variants = 'Variants'
}
