import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bool',
  pure: false
})
export class BoolPipe implements PipeTransform {
  transform(
    value: any,
    property?: string,
    propertyValue?: any,
    property1?: string,
    propertyValue1?: any
  ): any {
    if (!value || !property) {
      return value;
    }
    let result = value.filter(item => item[property] == propertyValue);
    if (property1) {
      result = result.filter(item => item[property1] == propertyValue1);
    }
    return result;
  }
}

@NgModule({
  declarations: [BoolPipe],
  imports: [CommonModule],
  exports: [BoolPipe]
})
export class BoolPipeModule {}
