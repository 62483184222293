import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  Injectable,
  Input,
  Output,
  EventEmitter,
  OnInit,
  HostListener
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-quantity-input',
  templateUrl: './quantity-input.component.html',
  styleUrls: ['./quantity-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QuantityInputComponent)
    }
  ]
})
export class QuantityInputComponent implements ControlValueAccessor, OnInit {
  quantity;
  touched = false;
  disabled = false;

  @Input() increment: number = 1;
  @Input() isGreyBorder: boolean = false;
  @Input() digitsOnly: boolean;
  @Output() qtyModified: EventEmitter<number> = new EventEmitter<number>(null);
  @Input() classList!: string;
  @Input() placeholder: string;
  @Input() isDisabled: boolean;

  ngOnInit(): void {
    if (this.isGreyBorder == true) {
      this.classList += ' grey';
    }
  }

  onChange = quantity => {
    this.qtyModified.emit(quantity);
  };

  onTouched = () => {};

  onAdd(): void {
    this.markAsTouched();
    if (!this.disabled && !this.isDisabled) {
      if (this.quantity) {
        this.quantity = parseInt(this.quantity) + this.increment;
      } else {
        this.quantity = this.increment;
      }
      this.onChange(this.quantity);
      this.qtyModified.emit(this.quantity);
    }
  }

  onRemove(): void {
    this.markAsTouched();
    if (!this.disabled && this.quantity > 1) {
      this.quantity = parseInt(this.quantity) - this.increment;
      this.onChange(this.quantity);
      this.qtyModified.emit(this.quantity);
    }
  }

  writeValue(quantity: number): void {
    this.quantity = quantity;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: any): void {
    if (this.quantity >= 0) {
      this.qtyModified.emit(value);
    }
  }
}
