import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from '../../../../shared/services/alert.service';
import { AppTranslationService } from '../../../../shared/services/app-translation.service';
import { Utilities } from '../../../../shared/services/utilities';
import { ActivatedRoute } from '@angular/router';
import { District } from 'src/app/shared/models/district.models';
import { DistrictService } from '../../../services/district.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-districts-list',
  templateUrl: './districts-list.component.html',
  styleUrls: ['./districts-list.component.scss']
})
export class DistrictsListComponent implements OnInit {
  columns: any[] = [];
  rows: District[] = [];
  rowsCache: District[] = [];
  loadingIndicator: boolean;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private districtService: DistrictService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      var districs = data['districts'];

      districs = this.getIndecies(districs);

      this.rowsCache = [...districs];
      this.rows = districs;
    });

    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      { prop: 'name', name: gT('config.management.Name'), width: 190 },
      {
        prop: 'countryName',
        name: gT('config.management.District.CountryName'),
        width: 100
      },
      {
        prop: 'cityName',
        name: gT('config.management.District.CityName'),
        width: 100
      }
    ];

    if (this.canUpdateDistricts || this.canDeleteDistricts) {
      let optionsColumn = {
        prop: 'options',
        name: gT('config.management.District.Manage'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      };
      this.columns.push(optionsColumn);
    }
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  deleteDistrict(row: District) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' + row.name + '" district ?',
      DialogType.confirm,
      () => this.deleteDistrictHelper(row)
    );
  }

  deleteDistrictHelper(row: District) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.districtService.deleteDistrict(row.id).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter(item => item !== row);

        this.rowsCache = this.getIndecies(this.rowsCache);
        this.rows = this.rowsCache;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the district.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  getIndecies(array: any) {
    array.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }

  get canCreateDistricts(): boolean {
    return this.authService.canCreateDistricts;
  }

  get canUpdateDistricts(): boolean {
    return this.authService.canUpdateDistricts;
  }

  get canDeleteDistricts(): boolean {
    return this.authService.canDeleteDistricts;
  }
}
