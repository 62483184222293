<div class="row w-100">
  <div
    [ngClass]="{
      'col-6': moduleName == module.Supplier,
      'col-4': moduleName == module.CompanyProfile
    }"
  >
    <div class="title">Free Hours Loading</div>
    <div class="value">
      {{ moduleName === 'supplier' ? (termsAndConditions?.freeHoursLoading ?? '-') : (freeHoursLoading ?? '-') }}
    </div>
  </div>
  <div class="col-6">
    <div class="title">Free Hours Off Loading</div>
    <div class="value">
      {{ moduleName === 'supplier' ? (termsAndConditions?.freeHoursOffloading ?? '-') : (freeHoursOffLoading ?? '-') }}
    </div>
  </div>
</div>

<div class="row w-100" *ngIf="moduleName == module.Supplier">
  <div class="col-6">
    <div class="title">Detention price after Free Hours</div>
    <div class="value">
      {{ termsAndConditions?.detentionPriceAfterFreeHours ?? '-' }}
      {{
        termsAndConditions?.detentionPriceAfterFreeHours
          ? termsAndConditions?.currencyCode
          : ""
      }}
    </div>
  </div>
  <div class="col-6">
    <div class="title">Extra destination Price</div>
    <div class="value">
      {{ termsAndConditions?.extraDestinationPrice ?? '-' }}
      {{
        termsAndConditions?.extraDestinationPrice
          ? termsAndConditions?.currencyCode
          : ""
      }}
    </div>
  </div>
</div>

<div class="row w-100" *ngIf="moduleName == module.CompanyProfile">
  <div class="col-4">
    <div class="title">Detention price after Free Hours</div>
    <div class="value">
      {{ detentionPriceAfterFreeHours ?? '-' }}
      {{ detentionPriceAfterFreeHours ? currencyCode : "" }}
    </div>
  </div>
  <div class="col-4">
    <div class="title">FreePL detention price after FH</div>
    <div class="value">
      {{ freePLDetentionPriceAfterFreeHours ?? '-' }}
      {{ freePLDetentionPriceAfterFreeHours ? currencyCode : "" }}
    </div>
  </div>
  <div class="col-4">
    <div class="title">Difference</div>
    <div class="value">
      {{
        calculateDifference(
          detentionPriceAfterFreeHours,
          freePLDetentionPriceAfterFreeHours
        ) + " %"
      }}
    </div>
  </div>
</div>
<div class="row w-100" *ngIf="moduleName == module.CompanyProfile">
  <div class="col-4">
    <div class="title">Extra destination price</div>
    <div class="value">
      {{ extraDestinationPrice ?? '-' }}
      {{ extraDestinationPrice ? currencyCode : "" }}
    </div>
  </div>
  <div class="col-4">
    <div class="title">FreePL extra destination price</div>
    <div class="value">
      {{ freePLExtraDestinationPrice ?? '-' }}
      {{ freePLExtraDestinationPrice ? currencyCode : "" }}
    </div>
  </div>
  <div class="col-4">
    <div class="title">Difference</div>
    <div class="value">
      {{
        calculateDifference(
          extraDestinationPrice,
          freePLExtraDestinationPrice
        ) + " %"
      }}
    </div>
  </div>
</div>
