import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RegistrationActivityTransition } from '../models/registration-activity-transition.model';
import { RegistrationActivity } from '../models/registration-activity.model';

@Injectable({
  providedIn: 'root'
})
export class RegistrationActivityService {
  baseURL = environment.apiUrl + 'RegistrationActivity';

  constructor(private http: HttpClient) {}

  getAll(): Observable<RegistrationActivity[]> {
    return this.http.get<RegistrationActivity[]>(`${this.baseURL}`);
  }

  deleteRegistrationActivity(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseURL}/${id}`);
  }

  getById(id: number): Observable<RegistrationActivity> {
    return this.http.get<RegistrationActivity>(`${this.baseURL}/${id}`);
  }

  getByName(activityName: string): Observable<RegistrationActivity> {
    return this.http.get<RegistrationActivity>(
      `${this.baseURL}/${activityName}/Transition/NextActivities`
    );
  }

  addRegistrationActivity(
    registrationActivity: RegistrationActivity
  ): Observable<RegistrationActivity> {
    return this.http.post<RegistrationActivity>(
      `${this.baseURL}`,
      registrationActivity
    );
  }

  updateRegistrationActivity(
    registrationActivity: RegistrationActivity
  ): Observable<RegistrationActivity> {
    return this.http.put<RegistrationActivity>(
      `${this.baseURL}`,
      registrationActivity
    );
  }

  getAllActivitiesWithTransitions(): Observable<
    RegistrationActivityTransition[]
  > {
    return this.http.get<RegistrationActivityTransition[]>(
      `${this.baseURL}/transition`
    );
  }

  getWithTransitionById(id: number): Observable<RegistrationActivityTransition> {
    return this.http.get<RegistrationActivityTransition>(`${this.baseURL}/${id}/Transition`);
  }

  addNewTransition(
    id: number,
    nextIds: number[]
  ): Observable<RegistrationActivityTransition> {
    return this.http.post<RegistrationActivityTransition>(
      `${this.baseURL}/${id}/Transition`,
      nextIds
    );
  }

  deleteTransition(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseURL}/${id}/Transition`);
  }

  updateTransition(
    currentId: number,
    nextIds: number[]
  ): Observable<RegistrationActivityTransition> {
    return this.http.put<RegistrationActivityTransition>(
      `${this.baseURL}/${currentId}/Transition`,
      nextIds
    );
  }
}
