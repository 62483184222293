import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Document } from '../models/document.model';
import { DocumentService } from '../services/document.service';

@Directive({
  selector: '[appFileUpload]'
})
export class FileUploadDirective {

  @Output() documentSelectedEmitter = new EventEmitter<File>();
  fileName = "";
  fileId: number;

  constructor(private documentService: DocumentService) {}

  @HostListener('change', ['$event']) onChange(event) {
    const file: File = event.target.files[0];
    this.submitFile(file);
  }

  submitFile(file: File) {
    if (file) {
      this.documentSelectedEmitter.emit(file);
    }
  }
}
