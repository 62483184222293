import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntityType } from 'src/app/createShipment/models/enums/entity-type.enum';
import { Supplier } from '../../models/supplier.model';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SupplierService } from 'src/app/supplier/services/supplier.service';

@Component({
  selector: 'app-suppliers-list',
  templateUrl: './suppliers-list.component.html',
  styleUrls: ['./suppliers-list.component.scss']
})
export class SuppliersListComponent implements OnInit {
  entityType = EntityType;

  columns: any[] = [];

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('statusTemplate', { static: true })
  statusTemplate: TemplateRef<any>;

  @ViewChild('nameTemplate', { static: true })
  nameTemplate: TemplateRef<any>;

  @ViewChild('headquatersTemplate', { static: true })
  headquatersTemplate: TemplateRef<any>;

  allSuppliers: Supplier[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private translationService: AppTranslationService,
    private authService: AuthService,
    private supplierService: SupplierService
  ) {}

  ngOnInit() {
    this.supplierService.supplier = null;

    this.activatedRoute.data.subscribe(data => {
      this.allSuppliers = data['allSuppliers'];
    });

    const gT = (key: string) => this.translationService.getTranslation(key);
    this.columns = [
      {
        prop: 'name',
        name: gT('config.management.Supplier.Name'),
        cellTemplate: this.nameTemplate,
        width: 560
      },
      {
        prop: 'type.name',
        name: gT('config.management.Supplier.Type'),
        width: 560
      },
      {
        prop: 'id',
        name: gT('config.management.Supplier.Headquarters'),
        cellTemplate: this.headquatersTemplate,
        width: 560
      },
      {
        prop: 'status.name',
        name: gT('config.management.Supplier.Status'),
        cellTemplate: this.statusTemplate,
        width: 560,
        resizeable: false
      },
      {
        prop: 'options',
        name: gT('config.management.Supplier.Actions'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      }
    ];
  }

  get currentCompany() {
    return parseInt(this.authService.currentUser.defaultCustomerCompany);
  }

  getHeadquarters(id: string): string {
    let supplier = this.allSuppliers.find(e => e.id == id);
    let headquarters = supplier?.city?.name + ', ' + supplier?.country?.name;
    if (headquarters.includes('undefined')) {
      return '';
    }
    return headquarters;
  }
}
