import { Injectable } from '@angular/core';
import { ProductRegistrationDetails } from 'src/app/product-registration/models/product-registration-details.model';
import { CancelationDetails } from 'src/app/shared/models/cancelation-details';
import { PauseDetails } from 'src/app/shared/models/pause-details';
import { ProductRegistrationSystemStatusEnum } from '../enums/product-registration-system-status';
import { ProductRegistration } from '../models/product-registration.model';
import { LocalStoreManager } from 'src/app/shared/services/local-store-manager.service';
import { ProductRegistrationViewColumn } from 'src/app/product-registration/models/product-registration-view-column.model';
import { ProductRegistrationColumns } from 'src/app/product-registration/enums/product-registration-columns.enum';
import { ProductActivityLogViewModel } from '../models/product-activity-log-view-model';
import { ProductInformationGroupingEnum } from '../enums/product-information-grouping.enum';
import { ProductDocument } from 'src/app/shared/models/product-document.model';
import { ProductDetails } from '../models/product-details.model';
import { ProductVariant } from 'src/app/create-product/models/product-variant.model';

@Injectable({
  providedIn: 'root'
})
export class ProductRegistrationHelperService {
  constructor(private localStorage: LocalStoreManager) {}

  getProductRegistrationNumberString(
    productRegistration: ProductRegistration
  ): string {
    return productRegistration?.productRegistrationNumberString;
  }

  getSubmittedAt(productRegistration: ProductRegistration): Date {
    return productRegistration?.submittedAt;
  }

  isProductGroupingSelected(productRegistration: ProductRegistration): boolean {
    return (
      productRegistration?.productGrouping ==
        ProductInformationGroupingEnum.Bundle ||
      productRegistration?.productGrouping ==
        ProductInformationGroupingEnum.System
    );
  }

  isSingleProductGrouping(productRegistration: ProductRegistration): boolean {
    return (
      productRegistration?.productGrouping ==
      ProductInformationGroupingEnum.Single
    );
  }

  getCurrentStatus(productRegistration: ProductRegistration): string {
    return productRegistration?.productStatus?.name;
  }

  getUpdatedStatusDate(productRegistration: ProductRegistration): Date {
    return productRegistration?.productStatus?.createdAt;
  }

  getAcceptanceNumber(productRegistration: ProductRegistration): string {
    return productRegistration?.acceptanceNumber;
  }

  getRegistrationLicenseNumber(
    productRegistration: ProductRegistration
  ): string {
    return productRegistration?.registrationLicenseNumber;
  }

  getCategory(productRegistration: ProductRegistration): string {
    return productRegistration?.category;
  }

  getSubmittedBy(productRegistration: ProductRegistration): string {
    return productRegistration?.submittedBy?.fullName;
  }

  getSubmittedByProfileUrl(
    productRegistrationDetails: ProductRegistrationDetails
  ): string {
    return productRegistrationDetails?.actionActor?.actorProfilePictureUrl;
  }

  isActiveSystemStatus(productRegistration: ProductRegistration): boolean {
    return (
      productRegistration.productSystemStatus?.name ==
      ProductRegistrationSystemStatusEnum.Active
    );
  }

  isNewSystemStatus(productRegistration: ProductRegistration): boolean {
    return (
      productRegistration?.productSystemStatus?.name ==
      ProductRegistrationSystemStatusEnum.New
    );
  }

  isCancelledStatus(productRegistration: ProductRegistration): boolean {
    return (
      productRegistration?.productStatus?.name ==
      ProductRegistrationSystemStatusEnum.Cancelled
    );
  }

  getCancelationDetails(
    productRegistration: ProductRegistration
  ): CancelationDetails {
    return productRegistration.cancelationDetails;
  }

  getStatusName(productRegistration: ProductRegistration): string {
    return productRegistration?.productStatus?.name;
  }

  getProductId(productRegistration: ProductRegistration): string {
    return productRegistration?.id;
  }

  isPausedStatus(productRegistration: ProductRegistration): boolean {
    return (
      productRegistration?.productStatus?.name ==
      ProductRegistrationSystemStatusEnum.OnHold
    );
  }

  getPauseDetails(productRegistration: ProductRegistration): PauseDetails {
    return productRegistration.pauseDetails;
  }

  getCurrentProgressPointName(
    productRegistration: ProductRegistration
  ): string {
    return productRegistration?.progressPoint?.name;
  }

  getLastActivity(
    productRegistration: ProductRegistration
  ): ProductActivityLogViewModel {
    return productRegistration?.lastActivityLogViewModel;
  }

  getLastActivityName(productRegistration: ProductRegistration): string {
    return productRegistration?.lastActivityLogViewModel?.activityName;
  }

  getLastActiveActivityName(productRegistration: ProductRegistration): string {
    return productRegistration?.lastActiveActivityLog?.activityName;
  }

  getHasPendingActions(productRegistration: ProductRegistration): boolean {
    return productRegistration?.hasPendingAction;
  }

  getupdatedDate(productRegistration: ProductRegistration): Date {
    return productRegistration?.updatedDate;
  }

  getRegistrationLicenseExpiryDate(
    productRegistration: ProductRegistration
  ): Date {
    let registrationLicenseDocument = this.getProductRegistrationLicenseDocument(
      productRegistration
    );
    return registrationLicenseDocument?.expirationDate;
  }

  getStatusColor(productRegistration: ProductRegistration): string {
    return productRegistration?.productStatus?.colorCode;
  }

  isRegistrationLicenseExpired(
    productRegistration: ProductRegistration
  ): boolean {
    let expiryDate = new Date(
      this.getRegistrationLicenseExpiryDate(productRegistration)
    );
    let currentDate = new Date();

    return currentDate?.setHours(0, 0, 0, 0) > expiryDate?.setHours(0, 0, 0, 0);
  }

  clearEmployeeSelectedColumns() {
    this.localStorage.deleteData('productRegistrationSelectedColumns');
  }

  sortColumns(
    columns: ProductRegistrationViewColumn[]
  ): ProductRegistrationViewColumn[] {
    const sortedColumns: ProductRegistrationViewColumn[] = [];

    Object.values(ProductRegistrationColumns).forEach(enumValue => {
      const matchingColumn = columns.find(column => column.name === enumValue);
      if (matchingColumn) {
        sortedColumns.push(matchingColumn);
      }
    });

    columns.forEach(column => {
      if (!sortedColumns.includes(column)) {
        sortedColumns.push(column);
      }
    });

    return sortedColumns;
  }

  getProductGroupsName(productRegistration: ProductRegistration): string {
    let isBundleSystemProductGrouping = this.isProductGroupingSelected(
      productRegistration
    );

    if (isBundleSystemProductGrouping) {
      return productRegistration?.productGroupsName;
    }
    return null;
  }

  getProductGrouping(productRegistration: ProductRegistration): string {
    return productRegistration?.productGrouping;
  }

  getProductRegistrationLicenseDocument(
    productRegistration: ProductRegistration
  ): ProductDocument {
    return productRegistration?.registrationLicense;
  }

  getProductRegistrationTechnicalFileDocument(
    productRegistration: ProductRegistration
  ): ProductDocument {
    return productRegistration?.technicalFile;
  }

  isBundleProductGrouping(productRegistration: ProductRegistration): boolean {
    return (
      this.getProductGrouping(productRegistration) ==
      ProductInformationGroupingEnum.Bundle
    );
  }

  isSystemProductGrouping(productRegistration: ProductRegistration) {
    return (
      this.getProductGrouping(productRegistration) ==
      ProductInformationGroupingEnum.System
    );
  }
  getVariantPartNumber(variant: ProductVariant): string {
    return variant?.partNumber;
  }

  getVariantDescription(variant: ProductVariant): string {
    return variant?.description;
  }

  getVariantCountryOfOrigin(variant: ProductVariant): string {
    return variant?.actualManufacturer?.countryName;
  }

  getActualManufacturerName(variant: ProductVariant): string {
    return variant?.actualManufacturer?.companyName;
  }
}
