import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from './page-header.component';
import { HeaderDashedModule } from '../../../shared/components/header-dashed-underline/header-dashed.module';

@NgModule({
  imports: [CommonModule, HeaderDashedModule],
  declarations: [PageHeaderComponent],
  exports: [PageHeaderComponent]
})
export class PageHeaderModule {}
