import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseNamedEntity } from 'src/app/shared/models/shipment/base-named-entity.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ProductService } from '../../services/product.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-delete-linked-document',
  templateUrl: './delete-linked-document.component.html',
  styleUrls: ['./delete-linked-document.component.scss']
})
export class DeleteLinkedDocumentComponent implements OnInit {
  @Input() currentDocumentType: string;
  @Input() parentId: string;
  @Input() documentPath: string;
  @Input() selectedSingleDocumentTypeId: number;
  @Input() documentToReplaceSqlId: number;
  @Input() parentDocumentSqlId: number = null;

  selectedProducts: string[];
  form: UntypedFormGroup;
  selectedDocumentType: BaseNamedEntity;

  isDeleting: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private productService: ProductService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (!this.currentDocumentType) {
      this.currentDocumentType = this.data['currentDocumentType'];
    }
    if (!this.parentId) {
      this.parentId = this.data['parentId'];
    }
    if (!this.documentPath) {
      this.documentPath = this.data['documentPath'];
    }

    if (!this.selectedSingleDocumentTypeId) {
      this.selectedSingleDocumentTypeId = this.data[
        'selectedSingleDocumentTypeId'
      ];
    }
    if (!this.documentToReplaceSqlId) {
      this.documentToReplaceSqlId = this.data['documentToReplaceSqlId'];
    }

    if (!this.parentDocumentSqlId) {
      this.parentDocumentSqlId = this.data['parentDocumentSqlId'];
    }

    this.setSelectedDocumentType();
    this.initForm();
  }

  onSelectingProducts(products: string[]): void {
    this.selectedProducts = products;
  }

  initForm(): void {
    this.form = this.fb.group({
      documentTypeId: this.selectedSingleDocumentTypeId,
      notes: [null, Validators.required]
    });
  }

  setSelectedDocumentType(): void {
    this.selectedDocumentType = {
      id: this.selectedSingleDocumentTypeId,
      name: this.currentDocumentType
    } as BaseNamedEntity;
  }

  closeDialog(): void {
    this.dialogService.close();
  }

  isInvalidFormControl(formControlName: string): boolean {
    let control = this.form.get(formControlName);
    return control.touched && control.errors != null;
  }

  delete() {
    this.isDeleting = true;
    this.productService
      .deleteDocument(
        this.parentId,
        this.documentToReplaceSqlId,
        this.form.get('notes').value,
        this.selectedProducts,
        this.parentDocumentSqlId
      )
      .subscribe(res => {
        this.isDeleting = false;
        this.alertService.success('Document successfully deleted.');
        this.productService.productLibraryRequestUpdated.emit(res);
        this.closeDialog();
      });
  }
}
