import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  Optional,
  Inject,
} from '@angular/core';
import { SvgIconsRegistery } from './svg-icons.registery';
import { DOCUMENT } from '@angular/common';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'svg-icon',
  template: ` <ng-content></ng-content> `,
  styles: [':host::ngdeep svg{width:50px;height:50px}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconsComponent implements OnInit {
  private svgIcon: SVGElement;
  @Input()
  set name(iconName: string) {
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }
    const svgData = this.svgIconsRegistery.getIcon(iconName);
    this.svgIcon = this.svgElementFromString(svgData);
    this.element.nativeElement.appendChild(this.svgIcon);
  }
  constructor(
    private element: ElementRef,
    private svgIconsRegistery: SvgIconsRegistery,
    @Optional() @Inject(DOCUMENT) private document: any
  ) {}
  private svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    return (
      div.querySelector('svg') ||
      this.document.createElementNS('http://www.w3.org/2000/svg', 'path')
    );
  }
  ngOnInit(): void {}
}
