import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ShipmentLeadTimeRequest } from '../models/shipment-lead-time-request.model';
import { ShipmentLeadTimeResponse } from '../models/shipment-lead-time-response.model';

@Injectable({
  providedIn: 'root'
})
export class ShipmentLeadTimeService {
  baseURL = environment.apiUrl + 'ShipmentLeadTime';

  constructor(private http: HttpClient) {}

  getAll(): Observable<ShipmentLeadTimeResponse[]> {
    return this.http.get<ShipmentLeadTimeResponse[]>(`${this.baseURL}/leadTimes`);
  }

  getById(id: number): Observable<ShipmentLeadTimeResponse> {
    return this.http.get<ShipmentLeadTimeResponse>(`${this.baseURL}/${id}`);
  }

  deleteShipmentLeadTime(id: number): Observable<any> {
    return this.http.delete<any>(`${this.baseURL}/${id}`);
  }

  addShipmentLeadTime(
    shipmentLeadTime: ShipmentLeadTimeRequest
  ): Observable<any> {
    return this.http.post<any>(
      `${this.baseURL}`,
      shipmentLeadTime
    );
  }

  updateShipmentLeadTime(
    id: number,
    shipmentLeadTime: ShipmentLeadTimeRequest
  ): Observable<any> {
    return this.http.put<any>(
      `${this.baseURL}/${id}`,
      shipmentLeadTime
    );
  }
}
