<div>
  <form [formGroup]="chargeForm" class="chargeForm" (ngSubmit)="save()">
    <fieldset>
      <app-page-header class="title" [title]="title"></app-page-header>

      <div class="error-badge" [ngClass]="{'show': isInvalidForm}">
        <app-error-badge
          [message]="
            'Required fields are either left empty or filled incorrectly. Check all fields highlighted in red and try again.'
          "
        ></app-error-badge>
      </div>

      <div class="charge-details-container">
        <div>
          <P class="charge-Details">
            CHARGE DETAILS
          </P>
        </div>
        <div class="charge-details-fields">
          <div class="charge-name-container">
            <input
              formControlName="name"
              type="text"
              class="form-control name"
              placeholder="{{
                'config.management.ChargeTypes.NamePlaceHolder' | translate
              }}"
              [ngClass]="{ error: isValidControl('name') }"
            />
          </div>
          <div class="ddl-container">
            <div class="charge-categories-ddl-container">
              <ng-select
                formControlName="categoryId"
                placeholder="Charge Type"
                [items]="chargeCategories"
                [multiple]="false"
                [clearable]="false"
                [searchable]="false"
                [closeOnSelect]="false"
                bindLabel="name"
                bindValue="id"
                [clearSearchOnAdd]="true"
                [ngClass]="{ error: isValidControl('categoryId') }"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                >
                  <div class="d-flex align-items-center">
                    <mat-radio-button id="item-{{ index }}"
                                      [checked]="item$.selected"></mat-radio-button>
                    <div class="title d-flex align-items-center">
                      {{ item?.name }}
                    </div>
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div class="serviceTypes-ddl-container">
              <ng-select
                formControlName="services"
                placeholder="Related Service"
                [items]="serviceTypes"
                [multiple]="true"
                [clearable]="false"
                [searchable]="true"
                [closeOnSelect]="false"
                bindLabel="name"
                [clearSearchOnAdd]="true"
                [ngClass]="{ error: isValidControl('services') }"
              >
                <ng-template
                  ng-multi-label-tmp
                  let-items="items"
                  let-clear="clear"
                >
                  <div class="related-service-title">Related Service</div>
                  <div class="service-types-counter">
                    {{ selectedServiceTypesCount }}
                  </div>
                </ng-template>

                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                >
                  <div class="d-flex align-items-center">
                    <app-checkbox
                      id="item-{{ index }}"
                      [checked]="item$.selected"
                    ></app-checkbox>
                    <span class="mx-1"></span>
                    <span class="title">
                      {{ item?.name }}
                    </span>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <div iv class="btn-container">
        <button class="btn btn-cancel" (click)="cancel()">
          {{ "config.management.ChargeTypes.Editor.Cancel" | translate }}
        </button>
        <button class="btn btn-save" type="submit" [disabled]="isSaving">
          <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
          {{
            isSaving
              ? ("config.management.ChargeTypes.Editor.Saving" | translate)
              : ("config.management.ChargeTypes.Editor.Save" | translate)
          }}
        </button>
      </div>
    </fieldset>
  </form>
</div>
