import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { CargoHandling } from '../../models/enums/cargo-handling.enum';

@Component({
  selector: 'app-cargo-handling',
  templateUrl: './cargo-handling.component.html',
  styleUrls: ['./cargo-handling.component.scss']
})
export class CargoHandlingComponent implements OnInit {
  form: UntypedFormGroup;
  cargoHandlingEnum = CargoHandling;
  @Input() cargoHandlingFormControl: UntypedFormControl;
  @Input() cargoHandling: string;
  @Input() controlName: string;
  @Input() hasDomesticService: boolean;
  @Input() isDisableForm?: boolean;
  @Input() isQuoteSelected?: boolean;

  constructor() {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = new FormGroup({});
    this.cargoHandlingFormControl?.addValidators(Validators.required);

    this.form.addControl(this.controlName, this.cargoHandlingFormControl);

    this.form.get(this.controlName).setValue(this.cargoHandling);
  }
}
