import { NgModule } from '@angular/core';
import { svgIconCustomClearance } from 'projects/svg-icons/src/lib/svg-icons';
import { SvgIconsRegistery } from 'projects/svg-icons/src/lib/svg-icons.registery';
import { SharedModule } from '../shared/shared.module';
import { PauseProductRegistrationPopupComponent } from './components/pause-product-registration-popup/pause-product-registration-popup.component';
import { ProductDataSummaryComponent } from './components/product-data-summary/product-data-summary.component';
import { ResumeProductRegistrationPopupComponent } from './components/resume-product-registration-popup/resume-product-registration-popup.component';
import { ProductRegistrationDetailsComponent } from './pages/product-registration-details/product-registration-details.component';
import { ProductRegistrationListComponent } from './pages/product-registration-list/product-registration-list.component';
import { RegistrationRoutingModule as ProductRegistrationRoutingModule } from './product-registration-routing.module';
import { ProductRegistrationComponent } from './product-registration.component';
import { ProductRegistrationDetailsResolver } from './resolvers/product-registration-details.resolver';
import { ProductListResolver } from './resolvers/product-registration-list.resolver';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProgressPointComponent } from './components/progress-point/progress-point.component';
import { ProgressComponent } from './components/progress/progress.component';
import { ProgressPointLogsComponent } from './components/progress-point-logs/progress-point-logs.component';
import { ProductRegistrationCommentsResolver } from './resolvers/product-comments.resolver';
import { ProductRegistrationTableViewComponent } from './components/product-registration-table-view/product-registration-table-view.component';
import { ProductDocumentsSummaryComponent } from './components/product-documents-summary/product-documents-summary.component';
import { ProductRegistrationCommentSectionComponent } from './components/product-registration-comment-section/product-registration-comment-section.component';

@NgModule({
  declarations: [
    ProductRegistrationComponent,
    ProductRegistrationListComponent,
    ProductDataSummaryComponent,
    ProductRegistrationDetailsComponent,
    PauseProductRegistrationPopupComponent,
    ResumeProductRegistrationPopupComponent,
    ProgressPointComponent,
    ProgressComponent,
    ProgressPointLogsComponent,
    ProductRegistrationTableViewComponent,
    ProductDocumentsSummaryComponent,
    ProductRegistrationCommentSectionComponent
  ],
  imports: [ProductRegistrationRoutingModule, SharedModule, MatExpansionModule],
  exports: [ProductDataSummaryComponent, ProductDocumentsSummaryComponent],
  providers: [
    ProductRegistrationDetailsResolver,
    ProductListResolver,
    ProductRegistrationCommentsResolver
  ]
})
export class ProductRegistrationModule {
  constructor(private svgIconRegistery: SvgIconsRegistery) {
    this.svgIconRegistery.registerIcons([svgIconCustomClearance]);
  }
}
