import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { ActivityTransition } from '../../../models/activity-transition.model';
import { ActivityService } from '../../../services/activity.service';

@Component({
  selector: 'app-add-or-edit-activity-transition',
  templateUrl: './add-or-edit-activity-transition.component.html',
  styleUrls: ['./add-or-edit-activity-transition.component.scss']
})
export class AddOrEditActivityTransitionComponent implements OnInit {
  transitionForm: UntypedFormGroup;
  Activities: ActivityTransition[];
  currentActivity: ActivityTransition;
  selectedActivities: ActivityTransition[];
  nextActivity: ActivityTransition = new ActivityTransition();
  isSaving: boolean = false;
  isNewTransition: boolean = false;
  transitionToIsEmpty: boolean = false;
  duplicateSelection: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private translationService: AppTranslationService,
    private transitionService: ActivityService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.Activities = data['activityTransitions'];
      this.currentActivity = data['currentActivity'];
    });

    this.initForm();
  }

  initForm() {
    this.isNewTransition = this.currentActivity == null;
    this.currentActivity = this.currentActivity || ({} as ActivityTransition);
    this.currentActivity.nextActivities =
      this.currentActivity.nextActivities || [];

    if (this.isNewTransition) this.currentActivity.id = null;

    this.selectedActivities = this.currentActivity.nextActivities;

    this.transitionForm = this.fb.group({
      current: [
        { value: this.currentActivity.id, disabled: !this.isNewTransition },
        [Validators.required]
      ],
      next: [this.nextActivity.id]
    });
  }

  save() {
    const formVal = this.transitionForm.value;
    if (!this.isNewTransition) {
      formVal.current = this.currentActivity.id;
    }
    this.transitionToIsEmpty = this.currentActivity.nextActivities.length == 0;
    const formValid =
      this.transitionForm.valid &&
      !this.transitionToIsEmpty &&
      !this.hasDuplicatedTransition(formVal.current);

    if (!formValid) {
      this.alertService.error('Invalid Form!');
      return;
    }

    if (this.isNewTransition) {
      this.addNewRequest(formVal);
    } else {
      this.updateRequest(formVal);
    }
  }

  private hasDuplicatedTransition(currentId: number): boolean {
    const index = this.currentActivity.nextActivities.findIndex(
      i => i.id == currentId
    );

    this.duplicateSelection = index > -1 ? true : this.duplicateSelection;
    return index > -1;
  }

  updateRequest(formVal: any) {
    const nextIds = this.currentActivity.nextActivities.map(i => i.id);

    this.transitionService.updateTransition(formVal.current, nextIds).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  addNewRequest(formVal: any) {
    const nextIds = this.currentActivity.nextActivities.map(i => i.id);

    this.transitionService.addNewTransition(formVal.current, nextIds).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  addTransitionOnSelect(event: any) {
    const nextId = event.target.value;
    const nextTransition = this.Activities.find(i => i.id == nextId);

    this.duplicateSelection = this.previouslySelectedTransition(
      nextTransition.id
    );

    if (!this.duplicateSelection) {
      this.currentActivity.nextActivities.push(nextTransition);
      this.transitionToIsEmpty = false;
    }
  }

  deleteSingleTransition(transition: any) {
    this.currentActivity.nextActivities = this.currentActivity.nextActivities.filter(
      i => i != transition
    );
  }

  private saveSuccessHelper(transition?: ActivityTransition) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    if (this.isNewTransition) {
      this.alertService.showMessage(
        'Success',
        `Activity transition \"${transition.name}\" was created successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to activity transition \"${transition.name}\" were saved successfully`,
        MessageSeverity.success
      );
    }

    this.resetForm();
    this.router.navigate(['/settings/activity-transition']);
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occured whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  cancel() {
    this.resetForm();

    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/activity-transition']);
  }

  resetForm() {
    this.transitionForm.reset();
  }

  private previouslySelectedTransition(transitionId: number) {
    return (
      this.currentActivity.id == transitionId ||
      (this.currentActivity.nextActivities !== undefined &&
        this.currentActivity.nextActivities.findIndex(
          i => i.id == transitionId
        ) > -1)
    );
  }
}
