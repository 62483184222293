import { Injectable } from '@angular/core';
import { NotificationTypeName } from '../models/enums/notification-type-name.enum';

@Injectable({
  providedIn: 'root'
})
export class NotificationHelperService {

  constructor() { }

  isAssignOrUnassignAccountManagerNotification(notification: any): boolean {
    return notification?.notificationType?.name == NotificationTypeName.AccountManagerAssignedToCustomer
      || notification?.notificationType?.name == NotificationTypeName.AccountManagerUnassignedFromCustomer
  }

}
