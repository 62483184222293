import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActiveSessionsInsightsPerDay } from '../models/active-sessions-insights-per-day.model';

@Injectable({ providedIn: 'root' })
export class ActiveSessionsInsightsService {
  baseURL = environment.apiUrl + 'ActiveSessionsInsights';

  constructor(private http: HttpClient) {}

  updateEmployeeActiveSessionPerDay(
    isIdleEnded: boolean,
    isStartedNewLogginSession = false
  ): Observable<ActiveSessionsInsightsPerDay> {
    return this.http.put<ActiveSessionsInsightsPerDay>(
      this.baseURL +
        `/EmployeeActiveSessionPerDay?isIdleEnded=${isIdleEnded}&isStartedNewLogginSession=${isStartedNewLogginSession}`,
      null
    );
  }
}
