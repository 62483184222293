import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hsCode'
})
export class HSCodePipe implements PipeTransform {
  transform(value: string): any {
    if (value.length <= 4) {
      return value;
    }

    switch (value.length) {
      case 6:
        return value.slice(0, 4) + '.' + value.slice(4, 6);

      case 8:
        return (
          value.slice(0, 4) + '.' + value.slice(4, 6) + '.' + value.slice(6, 8)
        );

      case 10:
        return (
          value.slice(0, 4) + '.' + value.slice(4, 6) + '.' + value.slice(6, 10)
        );

      default:
        return value;
    }
  }
}
