import { Component } from '@angular/core';

import { AlertService, MessageSeverity } from '../../../../shared/services/alert.service';
import { AppTranslationService } from '../../../../shared/services/app-translation.service';
import { AccountService } from '../../../../shared/services/account.service';
import { Utilities } from '../../../../shared/services/utilities';
import { freePlLogo } from 'src/app/shared/models/freePlLogo';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent {
  isLoading = false;
  isSuccess: boolean;
  formResetToggle = true;
  usernameOrEmail: string;
  logo = new freePlLogo();

  gT = (key: string | Array<string>, interpolateParams?: object) =>
    this.translationService.getTranslation(key, interpolateParams);

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private accountService: AccountService
  ) {}

  showErrorAlert(caption: string, message: string) {
    if (caption) {
      caption = this.gT(caption);
    }

    if (message) {
      message = this.gT(message);
    }

    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  recoverPassword() {
    this.isLoading = true;  
    this.accountService.recoverPassword(this.usernameOrEmail).subscribe(
      () => {
        this.alertService.stopLoadingMessage();
        this.isLoading = false;
        this.isSuccess = true; 
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.isLoading = false;
        this.isSuccess = false;

        const errorMessage =
          Utilities.findHttpResponseMessage(
            Utilities.noNetworkMessageCaption,
            error
          ) ||
          Utilities.findHttpResponseMessage(
            Utilities.noNetworkMessageCaption,
            error
          ) ||
          Utilities.findHttpResponseMessage('error_description', error) ||
          Utilities.findHttpResponseMessage('error', error);

        if (errorMessage) {
          this.alertService.showStickyMessage(
            this.gT('recoverPassword.alerts.PasswordRecoveryFailed'),
            errorMessage,
            MessageSeverity.error,
            error
          );
        } else {
          this.alertService.showStickyMessage(
            this.gT('recoverPassword.alerts.PasswordRecoveryFailed'),
            this.gT('recoverPassword.alerts.PasswordRecoveryErrorOccured', {
              error: Utilities.getResponseBody(error)
            }),
            MessageSeverity.error,
            error
          );
        }
      }
    );
  }


}
