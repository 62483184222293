import { Pipe, PipeTransform } from '@angular/core';
import { MonthsNames } from 'src/app/shared/models/enums/months-names.enum';

@Pipe({
  name: 'monthNameByNumber'
})
export class MonthNameByNumberPipe implements PipeTransform {
  monthsNames: MonthsNames[] = [
    MonthsNames.JAN,
    MonthsNames.FEB,
    MonthsNames.MAR,
    MonthsNames.APR,
    MonthsNames.MAY,
    MonthsNames.JUN,
    MonthsNames.JUL,
    MonthsNames.AUG,
    MonthsNames.SEP,
    MonthsNames.OCT,
    MonthsNames.NOV,
    MonthsNames.DEC
  ];

  transform(value: number): string {
    if (value && value > 0 && value < 13) {
      return this.monthsNames[value - 1].toString();
    }
    return value.toString();
  }
}
