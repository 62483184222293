import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApprovalActivity } from '../models/approval-activity.model';
import { ApprovalActivityLookups } from '../models/approval-activity-lookups.model';
import { ApprovalActivityTransition } from '../models/approval-activity-transition.model';

@Injectable({
  providedIn: 'root'
})
export class ApprovalActivityService {
  baseURL = environment.apiUrl + 'ApprovalActivity';

  constructor(private http: HttpClient) {}

  getAll(): Observable<ApprovalActivity[]> {
    return this.http.get<ApprovalActivity[]>(`${this.baseURL}`);
  }

  getLookups(): Observable<ApprovalActivityLookups> {
    return this.http.get<ApprovalActivityLookups>(`${this.baseURL}/Lookups`);
  }

  getById(id: number): Observable<ApprovalActivity> {
    return this.http.get<ApprovalActivity>(`${this.baseURL}/${id}`);
  }

  deleteApprovalActivity(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseURL}/${id}`);
  }

  addNewApprovalActivity(
    approvalActivity: ApprovalActivity
  ): Observable<ApprovalActivity> {
    return this.http.post<ApprovalActivity>(
      `${this.baseURL}`,
      approvalActivity
    );
  }

  updateApprovalActivity(
    id: number,
    approvalActivity: ApprovalActivity
  ): Observable<ApprovalActivity> {
    return this.http.put<ApprovalActivity>(
      `${this.baseURL}/${id}`,
      approvalActivity
    );
  }

  getAllActivitiesWithTransitions(): Observable<ApprovalActivityTransition[]> {
    return this.http.get<ApprovalActivityTransition[]>(
      `${this.baseURL}/Transition`
    );
  }

  getWithTransitionById(id: number): Observable<ApprovalActivityTransition> {
    return this.http.get<ApprovalActivityTransition>(
      `${this.baseURL}/${id}/Transition`
    );
  }

  getWithTransitionByName(name: string): Observable<ApprovalActivityTransition> {
    return this.http.get<ApprovalActivityTransition>(
      `${this.baseURL}/${name}/TransitionByName`
    );
  }

  addNewTransition(
    id: number,
    nextIds: number[]
  ): Observable<ApprovalActivityTransition> {
    return this.http.post<ApprovalActivityTransition>(
      `${this.baseURL}/${id}/Transition`,
      nextIds
    );
  }

  deleteTransition(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseURL}/${id}/Transition`);
  }

  updateTransition(
    currentId: number,
    nextIds: number[]
  ): Observable<ApprovalActivityTransition> {
    return this.http.put<ApprovalActivityTransition>(
      `${this.baseURL}/${currentId}/Transition`,
      nextIds
    );
  }
}
