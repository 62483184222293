<div class="row ml-0">
  <p class="section-subtitle pl-2">
    {{ sectionSubTitle }}<span class="required-symbol">*</span>
  </p>
</div>
<div class="row container-details w-100" [formGroup]="containerDetailsForm">
  <app-check-selection-card
    class="col-lg-4 col-sm-8 mb-3"
    [title]="containerTitle.container20Title"
    [text]="'25,000 kg max'"
    [icon]="'container20'"
    (cardSelectedEmitter)="
      onContainerTypeSelection(containerTypeEnum.Container20, $event)
    "
    [hasError]="
      containerDetailsForm?.get('has20Container')?.touched &&
      containerDetailsForm?.get('has20Container')?.hasError('required')
    "
    [isSelected]="containerDetailsForm?.get('has20Container').value"
  ></app-check-selection-card>
  <app-check-selection-card
    class="col-lg-4 col-sm-8 mb-3"
    [title]="containerTitle.container40Title"
    [text]="'27,600 kg max'"
    [icon]="'container40'"
    (cardSelectedEmitter)="
      onContainerTypeSelection(containerTypeEnum.Container40, $event)
    "
    [hasError]="
      containerDetailsForm?.get('has40Container')?.touched &&
      containerDetailsForm?.get('has40Container')?.hasError('required')
    "
    [isSelected]="containerDetailsForm?.get('has40Container').value"
  ></app-check-selection-card>
  <app-check-selection-card
    class="col-lg-4 col-sm-8 mb-3"
    [title]="containerTitle.container40HcTitle"
    [text]="'28,560 kg max'"
    [icon]="'container40hc'"
    (cardSelectedEmitter)="
      onContainerTypeSelection(containerTypeEnum.Container40hc, $event)
    "
    [hasError]="
      containerDetailsForm?.get('has40HCContainer')?.touched &&
      containerDetailsForm?.get('has40HCContainer')?.hasError('required')
    "
    [isSelected]="containerDetailsForm?.get('has40HCContainer').value"
  ></app-check-selection-card>

  <div
    class="mx-3 mt-5 container-details-form w-100"
    *ngIf="
      isContainerSelected('has20Container') ||
      isContainerSelected('has40Container') ||
      isContainerSelected('has40HCContainer')
    "
  >
    <div class="px-3 row w-100">
      <app-container-details-form-1
        *ngIf="isContainerSelected('has20Container')"
        class="col-lg-12 col-sm-12 py-4"
        [form]="containerDetailsForm?.get('containers_20')"
        [title]="containerTitle.container20Title"
        [data]="containers20Data"
      ></app-container-details-form-1>

      <div
        class="container separator"
        *ngIf="
          isContainerSelected('has20Container') &&
          (isContainerSelected('has40Container') ||
            isContainerSelected('has40HCContainer'))
        "
      >
        <hr />
      </div>

      <app-container-details-form-1
        *ngIf="isContainerSelected('has40Container')"
        class="col-lg-12 col-sm-12 py-4"
        [form]="containerDetailsForm?.get('containers_40')"
        [title]="containerTitle.container40Title"
        [data]="containers40Data"
      ></app-container-details-form-1>

      <div
        class="container separator"
        *ngIf="
          isContainerSelected('has40Container') &&
          isContainerSelected('has40HCContainer')
        "
      >
        <hr />
      </div>

      <app-container-details-form-1
        *ngIf="isContainerSelected('has40HCContainer')"
        class="col-lg-12 col-sm-12 py-4"
        [form]="containerDetailsForm?.get('containers_40_HC')"
        [title]="containerTitle.container40HcTitle"
        [data]="containers40HCData"
      ></app-container-details-form-1>
    </div>
  </div>
</div>
