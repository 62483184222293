import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChargeTypeWithService } from '../models/charge-type-with-service.model';

@Injectable({
  providedIn: 'root'
})
export class ChargeTypeService {
  baseURL = environment.apiUrl + 'ChargeType';

  constructor(private http: HttpClient) {}

  getAllChargeTypes(): Observable<ChargeTypeWithService[]> {
    return this.http.get<ChargeTypeWithService[]>(this.baseURL);
  }

  getAllWithServices(): Observable<ChargeTypeWithService[]> {
    return this.http.get<ChargeTypeWithService[]>(this.baseURL + '/services');
  }

  getById(id: number): Observable<ChargeTypeWithService> {
    return this.http.get<ChargeTypeWithService>(this.baseURL + '/' + id);
  }

  addNewChargeType(
    chargeType: ChargeTypeWithService
  ): Observable<ChargeTypeWithService> {
    return this.http.post<ChargeTypeWithService>(this.baseURL, chargeType);
  }

  deleteChargeType(id: string): Observable<ChargeTypeWithService> {
    return this.http.delete<ChargeTypeWithService>(this.baseURL + '/' + id);
  }

  updateChargeType(
    id: number,
    chargeType: ChargeTypeWithService
  ): Observable<ChargeTypeWithService> {
    return this.http.put<ChargeTypeWithService>(
      this.baseURL + '/' + id,
      chargeType
    );
  }
}
