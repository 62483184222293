<div class="row bulk-replace-section w-100">
  <div class="section-title field-label">{{ title }}</div>
  <div class="subtitle">
    {{ subTitle }}
  </div>
  <div class="product-container mx-0 w-100 row">
    <app-checkbox
      class="check-box"
      [checked]="true"
      [classes]="productSelectionType"
      [preventDefaultAction]="true"
      name="chk-products"
    ></app-checkbox>
    Products
  </div>
  <div class="row mx-0 ml-4">
    <div class="col-1"></div>
    <div class="col-10">
      <div *ngFor="let product of products" class="row product-container">
        <app-checkbox
          class="check-box"
          [disabled]="product.productId === parentId"
          [checked]="isChecked(product)"
          (click)="changeChildValue(product)"
          name="chk-productItem"
        ></app-checkbox>
        {{ product.articleNumber }}
      </div>
    </div>
  </div>
</div>
