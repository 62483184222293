import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProductCategory } from 'src/app/shared/models/product-category.model';
import { ProductCategoryService } from '../services/product-category.service';

@Injectable()
export class ProductCategoryResolver implements Resolve<ProductCategory> {
  constructor(public productCategoryService: ProductCategoryService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ProductCategory> {
    let id = JSON.parse(route.params.id) as number;
    return this.productCategoryService.getById(id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
