import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-leaving-page-notification',
  templateUrl: './leaving-page-notification.component.html',
  styleUrls: ['./leaving-page-notification.component.scss']
})
export class LeavingPageNotificationComponent implements OnInit {
  @Input() message: string = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    if (this.data['message']) {
      this.message = this.data['message'];
    }
  }
}
