import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Carrier } from '../models/carrier.model';
import { CarrierService } from '../../admin-portal-configs/services/carrier.service';

@Injectable({
  providedIn: 'root'
})
export class CarrierListResolver implements Resolve<Carrier[]> {
  constructor(private carrierService: CarrierService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Carrier[]> {
    return this.carrierService.getAll().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
