import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { QuoteBase } from 'src/app/shared/quote/models/quote-base.model';
import { QuoteServices } from 'src/app/shared/quote/models/quote-services.model';
import { QuoteStatus } from '../../enums/quote-status.enum';
import { Utilities } from 'src/app/shared/services/utilities';
import { CreateQuoteHelperService } from 'src/app/create-quote/services/create-quote-helper.service';
import { ExpirationDate } from 'src/app/shared/models/expiration-date.model';
import { Charge } from 'src/app/shared/models/charge.model';
import { QuoteProposalDetails } from 'src/app/shared/quote/models/quote-proposal-details.model';
import { QuoteRates } from 'src/app/shared/quote/models/quote-rates.model';
import { ShipmentFreightType } from '../../../shared/models/enums/shipment-freight-type.enum';

@Component({
  selector: 'app-quote-card',
  templateUrl: './quote-card.component.html',
  styleUrls: ['./quote-card.component.scss']
})
export class QuoteCardComponent implements OnInit, AfterViewChecked {
  @Input() quote: QuoteBase;
  @Input() isAllSelected: boolean;

  expiration: ExpirationDate;

  constructor(
    private router: Router,
    private createQuoteHelperService: CreateQuoteHelperService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  get statusMessage(): string {
    let statusName = this.quote?.status?.name;
    let message = '';
    switch (statusName) {
      case QuoteStatus.Requested: {
        let currentDate = new Date().getDate();
        let differenceDays =
          currentDate - new Date(this.quote?.submittedDatetime)?.getDate();
        if (differenceDays >= 2) {
          message = 'Expected to be issued today.';
        } else if (differenceDays >= 1) {
          message = 'Expected to be issued within 1 working day(s).';
        } else {
          message = 'Expected to be issued within 2 working day(s).';
        }
        break;
      }
      case QuoteStatus.New: {
        message = QuoteStatus.New;
        break;
      }

      case QuoteStatus.ProposalInProgress: {
        message = QuoteStatus.ProposalInProgress;
        break;
      }
      case QuoteStatus.PendingApproval: {
        message = this.expirationDateFormat;
        break;
      }
      case QuoteStatus.Approved: {
        message = QuoteStatus.Approved;
        break;
      }
      case QuoteStatus.Rejected: {
        message = QuoteStatus.Rejected;
        break;
      }
      case QuoteStatus.Cancelled: {
        message = QuoteStatus.Cancelled;
        break;
      }
      default: {
        break;
      }
    }

    return message;
  }

  get quoteServices(): QuoteServices {
    return this.quote?.quoteServices;
  }

  get latestQuoteRates(): QuoteRates {
    return this.latestQuotePropsalSubmitted?.quoteRates;
  }

  get servicesIcons(): string[] {
    let servicesIcons = [];
    let customClearanceIcon = this.isInDashboard
      ? 'custom-clearance-icon-dashboard'
      : 'custom-clearance-icon';
    let trackingTruckingIcon = this.isInDashboard
      ? 'tracking-truck-icon-dashboard'
      : 'trackingTruckIcon';
    let planeIcon = this.isInDashboard
      ? 'blue-plane-icon-dashboard'
      : 'bluePlaneIcon';
    let trackingShipIcon = this.isInDashboard
      ? 'tracking-ship-icon-dashboard'
      : 'trackingShipIcon';
    let iorEorServiceIcon = this.isInDashboard
      ? 'ior-eor-service-icon-dashboard'
      : 'ior-eor-service-icon';
    let landTruckingIcon = this.isInDashboard
      ? 'dashboard-land-truck'
      : 'land-truck';
    if (
      !this.olderVersionsProposalDetailsLength &&
      !this.hasProposalSubmissionDetails
    ) {
      if (this.quoteServices?.customsClearance?.isSelected) {
        servicesIcons.push(customClearanceIcon);
      }
      if (this.quoteServices?.trucking) {
        servicesIcons.push(trackingTruckingIcon);
      }
      if (this.quoteServices?.freight?.isLand) {
        servicesIcons.push(landTruckingIcon);
      }
      if (this.quoteServices?.freight?.isAir) {
        servicesIcons.push(planeIcon);
      }
      if (this.quoteServices?.freight?.isOcean) {
        servicesIcons.push(trackingShipIcon);
      }
      if (this.quoteServices?.importExport) {
        servicesIcons.push(iorEorServiceIcon);
      }
    } else {
      if (this.latestQuoteRates?.isSelectedCustomsClearanceRate) {
        servicesIcons.push(customClearanceIcon);
      }
      if (
        this.latestQuoteRates?.isSelectedTruckingRate ||
        this.latestQuoteRates?.isSelectedDomesticTruckingRate
      ) {
        servicesIcons.push(trackingTruckingIcon);
      }
      if (
        (this.freightReference == ShipmentFreightType.Air ||
          this.createQuoteHelperService.isAirAndOcean(this.freightReference)) &&
        this.latestQuoteRates?.isSelectedFreightRate
      ) {
        servicesIcons.push(planeIcon);
      }
      if (
        (this.freightReference == ShipmentFreightType.Ocean ||
          this.createQuoteHelperService.isAirAndOcean(this.freightReference)) &&
        this.latestQuoteRates?.isSelectedFreightRate
      ) {
        servicesIcons.push(trackingShipIcon);
      }
      if (this.latestQuoteRates?.isSelectedIorEorRateRate) {
        servicesIcons.push(iorEorServiceIcon);
      }
    }

    return servicesIcons;
  }

  get isQuotesListPage(): boolean {
    return !(
      this.router.url.includes('details') ||
      this.router.url.includes('create-quote-proposal') ||
      this.router.url.includes('dashboard')
    );
  }

  get isInDashboard(): boolean {
    return this.router.url.includes('dashboard');
  }

  calculateExpiration(): string {
    if (!this.submitationDate) {
      return '';
    }

    let expiryDate = this.createQuoteHelperService.getExpiryDate(
      this.latestQuotePropsalSubmitted
    );
    if (!expiryDate) {
      return 'Depends on freight selection';
    }
    const submittedDateObj = Utilities.parseDate(expiryDate);
    const expirationDate = new Date(submittedDateObj);
    let startDate = new Date();

    this.expiration = Utilities.expirationDate(startDate, expirationDate);
    if (
      this.expiration.hoursRemaining <= 24 &&
      this.expiration.hoursRemaining > 0
    ) {
      return `Expires in ${this.expiration.hoursRemaining} hours`;
    }
    if (this.expiration.hoursRemaining > 24) {
      return `Expires in ${this.expiration.daysRemaining} days`;
    }
    if (this.expiration.hoursRemaining <= 0) {
      return `Expired`;
    }
  }
  get isPendingApprovalStatus(): boolean {
    return this.createQuoteHelperService.isPendingApprovalQuoteStatus(
      this.quote
    );
  }

  selectOrDeselectQuote(event): void {
    event.stopPropagation();
    const isChecked: boolean = event.target.checked;
    if (isChecked) {
      if (!this.isAllSelected) {
        this.createQuoteHelperService.addToSelectedQuoteList(
          this.quote?.quoteNumber
        );
      } else {
        this.createQuoteHelperService.removeFromSelectedQuotesList(
          this.quote?.quoteNumber
        );
      }
    } else {
      if (!this.isAllSelected) {
        this.createQuoteHelperService.removeFromSelectedQuotesList(
          this.quote?.quoteNumber
        );
      } else {
        this.createQuoteHelperService.addToSelectedQuoteList(
          this.quote?.quoteNumber
        );
      }
    }
  }

  isQuoteInList(): boolean {
    let isSelected: boolean = this.createQuoteHelperService.isQuoteInSelectedList(
      this.quote?.quoteNumber
    );
    return this.isAllSelected ? !isSelected : isSelected;
  }

  get hasProposalSubmissionDetails(): boolean {
    return this.createQuoteHelperService.hasProposalSubmissionDetails(
      this.quote
    );
  }

  get freightReference(): string {
    return this.createQuoteHelperService.getFreightReference(this.quote);
  }

  get olderVersionsProposalDetailsLength(): number {
    return this.createQuoteHelperService.getOlderVersionsProposalDetailsLength(
      this.quote
    );
  }

  get islessThanOneDay(): boolean {
    return this.expiration?.hoursRemaining <= 24;
  }

  get hasMultipleFreightOrCustomcClearanceRoutes(): boolean {
    return this.createQuoteHelperService.hasMultipleFreightOrCustomcClearanceRoutes(
      this.latestQuotePropsalSubmitted
    );
  }

  get quoteTotalValue(): Charge[] {
    return this.createQuoteHelperService?.quoteTotalValue(
      this.quote,
      this.latestQuotePropsalSubmitted
    );
  }

  get submitationDate(): Date {
    return this.quote?.quoteProposalDetails?.proposalSubmissionDetails
      ?.submittedAt;
  }

  get expirationDateFormat(): string {
    if (this.quote?.status?.name == QuoteStatus.PendingApproval) {
      return this.calculateExpiration();
    }
  }

  get isRejected(): boolean {
    return this.createQuoteHelperService.isRejectedQuoteStatus(this.quote);
  }
  get rejectedDate(): Date {
    return this.createQuoteHelperService.getRejectedDate(this.quote);
  }

  get isApproved(): boolean {
    return this.createQuoteHelperService.isApprovedQuoteStatus(this.quote);
  }

  get approvedDate(): Date {
    return this.createQuoteHelperService.getApprovedDate(this.quote);
  }

  get isNewVersionSubmitted(): boolean {
    return !this.createQuoteHelperService?.isNewVersionInPropgress(this.quote);
  }

  get latestQuotePropsalSubmitted(): QuoteProposalDetails {
    return this.createQuoteHelperService.getLatestQuotePropsalSubmitted(
      this.quote,
      this.isNewVersionSubmitted
    );
  }

  get isFreightRouteSelected(): boolean {
    return this.createQuoteHelperService.isFreightRouteSelected(
      this.quote.quoteProposalDetails
    );
  }
}
