<div class="delete-popup-container">
  <svg-icon name="removeDocument"></svg-icon>
  <div class="content">
    <p class="delete-content">
      {{ popUpTitleMessage }}
    </p>
    <p class="confirmation-warning-text">
      {{ popUpDescriptionMessage }}
    </p>
  </div>
  <div class="btns-container">
    <button class="btn cancel-btn" (click)="confirm(false)">
      {{ cancelButtonTitleMessage }}
    </button>
    <button class="btn delete-btn" (click)="confirm(true)">
      {{ deleteButtonMessageText }}
    </button>
  </div>
</div>
