<div
  [ngClass]="{ 'disabled-input': isDisabled }"
  [classList]="
    'quantity-input-con d-flex align-items-center justify-content-between default-border ' +
    classList
  "
>
  <div (click)="onRemove()" class="px-1 cursor-pointer position-relative">
    <span
      style="font-size: smaller"
      class="plus-minus-operator"
      [ngClass]="{ disabled: quantity === 1 || isDisabled }"
      ><i class="fa fa-minus"></i
    ></span>
  </div>
  <input
    [mask]="digitsOnly ? 'd*' : ''"
    [type]="digitsOnly ? 'text' : 'number'"
    [ngModel]="quantity"
    (ngModelChange)="onChange($event)"
    ngDefaultControl
    style="width: 100%"
    [placeholder]="placeholder ? placeholder : ''"
    [attr.disabled]="isDisabled ? '' : null"
  />

  <div (click)="onAdd()" class="px-1 cursor-pointer position-relative">
    <span class="plus-minus-operator" [ngClass]="{ disabled: isDisabled }"
      ><i style="font-size: smaller" class="fa fa-plus"></i
    ></span>
  </div>
</div>
