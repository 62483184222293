import { Component, Input, OnInit } from '@angular/core';
import { ProductInformationSummary } from 'src/app/create-product/enums/product-information-summary';
import { ProductHelperService } from 'src/app/create-product/services/product-helper.service';
import { ProductBase } from '../../models/product-library/product-base.model';
import { ProductCategoryEnum } from 'src/app/create-product/enums/product-category.enum';
import { MedicalProductInformationSummary } from 'src/app/create-product/enums/medical-product-infromation-summary.enum';
import { NonMedicalProductInformationSummary } from 'src/app/create-product/enums/non-medical-product-information-summary.enum';
import { ProductVariant } from 'src/app/create-product/models/product-variant.model';

@Component({
  selector: 'app-product-information-summary',
  templateUrl: './product-information-summary.component.html',
  styleUrls: ['./product-information-summary.component.scss']
})
export class ProductInformationSummaryComponent implements OnInit {
  @Input() product: ProductBase;
  productInformationSummary = ProductInformationSummary;
  medicalProductInformationSummary = MedicalProductInformationSummary;
  nonMedicalProductInformationSummary = NonMedicalProductInformationSummary;

  constructor(private productHelperService: ProductHelperService) {}

  ngOnInit() {}

  getEnumKeysForProductInformation() {
    return this.isHealthCareOrPharmaceuticalsCategory
      ? Object.keys(MedicalProductInformationSummary)
      : Object.keys(NonMedicalProductInformationSummary);
  }

  getEnumKeys(enumName): string[] {
    return Object.keys(enumName);
  }

  getEnumValue(enumName, key: string): string {
    return enumName[key];
  }

  getProductInformationSummaryContent(
    columnName: ProductInformationSummary
  ): string {
    switch (columnName) {
      case ProductInformationSummary.ProductName:
        return this.productHelperService?.getProductName(this.product);

      case ProductInformationSummary.ProductDescription:
        return this.productHelperService?.getProductDescription(this.product);

      case ProductInformationSummary.ProductCategory:
        return this.productHelperService?.getProductCategory(this.product);

      case ProductInformationSummary.ActualManufacturer:
        return this.productHelperService?.getActualManufacturer(this.product);

      case ProductInformationSummary.LegalManufacturer:
        return this.productHelperService?.getLegalManufacturer(this.product);

      case ProductInformationSummary.ArticleNumber:
        return this.productHelperService?.getArticleNumber(this.product);

      case ProductInformationSummary.GTIN:
        return this.productHelperService?.getGTIN(this.product);

      case ProductInformationSummary.HSCode:
        return this.productHelperService?.getHSCode(this.product);

      case ProductInformationSummary.COO:
        return this.productHelperService?.getCOO(this.product);

      case ProductInformationSummary.DangerousGoods:
        return this.productHelperService?.getDangerousGoods(this.product);

      case ProductInformationSummary.Variants:
        return this.productHelperService?.getProductVariants(this.product);

      default:
        return '';
    }
  }

  get isHealthCareOrPharmaceuticalsCategory(): boolean {
    return (
      this.productCategory === ProductCategoryEnum.Healthcare ||
      this.productCategory === ProductCategoryEnum.Pharmaceuticals
    );
  }

  get productCategory(): string {
    return this.productHelperService.getProductCategory(this.product);
  }

  hasVariants() {
     return this.productHelperService.getProductVariants(this.product) === 'Yes'
  }

  get variants(): ProductVariant[] {
    return this.productHelperService.getProductVariantsData(this.product);
  }
}
