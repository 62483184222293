<div [classList]="'default-border  ' + classList">
    <input
      type="text"
      (ngModelChange)="onChange($event)"
      ngDefaultControl
      [(ngModel)]="value" 
      appThousandSeparator
      [disabled]="disabled"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [maxlength]="maxLength"
      />

</div>