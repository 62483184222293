<div
  class="company-details-tabs"
  [ngClass]="isCustomerModule ? 'customer-company-details-tabs' : ''"
>
  <div class="tabs-wrapper">
    <a
      *ngIf="isCustomerModule"
      class="lnk-back-to-customers"
      routerLink="/customers-profile"
    >
      <svg-icon name="black-arrow-left" class="back-arrow-icon"></svg-icon>All
      Customers
    </a>
    <div class="logo-title-container" [ngClass]="company?.logo ? 'row' : ''">
      <div class="logo-container" *ngIf="company?.logo">
        <img class="company-logo" [src]="company?.logo" />
      </div>
      <h1 class="company-title m-0">
        {{ company?.name }}
      </h1>
    </div>

    <div class="tabs" *ngFor="let tab of companyTabsWithIcons">
      <div class="row mx-0" (click)="expandSubmenu(tab)" *ngIf="tab?.canView">
        <svg-icon [name]="tab.icon" class="icon"></svg-icon>
        <div
          class="pl-1 tab"
          [ngClass]="isSelectedTab(tab.name) ? 'selected-tab' : ''"
          (click)="navigateToPage(tab.name)"
        >
          {{ tab.name }}
        </div>
        <svg-icon
          class="expand-icon"
          *ngIf="tab?.subTabs?.length > 0"
          name="black-arrow-down"
        ></svg-icon>
      </div>

      <div class="sub-menu" *ngIf="tab.isExpanded">
        <div class="sub-menu-item" *ngFor="let subTab of tab?.subTabs">
          <span class="black-line"></span
          ><a
            class="sub-menu-title"
            [class]="!subTab.isActive ? 'disabled' : ''"
            [routerLink]="[getSubTabUrl(subTab?.id, subTab.name, subTab.type)]"
            [queryParams]="{ serviceOrder: subTab.serviceId }"
            [ngClass]="
              isSelectedSubTab(tab.name, subTab.name) ? 'selected-sub-tab' : ''
            "
            >{{ subTab.name }}
          </a>
          <svg-icon
            *ngIf="!subTab.isActive"
            class="align-text-bottom"
            name="small-alert-Mark"
            [ngbPopover]="subTab.errorMessage"
            placement="right"
            triggers="mouseenter:mouseleave"
          ></svg-icon>
        </div>
      </div>
    </div>
  </div>
</div>
