<div class="row">
  <a
    class="nav-link col-lg-3 col-sm-6 col-md-4"
    *ngFor="let item of itemsList"
    [routerLink]="toRoute(item)"
    routerLinkActive="active"
  >
    <div class="card text-center border border-primary shadow-0 ">
      <div class="card-body">
        <h5 class="card-title">{{ item }}</h5>
      </div>
    </div>
  </a>
</div>
