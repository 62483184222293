import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Industry } from 'src/app/shared/models/industry.model';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service'; 
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { IndustryService } from '../../../services/industry.service';

@Component({
  selector: 'app-add-or-edit-industry',
  templateUrl: './add-or-edit-industry.component.html',
  styleUrls: ['./add-or-edit-industry.component.scss']
})
export class AddOrEditIndustryComponent implements OnInit {
  industryForm: UntypedFormGroup;
  industry: Industry;
  isSaving: boolean = false;
  duplicateIndustry: boolean = false;
  isNewIndustry: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private industryService: IndustryService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.industry = data['industry'];
    });

    this.initForm();
  }

  initForm() {
    this.isNewIndustry = this.industry == null;
    this.industry = this.industry || ({} as Industry);

    this.industryForm = this.fb.group({
      name: [this.industry.name, [Validators.required]]
    });
  }

  save() {
    const formVal = this.industryForm.value;

    if (!this.industryForm.valid) {
      this.alertService.error('Invalid Form!');
      return;
    }

    if (this.isNewIndustry) {
      this.addNewRequest(formVal);
    } else {
      this.updateRequest(formVal);
    }
  }

  updateRequest(formVal: any) {
    this.industryService.updateIndustry(this.industry.id, formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  addNewRequest(formVal: any) {
    this.industryService.addNewIndustry(formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  private saveSuccessHelper(industry?: Industry) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    if (this.isNewIndustry) {
      this.alertService.showMessage(
        'Success',
        `Industry \"${industry.name}\" was created successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to industry \"${industry.name}\" were saved successfully`,
        MessageSeverity.success
      );
    }

    this.resetForm();
    this.router.navigate(['/settings/industries']);
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    if (error == '409') {
      this.duplicateIndustry = true;
      return;
    }
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occured whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  cancel() {
    this.resetForm();

    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/industries']);
  }

  resetForm() {
    this.industryForm.reset();
  }
}
