export const dangerousMaterialClasses : any[] = [
    {
      name: 'Explosives',
      id: 1,
      iconName: 'dangerous-material-class-explosives'
    },
    {
      name: 'Gases',
      id: 2,
      iconName: 'dangerous-material-class-gases'
    },
    {
      name: 'Flammable Liquids',
      id: 3,
      iconName: 'dangerous-material-class-liquid'
    },
    {
      name: 'Flammable Solids',
      id: 4,
      iconName: 'dangerous-material-class-solid'
    },
    {
      name: 'Oxidizers & Peroxides',
      id: 5,
      iconName: 'dangerous-material-class-oxidising'
    },
    {
      name: 'Toxic Substances',
      id: 6,
      iconName: 'dangerous-material-class-toxins'
    },
    {
      name: 'Radioactive Material',
      id: 7,
      iconName: 'dangerous-material-class-radioactive'
    },
    {
      name: 'Corrosive Substances',
      id: 8,
      iconName: 'dangerous-material-class-corrosive'
    },
    {
      name: 'Miscellaneous Substances',
      id: 9,
      iconName: 'dangerous-material-class-miscellaneous'
    }
  ];