<div class="row w-100 ml-0">
  <div
    class="title px-1"
    [ngClass]="{
      'col-1': moduleName == module.Supplier,
      'col-2': moduleName == module.CompanyProfile
    }"
  >
    No.
  </div>
  <div
    class="col-2 title"
    [ngClass]="{
      'pl-0': moduleName == module.CompanyProfile
    }"
  >
    Load Type
  </div>
  <div
    class="title"
    [ngClass]="{
      'col-3': moduleName == module.Supplier,
      'col-4 pl-0': moduleName == module.CompanyProfile
    }"
  >
    Truck Type
  </div>
  <div
    class="title"
    [ngClass]="{
      'col-3': moduleName == module.Supplier,
      'col-4 pl-0': moduleName == module.CompanyProfile
    }"
  >
    Truck Sub-type
  </div>
  <div class="title col-3" *ngIf="moduleName == module.Supplier">
    Transport Price
  </div>
</div>

<ng-container *ngIf="moduleName == module.Supplier">
  <div
    class="row w-100 ml-0"
    *ngFor="let truckDetail of excludeDeletedTruckDetails; let index = index"
    [class]="index > 0 ? 'row-top-margin' : ''"
  >
    <div class="value col-1 px-1">
      <strong>{{ index + 1 }}</strong>
    </div>
    <div class="value col-2">{{ truckDetail?.loadType }}</div>
    <div class="value col-3">{{ truckDetail?.truckType ?? '-' }}</div>
    <div class="value col-3">{{ truckDetail?.truckSubType ?? '-' }}</div>
    <div class="value col-3">
      <strong>{{ truckDetail?.transportPrice }}</strong>
      {{ truckDetail?.currencyCode }}
    </div>
  </div>
</ng-container>

<ng-container *ngIf="moduleName == module.CompanyProfile">
  <div class="row w-100 ml-0">
    <div class="value col-2 px-1">
      <strong>{{ 1 }}</strong>
    </div>
    <div
      class="value col-2"
      [ngClass]="{
        'pl-0': moduleName == module.CompanyProfile
      }"
    >
      {{ loadType?.name }}
    </div>
    <div
      class="value"
      [ngClass]="{
        'col-3': moduleName == module.Supplier,
        'col-4 pl-0': moduleName == module.CompanyProfile
      }"
    >
      {{ truckType?.name ?? '-' }}
    </div>
    <div
      class="value"
      [ngClass]="{
        'col-3': moduleName == module.Supplier,
        'col-4 pl-0': moduleName == module.CompanyProfile
      }"
    >
      {{ truckSubType?.name ?? '-' }}
    </div>
  </div>
</ng-container>
