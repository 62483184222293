import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  FormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { CompanyService } from 'src/app/company/services/company.service';
import { FreePLEmployee } from 'src/app/shared/models/freePL-employee.model';
import { CompanyIdAndName } from 'src/app/shared/models/company-id-and-name.model';
import { HelperService } from 'src/app/shared/services/helper.service';
import { ShipmentActorsViewFilterData } from '../../models/shipment-actors-view-filter-data.model';
import { ShipmentActorsViewFilter } from '../../models/shipment-actors-view-filter.model';

@Component({
  selector: 'app-shipment-actors-view-filter',
  templateUrl: './shipment-actors-view-filter.component.html',
  styleUrls: ['./shipment-actors-view-filter.component.scss']
})
export class ShipmentActorsViewFilterComponent implements OnInit, OnChanges {
  filtersForm: UntypedFormGroup;

  customers: CompanyIdAndName[];
  accountManagers: FreePLEmployee[];
  shipmentSubmitters: FreePLEmployee[];

  isNoCustomersAvailable: boolean = false;
  isNoAccountManagersAvailable: boolean = false;
  isNoShipmentSubmittersAvailable: boolean = false;

  isCustomersLoading: boolean = false;
  isAccountManagersLoading: boolean = false;
  isShipmentSubmittersLoading: boolean = false;

  @Input() shipmentActorsViewFilterData: ShipmentActorsViewFilterData;

  @Output() onSelectionChange = new EventEmitter<ShipmentActorsViewFilter>();
  @Input() isClearCustomFilters: boolean;

  selectedCustomers: number[] = [];
  selectedAccountManagers: FreePLEmployee[] = [];
  selectedShipmentSubmitters: FreePLEmployee[] = [];

  constructor(
    private fb: FormBuilder,
    private helperService: HelperService,
    private companyService: CompanyService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isClearCustomFilters) {
      this.clearFilters();
      this.isClearCustomFilters = false;
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.listenOnSearchBox();
    this.initData();
  }

  initForm() {
    this.filtersForm = this.fb.group({
      customerNameOrEmail: '',
      accountManagerNameOrEmail: '',
      shipmentSubmitternameOrEmail: ''
    });
  }

  clearFilters() {
    this.accountManagers = [];
    this.selectedAccountManagers = [];

    this.customers = [];
    this.selectedCustomers = [];

    this.shipmentSubmitters = [];
    this.selectedShipmentSubmitters = [];

    this.selectionChanged();
    this.initForm();
    this.listenOnSearchBox();
  }

  initData() {
    let selectedAccountManagersIds = this.shipmentActorsViewFilterData
      ?.accountManagerIds;
    if (selectedAccountManagersIds?.length > 0) {
      this.companyService
        .getAccountManagersById(selectedAccountManagersIds?.map(Number))
        .subscribe(res => {
          this.accountManagers = res;
          this.selectedAccountManagers = this.accountManagers;
          this.initFormOnSearchChange(this.accountManagers);
          this.initFormControls(this.selectedAccountManagers);
          this.selectionChanged();
        });
    }

    let selectedCustomerCompaniesIds = this.shipmentActorsViewFilterData
      ?.customerIds;
    if (selectedCustomerCompaniesIds?.length > 0) {
      this.companyService
        .getCustomersById(selectedCustomerCompaniesIds?.map(Number))
        .subscribe(res => {
          this.customers = res;
          this.initFormOnSearchChange(this.customers);
          this.initFormControls(this.customers);
          this.selectedCustomers = this.customers.map(c => c.id);
          this.selectionChanged();
        });
    }

    let selectedShipmentSubmittersIds = this.shipmentActorsViewFilterData
      ?.submitterIds;
    if (selectedShipmentSubmittersIds?.length > 0) {
      this.companyService
        .geShipmentSubmittersById(selectedShipmentSubmittersIds?.map(Number))
        .subscribe(res => {
          this.shipmentSubmitters = res;
          this.selectedShipmentSubmitters = this.shipmentSubmitters;
          this.initFormOnSearchChange(this.shipmentSubmitters);
          this.initFormControls(this.selectedShipmentSubmitters);
          this.selectionChanged();
        });
    }
  }

  initFormControls(data: any[]) {
    data?.forEach(item => {
      let formControlName = item.name + '_' + item.id;
      this.setFormControlValue(formControlName, true);
    });
  }

  initFormOnSearchChange(items: any[]) {
    items?.forEach(filterContent => {
      this.filtersForm.addControl(
        filterContent?.name + '_' + filterContent?.id,
        new UntypedFormControl(false)
      );
    });
  }

  listenOnSearchBox() {
    this.listenOnCustomerName();
    this.listenOnAccountManagerName();
    this.listenOnShipmentSubmitter();
  }

  listenOnCustomerName() {
    this.filtersForm
      .get('customerNameOrEmail')
      .valueChanges.pipe(
        filter(res => {
          return res !== null && res.length >= 3;
        }),
        debounceTime(800),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.isCustomersLoading = true;
        this.helperService.getCompaniesByNameOrEmail(value).subscribe(res => {
          this.isCustomersLoading = false;
          this.customers = res;

          if (res === null || res?.length <= 0) {
            this.isNoCustomersAvailable = true;
            return;
          }

          this.isNoCustomersAvailable = false;
          this.initFormOnSearchChange(this.customers);
        });
      });
  }

  listenOnAccountManagerName() {
    this.filtersForm
      .get('accountManagerNameOrEmail')
      .valueChanges.pipe(
        filter(res => {
          return res !== null && res.length >= 3;
        }),
        debounceTime(800),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.isAccountManagersLoading = true;
        let selecetdCustomerIds = this.getSelectedFilters(
          this.customersFilterName
        );

        this.helperService
          .getAccountManagersByNameOrEmail(value, selecetdCustomerIds)
          .subscribe(res => {
            this.isAccountManagersLoading = false;
            this.accountManagers = res;

            if (res === null || res?.length <= 0) {
              this.isNoAccountManagersAvailable = true;
              return;
            }

            this.isNoAccountManagersAvailable = false;
            this.initFormOnSearchChange(this.accountManagers);
          });
      });
  }

  listenOnShipmentSubmitter() {
    this.filtersForm
      .get('shipmentSubmitternameOrEmail')
      .valueChanges.pipe(
        filter(res => {
          return res !== null && res.length >= 3;
        }),
        debounceTime(800),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.isShipmentSubmittersLoading = true;
        let selecetdCustomerIds = this.getSelectedFilters(
          this.customersFilterName
        );

        this.helperService
          .getShipmentSubmitterByNameOrEmail(value, selecetdCustomerIds)
          .subscribe(res => {
            this.isShipmentSubmittersLoading = false;
            this.shipmentSubmitters = res;

            if (res === null || res?.length <= 0) {
              this.isNoShipmentSubmittersAvailable = true;
              return;
            }

            this.isNoShipmentSubmittersAvailable = false;
            this.initFormOnSearchChange(this.shipmentSubmitters);
          });
      });
  }

  get totalNumberOfSelectedFilters(): number {
    return (
      this.selectedAccountManagers?.length +
      this.selectedCustomers?.length +
      this.selectedShipmentSubmitters?.length
    );
  }

  get customersFilterName(): string[] {
    let filterNames = this.getFilterNames(this.customers);
    return filterNames;
  }

  get accountManagersFilterName(): string[] {
    let filterNames = this.getFilterNames(this.accountManagers);
    return filterNames;
  }

  get shipmentSubmittersFilterName(): string[] {
    let filterNames = this.getFilterNames(this.shipmentSubmitters);
    return filterNames;
  }

  getFilterNames(filters: any[]): string[] {
    let filterNames = [];

    filters?.forEach(filterContent => {
      let filetrName = filterContent?.name + '_' + filterContent?.id;
      filterNames.push(filetrName);
    });

    return filterNames;
  }

  getSelectedFilters(filterContent: string[]): number[] {
    let selectedFilters = [];
    filterContent.forEach(data => {
      if (this.getFormControlValue(data)) {
        let selectedId = this.getSelectedIdFromControlName(data);
        selectedFilters.push(selectedId);
      }
    });
    return selectedFilters;
  }

  getFormControlValue(controlName: string): boolean {
    return this.filtersForm.controls[controlName].value;
  }

  onChangeCustomerSelection(
    formControlName: string,
    selectedFilter: CompanyIdAndName
  ) {
    this.onChangeSelection(
      formControlName,
      selectedFilter?.id,
      this.selectedCustomers,
      'Customer'
    );
    if (!this.selectedCustomers || this.selectedCustomers?.length <= 0) {
      return;
    }

    this.selectedAccountManagers = this.clearConflictingSelections(
      this.selectedAccountManagers
    );
    this.selectedShipmentSubmitters = this.clearConflictingSelections(
      this.selectedShipmentSubmitters
    );

    this.selectionChanged();
  }

  clearConflictingSelections(
    selectedFilters: FreePLEmployee[]
  ): FreePLEmployee[] {
    selectedFilters?.forEach(selectedFilter => {
      let isCompatible = selectedFilter?.customerCompaniesIds?.find(c =>
        this.selectedCustomers.includes(c)
      );

      if (!isCompatible) {
        let formControlName = selectedFilter?.name + '_' + selectedFilter?.id;
        this.setFormControlValue(formControlName, false);
        selectedFilters = selectedFilters.filter(
          a => a.id != selectedFilter?.id
        );
      }
    });

    return selectedFilters;
  }

  setFormControlValue(controlName: string, value: boolean): void {
    this.filtersForm.controls[controlName].patchValue(value);
  }

  onChangeSelection(
    formControlName: string,
    selectedFilter: any,
    selectedFilters: any[],
    filterType: string
  ) {
    let controlValue = this.getFormControlValue(formControlName);

    if (controlValue) {
      selectedFilters = selectedFilters.concat(selectedFilter);
      selectedFilters = [...new Set(selectedFilters)];
    } else {
      selectedFilters = selectedFilters.filter(f => f.id != selectedFilter?.id);
    }

    switch (filterType) {
      case 'Customer':
        this.selectedCustomers = selectedFilters;
        break;
      case 'AccountManager':
        this.selectedAccountManagers = selectedFilters;
        break;
      case 'ShipmentSubmitter':
        this.selectedShipmentSubmitters = selectedFilters;
        break;

      default:
        break;
    }

    this.selectionChanged();
  }

  selectionChanged() {
    let selectedFilters: ShipmentActorsViewFilter = {
      submittedById: this.selectedShipmentSubmitters
        .map(s => s.id)
        ?.map(String),
      customerCompaniesIds: this.selectedCustomerCompaniesIds,
      shipmentActorsViewFilterData: {
        totalNumberOfSelectedFilters: this.totalNumberOfSelectedFilters,
        customerIds: this.selectedCustomers?.map(String),
        accountManagerIds: this.selectedAccountManagers
          ?.map(s => s.id)
          ?.map(String),
        submitterIds: this.selectedShipmentSubmitters
          ?.map(s => s.id)
          ?.map(String)
      }
    };

    this.onSelectionChange.emit(selectedFilters);
  }

  get selectedCustomerCompaniesIds(): string[] {
    let selecetdCustomerIds = [...this.selectedCustomers];

    let hasNoCustomersSelected =
      !selecetdCustomerIds || selecetdCustomerIds?.length <= 0;

    if (
      hasNoCustomersSelected &&
      this.customerCompaniesIdsFromAccountManagers?.length > 0
    ) {
      selecetdCustomerIds = selecetdCustomerIds.concat(
        this.customerCompaniesIdsFromAccountManagers
      );
    }

    return selecetdCustomerIds.map(String);
  }

  get customerCompaniesIdsFromAccountManagers(): number[] {
    let selectedCustomerCompaniesIds: number[] = [];

    this.selectedAccountManagers?.forEach(selectedAccountManager => {
      if (selectedAccountManager?.customerCompaniesIds?.length > 0) {
        selectedCustomerCompaniesIds = selectedCustomerCompaniesIds.concat(
          selectedAccountManager?.customerCompaniesIds
        );
      }
    });

    selectedCustomerCompaniesIds = [...new Set(selectedCustomerCompaniesIds)];
    return selectedCustomerCompaniesIds;
  }

  getSelectedIdFromControlName(formControlName: string): number {
    let startIndexOfId = formControlName?.indexOf('_');
    let selectedId = formControlName?.slice(startIndexOfId + 1);

    return +selectedId;
  }
}
