<div mat-dialog-content class="wrapper">
  <div class="centered-container">
    <svg-icon name="shipmentNowActive"></svg-icon>
  </div>

  <div class="centered-container">
    <span class="title">
      {{updatedEntitiesNumber}} of {{ totalNumber }} {{type}} are Successfully Updated!
    </span>
  </div>

  <div class="centered-container">
    <span class="description col-9">
      Some of the selected {{type}} are not eligible for the requested status
      update.
    </span>
  </div>

  <div class="centered-container mt-5">
    <button
      class="submit-button col-10"
      mat-button
      mat-dialog-close
      (click)="goToListPage()"
    >
      Return to {{type}}
    </button>
  </div>
</div>
