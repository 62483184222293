import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { CustomerServicesHelperService } from 'src/app/customer/services/customer-services-helper.service';
import { Currency } from 'src/app/shared/models/currency.model';
import { FormsHelperService } from '../../services/forms-helper.service';

@Component({
  selector: 'app-pricelist-terms-and-conditionts',
  templateUrl: './pricelist-terms-and-conditionts.component.html',
  styleUrls: ['./pricelist-terms-and-conditionts.component.scss']
})
export class PriceListTermsAndConditiontsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() currencies: Currency[];
  @Input() isForCustomerCompany: boolean = false;

  constructor(
    private customerServicesHelperService: CustomerServicesHelperService,
    private formsHelperService: FormsHelperService
  ) {}

  ngOnInit(): void {
    this.currencies = this.currencies || [];

    if (this.isForCustomerCompany) {
      this.calculateDetentionPriceDifference();
      this.calculateDestinationPriceDifference();
    }
  }

  onChangeSelectedCurrency(selectedCurrency: Currency): void {
    this.patchValueToControl('currencyCode', selectedCurrency?.code);
    this.patchValueToControl('currencyId', selectedCurrency?.id);
  }

  patchValueToControl(formControlName: string, value: any) {
    let control = this.getControl(formControlName);
    control?.patchValue(value);
  }

  getControl(formControlName: string): AbstractControl {
    let control = this.form?.get(formControlName);
    return control;
  }

  calculateDetentionPriceDifference() {
    let detentionPriceAfterFreeHours = this.getFormControlValue(
      'detentionPriceAfterFreeHours'
    );
    let freePLDetentionPriceAfterFreeHours = this.getFormControlValue(
      'freePLDetentionPriceAfterFreeHours'
    );

    let difference = this.customerServicesHelperService.calculatePercentageDifference(
      detentionPriceAfterFreeHours,
      freePLDetentionPriceAfterFreeHours
    );

    this.form.get('detentionPriceDifference').setValue(difference);
  }

  calculateDestinationPriceDifference() {
    let extraDestinationPrice = this.getFormControlValue(
      'extraDestinationPrice'
    );
    let freePLExtraDestinationPrice = this.getFormControlValue(
      'freePLExtraDestinationPrice'
    );

    let difference = this.customerServicesHelperService.calculatePercentageDifference(
      extraDestinationPrice,
      freePLExtraDestinationPrice
    );

    this.form.get('destinationPriceDifference').setValue(difference);
  }

  getFormControlValue(formControlName: string): number {
    return this.form.get(formControlName).value as number;
  }

  isInvalidFormControl(formControlName: string): boolean {
    return this.formsHelperService?.isInvalidFormControl(
      this.form,
      formControlName
    );
  }
}
