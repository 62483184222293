import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApprovalLeadTime } from '../models/approval-lead-time.model';

@Injectable({
  providedIn: 'root'
})
export class ApprovalKpiService {

  baseURL = environment.apiUrl + 'ApprovalLeadTime';
  constructor(private http: HttpClient) { }


  getAll(): Observable<ApprovalLeadTime[]> {
    return this.http.get<ApprovalLeadTime[]>(`${this.baseURL}`);
  }

  getById(id: number): Observable<ApprovalLeadTime> {
    return this.http.get<ApprovalLeadTime>(`${this.baseURL}/${id}`);
  }

  addApprovalLeadTime(leadTime: ApprovalLeadTime): Observable<ApprovalLeadTime> {
    return this.http.post<ApprovalLeadTime>(`${this.baseURL}`, leadTime);
  }

  updateApprovalLeadTime(id: number, leadTime: ApprovalLeadTime): Observable<ApprovalLeadTime> {
    return this.http.put<ApprovalLeadTime>(`${this.baseURL}/${id}`, leadTime);
  }


  deleteApprovalLeadTime(id: number): Observable<boolean>{
    return this.http.delete<boolean>(`${this.baseURL}/${id}`);
  }
}
