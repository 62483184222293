import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { TruckType } from 'src/app/shared/models/truck-type.model';
import { environment } from 'src/environments/environment';
import { TruckTypeWithSelectedLoadType } from '../models/truck-type-with-load-type';
import { BulkTruckType } from 'src/app/bulk-import/models/bulk-truck-type.model';

@Injectable({
  providedIn: 'root'
})
export class TruckTypeService {
  baseURL = environment.apiUrl + 'TruckType';

  constructor(private http: HttpClient) {}

  getAll(): Observable<TruckType[]> {
    return this.http.get<TruckType[]>(`${this.baseURL}`);
  }

  getById(id: number): Observable<TruckType> {
    return this.http.get<TruckType>(`${this.baseURL}/${id}`);
  }

  deleteTruckType(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseURL}/${id}`);
  }

  addNewTruckType(
    truckType: TruckTypeWithSelectedLoadType
  ): Observable<TruckType> {
    return this.http.post<TruckType>(`${this.baseURL}`, truckType);
  }

  updateTruckType(
    truckType: TruckTypeWithSelectedLoadType
  ): Observable<TruckType> {
    return this.http.put<TruckType>(
      `${this.baseURL}/${truckType.id}`,
      truckType
    );
  }

  addBulkTruckTypes(truckTypes: BulkTruckType[]): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseURL}/bulk`, truckTypes);
  }
}
