<div
  class="package-information-type-container"
  [formGroup]="ispackagesTotalsForm"
>
  <div class="mb-1"><label class="label title">Packing Information</label></div>
  <mat-radio-group
    [formControlName]="controlName"
    [disabled]="isDisableForm"
  >
    <mat-radio-button
    name="rdo-packageInformationTotal"
      [value]="true"
      class="font-size-14 radio-button-style"
      [class]="
        ispackagesTotalsFormControl.hasError('required') &&
        ispackagesTotalsFormControl.touched
          ? 'validation-error'
          : ''
      "
    >
      <label class="label radio-button-label padding-label">{{
        packageInformationTypeEnum.Totals
      }}</label>
    </mat-radio-button>

    <mat-radio-button
    name="rdo-packageInformationDetailed"
      [value]="false"
      class="font-size-14"
      [class]="
        ispackagesTotalsFormControl.hasError('required') &&
        ispackagesTotalsFormControl.touched
          ? 'validation-error'
          : ''
      "
    >
      <label class="label radio-button-label padding-label">{{
        packageInformationTypeEnum.Detailed
      }}</label>
    </mat-radio-button>
  </mat-radio-group>
</div>
