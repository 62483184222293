import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { QuoteStatus } from 'src/app/quote/enums/quote-status.enum';

@Component({
  selector: 'app-submit-quote-pop-up',
  templateUrl: './submit-quote-pop-up.component.html',
  styleUrls: ['./submit-quote-pop-up.component.scss']
})
export class SubmitQuotePopUpComponent {
  @Input() quoteNumber: string;
  @Input() companyId: string;
  @Input() status: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {
    this.quoteNumber = data['quoteNumber'];
    this.companyId = data['companyId'];
    this.status = data['status'] ;
  }

  goToQuotes(): void {
    this.router.navigate(['company/' + this.companyId + '/quotes']);
  }

  get title(): string {
    if (this.isPendingApprovalStatus) {
      return 'Quote is now active!';
    }
    return 'Request successfully submitted!';
  }

  get isPendingApprovalStatus(): boolean {
    return this.status == QuoteStatus.PendingApproval;
  }
}
