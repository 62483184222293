import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApprovalLeadTime } from '../models/approval-lead-time.model';
import { ApprovalKpiService } from '../services/approval-kpi.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApprovalKpiResolver implements Resolve<ApprovalLeadTime> {
  constructor(public kpiService: ApprovalKpiService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ApprovalLeadTime> {
    let id = JSON.parse(route.params.id) as number;
    return this.kpiService.getById(id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
