<div class="wrapper">
  <div class="title-wrapper">
    <div
      class="title d-flex align-items-center"
     >
      <div class="icon">
        <svg-icon [name]="icon"></svg-icon>
      </div>
      <span name="lbl_title" class="text-20 font-weight-bold">
        {{ title }}
      </span>
    </div>
    <div class="line"></div>
    <button class="action" [ngClass]="{ active: active }" (click)="toggle()">
      <div [ngClass]="{ flip: !active }">
        <svg-icon
          class="extend-section-button"
          [name]="'arrow-down'"
        ></svg-icon>
      </div>
    </button>
  </div>
  <div class="body" [ngClass]="{ active: active }">
    <ng-template [ngTemplateOutlet]="body"></ng-template>
  </div>
</div>
