import { Injectable, ErrorHandler } from '@angular/core';
import { AlertService, MessageSeverity } from './shared/services/alert.service';

@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(private alertService: AlertService) {
    super();
  }
  handleError(error: any) {
    //this.alertService.httpError(error);
    super.handleError(error);
  }
}
