<div class="remove-document-con">
    <svg-icon name="removeDocument"></svg-icon>
    <div class="spacing-between-icon-and-title"></div>
    <app-page-header [title]="'Document Removal'"></app-page-header>
    <div class="spacing-between-icon-and-title"></div>
    <div class="document-removal-warning-paragraph">
        You are about to delete this document. Instead, you can replace it with another version if needed.
    </div>
    <div class="document-removal-note-title">
        Why would you like to delete this document?
        <span class="required-symbol">*</span>
    </div>
    <textarea
    [(ngModel)]="notes"
    type="text"
    placeholder="Add note"
    class="deletion-reason"
    [class]="error? 'error': ''"
    maxlength="180"
    >
    </textarea>

    <div class="form-buttons">
        <button class="keep-doc removal-form-buttons" (click)="cancel(false)">Keep Document</button>
        <button class="delete-doc removal-form-buttons" (click)="deleteDocument()">Delete Document</button>
    </div>
    <div class="deletion-warning">
        <svg-icon [name]="'mark!'"></svg-icon>
        <div class="deletion-warning-text">This action cannot be undone.</div>
    </div>
</div>