<div class="d-flex flex-column">
  <label class="form-label"
    >{{ fieldName
    }}<span class="required-text" *ngIf="isRequired">*</span></label
  >

  <div
    class="document-field"
    (click)="uploadFile()"
    *ngIf="!uploadingFile"
    [ngClass]="{
      error: isInvalidFormControl(documentControlName)
    }"
  >
    <svg-icon name="uploadDocument" class="document-field-icon"></svg-icon>

    <input
      class="document-field-input"
      [placeholder]="placeholder"
      readonly="true"
    />
  </div>

  <ng-container *ngIf="uploadingFile">
    <div
      class="upload-card"
      [ngClass]="{
        'is-canceled': uploadingFile?.isCanceled
      }"
    >
      <div class="title">
        {{ uploadingFile?.typeName || uploadedFile?.documentTypeName }}
      </div>

      <div class="d-flex align-items-end justify-content-between">
        <span class="file-name flex-grow-1">
          <div
            class="d-flex align-items-end"
            (click)="viewFile(uploadedFile?.path)"
          >
            {{ uploadingFile?.fileName || uploadedFile?.fileName }}
          </div>
          <div
            class="progress-container"
            [ngClass]="{
              'uploading-bar': uploadingFile?.isUploading,
              'canceled-bar': uploadingFile?.isCanceled
            }"
          >
            <mat-progress-bar
              *ngIf="
                !uploadingFile?.isUploaded &&
                (uploadingFile?.isUploading || uploadingFile?.isCanceled)
              "
              mode="determinate"
              [value]="uploadingFile?.percentageValue"
            ></mat-progress-bar>
          </div>
        </span>

        <button
          class="cancel trash-icon"
          *ngIf="uploadedFile || uploadingFile?.isCanceled"
          (click)="deleteUploadedFile()"
        >
          <svg-icon [name]="'shipmentDetailsActionDelete'"></svg-icon>
        </button>
        <button
          class="cancel"
          *ngIf="!uploadingFile?.isCanceled && !uploadedFile"
          (click)="cancelUploadingFile()"
        >
          <svg-icon [name]="'cancel'"></svg-icon>
        </button>
      </div>

      <div class="note">
        {{ uploadedFile?.description || "No notes added." }}
      </div>
    </div>
    <div
      *ngIf="!uploadingFile?.isUploaded && uploadingFile?.isCanceled"
      class="d-flex align-items-center"
    >
      <span class="pb -1">
        <svg-icon [name]="'alertMark'"></svg-icon>
      </span>
      <span class="text-alert mx-1 font-size-14">
        Upload has been canceled.</span
      >
      <span color="red">
        <svg-icon [name]="'refresh'"></svg-icon>
      </span>
      <span
        (click)="reuploadFile()"
        class="text-border font-size-14 mx-1 text-underline cursor-pointer"
        >Re-upload File</span
      >
    </div>
  </ng-container>
</div>
