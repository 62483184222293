import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators"; 
import { DocumentTypeService } from "../../admin-portal-configs/services/document-type.service";

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeListResolver implements Resolve<DocumentType[]> {
  constructor(private router: Router, private documentTypeService: DocumentTypeService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<DocumentType[]> {
    return (
      this.documentTypeService
        .getDocumentTypesWithServices()
        .pipe(
          catchError((error) => {
            return of(null);
          })
        )
    );
  }
}
