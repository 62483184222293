<div class="row w-100">
  <div class="col-4" *ngIf="moduleName == module.CompanyProfile">
    <div class="title">
      Supplier Name
    </div>
    <div class="value">
      {{ customerRouteDetailsSupplierName }}
    </div>
  </div>

  <div class="col-6" *ngIf="isDomestic != true">
    <div class="title">
      Route direction
    </div>
    <div class="value">
      {{ routeDirection || customerRouteDetailsDirection }}
    </div>
  </div>
  <div
    [ngClass]="{
      'col-6': moduleName == module.Supplier,
      'col-4': moduleName == module.CompanyProfile
    }"
  >
    <div class="title">
      Pickup From
    </div>
    <div class="value">
      {{ pickUpFromDecider }}
    </div>
  </div>
  <div class="col-6 ">
    <div class="title">
      Deliver To
    </div>
    <div class="value">
      {{ deliverToDecider }}
    </div>
  </div>
</div>
