<div id="wrapper" [classList]="classList">
    <div class="selected-icon">
      <svg-icon name="small-white-check"></svg-icon>
    </div>
    <div class="row d-flex align-items-center dangerous-good-content">
      <div class="col-3 icon-container">
        <svg-icon [name]="dangerousGoodClass?.iconName"></svg-icon>
      </div>
      <div class="col-8  name-container ">
        <div class="index col-2">{{ index + 1 }}.</div>
        <div class="title col-9">{{ dangerousGoodClass?.name }}</div>
      </div>
    </div>
  </div>
  