import { Component, Input, OnInit } from '@angular/core';
import { ProductRegistration } from 'src/app/create-product-registration/models/product-registration.model';
import { ProductRegistrationColumns } from '../../enums/product-registration-columns.enum';
import { ProductRegistrationHelperService } from 'src/app/create-product-registration/service/product-registration-helper.service';
import { ProductRegistrationViewColumn } from '../../models/product-registration-view-column.model';
import { ProductRegistrationService } from '../../services/product-registration.service';

@Component({
  selector: 'app-product-registration-table-view',
  templateUrl: './product-registration-table-view.component.html',
  styleUrls: ['./product-registration-table-view.component.scss']
})
export class ProductRegistrationTableViewComponent implements OnInit {
  @Input() productsList: ProductRegistration[];
  @Input() isAllSelected: boolean;

  @Input() columns: ProductRegistrationViewColumn[];

  dateColumnNames: string[] = [
    ProductRegistrationColumns.Created,
    ProductRegistrationColumns.LastUpdate,
    ProductRegistrationColumns.ValidUntil
  ];

  columnNames = ProductRegistrationColumns;
  maxLengthBeforeTruncation: number = 14;

  constructor(
    private productRegistrationHelperService: ProductRegistrationHelperService,
    private productRegistrationService: ProductRegistrationService
  ) {}

  ngOnInit(): void {}

  getIsDateColumn(column: ProductRegistrationViewColumn): boolean {
    return this.dateColumnNames.includes(column.name);
  }

  getColumnWidth(column: ProductRegistrationViewColumn): string {
    switch (column.name) {
      case ProductRegistrationColumns.Status:
        return '230';
      case ProductRegistrationColumns.Category:
        return '240';
      default:
        return '125';
    }
  }

  getProductRegistrationRouterLink(product: ProductRegistration): string {
    return product?.id + '/details';
  }

  getDatesFromProductRegistration(
    column: ProductRegistrationViewColumn,
    product: ProductRegistration
  ): Date {
    switch (column.name) {
      case ProductRegistrationColumns.Created:
        return this.productRegistrationHelperService.getSubmittedAt(product);

      case ProductRegistrationColumns.LastUpdate:
        return this.productRegistrationHelperService.getupdatedDate(product);

      case ProductRegistrationColumns.ValidUntil:
        return this.productRegistrationHelperService.getRegistrationLicenseExpiryDate(
          product
        );

      default:
        break;
    }
  }

  getSatusColor(product: ProductRegistration): string {
    return this.productRegistrationHelperService.getStatusColor(product);
  }

  getColumnContent(
    column: ProductRegistrationViewColumn,
    product: ProductRegistration
  ): string {
    switch (column.name) {
      case ProductRegistrationColumns.RegistrationNumber:
        return this.productRegistrationHelperService.getProductRegistrationNumberString(
          product
        );

      case ProductRegistrationColumns.ProductName:
        return '';

      case ProductRegistrationColumns.Status:
        return this.productRegistrationHelperService.getCurrentStatus(product);

      case ProductRegistrationColumns.AcceptanceNumber:
        return (
          this.productRegistrationHelperService.getAcceptanceNumber(product) ||
          '-'
        );

      case ProductRegistrationColumns.Category:
        return this.productRegistrationHelperService.getCategory(product);

      case ProductRegistrationColumns.LicenseNumber:
        return (
          this.productRegistrationHelperService.getRegistrationLicenseNumber(
            product
          ) || '-'
        );

      default:
        break;
    }
  }

  isRegistrationLicenseExpired(product: ProductRegistration): boolean {
    return this.productRegistrationHelperService.isRegistrationLicenseExpired(
      product
    );
  }

  selectOrDeselectProduct(
    event: Event,
    productRegistrationNumberString: string
  ): void {
    event.stopPropagation();
    const target = event.target as HTMLInputElement;
    const isChecked: boolean = target.checked;

    if (isChecked) {
      if (!this.isAllSelected) {
        this.productRegistrationService.addToListOfSelectedProducts(
          productRegistrationNumberString
        );
      } else {
        this.productRegistrationService.removeFromListOfSelectedProducts(
          productRegistrationNumberString
        );
      }
    } else {
      if (!this.isAllSelected) {
        this.productRegistrationService.removeFromListOfSelectedProducts(
          productRegistrationNumberString
        );
      } else {
        this.productRegistrationService.addToListOfSelectedProducts(
          productRegistrationNumberString
        );
      }
    }
  }

  isProductInSelectedProductsList(product: ProductRegistration): boolean {
    const isSelected = this.productRegistrationService.isProductInSelectedProductsList(
      product.productRegistrationNumberString
    );

    return this.isAllSelected ? !isSelected : isSelected;
  }
}
