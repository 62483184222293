import { Component, HostBinding, OnInit } from '@angular/core';
import { SidenavService } from '../../services/sidenav.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.scss']
})
export class NavSidebarComponent implements OnInit {
  isWorkflowSubmenuExpanded: boolean = false;
  isSystemFieldsSubmenuExpanded: boolean = false;
  isKpisSubmenuExpanded: boolean = false;
  isNetworkSubmenuExpanded: boolean = false;
  isSecuritySubmenuExpanded: boolean = false;

  constructor(
    public sidenavService: SidenavService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.sidenavService.closeSidebar$.subscribe(() => {
      this.sidenavService.collapseSidenav();
      this.closeAllSubMenus();
    });
  }

  expandWorkflowSubmenu(): void {
    if (!this.isWorkflowSubmenuExpanded && !this.isExpanded) {
      this.sidenavService.toggleSidenav();
    }

    setTimeout(() => {
      this.isWorkflowSubmenuExpanded = !this.isWorkflowSubmenuExpanded;
      this.isSystemFieldsSubmenuExpanded = false;
      this.isKpisSubmenuExpanded = false;
      this.isNetworkSubmenuExpanded = false;
      this.isSecuritySubmenuExpanded = false;
    }, 130);
  }

  expandSystemFieldsSubmenu(): void {
    if (!this.isSystemFieldsSubmenuExpanded && !this.isExpanded) {
      this.sidenavService.toggleSidenav();
    }

    setTimeout(() => {
      this.isSystemFieldsSubmenuExpanded = !this.isSystemFieldsSubmenuExpanded;
      this.isWorkflowSubmenuExpanded = false;
      this.isKpisSubmenuExpanded = false;
      this.isNetworkSubmenuExpanded = false;
      this.isSecuritySubmenuExpanded = false;
    }, 130);
  }

  expandKpisSubmenu(): void {
    if (!this.isKpisSubmenuExpanded && !this.isExpanded) {
      this.sidenavService.toggleSidenav();
    }

    setTimeout(() => {
      this.isKpisSubmenuExpanded = !this.isKpisSubmenuExpanded;
      this.isWorkflowSubmenuExpanded = false;
      this.isSystemFieldsSubmenuExpanded = false;
      this.isNetworkSubmenuExpanded = false;
      this.isSecuritySubmenuExpanded = false;
    }, 130);
  }

  expandNetworkSubmenu(): void {
    if (!this.isNetworkSubmenuExpanded && !this.isExpanded) {
      this.sidenavService.toggleSidenav();
    }

    setTimeout(() => {
      this.isNetworkSubmenuExpanded = !this.isNetworkSubmenuExpanded;
      this.isWorkflowSubmenuExpanded = false;
      this.isSystemFieldsSubmenuExpanded = false;
      this.isKpisSubmenuExpanded = false;
      this.isSecuritySubmenuExpanded = false;
    }, 130);
  }

  expandSecuritySubmenu(): void {
    if (!this.isSecuritySubmenuExpanded && !this.isExpanded) {
      this.sidenavService.toggleSidenav();
    }

    setTimeout(() => {
      this.isSecuritySubmenuExpanded = !this.isSecuritySubmenuExpanded;
      this.isWorkflowSubmenuExpanded = false;
      this.isSystemFieldsSubmenuExpanded = false;
      this.isKpisSubmenuExpanded = false;
      this.isNetworkSubmenuExpanded = false;
    }, 130);
  }

  closeAllSubMenus(): void {
    this.isSecuritySubmenuExpanded = false;
    this.isWorkflowSubmenuExpanded = false;
    this.isSystemFieldsSubmenuExpanded = false;
    this.isKpisSubmenuExpanded = false;
    this.isNetworkSubmenuExpanded = false;
  }

  @HostBinding('class.is-expanded')
  get isExpanded(): boolean {
    return this.sidenavService.isExpanded;
  }

  get canViewShipmentActivities(): boolean {
    return this.authService.canViewShipmentActivities;
  }

  get canViewRegistrationActivities(): boolean {
    return this.authService.canViewRegistrationActivities;
  }

  get canViewRegistrationWorkflows(): boolean {
    return this.authService.canViewRegistrationWorkflows;
  }

  get canViewShipmentKPIs(): boolean {
    return this.authService.canViewShipmentKPIs;
  }

  get canViewCarriers(): boolean {
    return this.authService.canViewCarriers;
  }

  get canViewProductRegistrationKPI(): boolean {
    return this.authService.canViewProductRegistrationKPI;
  }

  get canViewChargeType(): boolean {
    return this.authService.canViewChargeType;
  }

  get canViewShipmentWorkflow(): boolean {
    return this.authService.canViewShipmentWorkflow;
  }

  get canViewCities(): boolean {
    return this.authService.canViewCities;
  }

  get canViewCurrency(): boolean {
    return this.authService.canViewCurrency;
  }

  get canViewUsers(): boolean {
    return this.authService.canViewUsers;
  }

  get canViewDistricts(): boolean {
    return this.authService.canViewDistricts;
  }

  get canViewDocumentType(): boolean {
    return this.authService.canViewDocumentType;
  }

  get canViewProductCategories(): boolean {
    return this.authService.canViewProductCategories;
  }

  get canViewReports(): boolean {
    return this.authService.canViewReports;
  }
  get canViewFreePLCustomers(): boolean {
    return this.authService.canViewFreePLCustomers;
  }
}
