<div
  class="app-component app-container"
  [ngClass]="{
    'gradient-background': !isUserLoggedIn,
    'app-styling': isUserLoggedIn,
    'customer-app-styling': !canSeeAdminSide,
    'admin-app-styling': canSeeAdminSide
  }"
  [class]="translationService.getCurrentLanguage() + 'Class'"
>
  <ng-container *ngIf="!isUserLoggedIn">
    <div class="promotional-header">
      The Smartest Way to Manage Your Cargo
    </div>
    <div class="shipment-background"></div>
  </ng-container>
  <ngx-toasta></ngx-toasta>

  <app-white-nav-bar *ngIf="isUserLoggedIn"></app-white-nav-bar>

  <app-nav-sidebar id="sidenav-bar" *ngIf="isUserLoggedIn && canSeeAdminSide">
  </app-nav-sidebar>

  <app-non-admin-sidebar
    *ngIf="isUserLoggedIn && !canSeeAdminSide"
  ></app-non-admin-sidebar>

  <div
    id="pre-bootstrap"
    *ngIf="!removePrebootScreen"
    [class.prebootShow.app-component]="!isAppLoaded"
    class="app-component prebootStep"
  >
    <div class="messaging">
      <h1>Loaded!</h1>
      <!-- Hide FreePL Brand -->
      <!-- <p>FreePl<span style="font-style: italic">App</span></p> -->
    </div>
  </div>

  <main
    class="app-component container main-component"
    [ngClass]="{
      'padding-after-login': isUserLoggedIn && !canSeeAdminSide,
      'padding-after-login-is-admin': isUserLoggedIn && canSeeAdminSide,
      'custom-page': !excludedPages,
      'pr-0': excludedPages
    }"
    (click)="isAdminSidebarExpanded ? closeAdminSidebar() : null"
  >
    <div [class]="isUserLoggedIn ? '' : 'login-page-container'">
      <br *ngIf="!excludedPages" />
      <router-outlet *ngIf="!isLoading"></router-outlet>
    </div>
    <app-loading-indicator *ngIf="isLoading"></app-loading-indicator>
  </main>

  <div
    *ngIf="shouldShowLoginModal"
    class="modal fade login-control"
    bsModal
    #loginModal="bs-modal"
    (onShown)="onLoginModalShown()"
    (onHidden)="onLoginModalHidden()"
    (onHide)="onLoginModalHide()"
    [config]="{ backdrop: 'static' }"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-lg h-75 d-flex flex-column justify-content-center my-0"
    >
      <div class="modal-content">
        <div class="modal-body">
          <app-login #loginControl [isModal]="true"></app-login>
        </div>
      </div>
    </div>
  </div>
</div>
