<div *ngIf="hasBackGround">
  <div [ngClass]="{ 'error-badge-thin': thinBar, 'error-badge': !thinBar }">
    <p class="error-message">
      <i class="fa fa-exclamation-circle"></i>
      {{ message || "Error" }}
    </p>
  </div>
</div>

<div *ngIf="!hasBackGround">
  <div>
    <p class="error-message">
      <i class="fa fa-exclamation-circle"></i>
      {{ message || "Error" }}
    </p>
  </div>
</div>
