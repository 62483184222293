<div class="cargo-handling-container" [formGroup]="form">
  <div class="mb-1"><label class="label title">Cargo Handling</label></div>
  <mat-radio-group [formControlName]="controlName" [disabled]="isDisableForm">
    <mat-radio-button
    name="rdo-cargoHandlingStackable"
      [value]="!isQuoteSelected ? cargoHandlingEnum.Stackable : true"
      class="font-size-14 radio-button-space"
      [class]="
        cargoHandlingFormControl?.hasError('required') &&
        cargoHandlingFormControl?.touched
          ? 'validation-error'
          : ''
      "
    >
      <label class=" label radio-button-label padding-label">{{
        cargoHandlingEnum.Stackable
      }}</label>
    </mat-radio-button>

    <mat-radio-button
    name="rdo-cargoHandlingNonstackable"
      [value]="!isQuoteSelected ? cargoHandlingEnum.NonStackable : false"
      class="font-size-14"
      [class]="
        cargoHandlingFormControl?.hasError('required') &&
        cargoHandlingFormControl?.touched
          ? 'validation-error'
          : ''
      "
    >
      <label class="label radio-button-label padding-label">{{
        cargoHandlingEnum.NonStackable
      }}</label>
    </mat-radio-button>
  </mat-radio-group>
</div>
