import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-number-of-updated-entities-modal',
  templateUrl: './number-of-updated-entities-modal.component.html',
  styleUrls: ['./number-of-updated-entities-modal.component.scss']
})
export class NumberOfUpdatedEntitiesModalComponent implements OnInit {
  @Input() updatedEntitiesNumber: number;
  @Input() totalNumber: number;
  @Input() type: string;
  @Input() companyId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {
    this.updatedEntitiesNumber = data['updatedEntitiesNumber'];
    this.totalNumber = data['totalNumber'];
    this.companyId = data['companyId'];
    this.type = data['type'];
  }

  ngOnInit(): void {}

  goToListPage(): void {
    this.router.navigate(['company/' + this.companyId + '/' + this.type]);
  }
}
