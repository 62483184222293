import { Component, Input, OnInit } from '@angular/core';
import { ProgressAndActivityLog } from '../../models/progress-and-activity-log.model';
import { ProductRegistrationCommentWithActionActors } from '../../models/product-registration-comment-with-action-actors.model';

@Component({
  selector: 'app-progress-point',
  templateUrl: './progress-point.component.html',
  styleUrls: ['./progress-point.component.scss']
})
export class ProgressPointComponent implements OnInit {
  @Input() progressAndActivityLogs: ProgressAndActivityLog;
  @Input() isDisabled: boolean;
  @Input() isCurrentProgress: boolean;
  @Input() isDisabletrackingLine: boolean;
  @Input() productRegistrationComments: ProductRegistrationCommentWithActionActors[];
  @Input() productId: string;

  constructor() {}

  ngOnInit(): void {
  }

  get progressActualDate() {
    if (this.isDateDisplay) {
      return this.progressAndActivityLogs?.activityLogs[0]?.createdAt;
    }
  }

  get isDateDisplay(): boolean {
    if (this.progressAndActivityLogs?.activityLogs?.length == 0) {
      return false;
    }
    return true;
  }
}
