<div class="routing-summary-container">
  <div class="row">
    <div class="section-title col-2">Origin</div>
    <div class="col-10">
      <div class="row">
        <div class="col-2 title">Pickup Date</div>
        <div class="col-2 title district-area" *ngIf="isDomesticQuote">
          Origin District or Area
        </div>
        <div class="col-2 title" *ngIf="isDomesticQuote">
          Origin Address
        </div>
        <div class="col-2 title" *ngIf="!isDomesticQuote">Pickup City</div>
        <div
          class="col-2 title"
          *ngIf="isShowPort && !isDomesticQuote && !isLandStandaloneService"
        >
          Origin Port
        </div>
        <div
          class="col-3 title"
          *ngIf="!isDomesticQuote && !isLandStandaloneService"
        >
          Trucking from Port/Airport
        </div>

        <div class="col-2 title" *ngIf="isLandStandaloneService">
          Origin Address
        </div>

        <div
          class="col-3 title"
          *ngIf="!isDomesticQuote && isExpressAirFreight"
        >
          Shipment Type
        </div>
      </div>

      <div class="row">
        <div class="col-2 routing-data">
          {{
            quoteRouting?.pickupLocationDetails?.isCustomerNotSureDate
              ? "Not Sure"
              : (quoteRouting?.pickupLocationDetails?.date | date: "dd/MM/YYYY")
          }}
        </div>
        <div class="col-2 routing-data district-area" *ngIf="isDomesticQuote">
          {{
            quote?.routingDetails?.pickupLocationDetails?.domesticTruckingAddress?.districtName
          }}
        </div>
        <div class="col-2 routing-data" *ngIf="isDomesticQuote">
          {{ quote?.routingDetails?.pickupLocationDetails?.domesticTruckingAddress?.address }}
        </div>
        <div class="col-2 routing-data" *ngIf="!isDomesticQuote">
          {{ originCity?.name }}, {{ originCountry?.name }}
        </div>
        <div
          class="col-2  routing-data"
          *ngIf="isShowPort && !isDomesticQuote && !isLandStandaloneService"
        >
          {{ quoteRouting?.pickupLocationDetails?.portName || "-" }}
        </div>
        <div
          class="col-3 routing-data"
          *ngIf="!isDomesticQuote && !isLandStandaloneService"
        >
          <svg-icon name="truck" class="truck-icon"></svg-icon>
          <span *ngIf="!quoteRouting?.pickupLocationDetails?.isTrucking" class="not-included"
            >Not included</span
          >
          <span *ngIf="quoteRouting?.pickupLocationDetails?.isTrucking"
            >From {{ quoteRouting?.pickupLocationDetails?.truckingAddress?.address
            }}{{ quoteRouting?.pickupLocationDetails?.truckingAddress?.districtName ? ", " : ""
            }}{{ quoteRouting?.pickupLocationDetails?.truckingAddress?.districtName
            }}{{ quoteRouting?.pickupLocationDetails?.city ? ", " : ""
            }}{{ quoteRouting?.pickupLocationDetails?.city
            }}{{ quoteRouting?.pickupLocationDetails?.truckingAddress?.postalCode ? ", " : ""
            }}{{ quoteRouting?.pickupLocationDetails?.truckingAddress?.postalCode }}</span
          >
        </div>

        <div class="col-4 routing-data" *ngIf="isLandStandaloneService">
          {{ quoteRouting?.pickupLocationDetails?.truckingAddress?.address }}
          {{ quoteRouting?.pickupLocationDetails?.truckingAddress?.postalCode ? ", " : "" }}
          {{ quoteRouting?.pickupLocationDetails?.truckingAddress?.postalCode }}
          {{ quoteRouting?.pickupLocationDetails?.city ? ", " : "" }}
          {{ quoteRouting?.pickupLocationDetails?.city }}
          {{ quoteRouting?.pickupLocationDetails?.country ? ", " : "" }}
          {{ quoteRouting?.pickupLocationDetails?.country }}
        </div>
        <div
          class="col-3  routing-data"
          *ngIf="!isDomesticQuote && isExpressAirFreight"
        >
          Express air
        </div>
      </div>
    </div>
  </div>

  <div class="divider row"></div>

  <div class="row">
    <div class="section-title col-2">Destination</div>
    <div class="col-10">
      <div class="row">
        <div class="col-2 title">Delivery Date</div>
        <div class="col-2 title district-area" *ngIf="isDomesticQuote">
          Delivery District or Area
        </div>
        <div class="col-2 title" *ngIf="isDomesticQuote">
          Delivery Address
        </div>
        <div class="col-2 title" *ngIf="!isDomesticQuote">Delivery City</div>
        <div
          class="col-2 title"
          *ngIf="isShowPort && !isDomesticQuote && !isLandStandaloneService"
        >
          Delivery Port
        </div>
        <div
          class="col-4 title"
          *ngIf="!isDomesticQuote && !isLandStandaloneService"
        >
          Trucking to Port/Airport
        </div>
        <div class="col-2 title" *ngIf="isLandStandaloneService">
          Delivery Address
        </div>
      </div>

      <div class="row">
        <div class="col-2 routing-data">
          {{
            quoteRouting?.deliveryLocationDetails?.isCustomerNotSureDate
              ? "Not Sure"
              : (quoteRouting?.deliveryLocationDetails?.date | date: "dd/MM/YYYY")
          }}
        </div>
        <div class="col-2 routing-data district-area" *ngIf="isDomesticQuote">
          {{
            quote?.routingDetails?.deliveryLocationDetails?.domesticTruckingAddress?.districtName
          }}
        </div>
        <div class="col-2 routing-data" *ngIf="isDomesticQuote">
          {{ quote?.routingDetails?.deliveryLocationDetails?.domesticTruckingAddress?.address }}
        </div>
        <div class="col-2 routing-data" *ngIf="!isDomesticQuote">
          {{ deliveryCity?.name }}, {{ deliveryCountry?.name }}
        </div>
        <div
          class="col-2  routing-data"
          *ngIf="isShowPort && !isDomesticQuote && !isLandStandaloneService"
        >
          {{ quoteRouting?.deliveryLocationDetails?.portName || "-" }}
        </div>
        <div
          class="col-4 routing-data"
          *ngIf="!isDomesticQuote && !isLandStandaloneService"
        >
          <svg-icon name="truck" class="truck-icon"></svg-icon>
          <span *ngIf="!quoteRouting?.deliveryLocationDetails?.isTrucking" class="not-included"
            >Not included</span
          >
          <span *ngIf="quoteRouting?.deliveryLocationDetails?.isTrucking"
            >To {{ quoteRouting?.deliveryLocationDetails?.truckingAddress?.address
            }}{{
              quoteRouting?.deliveryLocationDetails?.truckingAddress?.districtName ? ", " : ""
            }}{{ quoteRouting?.deliveryLocationDetails?.truckingAddress?.districtName
            }}{{ quoteRouting?.deliveryLocationDetails?.city ? ", " : ""
            }}{{ quoteRouting?.deliveryLocationDetails?.city
            }}{{ quoteRouting?.deliveryLocationDetails?.truckingAddress?.postalCode ? ", " : ""
            }}{{ quoteRouting?.deliveryLocationDetails?.truckingAddress?.postalCode }}</span
          >
        </div>

        <div class="col-4 routing-data" *ngIf="isLandStandaloneService">
          {{ quoteRouting?.deliveryLocationDetails?.truckingAddress?.address }}
          {{ quoteRouting?.deliveryLocationDetails?.truckingAddress?.postalCode ? ", " : "" }}
          {{ quoteRouting?.deliveryLocationDetails?.truckingAddress?.postalCode }}
          {{ quoteRouting?.deliveryLocationDetails?.city ? ", " : "" }}
          {{ quoteRouting?.deliveryLocationDetails?.city }}
          {{ quoteRouting?.deliveryLocationDetails?.country ? ", " : "" }}
          {{ quoteRouting?.deliveryLocationDetails?.country }}
        </div>
      </div>
    </div>
  </div>

  <div class="divider row" *ngIf="!isDomesticQuote"></div>

  <div class="row" *ngIf="!isDomesticQuote">
    <div class="section-title col-2">Additional Details</div>
    <div class="col-10">
      <div class="title" *ngIf="!isLandStandaloneService">Incoterms</div>
      <div class="routing-data" *ngIf="!isLandStandaloneService">
        {{ quoteRouting?.incoterm }}
      </div>

      <div class="row-margin" *ngIf="!isLandStandaloneService"></div>

      <div class="row">
        <div class="col-2 title">Shipper Name</div>
        <div class="col-10 title">Shipper Address</div>
      </div>
      <div class="row">
        <div
          class="col-2 routing-data"
          *ngIf="quoteRouting?.shipper?.name"
          [placement]="'bottom-left'"
          [ngbPopover]="
            quoteRouting?.shipper?.name?.length > SHIPPER_CONSIGNEE_MAX_LIMIT
              ? shipperTemplate
              : ''
          "
          triggers="hover:hover"
        >
          {{
            quoteRouting?.shipper?.name | slice: 0:SHIPPER_CONSIGNEE_MAX_LIMIT
          }}
          {{
            quoteRouting?.shipper?.name?.length > SHIPPER_CONSIGNEE_MAX_LIMIT
              ? "..."
              : ""
          }}
        </div>
        <ng-template #shipperTemplate>
          <div>{{ quoteRouting?.shipper?.name }}</div>
        </ng-template>
        <div
          class="col-2 routing-data"
          *ngIf="
            !quoteRouting?.shipper?.name &&
            quoteRouting?.useFreePLEntityAsShipper
          "
        >
          FreePL Entity
        </div>
        <div class="col-10 routing-data">
          {{ quoteRouting?.shipper?.address | noValueReplace: "-" }}
        </div>
      </div>

      <div class="row-margin"></div>

      <div class="row">
        <div class="col-2 title">Consignee Name</div>
        <div class="col-10 title">Consignee Address</div>
      </div>
      <div class="row">
        <div
          class="col-2 routing-data"
          *ngIf="quoteRouting?.consignee?.name"
          [placement]="'bottom-left'"
          [ngbPopover]="
            quoteRouting?.consignee?.name?.length > SHIPPER_CONSIGNEE_MAX_LIMIT
              ? consigneeTemplate
              : ''
          "
          triggers="hover:hover"
        >
          {{
            quoteRouting?.consignee?.name | slice: 0:SHIPPER_CONSIGNEE_MAX_LIMIT
          }}
          {{
            quoteRouting?.consignee?.name?.length > SHIPPER_CONSIGNEE_MAX_LIMIT
              ? "..."
              : ""
          }}
        </div>
        <ng-template #consigneeTemplate>
          <div>{{ quoteRouting?.consignee?.name }}</div>
        </ng-template>
        <div
          class="col-2 routing-data"
          *ngIf="
            !quoteRouting?.consignee?.name &&
            quoteRouting?.useFreePLEntityAsConsignee
          "
        >
          FreePL Entity
        </div>
        <div class="col-10 routing-data">
          {{ quoteRouting?.consignee?.address | noValueReplace: "-" }}
        </div>
      </div>
    </div>
  </div>
</div>
