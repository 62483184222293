<div class="sidenav-body-container">
  <div class="sidenav-body">
    <div class="nav-logo d-flex">
      <svg-icon
        class="expand-button cursor-pointer"
        name="three-white-stripes"
        (click)="sidenavService.toggleSidenav(); closeAllSubMenus()"
        [class.is-flipped]="sidenavService.isExpanded"
      ></svg-icon>
      <img
        src="assets/images/PNG/FPL_Logo.png"
        class="d-inline-block align-top logo"
        alt="logo"
      />
    </div>

    <div
      class="nav-item"
      placement="right"
      [ngbPopover]="'Workflow Attributes'"
      triggers="mouseenter:mouseleave"
      [disablePopover]="isExpanded"
    >
      <div
        class="d-flex align-items-center cursor-pointer"
        (click)="expandWorkflowSubmenu()"
      >
        <svg-icon name="workflow" class="ml-3"></svg-icon>
        <div class="nav-item-text overflow-hidden ml-4 text-left">
          Workflow Attributes
        </div>
        <svg-icon class="ddl-icon ml-auto" name="large-arrowDown"></svg-icon>
      </div>

      <div class="sub-menu mb-3" *ngIf="isWorkflowSubmenuExpanded">
        <div class="sub-menu-item" *ngIf="canViewShipmentActivities">
          <span class="white-line position-fixed"></span>
          <a routerLink="settings/activity">
            Shipment Activities and Statuses
          </a>
        </div>
        <div class="sub-menu-item" *ngIf="canViewShipmentWorkflow">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/activity-transition">Shipment Workflow</a>
        </div>
        <div class="sub-menu-item" *ngIf="canViewRegistrationActivities">
          <span class="white-line position-fixed"></span>
          <a routerLink="settings/registration-activities">
            Registration Activities
          </a>
        </div>
        <div
          class="sub-menu-item"
          [class]="!canViewRegistrationWorkflows ? 'hidden' : ''"
        >
          <span class="white-line position-fixed"></span>
          <a routerLink="settings/registration-activity-transition">
            Registration Workflow
          </a>
        </div>
        <div class="sub-menu-item">
          <span class="white-line position-fixed"></span>
          <a routerLink="settings/approval-activities">
            Approval Activities
          </a>
        </div>
        <div class="sub-menu-item">
          <span class="white-line position-fixed"></span>
          <a routerLink="settings/approval-activity-transitions">
            Approval Workflow
          </a>
        </div>
      </div>
    </div>

    <div
      class="nav-item"
      placement="right"
      [ngbPopover]="'System Fields'"
      triggers="mouseenter:mouseleave"
      [disablePopover]="isExpanded"
    >
      <div
        class="d-flex align-items-center cursor-pointer"
        (click)="expandSystemFieldsSubmenu()"
      >
        <svg-icon name="system-fields" class="ml-3"></svg-icon>
        <div class="nav-item-text overflow-hidden ml-4 text-left">
          System Fields
        </div>
        <svg-icon class="ddl-icon ml-auto" name="large-arrowDown"></svg-icon>
      </div>
      <div class="sub-menu mb-3" *ngIf="isSystemFieldsSubmenuExpanded">
        <div class="sub-menu-item" *ngIf="canViewCarriers">
          <span class="white-line position-fixed"></span>
          <a routerLink="settings/carriers">Carriers</a>
        </div>
        <div class="sub-menu-item" *ngIf="canViewChargeType">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/charge-type">Charge Type</a>
        </div>
        <div class="sub-menu-item" *ngIf="canViewCities">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/cities">Cities</a>
        </div>
        <div class="sub-menu-item" *ngIf="canViewCurrency">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/currency">Currency</a>
        </div>
        <div class="sub-menu-item">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/countries">Countries</a>
        </div>
        <div class="sub-menu-item" *ngIf="canViewDistricts">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/districts">Districts</a>
        </div>
        <div class="sub-menu-item" *ngIf="canViewDocumentType">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/document-type">Document Type</a>
        </div>
        <div class="sub-menu-item">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/hs-code">HS Code</a>
        </div>
        <div class="sub-menu-item">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/industries">Industries</a>
        </div>
        <div class="sub-menu-item">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/ports">Ports</a>
        </div>
        <div class="sub-menu-item" *ngIf="canViewProductCategories">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/product-categories">Product Categories</a>
        </div>
        <div class="sub-menu-item">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/truck-type">Truck Types</a>
        </div>
        <div class="sub-menu-item">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/land-routes">Land Routes</a>
        </div>
      </div>
    </div>

    <div
      class="nav-item"
      placement="right"
      [ngbPopover]="'KPIs'"
      triggers="mouseenter:mouseleave"
      [disablePopover]="isExpanded"
    >
      <div
        class="d-flex align-items-center cursor-pointer"
        (click)="expandKpisSubmenu()"
      >
        <svg-icon name="kpis" class="ml-3"></svg-icon>
        <div class="nav-item-text overflow-hidden ml-4 text-left">KPIs</div>
        <svg-icon class="ddl-icon ml-auto" name="large-arrowDown"></svg-icon>
      </div>
      <div class="sub-menu mb-3" *ngIf="isKpisSubmenuExpanded">
        <div class="sub-menu-item" *ngIf="canViewShipmentKPIs">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/shipment-kpis">Shipment KPIs</a>
        </div>
        <div class="sub-menu-item" *ngIf="canViewProductRegistrationKPI">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/product-registration-kpis"
            >Product Registration KPIs</a
          >
        </div>
        <div class="sub-menu-item">
          <span class="white-line position-fixed"></span
          ><a routerLink="settings/approval-kpis">Approval KPIs</a>
        </div>
      </div>
    </div>

    <div
      class="nav-item"
      placement="right"
      [ngbPopover]="'Network'"
      triggers="mouseenter:mouseleave"
      [disablePopover]="isExpanded"
    >
      <div
        class="d-flex align-items-center cursor-pointer"
        (click)="expandNetworkSubmenu()"
      >
        <svg-icon name="network" class="ml-3"></svg-icon>
        <div class="nav-item-text overflow-hidden ml-4 text-left">Network</div>
        <svg-icon class="ddl-icon ml-auto" name="large-arrowDown"></svg-icon>
      </div>
      <div class="sub-menu mb-3" *ngIf="isNetworkSubmenuExpanded">
        <div class="sub-menu-item">
          <span class="white-line position-fixed"></span
          ><a routerLink="suppliers">Suppliers</a>
        </div>
      </div>
    </div>

    <div
      class="nav-item"
      placement="right"
      [ngbPopover]="'Security'"
      triggers="mouseenter:mouseleave"
      [disablePopover]="isExpanded"
    >
      <div
        class="d-flex align-items-center cursor-pointer"
        (click)="expandSecuritySubmenu()"
      >
        <svg-icon name="security" class="ml-3"></svg-icon>
        <div class="nav-item-text overflow-hidden ml-4 text-left">Security</div>
        <svg-icon class="ddl-icon ml-auto" name="large-arrowDown"></svg-icon>
      </div>
      <div class="sub-menu mb-3" *ngIf="isSecuritySubmenuExpanded">
        <div class="sub-menu-item" *ngIf="canViewUsers">
          <span class="white-line position-fixed"></span
          ><a [routerLink]="'settings'" fragment="users">Users</a>
        </div>
        <div class="sub-menu-item">
          <span class="white-line position-fixed"></span
          ><a [routerLink]="'settings'" fragment="roles">Roles</a>
        </div>
        <div class="sub-menu-item">
          <span class="white-line position-fixed"></span
          ><a [routerLink]="'/'">Permissions</a>
        </div>
      </div>
    </div>

    <div
      class="nav-item"
      *ngIf="canViewFreePLCustomers"
      placement="right"
      [ngbPopover]="'Customers'"
      triggers="mouseenter:mouseleave"
      [disablePopover]="isExpanded"
    >
      <app-sidenav-link class="d-flex cursor-pointer" routerLink="customers">
        <svg-icon name="customers" class="ml-3"></svg-icon>
        <div class="nav-item-text overflow-hidden ml-4 text-left">
          Customers
        </div>
      </app-sidenav-link>
    </div>

    <div
      class="nav-item"
      *ngIf="canViewFreePLCustomers"
      placement="right"
      [ngbPopover]="'Customers Profile'"
      triggers="mouseenter:mouseleave"
      [disablePopover]="isExpanded"
    >
      <app-sidenav-link
        class="d-flex cursor-pointer"
        routerLink="customers-profile"
      >
        <svg-icon name="customers" class="ml-3"></svg-icon>
        <div class="nav-item-text overflow-hidden ml-4 text-left">
          Customers Profile
        </div>
      </app-sidenav-link>
    </div>

    <div
      class="nav-item"
      *ngIf="canViewReports"
      placement="right"
      [ngbPopover]="'Reports'"
      triggers="mouseenter:mouseleave"
      [disablePopover]="isExpanded"
    >
      <app-sidenav-link class="d-flex cursor-pointer" routerLink="reports">
        <svg-icon name="reports" class="ml-3"></svg-icon>
        <div class="nav-item-text overflow-hidden ml-4 text-left">Reports</div>
      </app-sidenav-link>
    </div> 
    
    <div
      class="nav-item"
      placement="right"
      [ngbPopover]="'Company Profile'"
      triggers="mouseenter:mouseleave"
      [disablePopover]="isExpanded"
    >
      <app-sidenav-link
        class="d-flex cursor-pointer"
        routerLink="company-profile/1/details"
      >
        <svg-icon name="company-details" class="ml-3"></svg-icon>
        <div class="nav-item-text overflow-hidden ml-4 text-left">
          Company Profile
        </div>
      </app-sidenav-link>
    </div>

    <div
      class="nav-item"
      placement="right"
      [ngbPopover]="'Bulk Import'"
      triggers="mouseenter:mouseleave"
      [disablePopover]="isExpanded"
    >
      <app-sidenav-link class="d-flex cursor-pointer" routerLink="bulk-import">
        <svg-icon name="kpis" class="ml-3"></svg-icon>
        <div class="nav-item-text overflow-hidden ml-4 text-left">
          Bulk Import
        </div>
      </app-sidenav-link>
    </div>
  </div>
</div>
