<div class="row">
  <div class="col-5 popOver-label">{{ actionLabel }} on</div>

  <div class="col-7 popOver-field">
    {{ date  | fromutc | date: "dd/MM/YYYY hh:mm a " }}
  </div>
</div>
<div class="row mt-2">
  <div class="col-5 popOver-label">{{ actionLabel }} by</div>

  <div class="col-7 popOver-field">
    <span>
      {{ autherName }},
      {{ companyName }}
    </span>
  </div>
</div>
<div class="row mt-2" *ngIf="reason">
  <div class="col-5 popOver-label">Reason</div>
  <div class="col-7 popOver-field">
    {{ reason }}
  </div>
</div>
