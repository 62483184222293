<div class="resume-request-con">
  <div style="text-align: center;">
    <svg-icon name="resumeShipment"></svg-icon>
    <div class="spacing-between-icon-and-title"></div>
    <div>
      <h3 class="title">
        This request will be resumed
      </h3>
    </div>
    <div class="spacing-between-icon-and-title"></div>
    <div class="resume-request-warning-paragraph">
      To confirm this action please click resume request and the request
      <br />
      status will be changed accordingly.
    </div>
    <div class="resume-request-note-title"></div>
  </div>

  <div class="form-buttons row">
    <div class="col-6">
      <button class="cancel removal-form-buttons" (click)="cancel()">
        Cancel
      </button>
    </div>
    <div class="col-6">
      <button
        class="resume removal-form-buttons"
        (click)="resumeProductRegistration()"
        [disabled]="isLoading"
      >
      Resume Request
        <i *ngIf="!isLoading" class="fa fa-play-circle"></i
        ><i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
      </button>
    </div>
  </div>
</div>
