import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminPortalConfigsService {
  configsItems: string[] = [
    'Document Type',
    'Currency',
    'Charge Type',
    'Ports',
    'HS Code',
    'Activity Transition',
    'Activity',
    'Districts',
    'Cities',
    'Industries',
    'Carriers',
    'Shipment KPIs',
    'Truck Type',
    'Registration Activities',
    'Registration Activity Transition',
    'Product Registration KPIs',
    'Product Categories'
  ];
  constructor() {}

  getItems() {
    return this.configsItems;
  }
}
