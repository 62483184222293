import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-submit-popup',
  templateUrl: './submit-popup.component.html',
  styleUrls: ['./submit-popup.component.scss']
})
export class SubmitPopupComponent implements OnInit {
  submissionNumberString?: string;
  moduleName?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private authService: AuthService
  ) {
    this.submissionNumberString = data['submissionNumberString'];
    this.moduleName = data['moduleName'];
  }

  ngOnInit() {}

  OnSubmissionRouting(): void {
    this.router.navigate([
      'company/' + this.defaultCompanyId + `/${this.moduleName.toLowerCase()}s`
    ]);
  }

  get defaultCompanyId(): string {
    return this.authService?.currentUser?.defaultCustomerCompany;
  }
}
