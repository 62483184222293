import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input, forwardRef, ChangeDetectorRef,
} from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-card-two',
  templateUrl: './card-two.component.html',
  styleUrls: ['./card-two.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CardTwoComponent),
    },
  ],
})
export class CardTwoComponent implements OnInit, ControlValueAccessor {
  @Input() title: string;
  @Input() description: string;
  @Input() iconPath: string;
  @Input() classList: string;
  control: AbstractControl;
  @Input() formControlName: string;
  @Input() hasError: boolean; 

  constructor(private controlContainer: ControlContainer, private readonly cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this.controlContainer && this.formControlName) {
      this.control = this.controlContainer.control.get(this.formControlName);
    }
  }

  onChange = (value: any) => {
  };

  onTouched = ($event) => {
    this.control.markAsTouched();
  };

  writeValue(obj: any): void {
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
