<div class="unSaved-data-con">
  <svg-icon name="opened-door-icon"></svg-icon>
  <div class="title" [innerHTML]="message"></div>

  <div class="form-buttons">
    <button
      name="btn_cancel"
      class="form-btn cancel"
      [mat-dialog-close]="false"
    >
      Cancel
    </button>
    <button
      name="btn_leavePage"
      class="form-btn confirm"
      [mat-dialog-close]="true"
    >
      Leave Page
    </button>
  </div>
</div>
