<div class="employee-list-container">
  <div>
    <app-page-header [title]="'Employees'"></app-page-header>
  </div>
  <div class="search-bar">
    <div class="d-flex justify-content-between">
      <app-admin-search-bar
        (searchChange)="onSearchChanged($event)"
        [placeholder]="'Search for employee'"
      ></app-admin-search-bar>

      <button class="add-btn-container" *ngIf="canCreateEmployees">
        <a
          class="nav-link"
          [routerLink]="
            isCustomerRoute
              ? ['/customer/' + companyId + '/employees/add/']
              : ['/company/' + companyId + '/employee/add/']
          "
        >
          <span class="plus">+</span>
          <span class="add-btn">{{
            "employee.AddNewEmployee" | translate
          }}</span>
        </a>
      </button>
    </div>
  </div>
  <app-add-empty-data-msg
    *ngIf="employees?.length <= 0; else employeesTemplate"
    [normalText]="'Employees will appear here once added.'"
    [boldText]="'There are no employees added yet!'"
  ></app-add-empty-data-msg>

  <ng-template #employeesTemplate>
    <ngx-datatable
      class="material colored-header sm table table-striped table-hover"
      [loadingIndicator]="loadingIndicator"
      [rows]="rows"
      [rowHeight]="40"
      [headerHeight]="35"
      [footerHeight]="35"
      [columns]="columns"
      [scrollbarV]="true"
      [columnMode]="'force'"
      (activate)="onClick($event)"
    >
    </ngx-datatable>
  </ng-template>
  <ng-template
    #lockAndUnlockTemplate
    let-row="row"
    let-value="value"
    let-i="index"
  >
    <span
      *ngIf="value !== employeeStatus.Inactive; else falseLabel"
      class="status active-status"
    >
      Active
    </span>

    <ng-template #falseLabel>
      <span class="status inactive-status">
        Inactive
      </span>
    </ng-template>
  </ng-template>

  <ng-template #employeeNameTemplate let-row="row" let-value="value">
    <span>
      {{ value.firstName + " " + value.lastName }}
    </span>
  </ng-template>

  <ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
    <a
      *ngIf="canUpdateEmployees"
      title="{{ 'forms.Edit' | translate }}"
      [routerLink]="
        isAdministratorRole(row.type) && !isAdministrator
          ? null
          : isCustomerRoute
          ? ['/customer', row.companyId, 'employees', 'edit', row.id]
          : ['/company', row.companyId, 'employee', 'edit', row.id]
      "
    >
      <svg-icon name="circle-pen"></svg-icon>
    </a>

    <button
      (click)="onclickChangeEmployeeStatus($event, row.id, false)"
      style="font-size: 1rem"
      *ngIf="row.status != employeeStatus.Inactive && canUpdateEmployees"
      class="action-btn"
      [disabled]="isAdministratorRole(row.type)"
    >
      <svg-icon name="circle-lock"></svg-icon>
    </button>
    <button
      (click)="onclickChangeEmployeeStatus($event, row.id, true)"
      style="font-size: 1rem"
      *ngIf="row.status == employeeStatus.Inactive && canUpdateEmployees"
      class="action-btn"
      [disabled]="isAdministratorRole(row.type)"
    >
      <svg-icon name="circle-unlock"></svg-icon>
    </button>
  </ng-template>
</div>
