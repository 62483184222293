import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Paginated } from 'src/app/shared/models/shipment/paginated.model';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { CountryService } from 'src/app/shared/services/country.service';

@Component({
  selector: 'app-countries-list',
  templateUrl: './countries-list.component.html',
  styleUrls: ['./countries-list.component.scss']
})
export class CountriesListComponent implements OnInit {
  paginatedCountries: Paginated;
  columns: any;
  @ViewChild('actionTemplate', { static: true })
  actionTemplate: TemplateRef<any>;
  pageIndex: number = 0;
  pageSize: number = 10;

  constructor(
    private route: ActivatedRoute,
    private translationService: AppTranslationService,
    private countryService: CountryService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.paginatedCountries = data['countries'];
      this.initTable();
    });
  }
  initTable() {
    const gT = (key: string) => this.translationService.getTranslation(key);
    this.columns = [
      {
        prop: 'country',
        name: gT('config.management.City.CountryName'),
        width: 100
      },
      { prop: 'code', name: gT('Code'), width: 20 },
      {
        prop: 'lat',
        name: gT('config.management.Country.Latitude'),
        width: 50
      },
      {
        prop: 'lng',
        name: gT('config.management.Country.Longitude'),
        width: 50
      },
      {
        prop: 'isReference',
        name: gT('config.management.Country.ReferenceCountry'),
        width: 80
      },
      {
        prop: 'isLandFreight',
        name: gT('config.management.Country.LandFreightAccessible'),
        width: 100
      },

      {
        prop: '',
        name: gT('customer.services.Action'),
        width: 10,
        cellTemplate: this.actionTemplate,
        canAutoResize: true
      }
    ];
  }

  getCountries() {
    this.countryService
      .getCountriesPaginated('', this.pageIndex, this.pageSize)
      .subscribe(countries => {
        this.paginatedCountries.items = [
          ...this.paginatedCountries.items,
          ...countries.items
        ];
      });
  }

  onSearchChanged(event: any) {
    this.countryService.getCountriesPaginated(event).subscribe(data => {
      if (data.items.length > 0) {
        this.paginatedCountries.items = data?.items;
      }
    });
  }

  onScroll(event) {
    const scrollTop = event.target?.scrollTop;
    const scrollHeight = event.target?.scrollHeight;
    const clientHeight = event.target?.clientHeight;
    if (scrollTop + 1 + clientHeight >= scrollHeight) {
      this.pageIndex++;
      this.getCountries();
    }
  }

  get countriesExist(): boolean {
    return this.paginatedCountries?.items?.length > 0;
  }
}
