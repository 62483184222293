import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { AuthService } from './auth.service';
import { ActiveSessionsInsightsService } from './active-sessions-insights.service';
import { LocalStoreManager } from './local-store-manager.service';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  isStartedLogginSession: boolean = false;

  constructor(
    private idle: Idle,
    private authService: AuthService,
    private activeSessionsInsightsService: ActiveSessionsInsightsService,
    private localStorage: LocalStoreManager
  ) {
    window.addEventListener('storage', this.handleStorageEvent);
    window.addEventListener('scroll', this.startIdleWatch);
    window.addEventListener('keydown', this.startIdleWatch);
    window.addEventListener('mousemove', this.startIdleWatch);

    this.idle.setIdle(900); // idle time in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      if (isLoggedIn && !this.isStartedLogginSession) {
        this.handleStartedLogginSession();
      }
      if (!isLoggedIn) {
        this.handleLoggoutSession();
      }
    });

    // Idle event listeners
    this.idle.onIdleEnd.subscribe(() => {
      this.handleIdleEvent('idleEnd');
    });

    this.idle.onIdleStart.subscribe(() => {
      this.handleIdleEvent('idleStart');
    });
  }

  private handleIdleEvent(event: string) {
    let isIdleStarted = this.localStorage.getData('isIdleStarted');
    if (event === 'idleStart' && !isIdleStarted) {
      this.localStorage.saveSyncedSessionData(true, 'isIdleStarted');
      this.updateEmployeeActiveSessionPerDay(false);
    } else if (event === 'idleEnd' && isIdleStarted) {
      this.localStorage.saveSyncedSessionData(false, 'isIdleStarted');
      this.updateEmployeeActiveSessionPerDay(true);
    }
  }

  updateEmployeeActiveSessionPerDay(
    isIdleEnded: boolean,
    isStartedNewLogginSession: boolean = false
  ) {
    if (!(this.isLoggedIn && this.isAccountManager)) {
      return;
    }
    this.activeSessionsInsightsService
      .updateEmployeeActiveSessionPerDay(isIdleEnded, isStartedNewLogginSession)
      .subscribe(x => {});
  }

  public stopIdleWatch() {
    this.idle.stop();
  }

  startIdleWatch = () => {
    this.idle.watch();
  };

  get isAccountManager(): boolean {
    return this.authService.isAccountManager;
  }

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  handleLoggoutSession() {
    this.isStartedLogginSession = false;
    this.stopIdleWatch();
    this.updateEmployeeActiveSessionPerDay(true);
    this.localStorage.deleteData('isIdleStarted');
  }

  handleStartedLogginSession() {
    this.isStartedLogginSession = true;
    this.updateEmployeeActiveSessionPerDay(false, true);
  }

  private handleStorageEvent = (event: StorageEvent) => {
    if (event.key === 'isIdleStarted') {
      this.handleIdleEvent(event.newValue === 'true' ? 'idleStart' : 'idleEnd');
    }
  };
}
