import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelWithInputComponent } from './label-with-input/label-with-input.component';



@NgModule({
  declarations: [
    LabelWithInputComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LabelWithInputComponent
  ]
})
export class LabelWithInputModule { }
