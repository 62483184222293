import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CityService } from '../../admin-portal-configs/services/city.service';
import { Paginated } from '../models/shipment/paginated.model';
 
@Injectable({
  providedIn: 'root'
})
export class CitiesListResolver implements Resolve<Paginated> {
  constructor(private router: Router, private cityService: CityService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Paginated> {
    return this.cityService.searchCities('').pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
