<div class="row p-3">
  <div class="col-lg-3 col-md-1 col-sm-1"></div>
  <div class="col-lg-6 col-md-10 col-sm-10 Card pb-1">
    <form [formGroup]="carrierForm" id="carrierForm" (ngSubmit)="save()">
      <h4>
        {{
          !isNewCarrier
            ? ("config.management.Carrier.Edit" | translate) +
              " " +
              carrier.name
            : ("config.management.Carrier.New" | translate)
        }}
      </h4>
      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <fieldset class="nested-fieldset">
        <div *ngIf="duplicateCarrier" class="error-message">
          <app-error-badge
            [thinBar]="true"
            message="{{
              'config.management.Carrier.Editor.DuplicateEntry' | translate
            }}"
            class="warning-badge"
          ></app-error-badge>
        </div>

        <div *ngIf="typeRequired" class="error-message">
          <app-error-badge
            [thinBar]="true"
            message="{{
              'config.management.Carrier.Editor.TypeRequired' | translate
            }}"
            class="warning-badge"
          ></app-error-badge>
        </div>

        <label for="modeOfTransport">Mode of Transport</label>
        <div class="row ml-1">
          <div class="col form-group ">
            <input
              formControlName="isAirCarrier"
              type="checkbox"
              id="isAirCarrier"
              (change)="validateFields()"
              class="form-check-input"
              [value]="true"
            />
            <label for="isAirCarrier" class="form-check-label">{{
              "config.management.Carrier.AirCarrier" | translate
            }}</label>
          </div>
          <div class="col form-group">
            <input
              formControlName="isOceanCarrier"
              type="checkbox"
              id="isOceanCarrier"
              (change)="validateFields()"
              class="form-check-input"
              [value]="true"
            />
            <label for="isOceanCarrier" class="form-check-label">{{
              "config.management.Carrier.OceanCarrier" | translate
            }}</label>
          </div>
          <div class="col form-group">
            <input
              formControlName="isLandCarrier"
              type="checkbox"
              id="isLandCarrier"
              (change)="validateFields()"
              class="form-check-input"
              [value]="true"
            />
            <label for="isLandCarrier" class="form-check-label">{{
              "config.management.Carrier.LandCarrier" | translate
            }}</label>
          </div>
        </div>

        <div class="form-group mb-3">
          <label for="name">{{ "config.management.Name" | translate }}</label>
          <input
            formControlName="name"
            [ngClass]="{
              'is-invalid':
                carrierForm.get('name').errors &&
                carrierForm.get('name').touched
            }"
            type="text"
            class="form-control"
            id="name"
            placeholder="{{
              'config.management.Carrier.NamePlaceHolder' | translate
            }}"
          />
          <small
            *ngIf="
              carrierForm.get('name').touched &&
              carrierForm.get('name').hasError('required')
            "
            class="form-text text-danger"
            >{{
              "config.management.Carrier.Editor.CarrierNameRequired" | translate
            }}</small
          >
        </div>

        <div class="form-group mb-3">
          <label for="carrierAbbreviatedName">{{
            "config.management.Carrier.AbbreviatedName" | translate
          }}</label>
          <input
            formControlName="carrierAbbreviatedName"
            [ngClass]="{
              'is-invalid':
                carrierForm.get('carrierAbbreviatedName').errors &&
                carrierForm.get('carrierAbbreviatedName').touched
            }"
            type="text"
            class="form-control"
            id="carrierAbbreviatedName"
            placeholder="{{
              'config.management.Carrier.AbbreviatedNamePlaceHolder' | translate
            }}"
          />
          <small
            *ngIf="
              carrierForm.get('carrierAbbreviatedName').touched &&
              carrierForm.get('carrierAbbreviatedName').hasError('required')
            "
            class="form-text text-danger"
            >{{
              "config.management.Carrier.Editor.CarrierAbbreviatedNameRequired"
                | translate
            }}
          </small>
        </div>

        <div class="form-group mb-3" *ngIf="isAirCarrier">
          <label for="iata/icao">{{
            "config.management.Carrier.IATA/ICAO" | translate
          }}</label>
          <input
            formControlName="iataicaoCode"
            [ngClass]="{
              'is-invalid':
                carrierForm.get('iataicaoCode').errors &&
                carrierForm.get('iataicaoCode').touched
            }"
            type="text"
            class="form-control"
            id="iataicaoCode"
            placeholder="{{
              'config.management.Carrier.CodePlaceHolder' | translate
            }}"
          />
          <small
            *ngIf="
              carrierForm.get('iataicaoCode').touched &&
              carrierForm.get('iataicaoCode').hasError('required')
            "
            class="form-text text-danger"
            >{{ "config.management.Carrier.Editor.CodeRequired" | translate }}
          </small>
          <label for="airlineCode">{{
            "config.management.Carrier.AirlineCode" | translate
          }}</label>
          <input
            formControlName="airlineCode"
            [ngClass]="{
              'is-invalid':
                carrierForm.get('airlineCode').errors &&
                carrierForm.get('airlineCode').touched
            }"
            type="text"
            class="form-control"
            id="airlineCode"
            placeholder="{{
              'config.management.Carrier.AirlineCodePlaceHolder' | translate
            }}"
          />
          <small
            *ngIf="
              carrierForm.get('airlineCode').touched &&
              carrierForm.get('airlineCode').hasError('required')
            "
            class="form-text text-danger"
            >{{
              "config.management.Carrier.Editor.AirlineCodeRequired" | translate
            }}
          </small>
        </div>

        <div class="form-group mb-3" *ngIf="isOceanCarrier">
          <label for="scacCode">{{
            "config.management.Carrier.SCAC" | translate
          }}</label>
          <input
            formControlName="scacCode"
            [ngClass]="{
              'is-invalid':
                carrierForm.get('scacCode').errors &&
                carrierForm.get('scacCode').touched
            }"
            type="text"
            class="form-control"
            id="scacCode"
            placeholder="{{
              'config.management.Carrier.CodePlaceHolder' | translate
            }}"
          />
          <small
            *ngIf="
              carrierForm.get('scacCode').touched &&
              carrierForm.get('scacCode').hasError('required')
            "
            class="form-text text-danger"
            >{{ "config.management.Carrier.Editor.CodeRequired" | translate }}
          </small>
        </div>
      </fieldset>

      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <div class="col-sm-12 form-group">
        <div ngPreserveWhitespaces class="float-right">
          <button (click)="cancel()" class="btn btn-danger">
            <i class="fa fa-times"></i>
            {{ "config.management.Carrier.Editor.Cancel" | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="isSaving || !carrierForm.valid"
          >
            <i *ngIf="!isSaving" class="fa fa-save"></i>
            <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
            {{
              isSaving
                ? ("config.management.Carrier.Editor.Saving" | translate)
                : ("config.management.Carrier.Editor.Save" | translate)
            }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
