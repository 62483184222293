import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-port-popup',
  templateUrl: './port-popup.component.html',
  styleUrls: ['./port-popup.component.scss']
})
export class PortPopupComponent implements OnInit {
  @Input() name;
  @Input() isAir = true;

  constructor() {
  }

  ngOnInit() {
  }

}
