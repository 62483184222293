import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from '../../../../shared/services/alert.service';
import { AppTranslationService } from '../../../../shared/services/app-translation.service';
import { Utilities } from '../../../../shared/services/utilities';
import { ActivatedRoute } from '@angular/router';
import { ChargeType } from 'src/app/shared/models/shipment/charge-type.model';
import { ChargeTypeService } from '../../../services/charge-type.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-charge-type-list',
  templateUrl: './charge-type-list.component.html',
  styleUrls: ['./charge-type-list.component.scss']
})
export class ChargeTypeListComponent implements OnInit {
  columns: any[] = [];
  rows: ChargeType[] = [];
  rowsCache: ChargeType[] = [];
  loadingIndicator: boolean;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private chargeTypeService: ChargeTypeService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      var chargeTypes = data['chargeTypes'];

      chargeTypes = this.getIndecies(chargeTypes);

      this.rowsCache = [...chargeTypes];
      this.rows = chargeTypes;
    });

    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      { prop: 'name', name: gT('config.management.Name'), width: 100 }
    ];

    if (this.canUpdateChargeType || this.canDeleteChargeType) {
      this.columns.push({
        prop: 'options',
        name: gT('config.management.ChargeTypes.Manage'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      });
    }
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  deleteChargeType(row: ChargeType) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' + row.name + '" charge type?',
      DialogType.confirm,
      () => this.deleteChargeTypeHelper(row)
    );
  }

  deleteChargeTypeHelper(row: ChargeType) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.chargeTypeService.deleteChargeType(row.id.toString()).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter(item => item !== row);
        this.rows = this.rows.filter(item => item !== row);

        this.rowsCache = this.getIndecies(this.rowsCache);

        this.rows = this.rowsCache;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the charge type.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  getIndecies(array: any) {
    array.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }

  get canCreateChargeType(): boolean {
    return this.authService.canCreateChargeType;
  }

  get canUpdateChargeType(): boolean {
    return this.authService.canUpdateChargeType;
  }

  get canDeleteChargeType(): boolean {
    return this.authService.canDeleteChargeType;
  }
}
