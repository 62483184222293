import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NotificationHelperService } from 'src/app/shared/services/notification-helper.service';
import { Notification } from '../../../models/notification.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { NotificationService } from '../../../services/notification.service';
import { SignalRService } from 'src/app/shared/services/signal-r.service';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-notifications-viewer',
  templateUrl: './notifications-viewer.component.html',
  styleUrls: ['./notifications-viewer.component.scss']
})
export class NotificationsViewerComponent implements OnInit, OnDestroy {
  loadingIndicator: boolean;
  notificationSubscription : Subscription;
  dataLoadingConsecutiveFailurs = 0;
  dataLoadingSubscription: any;
  pageIndex = 0;
  pageSize = 5;
  isLoading: boolean;

  private timerSubscription: Subscription;

  constructor(
    private alertService: AlertService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private notificationHelper: NotificationHelperService,
    private router: Router,
    private sginalRService: SignalRService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initDataLoading();
    this.subscribeOnSignalRNotification();

    this.timerSubscription = interval(60000).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  subscribeOnSignalRNotification() {
    this.notificationSubscription = this.sginalRService?.notificationAdded.subscribe(data => {
      this.notificationService.pushToRecentNotification(data?.notification);
    });
  }

  ngOnDestroy() {
    if (this.dataLoadingSubscription) {
      this.dataLoadingSubscription.unsubscribe();
    }
    this.timerSubscription.unsubscribe();
    this.notificationSubscription.unsubscribe();
  }

  get notifications(): Notification[] {
    return this.notificationService.recentNotifications;
  }

  initDataLoading() {
    if (!this.authService.isLoggedIn || this.authService.isAdmin) {
      this.notificationService.recentNotifications = null;
      return;
    }

    if (this.notificationService.recentNotifications) {
      return;
    }

    this.loadingIndicator = true;

    const dataLoadTask = this.notificationService.getEmployeeNotifications();

    if (dataLoadTask) {
      if (this.authService.isLoggedIn) {
        this.dataLoadingSubscription = dataLoadTask.subscribe(
          notifications => {
            this.loadingIndicator = false;
            this.dataLoadingConsecutiveFailurs = 0;
          },
          error => {
            this.loadingIndicator = false;

            this.alertService.logError(error);

            if (this.dataLoadingConsecutiveFailurs++ < 5) {
              setTimeout(() => this.initDataLoading(), 5000);
            } else {
              this.alertService.logError(error);
            }
          }
        );
      }
    }

    this.dataLoadingSubscription = null;
  }

  onClickNotification(notification: Notification) {
    if (!notification.isViewed) {
      this.notificationService
        .readUnreadNotification([notification.id])
        .subscribe(data => {
          notification.isViewed = true;
        });
    }

    if (
      !this.notificationHelper.isAssignOrUnassignAccountManagerNotification(
        notification
      )
    ) {
      this.router.navigate([notification.url]);
    }
  }

  getEmployeeNotifications() {
    return this.notificationService
      .getEmployeeNotifications(this.pageIndex, this.pageSize)
      .subscribe(result => {
        this.isLoading = false;
      });
  }

  get unreadNotifications(): Notification[] {
    return this.notifications?.filter(n => !n.isViewed);
  }

  markAllNotificationsAsRead(): void {
    this.notificationService.markAllNotificationsAsRead().subscribe(
      response => {
        for (const n of this.unreadNotifications) {
          n.isViewed = true;
        }
      },
      error => {
        this.alertService.logError(error);
        this.alertService.showMessage(
          'Notification Error',
          'Marking read notifications failed',
          MessageSeverity.error
        );
      }
    );
  }

  markNotificationsAsRead(): void {
    if (this.unreadNotifications && this.unreadNotifications.length > 0) {
      this.notificationService
        .readUnreadNotification(this.unreadNotifications.map(n => n.id))
        .subscribe(
          response => {
            for (const n of this.unreadNotifications) {
              n.isViewed = true;
            }
          },
          error => {
            this.alertService.logError(error);
            this.alertService.showMessage(
              'Notification Error',
              'Marking read notifications failed',
              MessageSeverity.error
            );
          }
        );
    }
  }

  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight &&
      this.pageIndex + 1 < this.totalNumberOfPages &&
      !this.isLoading
    ) {
      this.pageIndex++;
      this.isLoading = true;
      this.getEmployeeNotifications();
    }
  }

  get totalNumberOfPages() {
    return this.totalNotificationsCount / this.pageSize;
  }

  get totalNotificationsCount() {
    return this.notificationService.totalNotificationsCount;
  }
}
