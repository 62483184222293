import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApprovalActivityTransition } from '../models/approval-activity-transition.model';
import { ApprovalActivityService } from '../services/approval-activity.service';

@Injectable()
export class ApprovalActivityTransitionResolver
  implements Resolve<ApprovalActivityTransition> {
  constructor(public approvalActivityService: ApprovalActivityService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<ApprovalActivityTransition> {
    let id = JSON.parse(route.params.id) as number;
    return this.approvalActivityService.getWithTransitionById(id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
