import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContainerTitle } from '../../models/enums/container-title.enum';

@Component({
  selector: 'app-check-selection-card',
  templateUrl: './check-selection-card.component.html',
  styleUrls: ['./check-selection-card.component.scss']
})
export class CheckSelectionCardComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() icon: string = null;
  @Input() isSelected: boolean = false;
  @Input() hasError: boolean;

  @Output() cardSelectedEmitter = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  submit(): void {
    this.isSelected = this.isSelected ?  null : !this.isSelected;
    this.cardSelectedEmitter.emit(this.isSelected);
  }

  get isContainers(): boolean {
    let isContainers =
      this.title === ContainerTitle.container20Title ||
      this.title === ContainerTitle.container40Title ||
      this.title === ContainerTitle.container40HcTitle;

    return isContainers;
  }
}
