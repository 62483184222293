import { Component, OnInit, Input } from '@angular/core';
import { QuoteBase } from '../../models/quote-base.model';
import { DomesticTruckingInformation } from 'src/app/shared/models/domestic-trucking-information.model';
import { CreateQuoteHelperService } from 'src/app/create-quote/services/create-quote-helper.service';
import { LandTrucking } from 'src/app/shared/models/land-trucking';

@Component({
  selector: 'app-summary-trucking-section',
  templateUrl: './summary-trucking-section.component.html',
  styleUrls: ['./summary-trucking-section.component.scss']
})
export class SummaryTruckingSectionComponent implements OnInit {
  @Input() quote: QuoteBase;

  constructor(private quoteHelpersService: CreateQuoteHelperService) {}

  ngOnInit(): void {}

  get hasDomesticService(): boolean {
    return this.quoteHelpersService.isSelectedDomesticService(
      this.quote?.quoteServices
    );
  }

  get domesticTruckingInformation(): DomesticTruckingInformation {
    return this.quoteHelpersService.getDomesticTruckingInformation(this.quote);
  }

  get domesticTruckingRoute(): string {
    if (this.hasDomesticService) {
      let domesticTruckingAddressPickup = this.quoteHelpersService.getDomesticTruckingAddressPickup(
        this.quote
      );
      let domesticTruckingAddressDelivery = this.quoteHelpersService.getDomesticTruckingAddressDelivery(
        this.quote
      );
      let cityNamePickup = domesticTruckingAddressPickup.cityName;
      let districtPickup = domesticTruckingAddressPickup.districtName;

      let cityNameDelivery = domesticTruckingAddressDelivery.cityName;
      let districtDelivery = domesticTruckingAddressDelivery.districtName;
      return (
        districtPickup +
        ', ' +
        cityNamePickup +
        ' -> ' +
        districtDelivery +
        ', ' +
        cityNameDelivery
      );
    }
    return '-';
  }

  get isLandStandaloneService(): boolean {
    return this.quoteHelpersService.isLandStandaloneService(
      this.quote?.quoteServices
    );
  }

  get quoteLandTruckingDetails(): LandTrucking {
    return this.quoteHelpersService.getQuoteLandTruckingDetails(this.quote);
  }
}
