import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators'; 
import { ActivityService } from '../../admin-portal-configs/services/activity.service';
import { Activity } from '../models/shipment/activity.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityListResolver
  implements Resolve<Activity[]> {
  constructor(
    private router: Router,
    private activityService: ActivityService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Activity[]> {
    return this.activityService.getAll().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
