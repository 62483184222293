<div class="main-container">
  <img [src]="freePlLogo.logoPath" alt="freePl" class="freepl-logo" />
  <h3>{{ "login.WelcomeBack" | translate }}</h3>
  <div>
    <button
      *ngIf="isModal"
      type="button"
      class="close float-right text-light"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form
    *ngIf="formResetToggle"
    class="login-form container"
    name="loginForm"
    #f="ngForm"
    novalidate
    (ngSubmit)="f.form.valid ? login() : null"
  >
    <div class="form-group row">
      <label for="username" class="form-label">Email</label>
      <input
        type="text"
        id="login-username"
        name="username"
        placeholder="{{ 'login.UsernamePlaceholder' | translate }}"
        autocomplete="username"
        class="form-control"
        [ngClass]="{
          'is-valid': f.submitted && username.valid,
          'is-invalid': f.submitted && !username.valid
        }"
        [(ngModel)]="userLogin.userName"
        #username="ngModel"
        required
      />
    </div>
    <div class="row invalid-login" *ngIf="f.submitted && !username.valid">
      <span class="invalid-login-message">
        {{ "login.alerts.EmptyUsername" | translate }}
      </span>
    </div>
    <div class="form-group row">
      <label for="password" class="form-label">Password</label>
      <input
        type="password"
        id="login-password"
        name="password"
        placeholder="{{ 'login.PasswordPlaceholder' | translate }}"
        autocomplete="current-password"
        class="form-control"
        [ngClass]="{
          'is-valid': f.submitted && password.valid,
          'is-invalid': f.submitted && !password.valid
        }"
        [(ngModel)]="userLogin.password"
        #password="ngModel"
        required
      />
    </div>

    <div
      class="row invalid-login"
      *ngIf="isInvalidLogin || (f.submitted && !username.valid)"
    >
      <span class="invalid-login-message">
        {{
          userLogin.password
            ? ("login.alerts.InvalidUsernameOrPassword" | translate)
            : ("login.alerts.EmptyPassword" | translate)
        }}
      </span>
    </div>

    <div class="mb-3 row options-section">
      <div class="form-check">
        <mat-checkbox
          class="login-rememberme"
          name="login-rememberme"
          [(ngModel)]="userLogin.rememberMe"
        >
          <label for="login-rememberme" class="login-rememberme-label">{{
            "login.RememberMe" | translate | translate
          }}</label>
        </mat-checkbox>
      </div>
      <div *ngIf="!isModal">
        <!-- <span class="card-text"
                >{{ "login.RegisterIntro" | translate }} </span
              ><a class="card-link" routerLink="/register">{{
                "login.Register" | translate
              }}</a>
              | -->
        <a class="card-link cant-access-acount" routerLink="/recoverpassword">{{
          "login.CantAccessAccount" | translate
        }}</a>
      </div>
    </div>
    <div class="form-group row login-btn">
      <div>
        <button
          *ngIf="!isExternalLogin"
          type="submit"
          class="btn login-button"
          [disabled]="isLoading"
        >
          <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
          {{
            isLoading
              ? ("login.LoggingIn" | translate)
              : ("login.Login" | translate)
          }}
        </button>
        <!-- <div
                  *ngIf="!isLoading || isExternalLogin"
                  class="d-flex h-100 float-right"
                >
                  <span class="align-self-center text-muted mr-1"
                    >{{ "login.ConnectWith" | translate }}
                  </span>
                  <button
                    class="
                      align-self-center
                      btn btn-outline-danger btn-sm btn-social
                    "
                    (click)="loginWithGoogle()"
                    [disabled]="isLoading"
                  >
                    <i class="fa fa-google"></i>
                  </button>
                  <button
                    class="
                      align-self-center
                      btn btn-outline-primary btn-sm btn-social
                      mx-1
                    "
                    (click)="loginWithFacebook()"
                    [disabled]="isLoading"
                  >
                    <i class="fa fa-facebook"></i>
                  </button>
                </div> -->
      </div>
    </div>
  </form>
</div>

<!-- Language Selectors: Not used right now -->
<!-- <div *ngIf="!isModal" ngPreserveWhitespaces class="language-select">
  <button
    type="button"
    (click)="changeLanguage('en')"
    class="btn english"
  ></button>
  <button
    type="button"
    (click)="changeLanguage('fr')"
    class="btn french"
  ></button>
  <button
    type="button"
    (click)="changeLanguage('de')"
    class="btn german"
  ></button>
  <button
    type="button"
    (click)="changeLanguage('pt')"
    class="btn portuguese"
  ></button>
  <button
    type="button"
    (click)="changeLanguage('ar')"
    class="btn arabic"
  ></button>
  <button
    type="button"
    (click)="changeLanguage('ko')"
    class="btn korean"
  ></button>
</div> -->
<!--<app-container-card [type]=""></app-container-card>-->
