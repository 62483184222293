import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  CanLoad,
  Route
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    if (this.authorize()) {
      return true;
    }
   // this.authService.loginRedirectUrl = url;
    this.router.navigate(['/login']);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    //const url = `/${route.path}`;
    if (this.authorize()) {
      return true;
    }
    //this.authService.loginRedirectUrl = url;
    this.router.navigate(['/login']);
  }

  checkLogin(): boolean {
    if (this.authService.isLoggedIn) {
      return true;
    }
    return false;
  }

  authorize() {
    const isLoggedIn = this.checkLogin();
    const isAdmin = this.authService.canSeeAdminSide;
    if (isLoggedIn && isAdmin) {
      return true;
    }
    return false;
  }
}
