import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable()
export class PaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  firstPageLabel = `First page`;
  itemsPerPageLabel = `Showing 0 - 0 of 0  ${this.pageLabel}`;
  lastPageLabel = `Last page`;

  nextPageLabel = 'Next page';
  previousPageLabel = 'Previous page';

  constructor(private router: Router) {}
  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      this.itemsPerPageLabel = `Showing 0 - 0 of 0 ${this.pageLabel}`;
      return `Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);

    const startIndex = page * pageSize + 1;
    const endIndex = Math.min(length, (page + 1) * pageSize);

    this.itemsPerPageLabel = `Showing ${startIndex} - ${endIndex} of ${length} ${this.pageLabel}`;
    return `Page ${page + 1} of ${amountPages}`;
  }

  get pageLabel(): string {
    let isQuotesPage = this.router.url.includes('quote');
    let isRegistrationsPage = this.router.url.includes('registrations');
    let isProductsPage = this.router.url.includes('products-library');
    let isApprovalsPage = this.router.url.includes('approvals');
    if (isQuotesPage) {
      return 'Quotes';
    } else if (isRegistrationsPage) {
      return 'Registrations';
    } else if (isProductsPage) {
      return 'Products';
    } else if (isApprovalsPage) {
      return 'Approvals';
    } else {
      return 'Shipments';
    }
  }
}
