import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectedLoadType } from 'src/app/admin-portal-configs/models/selected-load-type';
import { TruckTypeWithSelectedLoadType } from 'src/app/admin-portal-configs/models/truck-type-with-load-type';
import { TruckTypeService } from 'src/app/admin-portal-configs/services/truck-type.service';
import { HelperService } from 'src/app/shared/services/helper.service';
@Component({
  selector: 'app-add-or-edit-truck-type',
  templateUrl: './add-or-edit-truck-type.component.html',
  styleUrls: ['./add-or-edit-truck-type.component.scss']
})
export class AddOrEditTruckTypeComponent implements OnInit {
  truckType: TruckTypeWithSelectedLoadType;
  truckTypeForm: UntypedFormGroup;
  loadTypes: SelectedLoadType[];
  isDuplicateTruckType: boolean;
  isSaving: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private truckTypeService: TruckTypeService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.truckType = data['truckType'];
    });
    this.getAllLoadsType();
    this.initForm();
    this.addSelectedLoadType();
  }

  saveTruckType() {
    if (this.truckTypeForm.invalid) {
      return;
    }
    const formVal = this.truckTypeForm.value;

    if (this.isForAdd) {
      this.addNewTruckType(formVal);
    } else {
      this.updateTruckType(formVal);
    }
  }

  addNewTruckType(newTruckType: TruckTypeWithSelectedLoadType) {
    this.isSaving = true;
    this.truckTypeService.addNewTruckType(newTruckType).subscribe(
      data => {
        this.router.navigate(['/settings/truck-type']);
      },
      error => {
        this.isSaving = false;
        this.saveFailedHelper(error);
      }
    );
  }

  updateTruckType(truckType: TruckTypeWithSelectedLoadType) {
    this.isSaving = true;
    truckType.id = this.truckType.id;
    this.truckTypeService.updateTruckType(truckType).subscribe(
      data => {
        this.router.navigate(['/settings/truck-type']);
      },
      error => {
        this.isSaving = false;
        this.saveFailedHelper(error);
      }
    );
  }

  private saveFailedHelper(error: any) {
    if (error == '409') {
      this.isDuplicateTruckType = true;
      return;
    }
  }

  selectLoadType(event: any) {
    const formArray: UntypedFormArray = this.loadTypesFormArray;

    if (event.target.checked) {
      formArray.push(
        new UntypedFormControl({
          id: event.target.value,
          name: this.loadTypes.find(i => i.id == event.target.value).name,
          isSelected: true
        })
      );
    } else {
      let i: number = 0;

      formArray.controls.forEach((ctrl: UntypedFormControl) => {
        if (ctrl.value.id == event.target.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  cancel() {
    this.router.navigate(['/settings/truck-type']);
  }

  getAllLoadsType() {
    this.helperService.getLoadTypes().subscribe(response => {
      this.loadTypes = response;
    });
  }

  private checkIfSelected(loadType: any): boolean {
    if (!this.truckType.loadTypes) {
      return false;
    }

    return this.truckType.loadTypes.findIndex(i => i.id == loadType.id) > -1;
  }

  private addSelectedLoadType() {
    if (this.truckType.loadTypes) {
      const formArray: UntypedFormArray = this.loadTypesFormArray;

      this.truckType.loadTypes.forEach(loadType => {
        formArray.push(
          new UntypedFormControl({
            id: loadType.id,
            name: loadType.name,
            isSelected: true
          })
        );
      });
    }
  }

  private initForm() {
    this.truckType = this.truckType || ({} as TruckTypeWithSelectedLoadType);
    this.truckType.loadTypes = this.truckType.loadTypes || []; // Initialize as an empty array

    this.truckTypeForm = this.fb.group(
      {
        name: [this.truckType.name || '', [Validators.required]],
        loadTypes: this.fb.array([], Validators.required),
        isDomesticTrucking: [this.truckType.isDomesticTrucking],
        isPortTrucking: [this.truckType.isPortTrucking],
        isLandTrucking: [this.truckType.isLandTrucking]
      },
      { validator: this.compatibleServicesValidator() }
    );
  }
  compatibleServicesValidator(): Validators {
    return (formGroup: UntypedFormGroup): { [key: string]: boolean } | null => {
      const isDomesticTruckingChecked = formGroup.get('isDomesticTrucking')
        ?.value;
      const isPortTruckingChecked = formGroup.get('isPortTrucking')?.value;
      const isLandTruckingChecked = formGroup.get('isLandTrucking')?.value;

      const checked =
        isDomesticTruckingChecked ||
        isPortTruckingChecked ||
        isLandTruckingChecked;

      return checked ? null : { compatibleTrucking: true };
    };
  }
  get isForAdd(): boolean {
    return !this.router.url.includes('/edit');
  }
  get loadTypesFormArray(): UntypedFormArray {
    return this.truckTypeForm.get('loadTypes') as UntypedFormArray;
  }
}
