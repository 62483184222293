import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivityTransition } from '../../admin-portal-configs/models/activity-transition.model';
import { ActivityService } from '../../admin-portal-configs/services/activity.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityTransitionResolver
  implements Resolve<ActivityTransition[]> {
  id: number;
  constructor(
    private router: Router,
    private activityService: ActivityService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ActivityTransition[]> {
    this.id = JSON.parse(route.params.id) as number;
    return this.activityService.getWithTransitionById(this.id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
