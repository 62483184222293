import { Component, OnInit } from '@angular/core';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { AdminPortalConfigsService } from '../../admin-portal-configs.service';

@Component({
  selector: 'app-configs-list',
  templateUrl: './configs-list.component.html',
  styleUrls: ['./configs-list.component.scss']
})
export class ConfigsListComponent implements OnInit {
  itemsList: string[] = [];

  constructor(
    private configsItems: AdminPortalConfigsService,
  ) {}

  ngOnInit() {
    this.itemsList = this.configsItems.getItems();
  }

  toRoute(value: string) {
    return value.toLowerCase().replace(/\s/g, '-');
  }
}
