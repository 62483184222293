import { AfterViewChecked, Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appThousandSeparator]'
})
export class ThousandSeparatorDirective implements OnInit, AfterViewChecked {
  constructor(private el: ElementRef) {}
  ngAfterViewChecked(): void {
    this.formatInitValue();
  }
  ngOnInit() {
    this.formatInitValue();
  }
  
  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    if (value) {
      const formattedValue = this.formatValue(value);
      this.el.nativeElement.value = formattedValue;
    }
  }

  public formatValue(value: string): string {
    // Remove non-numeric and non-decimal characters except the dot
    const cleanValue = value.replace(/[^0-9.]/g, '');

    // Split the value into whole number and decimal parts
    const parts = cleanValue.split('.');

    const wholeNumber = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let decimalValue = '';

    // Handle decimal part if it exists
    if (parts.length > 1) {
      decimalValue = '.' + parts[1];
    }

    // Concatenate whole number and decimal part (if any)
    const formattedValue = wholeNumber + decimalValue;
    return formattedValue;
  }
  private formatInitValue()
  {
    const initialValue = this.el.nativeElement.value;
    if (initialValue) {
      const formattedValue = this.formatValue(initialValue);
      this.el.nativeElement.value = formattedValue;
    }  

  }
}
