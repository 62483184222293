import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ApprovalActivityService } from '../../services/approval-activity.service';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { ApprovalActivity } from '../../models/approval-activity.model';
import { ApprovalActivityLookups } from '../../models/approval-activity-lookups.model';
import { ApprovalActivityCategotyEnum } from '../../enums/approval-activity-categoty.enum';

@Component({
  selector: 'app-add-or-edit-approval-activity',
  templateUrl: './add-or-edit-approval-activity.component.html',
  styleUrls: ['./add-or-edit-approval-activity.component.scss']
})
export class AddOrEditApprovalActivityComponent implements OnInit {
  activityForm: UntypedFormGroup;
  activity: ApprovalActivity;
  lookups: ApprovalActivityLookups;
  isSaving: boolean = false;
  isNewApprovalActivity: boolean = false;
  isDuplicateActivity: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private formsHelperService: FormsHelperService,
    private approvalActivityService: ApprovalActivityService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.activity = data['approvalActivity'];
      this.lookups = data['lookups'];
    });

    this.isNewApprovalActivity = this.activity == null;

    this.initForm();
  }

  initForm(): void {
    this.activityForm = this.fb.group({
      name: [this.activity?.name, [Validators.required]],
      statusId: [this.activity?.statusId, [Validators.required]],
      systemStatusId: [this.activity?.systemStatusId, [Validators.required]],
      progressPointId: [this.activity?.progressPointId]
    });
  }

  save(): void {
    const formValue = this.activityForm.value;

    if (!this.activityForm.valid) {
      this.alertService.error('Invalid Form!');
      return;
    }

    if (this.isNewApprovalActivity) {
      this.addNewApprovalActivity(formValue);
    } else {
      this.updateApprovalActivity(formValue);
    }
  }

  addNewApprovalActivity(formValue: ApprovalActivity): void {
    this.approvalActivityService.addNewApprovalActivity(formValue).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  updateApprovalActivity(formValue: ApprovalActivity): void {
    this.approvalActivityService
      .updateApprovalActivity(this.activity.id, formValue)
      .subscribe(
        data => {
          this.saveSuccessHelper(data);
        },
        error => {
          this.saveFailedHelper(error);
        }
      );
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    if (error == '409') {
      this.isDuplicateActivity = true;
      return;
    }
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occured whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  cancel(): void {
    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/approval-activities']);
  }

  isInvalidFormControl(controlName: string): boolean {
    return this.formsHelperService.isInvalidFormControl(
      this.activityForm,
      controlName
    );
  }

  private saveSuccessHelper(activity?: ApprovalActivity) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    if (this.isNewApprovalActivity) {
      this.alertService.showMessage(
        'Success',
        `Approval Activity \"${activity.name}\" was created successfully.`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to Approval Activity \"${activity.name}\" were saved successfully.`,
        MessageSeverity.success
      );
    }

    this.router.navigate(['/settings/approval-activities']);
  }

  get diaplayedCategory(): string {
    let categoryName = this.activity?.approvalActivityCategory?.name;
 
    if (!categoryName) {
      return null;
    }
    switch (categoryName) {
      case ApprovalActivityCategotyEnum.RequestSubmitted:
        return 'submission of requests';
      default:
        return '';
    }
  }
}
