import { HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SelectedServices } from '../models/selected-services.model';
import { DocumentTypeWithService } from '../models/document-type-with-service.models';
import { DocumentType } from 'src/app/shared/models/document-type.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService {
  baseURL = environment.apiUrl + 'DocumentType';

  constructor(private http: HttpClient) {}

  getDocumentTypesForSelectedParent(parentType: string): Observable<any> {
    return this.http.get<DocumentType[]>(
      this.baseURL + `/parent/` + parentType
    );
  }

  getDocumentTypesWithServices(): Observable<DocumentTypeWithService[]> {
    return this.http.get<DocumentTypeWithService[]>(this.baseURL + '/services');
  }

  getById(id: number): Observable<DocumentType> {
    return this.http.get<DocumentType>(`${this.baseURL}/${id}`);
  }

  getDocumentTypeByName(documentTypeName: string): Observable<DocumentType> {
    return this.http.get<DocumentType>(
      `${this.baseURL}/documentTypeName/${documentTypeName}`
    );
  }

  addNewDocumentType(
    documentType: DocumentTypeWithService,
    servicesList: SelectedServices[]
  ): Observable<DocumentTypeWithService> {
    documentType.services = servicesList.filter(i => i.isSelected == true);
    return this.http.post<DocumentTypeWithService>(this.baseURL, documentType);
  }

  deleteDocumentType(id: string): Observable<DocumentTypeWithService> {
    return this.http.delete<DocumentTypeWithService>(this.baseURL + '/' + id);
  }

  updateDocumentType(
    id: number,
    servicesList: SelectedServices[],
    documentType: DocumentTypeWithService
  ): Observable<DocumentTypeWithService> {
    documentType.services = servicesList.filter(i => i.isSelected == true);

    return this.http.put<DocumentTypeWithService>(
      `${this.baseURL}/${id}`,
      documentType
    );
  }
}
