import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativeTime',
  pure: false
})
export class RelativeTimePipe implements PipeTransform {
  transform(value: string | Date): string {
    if (!value) {
      return;
    }
    const date = new Date(value);
    const now = new Date();
    let seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    let isLeapYear = Number.isInteger(now.getFullYear() / 4) ? true : false;

    if (seconds < 60) {
      return 'just now';
    }

    const intervals = {
      year: isLeapYear ? 31622400 : 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60
    };

    let counter;
    for (let unit in intervals) {
      counter = Math.floor(seconds / intervals[unit]);
      if (counter > 0) {
        if (counter === 1) {
          return `${counter} ${unit} ago`;
        } else {
          return `${counter} ${unit}s ago`;
        }
      }
    }
  }
}
