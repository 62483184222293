import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { imageExtensions } from '../../constants/imageExtensions';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {
  url: string;
  officeExtensions = ['ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx'];
  imageExtensions = imageExtensions


  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.url = data['url'];
  }
  ngOnInit() {}

  get isImage(): boolean {
    var subStrings = this.url?.split('.');
    const fileExtension = subStrings[subStrings?.length - 1];
    return this.imageExtensions.includes(fileExtension);
  }
  get viewerType() {
    var subStrings = this.url?.split('.');
    const fileExtension = subStrings[subStrings?.length - 1];
    if (this.officeExtensions.includes(fileExtension)) {
      return 'office';
    } else {
      return 'google';
    }
  }
}
