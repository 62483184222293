import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { ActivityTransition } from '../../../models/activity-transition.model';
import { ProductRegistrationLeadTime } from 'src/app/admin-portal-configs/models/product-registration-lead-time.model';
import { ProductRegistrationKpisService } from 'src/app/admin-portal-configs/services/product-registration-kpis.service';
import { RegistrationActivityService } from 'src/app/admin-portal-configs/services/registration-activity.service';
import { RegistrationActivity } from 'src/app/admin-portal-configs/models/registration-activity.model';

@Component({
  selector: 'app-add-or-edit-product-registration-kpis',
  templateUrl: './add-or-edit-product-registration-kpis.component.html',
  styleUrls: ['./add-or-edit-product-registration-kpis.component.scss']
})
export class AddOrEditProductRegistrationKpisComponent implements OnInit {
  leadTimeForm: UntypedFormGroup;
  productLeadTime: ProductRegistrationLeadTime;
  isSaving: boolean = false;
  isDuplicateProductLeadTime: boolean = false;
  isNewProductLeadTime: boolean = false;
  activities: RegistrationActivity[];
  endTriggers: RegistrationActivity[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private productLeadTimeService: ProductRegistrationKpisService,
    private route: ActivatedRoute,
    private activityService: RegistrationActivityService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.productLeadTime = data['productRegistrationLeadTime'];
    });

    this.loadActivities();
    this.initForm();
  }

  loadActivities() {
    this.activityService.getAll().subscribe(res => {
      this.activities = res;
      if (this.productLeadTime != null) {
        this.onChangingStartTrigger(null, this.productLeadTime.startTriggerId);
      }
    });
  }

  initForm() {
    this.isNewProductLeadTime = this.productLeadTime == null;
    this.productLeadTime =
      this.productLeadTime || ({} as ProductRegistrationLeadTime);

    this.leadTimeForm = this.fb.group({
      name: [this.productLeadTime.name, [Validators.required]],
      leadTime: [
        this.productLeadTime.leadTime,
        [Validators.required, Validators.min(1)]
      ],
      startTriggerId: [
        this.productLeadTime.startTriggerId,
        [Validators.required]
      ],
      endTriggerId: [this.productLeadTime.endTriggerId, [Validators.required]]
    });
  }

  save() {
    const formVal = this.leadTimeForm.value;

    if (!this.validateForm(formVal)) {
      this.alertService.error('Invalid Form!');
      return;
    }

    formVal.isActive = true;

    if (this.isNewProductLeadTime) {
      this.addNewRequest(formVal);
    } else {
      this.updateRequest(formVal);
    }
  }

  private validateForm(formVal: any) {
    const validTriggers =
      formVal.startTriggerId != 'null' && formVal.endTriggerId != 'null';
    return validTriggers && this.leadTimeForm.valid;
  }

  updateRequest(formVal: any) {
    this.productLeadTimeService
      .updateLeadTime(this.productLeadTime.id, formVal)
      .subscribe(
        data => {
          if (data?.statusCode === 409) {
            this.saveFailedHelper('409');
          } else {
            this.saveSuccessHelper(formVal);
          }
        },
        error => {
          this.saveFailedHelper(error);
        }
      );
  }

  onChangingStartTrigger(event?: any, id?: number) {
    if (id) {
      this.endTriggers = this.activities.filter(a => a.id != id);
    } else if (event) {
      this.endTriggers = this.activities.filter(
        a => a.id != event.target?.value
      );
    }
  }

  addNewRequest(formVal: any) {
    this.productLeadTimeService.addNewLeadTime(formVal).subscribe(
      data => {
        if (data?.statusCode === 409) {
          this.saveFailedHelper('409');
        } else {
          this.saveSuccessHelper(formVal);
        }
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  private saveSuccessHelper(formVal: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    if (this.isNewProductLeadTime) {
      this.alertService.showMessage(
        'Success',
        `Product Registration lead time \"${formVal.name}\" was created successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to product registration lead time \"${formVal.name}\" were saved successfully`,
        MessageSeverity.success
      );
    }

    this.resetForm();
    this.router.navigate(['/settings/product-registration-kpis']);
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    if (error == '409') {
      this.isDuplicateProductLeadTime = true;
      return;
    }
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occured whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  cancel() {
    this.resetForm();

    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/product-registration-kpis']);
  }

  resetForm() {
    this.leadTimeForm.reset();
  }
}
