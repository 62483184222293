import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-snack-bar',
  templateUrl: './success-snack-bar.component.html',
  styleUrls: ['./success-snack-bar.component.scss']
})
export class SuccessSnackBarComponent implements OnInit {
  constructor(
    private router: Router,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<SuccessSnackBarComponent>
  ) {}

  ngOnInit() {}
  onClick(event) {
    if (event.type == 'click') {
      this.router.navigate([this.data.link]);
    }
  }
}
