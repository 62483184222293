import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ShipmentLeadTimeResponse } from '../models/shipment-lead-time-response.model';
import { ShipmentLeadTimeService } from '../services/shipment-lead-time.service';

@Injectable()
export class ShipmentLeadTimeListResolver
  implements Resolve<ShipmentLeadTimeResponse[]> {
  constructor(public shipmentLeadTimeService: ShipmentLeadTimeService) {}

  resolve(): Observable<ShipmentLeadTimeResponse[]> {
    return this.shipmentLeadTimeService.getAll().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
