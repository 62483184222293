export type PermissionNames = 'Manage Registration and Approval services';

export type PermissionValues = 'registrationAndApprovalServices.manage';

export class Permission {
  public static readonly registrationAndApprovalServicesManagementPermission: PermissionValues =
    'registrationAndApprovalServices.manage';
 
  constructor(
    name?: PermissionNames,
    value?: PermissionValues,
    groupName?: string,
    description?: string
  ) {
    this.name = name;
    this.value = value;
    this.groupName = groupName;
    this.description = description;
  }

  public name: PermissionNames;
  public value: PermissionValues;
  public groupName: string;
  public description: string;
  public permissionCategoryId: number;
  public isSelected?: boolean
}
