import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CompanyService } from '../../company/services/company.service';
import { CommissionTieredRate } from '../../company/models/commission-tiered-rate.model';

@Injectable()
export class CommissionTieredRateResolver
  implements Resolve<CommissionTieredRate> {
  companyId: number;
  service: string;
  constructor(private companyService: CompanyService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<CommissionTieredRate> {
    this.companyId = JSON.parse(route.params.companyId) as number;
    this.service = route.params.service;
    return this.companyService
      .getCommissionTieredRate(this.companyId, this.service)
      .pipe(
        catchError(error => {
          return of(null);
        })
      );
  }
}
