<div class="notfound-container ">
  <header class="pageHeader">
    <h3>
      <i
        class="fa fa-exclamation-circle fa-lg page-caption"
        aria-hidden="true"
      ></i>
      {{ "pageHeader.NotFound" | translate }}
    </h3>
  </header>

  <div [@fadeInOut] class="d-flex h-75">
    <div class="m-auto">
      <div class="icon-container">
        <i class="fa fa-exclamation-circle"></i>
        {{ "notFound.404" | translate }}
      </div>
      <div class="text-muted error-description">
        {{ "notFound.pageNotFound" | translate }}
      </div>
      <div>
        <a class="btn btn-primary" routerLink="/"
          ><i class="fa fa-home"></i> {{ "notFound.backToHome" | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
