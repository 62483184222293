import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from '../../../../shared/services/alert.service';
import { AppTranslationService } from '../../../../shared/services/app-translation.service';
import { Utilities } from '../../../../shared/services/utilities';
import { ActivatedRoute } from '@angular/router';
import { CityLocation } from 'src/app/admin-portal-configs/models/city-location.model';
import { CityService } from '../../../services/city.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Paginated } from 'src/app/shared/models/shipment/paginated.model';

@Component({
  selector: 'app-cities-list',
  templateUrl: './cities-list.component.html',
  styleUrls: ['./cities-list.component.scss']
})
export class CitiesListComponent implements OnInit {
  columns: any[] = [];
  rows: CityLocation[] = [];
  rowsCache: CityLocation[] = [];
  loadingIndicator: boolean;
  paginatedCities: Paginated;
  isLoading: boolean;
  pageIndex: number = 0;
  pageSize: number = 10;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private cityService: CityService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.paginatedCities = data['paginatedCities'];
      const cities = this.getIndecies(this.paginatedCities?.items);

      this.rowsCache = [...cities];
      this.rows = this.rowsCache;
    });

    this.initColumns();
  }

  initColumns() {
    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      { prop: 'city', name: gT('config.management.Name'), width: 190 },
      {
        prop: 'country',
        name: gT('config.management.City.CountryName'),
        width: 100
      },
      {
        prop: 'lat',
        name: gT('config.management.City.Latitude'),
        width: 100
      },
      {
        prop: 'lng',
        name: gT('config.management.City.Longtitude'),
        width: 100
      }
    ];
    if (this.canUpdateCities || this.canDeleteCities) {
      let optionsColumn = {
        prop: 'options',
        name: gT('config.management.City.Manage'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      };
      this.columns.push(optionsColumn);
    }
  }

  onSearchChanged(event: any) {
    this.cityService.searchCities(event).subscribe(
      data => {
        this.rows = this.getIndecies(data?.items);
      },
      error => this.alertService.error(error)
    );
  }

  deleteCity(row: CityLocation) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' +
        row.city +
        '" city and all it\'s data ?',
      DialogType.confirm,
      () => this.deleteCityHelper(row)
    );
  }

  deleteCityHelper(row: CityLocation) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.cityService.deleteCity(row.id).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter(item => item !== row);

        this.rowsCache = this.getIndecies(this.rowsCache);
        this.rows = this.rowsCache;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the city.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  getIndecies(array: any) {
    array.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }

  get canCreateCities(): boolean {
    return this.authService.canCreateCities;
  }

  get canUpdateCities(): boolean {
    return this.authService.canUpdateCities;
  }

  get canDeleteCities(): boolean {
    return this.authService.canDeleteCities;
  }

  onScroll(event) {
    const scrollTop = event.target?.scrollTop;
    const scrollHeight = event.target?.scrollHeight;
    const clientHeight = event.target?.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight) {
      if (
        this.paginatedCities?.items?.length <
          this.paginatedCities?.totalItems &&
        !this.isLoading
      ) {
        this.pageIndex++;
        this.getCities();
      }
    }
  }

  getCities() {
    this.isLoading = true;
    this.cityService
      .searchCities('', this.pageIndex, this.pageSize)
      .subscribe(cities => {
        this.isLoading = false;
        let allReturnedCities = [...this.rowsCache, ...cities?.items];
        this.rowsCache = allReturnedCities;
        this.rows = this.getIndecies(allReturnedCities);
      });
  }
}
