import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateExpired } from './date-expired.pipe';

@NgModule({
  declarations: [DateExpired],
  imports: [CommonModule],
  exports: [DateExpired]
})
export class DateExpiredPipeModule {}
