import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { closeDocumentRemovalPopUp } from '../models/shipment/close-document-removal-pop-up.model';
import { DocumentType } from '../models/document-type.model';
import { UploadedFile } from '../models/types';
import { ProductBase } from '../models/product-library/product-base.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  baseURL = environment.apiUrl + 'document';
  helperURL = environment.apiUrl + 'Helper';

  @Output() fileUploadingEmitter: EventEmitter<{
    val: boolean;
    uploadedFile: any;
    data?: any;
    isError?: boolean;
    errorMsg?: string;
    type?: any;
  }> = new EventEmitter<{
    uploadedFile: any;
    val: boolean;
    data?: any;
    isError?: boolean;
    errorMsg?: string;
    type?: any;
  }>();

  @Output() cancelFileUploadingEmitter: EventEmitter<{
    val: boolean;
    uploadedFile: UploadedFile;
  }> = new EventEmitter<{ uploadedFile: UploadedFile; val: boolean }>();

  uploadedFile: UploadedFile = null;

  fileUploadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  @Output() closeDocumentRemovalModalEmitter: EventEmitter<{
    closeDocumentRemoval: closeDocumentRemovalPopUp;
  }> = new EventEmitter<{ closeDocumentRemoval: closeDocumentRemovalPopUp }>();

  @Output() documentNotesChanged: EventEmitter<{
    notes: string;
    id: number;
  }> = new EventEmitter<{ notes: string; id: number }>();

  @Output() documentOtherNameUpdated: EventEmitter<{
    documentOtherName: string;
    oldDocumentTypeName: string;
  }> = new EventEmitter<{
    documentOtherName: string;
    oldDocumentTypeName: string;
  }>();

  @Output() documentDeleted = new EventEmitter();

  constructor(private http: HttpClient) {}

  Upload(formData: any): Observable<any> {
    return this.http.post<any>(this.baseURL, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  UploadProfilePicture(formData: any): Observable<any> {
    return this.http.post<any>(this.baseURL + '/ProfilePicture', formData);
  }

  UploadCompanyLogo(formData: any): Observable<any> {
    return this.http.post<any>(this.baseURL + '/CompanyLogo', formData);
  }

  uploadCommentAttachment(formData: any): Observable<any> {
    return this.http.post<any>(this.baseURL + '/CommentAttachment', formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  deleteDocument(documentId: number, reason?: string): Observable<any> {
    return this.http.delete<any>(
      this.baseURL + `/${documentId}?reason=${reason}`
    );
  }

  getDocumentsTypes$(
    serviceName: string[],
    isAir,
    isOcean,
    isLand?
  ): Observable<DocumentType[]> {
    let serviceNames = '';
    serviceName.forEach(service => {
      serviceNames = serviceNames + `&serviceName=${service}`;
    });
    return this.http.get<DocumentType[]>(
      environment.apiUrl +
        `DocumentType/service?isAir=${isAir}&isOcean=${isOcean}&isLand=${isLand}${serviceNames}`
    );
  }

  uploadingFile(
    isUploading: boolean,
    uploadedFile: UploadedFile,
    data?: any,
    isError?: boolean,
    errorMsg?: string,
    type?: any
  ) {
    if (uploadedFile) {
      this.uploadedFile = uploadedFile;
    }
    this.fileUploadingEmitter.emit({
      val: isUploading,
      uploadedFile: uploadedFile,
      data: data,
      isError,
      errorMsg,
      type
    });
  }

  cancelFileUpload(uploadedFile: UploadedFile) {
    this.cancelFileUploadingEmitter.emit({
      val: false,
      uploadedFile: this.uploadedFile
    });
    this.uploadedFile = null;
  }

  closeDocumentRemovalPopUp(
    isDeleted: boolean,
    documentSqlId: number,
    reason?: string,
    deletedAt?: Date,
    parentDocumentSqlId?: number
  ) {
    let closeDocumentRemoval: closeDocumentRemovalPopUp = {
      val: true,
      isDeleted: isDeleted,
      reason: reason,
      documentSqlId: documentSqlId,
      deletedAt: deletedAt,
      parentDocumentSqlId: parentDocumentSqlId
    };

    this.closeDocumentRemovalModalEmitter.emit({
      closeDocumentRemoval: closeDocumentRemoval
    });
    return;
  }

  updateDocumentNotes(shipmentId: string, id: number, notes: string) {
    return this.http.put(
      environment.apiUrl +
        `Document/Notes?shipmentId=${shipmentId}&id=${id}&notes=${notes}`,
      null
    );
  }

  updateDocumentOtherName(
    shipmentId: string,
    oldDocumentTypeName: string,
    newDocumentOtherName: string
  ) {
    return this.http.put(
      environment.apiUrl +
        `Document/DocumentOtherName?shipmentId=${shipmentId}&oldDocumentTypeName=${oldDocumentTypeName}&newDocumentOtherName=${newDocumentOtherName}`,
      null
    );
  }

  uploadSupplierLogo(formData: any): Observable<any> {
    return this.http.post<any>(this.baseURL + '/SupplierLogo', formData);
  }

  getBulkUploadTemplate(entityName: string): Observable<Blob> {
    return this.http.get(this.baseURL + `/BulkUploadTemplate/${entityName}`, {
      responseType: 'blob'
    });
  }

  replaceMultipleProductDocuments(
    formData: any,
    productIds: string[]
  ): Observable<ProductBase> {
    const queryString = productIds
      .map(id => `productIds=${encodeURIComponent(id)}`)
      .join('&');
    return this.http.post<ProductBase>(
      `${this.baseURL}/MultipleProductDocumentReplacement?${queryString}`,
      formData
    );
  }
}
