import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CompanyService } from '../../company/services/company.service';
import { MyProfileComponent } from 'src/app/employee-profile/pages/my-profile/my-profile.component';
import { EmployeeDetails } from 'src/app/company/models/employee-details.model';

@Injectable()
export class EmployeeResolver implements Resolve<EmployeeDetails> {
  id: number;
  isInMyprofile: boolean = false;
  constructor(private companyService: CompanyService) {}
  resolve(route: ActivatedRouteSnapshot): any {
    this.id = JSON.parse(route.params.id) as number;
    this.isInMyprofile = route.component == MyProfileComponent;
    return this.companyService.getEmployee(this.id, this.isInMyprofile).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
