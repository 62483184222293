import { Component, Input, OnInit } from '@angular/core';
import { FileViewerService } from 'src/app/shared/components/file-viewer/file-viewer.service';
import { ProductDocument } from 'src/app/shared/models/product-document.model';

@Component({
  selector: 'app-product-documents-summary',
  templateUrl: './product-documents-summary.component.html',
  styleUrls: ['./product-documents-summary.component.scss']
})
export class ProductDocumentsSummaryComponent implements OnInit {
  @Input() productDocuments: ProductDocument[];

  constructor(private fileViewerService: FileViewerService) {}

  ngOnInit(): void {}

  viewFile(path: string) {
    this.fileViewerService.view(path);
  }
}
