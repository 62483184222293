import { Component, OnInit } from '@angular/core';
import{
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { ChargeTypeWithService } from '../../../models/charge-type-with-service.model';
import { SelectedServices } from '../../../models/selected-services.model';
import { ChargeTypeService } from '../../../services/charge-type.service';
import { FormsHelperService } from '../../../../shared/services/forms-helper.service';
import { BaseNamedEntity } from '../../../../shared/models/shipment/base-named-entity.model';


@Component({
  selector: 'app-add-or-edit-charge-type',
  templateUrl: './add-or-edit-charge-type.component.html',
  styleUrls: ['./add-or-edit-charge-type.component.scss']
})
export class AddOrEditChargeTypeComponent implements OnInit {
  chargeType: ChargeTypeWithService;
  chargeForm: UntypedFormGroup;
  isSaving: boolean = false;
  serviceTypes: SelectedServices[] = [];
  chargeCategories: BaseNamedEntity[];
  isInvalidForm: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private chargeTypeService: ChargeTypeService,
    private alertService: AlertService,
    private router: Router,
    private translationService: AppTranslationService,
    private route: ActivatedRoute,
    private formHelperService: FormsHelperService,
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.chargeType = data['chargeType'];
      this.serviceTypes = data['lookups'].freePlServices;
      this.chargeCategories = data['lookups'].chargeCategories;
    });
    this.initForm();
  }

  private initForm() {
    this.chargeType = this.chargeType || ({} as ChargeTypeWithService);
    this.chargeForm = this.fb.group({
      name: [
        this.chargeType.name,
        [Validators.required, Validators.maxLength(80)]
      ],
      isNotServiceCharge: [this.chargeType.isNotServiceCharge],
      services: [this.chargeType.services, [Validators.required]],
      categoryId: [this.chargeType.categoryId, [Validators.required]]
    });
  }

  isValidControl(controlName: string) {
    if (this.isInvalidForm) {
      return this.formHelperService.isInvalidFormControl(this.chargeForm, controlName)
    }
  }

  save() {
    this.chargeForm.markAllAsTouched();
    const formVal = this.chargeForm.value;
    if (this.chargeForm.invalid) {
      this.isInvalidForm = true;
      return;
    }
    this.isSaving = true;
    this.processForm(formVal);
    if (this.isNewChargeType) {
      this.addNewRequest(formVal);
    } else {
      this.updateRequest(formVal);
    }
  }

  updateRequest(chargeType: ChargeTypeWithService) {
    const id = this.chargeType.id;
    this.chargeTypeService.updateChargeType(id, chargeType).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  addNewRequest(chargeType: ChargeTypeWithService) {
    chargeType.createdAt = new Date();

    this.chargeTypeService.addNewChargeType(chargeType).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  private processForm(formVal: any) {
    formVal.services.forEach(service => {
      service.isSelected = true;
    });
  }

  private saveSuccessHelper(chargeType?: ChargeTypeWithService) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    if (this.isNewChargeType) {
      this.alertService.showMessage(
        'Success',
        `Charge type \"${chargeType.name}\" was created successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to charge type \"${chargeType.name}\" were saved successfully`,
        MessageSeverity.success
      );
    }
    this.isInvalidForm = false;
    this.resetForm();
    this.router.navigate(['/settings/charge-type']);
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occured whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  cancel() {
    this.resetForm();
    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );
    this.router.navigate(['/settings/charge-type']);
  }

  resetForm() {
    this.chargeForm.reset();
  }

  get selectedServiceTypesCount(): number {
    let services = this.chargeForm.get('services')?.value;
    return services?.length;
  }

  get title(): string {
    return this.isNewChargeType ? 'Add Charge Type' : 'Edit Charge Type';
  }

  get isNewChargeType(): boolean {
    return !this.chargeType?.id;
  }
}
