export enum ModuleName {
  Shipment = 'shipment',
  Quote = 'quote',
  ProductLibrary = 'product Library',
  Supplier = 'supplier',
  Approval = 'approval',
  Registration = 'registration',
  CompanyProfile = 'Company Profile',
  Warehousing = 'Warehousing'
}
