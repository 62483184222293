import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApprovalActivityLookups } from '../models/approval-activity-lookups.model';
import { ApprovalActivityService } from '../services/approval-activity.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovalActivityLookupsResolver
  implements Resolve<ApprovalActivityLookups> {
  constructor(private approvalActivityService: ApprovalActivityService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ApprovalActivityLookups> {
    return this.approvalActivityService.getLookups().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
