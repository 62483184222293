<div class="pause-product-con">
  <svg-icon name="pauseShipment"></svg-icon>
  <div class="spacing-between-icon-and-title"></div>
  <app-page-header [title]="'Pause Request'"></app-page-header>
  <div class="spacing-between-icon-and-title"></div>
  <div class="pause-warning-message">
    You are about to pause this request. The request status will be changed
    accordingly until further update from your side.
  </div>
  <div class="pause-note-title">
    Why would you like to pause this request?
    <span class="required-symbol">*</span>
  </div>
  <textarea
    [(ngModel)]="notes"
    type="text"
    placeholder="Add note"
    class="pause-reason"
    [class]="notesError ? 'error' : ''"
    maxlength="180"
  >
  </textarea>

  <div class="form-buttons row">
    <div class="col-6">
      <button class="cancel removal-form-buttons" (click)="closePopup()">
        Cancel
      </button>
    </div>
    <div class="col-6">
      <button 
      class="pause removal-form-buttons"
      (click)="pauseRequest()"
      [disabled]="isLoading">
        Pause Request
        <i *ngIf="!isLoading" class="fa fa-pause-circle-o"></i>
        <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
      </button>
    </div>
  </div>
</div>
