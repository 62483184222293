import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RegistrationActivityTransition } from '../models/registration-activity-transition.model';
import { RegistrationActivityService } from '../services/registration-activity.service';

@Injectable()
export class RegistrationActivityTransitionResolver
  implements Resolve<RegistrationActivityTransition>  {

  constructor(public registrationActivityService: RegistrationActivityService) {
  }


  resolve(route: ActivatedRouteSnapshot): Observable<RegistrationActivityTransition> {
    let id = JSON.parse(route.params.id) as number;
    return this.registrationActivityService.getWithTransitionById(id).pipe(
      catchError(error => {
        return of(null);
      }));
  }

}
