import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApprovalLeadTime } from '../models/approval-lead-time.model';
import { ApprovalKpiService } from '../services/approval-kpi.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApprovalKpisListResolver implements Resolve<ApprovalLeadTime[]> {

  constructor(public kpiService: ApprovalKpiService) { }


  resolve(): Observable<ApprovalLeadTime[]> {
    return this.kpiService.getAll().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
