import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarningBadgeComponent } from './warning-badge.component';
import { SvgIconsRegistery } from 'projects/svg-icons/src/lib/svg-icons.registery';
import { svgIconAlertMark, SvgIconsModule } from 'projects/svg-icons/src/lib/svg-icons';

@NgModule({
  imports: [CommonModule,SvgIconsModule],
  declarations: [WarningBadgeComponent],
  exports: [WarningBadgeComponent]
})
export class WarningBadgeModule {
  constructor(private svgIconRegistery: SvgIconsRegistery) {
    this.svgIconRegistery.registerIcons([svgIconAlertMark]);
  }
}
