import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProductRegistration } from 'src/app/create-product-registration/models/product-registration.model';
import { ProductRegistrationHelperService } from 'src/app/create-product-registration/service/product-registration-helper.service';
import { ProductDetails } from 'src/app/create-product-registration/models/product-details.model';
import { ProductHelperService } from 'src/app/create-product/services/product-helper.service';

@Component({
  selector: 'app-product-data-summary',
  templateUrl: './product-data-summary.component.html',
  styleUrls: ['./product-data-summary.component.scss']
})
export class ProductDataSummaryComponent implements OnInit {
  @Input() productRegistration: ProductRegistration;
  @Input() productsDetails: ProductDetails[];

  constructor(
    private router: Router,
    private productRegistrationHelperService: ProductRegistrationHelperService,
    private productHelperService: ProductHelperService
  ) {}

  ngOnInit(): void {}

  get isSingleProductGrouping(): boolean {
    return this.productRegistrationHelperService.isSingleProductGrouping(
      this.productRegistration
    );
  }

  get isBundleProductGrouping(): boolean {
    return this.productRegistrationHelperService.isBundleProductGrouping(
      this.productRegistration
    );
  }

  get isSystemProductGrouping(): boolean {
    return this.productRegistrationHelperService.isSystemProductGrouping(
      this.productRegistration
    );
  }

  get productGroupsName(): string {
    return this.productRegistrationHelperService.getProductGroupsName(
      this.productRegistration
    );
  }
  get isInOverview(): boolean {
    return this.router.url.includes('details');
  }

  download(isRegistration: boolean) {
    let fileName = this.getFileName(isRegistration);

    if (!fileName) {
      return;
    }

    let path = isRegistration
      ? this.productRegistration?.registrationLicense?.path
      : this.productRegistration?.technicalFile?.path;

    var link = document.createElement('a');
    link.setAttribute('download', fileName);
    link.setAttribute('href', path);

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  isFileExist(isRegistration: boolean): boolean {
    let fileName = this.getFileName(isRegistration);
    return !!fileName;
  }

  getFileName(isRegistration: boolean): string {
    let fileName = isRegistration
      ? this.productRegistration?.registrationLicense?.fileName
      : this.productRegistration?.technicalFile?.fileName;
    return fileName;
  }

  get singleSelectedArticleNumber(): string {
    return this.productsDetails != null
      ? this.productHelperService.getProductDetailsPartNumber(
          this.productsDetails[0]
        )
      : null;
  }

  get singleSelectedProductName(): string {
    return this.productsDetails != null
      ? this.productHelperService.getProductDetailsPartNumber(
          this.productsDetails[0]
        )
      : null;
  }
}
