import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators'; 
import { RegistrationActivity } from '../models/registration-activity.model';
import { RegistrationActivityService } from '../services/registration-activity.service';

@Injectable()
export class RegistrationActivityListResolver
  implements Resolve<RegistrationActivity[]> {
  constructor(public registrationActivityService: RegistrationActivityService ) {}

  resolve(): Observable<RegistrationActivity[]> {
    return this.registrationActivityService.getAll().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
