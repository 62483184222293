import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnlyDigitModule } from 'src/app/shared/directives/only-digit-directive/only-digit.module';
import { NgxMaskModule } from 'ngx-mask';
import { ThousandSeparatorModule } from 'src/app/shared/directives/thousand-separator-directive/thousand-separator.module';
import { InputNumberComponent } from './input-number/input-number.component';

@NgModule({
  declarations: [InputComponent, InputNumberComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OnlyDigitModule,
    NgxMaskModule.forRoot(),
    ThousandSeparatorModule
  ],
  exports: [
  InputComponent,
  InputNumberComponent
]
})
export class InputModule {}
