import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductCategoryService } from 'src/app/admin-portal-configs/services/product-category.service';
import { ProductCategory } from 'src/app/shared/models/product-category.model';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-add-or-edit-product-category',
  templateUrl: './add-or-edit-product-category.component.html',
  styleUrls: ['./add-or-edit-product-category.component.scss']
})
export class AddOrEditProductCategoryComponent implements OnInit {
  productCategoryForm: UntypedFormGroup;
  productCategory: ProductCategory;
  isNewProductCategory: boolean = false;
  duplicateProductCategory: boolean = false;
  isSaving: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private productCategoryService: ProductCategoryService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.productCategory = data['productCategory'];
    });
    this.initForm();
  }

  initForm() {
    this.isNewProductCategory = this.productCategory == null;
    this.productCategory = this.productCategory || ({} as ProductCategory);
    this.productCategoryForm = this.fb.group({
      name: [this.productCategory.name, [Validators.required]]
    });
  }

  save() {
    const formVal = this.productCategoryForm.value;

    if (this.isNewProductCategory) {
      this.addNewProductCategory(formVal);
    } else {
      this.editProductCategory(this.productCategory.id, formVal);
    }
  }

  addNewProductCategory(formVal: ProductCategory) {
    this.isSaving = true;
    this.productCategoryService.createProductCategory(formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
        this.isSaving = false;
      },
      error => {
        this.saveFailedHelper(error);
        this.isSaving = false;
      }
    );
  }

  editProductCategory(id: number, formVal: ProductCategory) {
    this.isSaving = true;
    this.productCategoryService.updateProductCategory(id, formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
        this.isSaving = false;
      },
      error => {
        this.saveFailedHelper(error);
        this.isSaving = false;
      }
    );
  }

  private saveSuccessHelper(productCategory?: ProductCategory) {
    this.alertService.stopLoadingMessage();

    if (this.isNewProductCategory) {
      this.alertService.showMessage(
        'Success',
        `Product Category \"${productCategory.name}\" was created successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to product category \"${productCategory.name}\" were saved successfully`,
        MessageSeverity.success
      );
    }

    this.resetForm();
    this.router.navigate(['/settings/product-categories']);
  }

  private saveFailedHelper(error: any) {
    this.alertService.stopLoadingMessage();
    if (error == '409') {
      this.duplicateProductCategory = true;
      return;
    }
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occured whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  resetForm() {
    this.productCategoryForm.reset();
  }

  cancel() {
    this.resetForm();

    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/product-categories']);
  }
}
