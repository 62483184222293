import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parentesisAroundLastWord'
})
export class ParentesisAroundLastWordPipe implements PipeTransform {
  transform(value: string): string {
    var position = value.lastIndexOf(' ');
    var output = value.substring(0, position) + ' (' + value.substring(position + 1) + ')';
    return output;
  }
}
