import { Injectable } from '@angular/core';
import { AccountEndpoint } from 'src/app/shared/services/account-endpoint.service';

import {
  Resolve,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { CategoryAndPermissions } from '../models/category-and-permissions';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryPermissionsResolver implements Resolve<CategoryAndPermissions[]> {
  constructor(private AccountEndpoint: AccountEndpoint) {}

  resolve(): Observable<CategoryAndPermissions[]> {
    return this.AccountEndpoint.getCategoriesAndPermissions().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
