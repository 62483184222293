<div class="table-view-colums-filter px-1">
  <form [formGroup]="columnsForm" (ngSubmit)="save()" class="text-center">
    <div class="form-title">
      Columns
    </div>
    <div class="info-container mb-4">
      <svg-icon name="info"></svg-icon>
      <span class="info-text pl-1">min {{ minColumns }} columns</span>
    </div>
    <div class="column-names-list pl-3 pr-5">
      <div class="form-group pr-3" *ngFor="let columnName of columnslist">
        <div class="row column-row">
          <div class="pr-2 colum-checkbox-container">
            <input
              class="column-checkbox"
              [formControlName]="columnName"
              type="checkbox"
            />
          </div>
          <div
            for="columnName"
            class="column-name ml-1"
            [ngbPopover]="columnName"
            placement="bottom-left"
            triggers="mouseenter:mouseleave"
            [disablePopover]="columnName?.length <= 17"
          >
            {{ columnName | slice: 0:17
            }}{{ columnName?.length > 17 ? "..." : "" }}
          </div>
        </div>
      </div>
    </div>
    <button
      type="submit"
      class="btn btn-primary row w-100 apply-column-filters mt-4"
      [disabled]="!isFormValid()"
    >
      Apply ({{ numberOfSelectedColumns }})
    </button>
  </form>
</div>
