import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'utcToAfricaCairo'
})
export class FromUtcToAfricaCairoPipe implements PipeTransform {
  transform(value: string | Date): string {
    return moment
      .utc(value)
      .tz('Africa/Cairo')
      .format('DD/MM/YYYY hh:mm A');
  }
}
