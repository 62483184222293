import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CountryMaster } from '../models/country.model';
import { Paginated } from '../models/shipment/paginated.model';
import { CountryDto } from 'src/app/admin-portal-configs/models/country-dto';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  baseURL = environment.apiUrl + 'Country';

  constructor(private http: HttpClient) {}

  getCountries(isLandFreight?: boolean): Observable<any> {
    return this.http.get<CountryMaster[]>(
      this.baseURL + `/CountryMaster?isLandFreight=${isLandFreight}`
    );
  }

  getCountriesPaginated(
    searchTerm: string = '',
    pageIndex: number = 0,
    pageSize: number = 10
  ): Observable<Paginated> {
    return this.http.get<Paginated>(
      `${this.baseURL}/PaginatedCountryMaster?searchTerm=${searchTerm}&pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
  }

  getCountry(country: string): Observable<CountryDto> {
    return this.http.get<CountryDto>(this.baseURL + `/${country}`);
  }

  addCountry(countryDto: CountryDto): Observable<any> {
    return this.http.post(this.baseURL, countryDto);
  }

  updateCountry(countryDto: CountryDto): Observable<any> {
    return this.http.put(this.baseURL + '/Update', countryDto);
  }
}
