
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { from, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';

import { LocalStoreManager } from './local-store-manager.service';
import { ConfigurationService } from './configuration.service';
import { DBkeys } from './db-keys';
import { LoginResponse } from '../models/login-response.model';
import { Utilities } from './utilities';

@Injectable()
export class OidcHelperService {

    private get baseUrl() { return this.configurations.baseUrl; }
    private clientId = 'freepl_spa';
    private scope = 'openid email phone profile offline_access roles freepl_api';

    constructor(
        private http: HttpClient,
        private oauthService: OAuthService,
        private configurations: ConfigurationService,
        private localStorage: LocalStoreManager) {

    }

    loginWithPassword(userName: string, password: string) {
        const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        const params = new HttpParams()
            .append('username', userName)
            .append('password', password)
            .append('client_id', this.clientId)
            .append('grant_type', 'password')
            .append('scope', this.scope);

        this.oauthService.issuer = this.baseUrl;

        return from(this.oauthService.loadDiscoveryDocument())
            .pipe(mergeMap(() => {
                return this.http.post<LoginResponse>(this.oauthService.tokenEndpoint, params, { headers: header });
            }));
    }

    refreshLogin() {
        const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        const params = new HttpParams()
            .append('refresh_token', this.refreshToken)
            .append('client_id', this.clientId)
            .append('grant_type', 'refresh_token');

        this.oauthService.issuer = this.baseUrl;

        return from(this.oauthService.loadDiscoveryDocument())
            .pipe(mergeMap(() => {
                return this.http.post<LoginResponse>(this.oauthService.tokenEndpoint, params, { headers: header });
            }));
    }

    loginWithExternalToken(token: string, provider: string, email?: string, password?: string) {
      const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
      let params = new HttpParams()
        .append('token', token)
        .append('provider', provider)
        .append('client_id', this.clientId)
        .append('grant_type', 'delegation')
        .append('scope', this.scope);

      if (email) {
        params = params.append('email', email);
      }

      if (password) {
        params = params.append('password', password);
      }

      this.oauthService.issuer = this.baseUrl;

      return from(this.oauthService.loadDiscoveryDocument())
        .pipe(mergeMap(() => {
          return this.http.post<LoginResponse>(this.oauthService.tokenEndpoint, params, { headers: header });
        }));
    }

    initLoginWithGoogle() {
      this.oauthService.configure({
        issuer: 'https://accounts.google.com',
        redirectUri: this.configurations.baseUrl + '/google-login',
        clientId: this.configurations.googleClientId,
        strictDiscoveryDocumentValidation: false,
        scope: 'openid profile email',
        sessionChecksEnabled: false
      });

      this.oauthService.loadDiscoveryDocument().then(() => {
        this.oauthService.initImplicitFlow();
      });
    }

    initLoginWithFacebook() {
      this.oauthService.configure({
        loginUrl: 'https://www.facebook.com/v5.0/dialog/oauth',
        redirectUri: this.configurations.baseUrl + '/facebook-login',
        clientId: this.configurations.facebookClientId,
        scope: 'email',
        oidc: false,
        sessionChecksEnabled: false
      });

      this.oauthService.initImplicitFlow();
    }

    get accessToken(): string {
        return this.localStorage.getData(DBkeys.ACCESS_TOKEN);
    }

    get accessTokenExpiryDate(): Date {
        return this.localStorage.getDataObject<Date>(DBkeys.TOKEN_EXPIRES_IN, true);
    }

    get refreshToken(): string {
        return this.localStorage.getData(DBkeys.REFRESH_TOKEN);
    }

    get isSessionExpired(): boolean {
        if (this.accessTokenExpiryDate == null) {
            return true;
        }

        return this.accessTokenExpiryDate.valueOf() <= new Date().valueOf();
    }
}
