import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from '../../../../shared/services/alert.service';
import { AppTranslationService } from '../../../../shared/services/app-translation.service';
import { Utilities } from '../../../../shared/services/utilities';
import { ActivatedRoute } from '@angular/router';
import { Carrier } from '../../../../shared/models/carrier.model';
import { CarrierService } from '../../../services/carrier.service';
import { ShipmentFreightType } from 'src/app/shared/models/enums/shipment-freight-type.enum';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-carrier-list',
  templateUrl: './carrier-list.component.html',
  styleUrls: ['./carrier-list.component.scss']
})
export class CarrierListComponent implements OnInit {
  columns: any[] = [];
  rows: Carrier[] = [];
  rowsCache: Carrier[] = [];
  loadingIndicator: boolean;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private carrierService: CarrierService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      var carriers = data['carriers'];

      carriers = this.getIndecies(carriers);

      this.rowsCache = [...carriers];
      this.rows = carriers;
      this.fillCarrierTypeField();
    });

    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      { prop: 'name', name: gT('config.management.Name'), width: 360 },
      {
        prop: 'carrierAbbreviatedName',
        name: gT('config.management.Carrier.AbbreviatedName'),
        width: 330
      },
      {
        prop: 'iataicaoCode',
        name: gT('config.management.Carrier.IATA/ICAO'),
        width: 300
      },
      {
        prop: 'scacCode',
        name: gT('config.management.Carrier.SCAC'),
        width: 300
      },
      {
        prop: 'carrierType',
        name: gT('config.management.Carrier.Type'),
        width: 400
      }
    ];

    if (this.canUpdateCarriers || this.canDeleteCarriers) {
      this.columns.push({
        prop: 'options',
        name: gT('config.management.Carrier.Manage'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      });
    }
  }

  private fillCarrierTypeField() {
    this.rows.forEach(item => {
      item.carrierType = '';
      if (item.isOceanCarrier) {
        item.carrierType += ShipmentFreightType.Ocean + ', ';
      }
      if (item.isLandCarrier) {
        item.carrierType += ShipmentFreightType.Land + ', ';
      }
      if (item.isAirCarrier) {
        item.carrierType += ShipmentFreightType.Air + ', ';
      }
      item.carrierType = item.carrierType.slice(0, -2);
    });
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  deleteCarrier(row: Carrier) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' + row.name + '" carrier ?',
      DialogType.confirm,
      () => this.deleteCarrierHelper(row)
    );
  }

  deleteCarrierHelper(row: Carrier) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.carrierService.deleteCarrier(row.id).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter(item => item !== row);

        this.rowsCache = this.getIndecies(this.rowsCache);
        this.rows = this.rowsCache;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the carrier.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  getIndecies(array: any) {
    array.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }

  get canCreateCarriers(): boolean {
    return this.authService.canCreateCarriers;
  }

  get canUpdateCarriers(): boolean {
    return this.authService.canUpdateCarriers;
  }

  get canDeleteCarriers(): boolean {
    return this.authService.canDeleteCarriers;
  }
}
