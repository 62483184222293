import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Permission } from '../../models/permission.model';
import { Role } from '../../models/role.model';
import { AccountService } from '../../services/account.service';
import {
  AlertService,
  MessageSeverity,
  DialogType
} from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { Utilities } from '../../services/utilities';
import { UserPermissions } from '../../models/enums/user-permission.enum';

@Component({
  selector: 'app-roles-management',
  templateUrl: './roles-management.component.html',
  styleUrls: ['./roles-management.component.scss']
})
export class RolesManagementComponent implements OnInit {
  columns: any[] = [];
  rows: Role[] = [];
  rowsCache: Role[] = [];
  allPermissions: Permission[] = [];
  editedRole: Role;
  sourceRole: Role;
  editingRoleName: { name: string };
  loadingIndicator: boolean;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      { prop: 'name', name: gT('roles.management.Name'), width: 180 },
      {
        prop: 'description',
        name: gT('roles.management.Description'),
        width: 320
      },
      { prop: 'usersCount', name: gT('roles.management.Users'), width: 50 }
     
    ];

    if (this.canUpdateRole || this.canDeleteRole) {
      this.columns.push( {
        name: '',
        width: 160,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      });
    }

    this.loadData();
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.accountService.getRolesAndPermissions().subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        const roles = results[0];
        const permissions = results[1];

        roles.forEach((role, index) => {
          (role as any).index = index + 1;
        });

        this.rowsCache = [...roles];
        this.rows = roles;

        this.allPermissions = permissions;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Load Error',
          `Unable to retrieve roles from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name, r.description)
    );
  }

  deleteRole(row: Role) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' + row.name + '" role?',
      DialogType.confirm,
      () => this.deleteRoleHelper(row)
    );
  }

  deleteRoleHelper(row: Role) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.accountService.deleteRole(row).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter(item => item !== row);
        this.rows = this.rows.filter(item => item !== row);
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the role.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }


  get canCreateRole(): boolean {
    return this.accountService.userHasPermissionTo(UserPermissions.CreateRole);
  }
  
  get canUpdateRole(): boolean {
    return this.accountService.userHasPermissionTo(UserPermissions.UpdateRole);
  }

  get canDeleteRole(): boolean {
    return this.accountService.userHasPermissionTo(UserPermissions.DeleteRole);
  }
}
