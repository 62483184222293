import { Component, Input, OnInit } from '@angular/core';
import { CreateQuoteHelperService } from 'src/app/create-quote/services/create-quote-helper.service';
import {
  CountryMaster,
  StateMaster
} from 'src/app/shared/models/country.model';
import { QuoteRoutingDetails } from 'src/app/shared/quote/models/quote-routing-details.model';
import { QuoteBase } from '../../models/quote-base.model';
import { CountryService } from 'src/app/shared/services/country.service';

@Component({
  selector: 'app-quote-routing-summary',
  templateUrl: './quote-routing-summary.component.html',
  styleUrls: ['./quote-routing-summary.component.scss']
})
export class QuoteRoutingSummaryComponent implements OnInit {
  @Input() quote: QuoteBase;
  countries: CountryMaster[];

  SHIPPER_CONSIGNEE_MAX_LIMIT = 11;

  constructor(
    private createQuoteHelperService: CreateQuoteHelperService,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.loadCountries();
  }

  loadCountries() {
    this.countryService.getCountries().subscribe(data => {
      this.countries = data;
    });
  }

  get quoteRouting(): QuoteRoutingDetails {
    return this.quote?.routingDetails;
  }

  get originCountry(): CountryMaster {
    let country = this.countries?.find(
      c => c.id === this.quoteRouting?.pickupLocationDetails?.countryId
    );

    return country;
  }

  get originCity(): StateMaster {
    let city = this.originCountry?.states?.find(
      s => s.id === this.quoteRouting?.pickupLocationDetails?.cityId
    );

    return city;
  }

  get deliveryCountry(): CountryMaster {
    let country = this.countries?.find(
      c => c.id === this.quoteRouting?.deliveryLocationDetails?.countryId
    );

    return country;
  }

  get deliveryCity(): StateMaster {
    let city = this.deliveryCountry?.states?.find(
      s => s.id === this.quoteRouting?.deliveryLocationDetails?.cityId
    );

    return city;
  }

  get isShowPort(): boolean {
    let isSelectedFreightService = this.createQuoteHelperService.isSelectedFreightService(
      this.quote?.quoteServices
    );
    let isSelectdTruckingStandalone = this.createQuoteHelperService.isSelectdTruckingStandalone(
      this.quote?.quoteServices
    );

    return (
      (!isSelectedFreightService && !isSelectdTruckingStandalone) ||
      (isSelectedFreightService &&
        !this.isAirAndOcean &&
        !this.isSelectCustomsClearanceAndTruckingInForeignCountry)
    );
  }

  get isSelectCustomsClearanceAndTruckingInForeignCountry(): boolean {
    return this.createQuoteHelperService?.isSelectCustomsClearanceAndTruckingInForeignCountry(
      this.quote
    );
  }

  get isAirAndOcean(): boolean {
    return (
      this.createQuoteHelperService.isAirService(this.quote?.quoteServices) &&
      this.createQuoteHelperService.isOceanService(this.quote?.quoteServices)
    );
  }

  get isDomesticQuote(): boolean {
    return this.createQuoteHelperService.isSelectedDomesticService(
      this.quote?.quoteServices
    );
  }

  get isSelectedTruckingService(): boolean {
    return this.createQuoteHelperService.isSelectedDomesticService(
      this.quote?.quoteServices
    );
  }

  get isSelectedCustomsClearanceService(): boolean {
    return this.createQuoteHelperService.isSelectedCustomsClearanceService(
      this.quote?.quoteServices
    );
  }

  get isExpressAirFreight(): boolean {
    return this.createQuoteHelperService.isExpressAirFreight(this.quote);
  }

  get isLandStandaloneService(): boolean {
    return this.createQuoteHelperService.isLandStandaloneService(
      this.quote?.quoteServices
    );
  }
}
