<app-admin-page-details
  [title]="'Add Land Route Details'"
  (submitButtonClicked)="saveORCancel($event)"
>
  <ng-container pageBody>
    <div class="page-container" [formGroup]="landRouteForm">
      <div *ngIf="isInvalidForm" class="error-badge">
        <app-error-badge
          [message]="
            'Required fields are either left empty or filled incorrectly. Check all fields highlighted in red and try again.'
          "
        ></app-error-badge>
      </div>

      <div class="row m-0">
        <div class="field-container">
          <div class="input-field-container">
            <label for="pickupCountryId" class="field-title"
              >Pickup Country<span class="text-error">*</span></label
            >
            <ng-select
              formControlName="pickupCountryId"
              bindLabel="name"
              bindValue="id"
              class="form-control field-input"
              placeholder="Select pickup country"
              [items]="pickupCountries"
              [searchable]="false"
              [clearable]="false"
              (change)="onPickupCountrySelected($event)"
              [ngClass]="{
                'is-invalid': isInvalidFormControl('pickupCountryId')
              }"
              name="ddl-pickupCountry"
            >
            </ng-select>
          </div>
          <div class="input-field-container">
            <label for="originPortId" class="field-title"
              >Port of Departure<span class="text-error">*</span></label
            >
            <ng-select
              formControlName="originPortId"
              bindLabel="name"
              bindValue="id"
              class="form-control field-input"
              placeholder="Select departure port"
              [searchable]="false"
              [clearable]="false"
              [items]="pickupPorts"
              [ngClass]="{
                'is-invalid': isInvalidFormControl('originPortId')
              }"
              name="ddl-originPort"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="row m-0">
        <div class="field-container">
          <div class="input-field-container">
            <label for="deliveryCountryId" class="field-title"
              >Delivery Country<span class="text-error">*</span></label
            >
            <ng-select
              formControlName="deliveryCountryId"
              bindLabel="name"
              bindValue="id"
              class="form-control field-input"
              placeholder="Select delivery country"
              [items]="deliveryCountries"
              [searchable]="false"
              [clearable]="false"
              (change)="onDeliveryCountrySelected($event)"
              [ngClass]="{
                'is-invalid': isInvalidFormControl('deliveryCountryId')
              }"
              name="ddl-deliveryCountry"
            >
              >
            </ng-select>
          </div>
          <div class="input-field-container">
            <label for="deliveryPortId" class="field-title"
              >Port of Delivery<span class="text-error">*</span></label
            >
            <ng-select
              formControlName="deliveryPortId"
              bindLabel="name"
              bindValue="id"
              class="form-control  field-input"
              placeholder="Select delivery port"
              [searchable]="false"
              [clearable]="false"
              [items]="deliveryPorts"
              [ngClass]="{
                'is-invalid': isInvalidFormControl('deliveryPortId')
              }"
              name="ddl-deliveryPort"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="row m-0">
        <div class="field-container">
          <div class="input-field-container">
            <label for="transitPortId" class="field-title"
              >Transit Port / Border</label
            >
            <ng-select
              formControlName="transitPortId"
              bindLabel="name"
              bindValue="id"
              class="form-control  field-input"
              placeholder="Select transit port"
              [items]="transitPorts"
              [searchable]="false"
              [clearable]="false"
              [ngClass]="{
                'is-invalid': isInvalidFormControl('transitPortId')
              }"
              name="ddl-transitPort"
            >
            </ng-select>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-admin-page-details>
