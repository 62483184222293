import { Component, Input, OnInit } from '@angular/core';
import { Currency } from 'src/app/shared/models/currency.model';

@Component({
  selector: 'app-currency-matrix',
  templateUrl: './currency-matrix.component.html',
  styleUrls: ['./currency-matrix.component.scss']
})
export class CurrencyMatrixComponent implements OnInit {

  @Input() currencies :Currency[] ;

  constructor() { }

  ngOnInit(): void { 
  } 

}
