import { Injectable } from '@angular/core';
import { ProductRegistrationDetails } from '../models/product-registration-details.model';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ProductRegistrationService } from '../services/product-registration.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ProductRegistrationDetailsResolver
  implements Resolve<ProductRegistrationDetails> {
  constructor(private productRegistrationService: ProductRegistrationService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<ProductRegistrationDetails> {
    let id = route.params.productId;

    return this.productRegistrationService
      .getProductRegistrationDetails(id)
      .pipe(
        catchError(error => {
          return of(null);
        })
      );
  }
}
