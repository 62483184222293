import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QuoteViewColumn } from '../models/quote-view-column.model';

@Injectable({
  providedIn: 'root'
})
export class QuoteViewColumnService {
  baseURL = environment.apiUrl + 'QuoteViewColumn';

  constructor(private http: HttpClient) {}

  getQuoteColumns(): Observable<QuoteViewColumn[]> {
    return this.http.get<QuoteViewColumn[]>(this.baseURL);
  }

  getEmployeeSelectedOrDefaultColumns(): Observable<QuoteViewColumn[]> {
    return this.http.get<QuoteViewColumn[]>(this.baseURL + `/SelectedColumn`);
  }

  updateEmployeeSelectedColumns(
    selectedColumns: string[]
  ): Observable<QuoteViewColumn[]> {
    return this.http.put<QuoteViewColumn[]>(this.baseURL, selectedColumns);
  }
}
