import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FromUTCToLocalPipe } from './from-utc-to-local-datetime.pipe';

@NgModule({
  declarations: [FromUTCToLocalPipe],
  imports: [CommonModule],
  exports: [FromUTCToLocalPipe]
})
export class FromUTCToLocalPipeModule {}
