<div class="row p-3">
  <div class="col-lg-3 col-md-1 col-sm-1"></div>
  <div class="col-lg-6 col-md-10 col-sm-10 Card pb-1">
    <form [formGroup]="transitionForm" id="transitionForm" (ngSubmit)="save()">
      <h4>
        {{
          !isNewTransition
            ? ("config.management.Activity.Edit" | translate) +
              " " +
              currentActivity.name
            : ("config.management.Activity.NewTransition" | translate)
        }}
      </h4>
      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <fieldset class="nested-fieldset">
        <legend>
          {{ "config.management.Activity.TransitionTitle" | translate }}
        </legend>
        <div *ngIf="duplicateSelection" class="error-message">
          <app-error-badge
            [thinBar]="true"
            message="{{
              'config.management.Activity.Editor.DuplicateErrorMessage' | translate
            }}"
            class="warning-badge"
          ></app-error-badge>
        </div>
        <div *ngIf="transitionToIsEmpty" class="error-message">
          <app-error-badge
            [thinBar]="true"
            message="{{
              'config.management.Activity.Editor.NoTransitionsErrorMessage' | translate
            }}"
            class="warning-badge"
          ></app-error-badge>
        </div>
        <div class="row">
          <div class="col-6 form-group">
            <label for="current">{{
              "config.management.Activity.TransitionFrom" | translate
            }}</label>
            <select
              class="form-control"
              formControlName="current"
              id="current"
              [value]="currentActivity.id"
              *ngIf="isNewTransition; else editTransition"
            >
              <option [value]="null">
                {{ "config.management.Activity.SelectInitial" | translate }}
              </option>
              <option *ngFor="let item of Activities" [value]="item.id">
                {{ item.name }}
              </option>
            </select>
            <ng-template #editTransition>
              <select
                class="form-control"
                formControlName="current"
                id="current"
                [value]="currentActivity.id"
              >
                <option [value]="currentActivity.id">
                  {{ currentActivity.name }}
                </option>
              </select>
            </ng-template>
          </div>

          <div class="col-6 form-group">
            <label for="next">{{
              "config.management.Activity.TransitionTo" | translate
            }}</label>
            <select
              class="form-control"
              formControlName="next"
              id="next"
              (change)="addTransitionOnSelect($event)"
            >
              <option [value]="null">
                {{ "config.management.Activity.SelectNext" | translate }}
              </option>
              <option *ngFor="let item of Activities" [value]="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="col-12">
            <div *ngFor="let item of currentActivity.nextActivities">
              <div
                class="btn mb-1 mr-2 badge badge-info badge-pill"
                (click)="deleteSingleTransition(item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <div class="col-sm-12 form-group">
        <div ngPreserveWhitespaces class="float-right">
          <button (click)="cancel()" class="btn btn-danger">
            <i class="fa fa-times"></i>
            {{ "config.management.Activity.Editor.Cancel" | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="isSaving || !transitionForm.valid"
          >
            <i *ngIf="!isSaving" class="fa fa-save"></i>
            <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
            {{
              isSaving
                ? ("config.management.Activity.Editor.Saving" | translate)
                : ("config.management.Activity.Editor.Save" | translate)
            }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
