import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats
} from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-mat-date-picker-header',
  templateUrl: './mat-date-picker-header.component.html',
  styleUrls: ['./mat-date-picker-header.component.scss'],
  styles: [
    `
      .date-header {
        display: flex;
        margin-bottom: 25px;
        justify-content: center;
        align-items: center;
      }

      .date-header-label {
        margin: 0 30px;
        font-family: AvertaPE;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.25;
        letter-spacing: 0.26px;
        color: var(--text);
      }

      .navigate-icons {
        transform: scale(0.8);
      }

      .navigate-icons:hover {
        cursor: pointer;
      }
    `
  ]
})
export class MatDatePickerHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter.format(
      this._calendar.activeDate,
      this._dateFormats.display.monthYearA11yLabel
    );
  }

  previousClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(
      this._calendar.activeDate,
      -1
    );
  }

  nextClicked() {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(
      this._calendar.activeDate,
      1
    );
  }
}
