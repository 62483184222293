import { Component, Input, OnInit } from '@angular/core';
import { ProductDocument } from '../../models/product-document.model';
import { SupplierDocument } from 'src/app/company/models/supplier-document.model';
import { AlertService } from '../../services/alert.service';
import { ModuleName } from '../../models/enums/module-name.enum';
import { DeleteDocumentComponent } from '../delete-document/delete-document.component';
import { ActivatedRoute } from '@angular/router';
import { FileViewerService } from '../file-viewer/file-viewer.service';
import { CompanyDocument } from '../../models/company-document';
import { DialogService } from '../../services/dialog.service';
import { DeleteLinkedDocumentComponent } from 'src/app/product-library/components/delete-linked-document/delete-linked-document.component';

@Component({
  selector: 'app-uploaded-documents-details-older-version',
  templateUrl: './uploaded-documents-details-older-version.component.html',
  styleUrls: ['./uploaded-documents-details-older-version.component.scss']
})
export class UploadedDocumentsDetailsOlderVersionComponent implements OnInit {
  @Input() oldVersions:
    | ProductDocument[]
    | SupplierDocument[]
    | CompanyDocument[];
  @Input() module: ModuleName;
  @Input() parentDocumentSqlId: number;

  @Input() canDownloadDocument: boolean = true;
  @Input() canReplaceDocument: boolean = true;
  @Input() canDeleteDocument: boolean = true;

  moduleName = ModuleName;
  id: string;

  documentNoteLimit = 66;

  constructor(
    private alertService: AlertService,
    private fileViewerService: FileViewerService,
    private route: ActivatedRoute,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.id =
      this.route.snapshot.paramMap.get('id') ||
      this.route.snapshot.paramMap.get('productId') ||
      this.route.snapshot.paramMap.get('approvalId') ||
      this.route.snapshot.paramMap.get('companyId');
  }

  isExceedNoteLimit(
    document: ProductDocument | SupplierDocument | CompanyDocument
  ): boolean {
    return document?.notes.length > this.documentNoteLimit;
  }

  download(version: ProductDocument | SupplierDocument | CompanyDocument) {
    if (!version.isDeleted) {
      var link = document.createElement('a');
      link.setAttribute('download', version?.fileName);
      link.setAttribute('href', version?.path);

      document.body.appendChild(link);
      link.click();
      link.remove();
      this.alertService.success('Document successfully downloading.');
    }
  }

  delete(version: ProductDocument | SupplierDocument | CompanyDocument) {
    if (version?.isDeleted) {
      return;
    }

    if (this.module === ModuleName.ProductLibrary) {
      this.dialogService.open(
        DeleteLinkedDocumentComponent,
        {
          currentDocumentType:
            version?.documentTypeName || version?.documentType?.name,
          parentId: this.id,
          documentPath: version?.path,
          selectedSingleDocumentTypeId: version.documentTypeId,
          documentToReplaceSqlId: version.documentSqlId,
          parentDocumentSqlId: this.parentDocumentSqlId
        },
        '820px',
        '696px'
      );
    } else {
      this.dialogService.open(
        DeleteDocumentComponent,
        {
          parentId: this.id,
          module: this.module,
          documentSqlId: version.documentSqlId,
          parentDocumentSqlId: this.parentDocumentSqlId
        },
        '600px',
        '650px'
      );
    }
  }

  viewFile(version: ProductDocument | SupplierDocument | CompanyDocument) {
    if (version.isDeleted) {
      return;
    }

    this.fileViewerService.view(version.path);
  }

  get isProductLibrary(): boolean {
    return this.module == ModuleName.ProductLibrary;
  }
}
