<h4>{{ "config.management.Ports.Manage" | translate }}</h4>
<hr class="separator-hr" />
<a class="btn btn-primary mb-3" routerLink="../" fragment="config">
  <i class="fa fa-arrow-left" aria-hidden="true"></i> {{ "back" | translate }}
</a>
<div>
  <div class="row control-box">
    <div class="col-lg-8">
      <div class="form-group search-box">
        <app-search-box
          (searchChange)="onSearchChanged($event)"
          placeholder="{{ 'config.management.Ports.Search' | translate }}"
          >></app-search-box
        >
      </div>
    </div>
    <div class="col-lg-4">
      <ul class="nav flex-column flex-lg-row justify-content-end">
        <li>
          <button class="btn btn-link" (click)="openForm(null, false)">
            <i class="fa fa-plus"></i>
            {{ "config.management.Ports.New" | translate }}
          </button>
        </li>
      </ul>
    </div>
  </div>
  <div>
    <ngx-datatable
      class="material colored-header sm table table-striped table-hover"
      [loadingIndicator]="loadingIndicator"
      [rows]="rows"
      [rowHeight]="35"
      [headerHeight]="35"
      [footerHeight]="35"
      [columns]="columns"
      [scrollbarV]="true"
      [columnMode]="'force'"
    >
    </ngx-datatable>

    <ng-template #indexTemplate let-value="value">
      <strong>{{ value }}</strong>
    </ng-template>

    <ng-template #actionsTemplate let-row="row" let-value="value" let-i="id">
      <button class="btn btn-link btn-sm" (click)="openForm(row, true)">
        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
        {{ "config.management.Ports.Edit" | translate }}
      </button>
      |
      <button class="btn btn-link btn-sm" (click)="deletePort(row)">
        <i class="fa fa-trash-o" aria-hidden="true"></i>
        {{ "config.management.Ports.Delete" | translate }}
      </button>
    </ng-template>
  </div>
</div>
