<div mat-dialog-content class="popup-window">
  <div class="centered-container image-container">
    <div class="image">
      <svg-icon name="shipmentNowActive"></svg-icon>
    </div>
  </div>

  <div class="centered-container">
    <div class="">
      <span class="title-message">
        Request successfully submitted!
      </span>
    </div>
  </div>

  <div class="centered-container">
    <div class="">
      <span class="submission-number">
        {{ moduleName }} No. #{{ submissionNumberString }}
      </span>
    </div>
  </div>

  <div class="centered-container">
    <div class="">
      <span class="account-manager-message">
        Your account manager will start working on your request soon.
      </span>
    </div>
  </div>

  <div class="centered-container">
    <button
      class="go-to-registrations-button"
      mat-button
      mat-dialog-close
      (click)="OnSubmissionRouting()"
    >
      Go to {{ moduleName }}s
    </button>
  </div>
</div>
