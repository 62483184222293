import { Component, Input, OnInit } from '@angular/core';
import { CreateQuoteHelperService } from 'src/app/create-quote/services/create-quote-helper.service';
import { QuoteBase } from '../../models/quote-base.model';
import { CargoHandling } from 'src/app/shared/models/enums/cargo-handling.enum';

@Component({
  selector: 'app-quote-cargo-details-summary',
  templateUrl: './quote-cargo-details-summary.component.html',
  styleUrls: ['./quote-cargo-details-summary.component.scss']
})
export class QuoteCargoDetailsSummaryComponent implements OnInit {
  @Input() quote: QuoteBase;
  cargoHandling = CargoHandling;

  constructor(private createQuoteHelperService: CreateQuoteHelperService) {}

  ngOnInit(): void {}

  get isDisplayPackagePalletSection(): boolean {
    let cargoDetails = this.quote?.cargoDetails;
    if (
      cargoDetails?.hasPackage ||
      cargoDetails?.hasPallet ||
      cargoDetails?.isPackageTotals ||
      this.hasBreakbulkPackages
    ) {
      return true;
    }
    return false;
  }

  get hasBreakbulkPackages(): boolean {
    return this.createQuoteHelperService.hasBreakbulkPackages(this.quote);
  }

  get isDisplayOceanFreight(): boolean {
    if (
      (this.isAirAndOcean && this.isLCLLoadType) ||
      this.isFCLLoadType ||
      this.isFTLLoadType
    ) {
      return true;
    }
    return false;
  }

  get isAirAndOcean(): boolean {
    return this.createQuoteHelperService.isAirAndOcean(this.freightReference);
  }

  get isFCLLoadType(): boolean {
    return this.createQuoteHelperService.isFCLLoadType(this.quote);
  }

  get freightReference(): string {
    return this.createQuoteHelperService.getFreightReference(this.quote);
  }

  get isDisplayTruckingSection(): boolean {
    return (
      this.isSelectedTruckingService ||
      this.quote?.routingDetails?.pickupLocationDetails?.isTrucking ||
      this.quote?.routingDetails?.deliveryLocationDetails?.isTrucking ||
      this.isLandStandaloneService
    );
  }

  get isSelectedTruckingService(): boolean {
    return this.createQuoteHelperService.isSelectedTruckingService(
      this.quote?.quoteServices
    );
  }

  get isFTLLoadType(): boolean {
    return this.createQuoteHelperService.isFTLLoadType(this.quote);
  }

  get isLCLLoadType(): boolean {
    return this.createQuoteHelperService.isLCLLoadType(this.quote);
  }

  get hasDomesticService(): boolean {
    return this.createQuoteHelperService.isSelectedDomesticService(
      this.quote?.quoteServices
    );
  }

  get isLandStandaloneService(): boolean {
    return this.createQuoteHelperService.isLandStandaloneService(
      this.quote?.quoteServices
    );
  }
}
