import { Component, OnInit, Input } from '@angular/core';
import { ProductBase } from '../../../shared/models/product-library/product-base.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-product-details-summary',
  templateUrl: './product-details-summary.component.html',
  styleUrls: ['./product-details-summary.component.scss']
})
export class ProductDetailsSummaryComponent implements OnInit {
  @Input() product: ProductBase;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  get isEditRoute(): boolean {
    return this.router.url.includes('product-details');
  }

  handleProductInformationRouting(): void {
    if (!this.isEditRoute) {
      this.router.navigate(['../details'], {
        relativeTo: this.route
      });
    } else {
      this.router.navigate(
        ['../../create-product/', this.product?.id, 'edit-product', 'details'],
        { relativeTo: this.route }
      );
    }
  }

  handleComplianceInformationRouting(): void {
    this.router.navigate(['../compliance-information'], {
      relativeTo: this.route
    });
  }

  get canEditProductDetailsManagement():boolean{
    return this.authService?.canEditProductDetailsManagement;
  }
}
