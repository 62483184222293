import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators'; 
import { RegistrationActivity } from '../models/registration-activity.model';
import { RegistrationActivityService } from '../services/registration-activity.service';

@Injectable()
export class RegistrationActivityResolver
  implements Resolve<RegistrationActivity> {
  constructor(public registrationActivityService: RegistrationActivityService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<RegistrationActivity> {
    let id = JSON.parse(route.params.id) as number;

    return this.registrationActivityService.getById(id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}