import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductCategoryService } from 'src/app/admin-portal-configs/services/product-category.service';
import { ProductCategory } from 'src/app/shared/models/product-category.model';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Utilities } from 'src/app/shared/services/utilities';

@Component({
  selector: 'app-product-category-list',
  templateUrl: './product-category-list.component.html',
  styleUrls: ['./product-category-list.component.scss']
})
export class ProductCategoryListComponent implements OnInit {
  loadingIndicator: boolean;
  columns: any[] = [];
  rowsCache: ProductCategory[] = [];
  rows: ProductCategory[] = [];

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  constructor(
    private translationService: AppTranslationService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private productCategoryService: ProductCategoryService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.rows = this.getIndecies(data['productCategories']);
      this.rowsCache = [...this.rows];
    });

    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      { prop: 'name', name: gT('config.management.Name'), width: 560 }
    ];

    if (this.canDeleteProductCategories || this.canUpdateProductCategories) {
      let optionsColumn = {
        prop: 'options',
        name: gT('config.management.ProductCategory.Manage'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      };
      this.columns.push(optionsColumn);
    }
  }

  getIndecies(array: any) {
    array.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  deleteCategory(row: ProductCategory) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' + row.name + '" category ?',
      DialogType.confirm,
      () => this.deleteCategoryHelper(row)
    );
  }

  deleteCategoryHelper(row: ProductCategory) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;
    this.productCategoryService.deleteProductCategory(row.id).subscribe(
      x => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.rows = this.rows.filter(item => item !== row);
        this.alertService.success(`Successfully Deleted ${row.name}`);
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the product category.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  get canCreateProductCategories(): boolean {
    return this.authService.canCreateProductCategories;
  }

  get canUpdateProductCategories(): boolean {
    return this.authService.canUpdateProductCategories;
  }

  get canDeleteProductCategories(): boolean {
    return this.authService.canDeleteProductCategories;
  }
}
