import { Component, OnInit, Input } from '@angular/core';
import { ProductBase } from '../../models/product-library/product-base.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductDetailsRegistrationsAndApprovals } from 'src/app/product-library/models/product-details-registrations-and-approvals';
import { ProductHelperService } from 'src/app/create-product/services/product-helper.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-product-compliance-summary',
  templateUrl: './product-compliance-summary.component.html',
  styleUrls: ['./product-compliance-summary.component.scss']
})
export class ProductComplianceSummaryComponent implements OnInit {
  @Input() product: ProductBase;
  @Input() productReference: ProductDetailsRegistrationsAndApprovals;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productHelperService: ProductHelperService,
    private authService : AuthService
  ) {}

  ngOnInit(): void {}

  get isHealthCareOrPharmaceuticalsCategory(): boolean {
    return this.productHelperService.getIsHealthCareOrPharmaceuticalsCategory(
      this.product
    );
  }

  handleComplianceInformationRouting(): void {
      this.router.navigate(
        ['../../create-product/', this.product?.id, 'edit-compliance','details'],
        { relativeTo: this.route }
      );
  }

  get canEditComplianceDetailsManagement():boolean{
    return this.authService.canEditComplianceDetailsManagement;
  }
}
