import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolNull',
  pure: false
})
export class BoolNullPipe implements PipeTransform {
  transform(value: any, property?: string,propertyValue?:any): any {

    if(!value || !property){
      return value;
    }
    return value.filter((item) => item[property]==null ||item[property] == propertyValue);
  }
}
