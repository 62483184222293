import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter, forwardRef, ChangeDetectorRef, Injectable,
} from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Injectable({providedIn: 'root'})

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'radio-button-card',
  templateUrl: './radio-button-card.component.html',
  styleUrls: ['./radio-button-card.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonCardComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonCardComponent implements ControlValueAccessor, OnInit {
  @Input() value: string;
  @Input() isSelected: boolean;
  @Input() moreDetails: boolean;
  @Input() disable: boolean;
  @Input() hasError: boolean;
  @Output() radioClicked = new EventEmitter<string>();

  @Input() title: string;
  @Input() description: string;
  @Input() descriptionBold: string;
  @Input() descriptionBoldColor: string;
  @Input() iconPath: string;
  @Input() classList: string;
  @Input() formControlName: string;
  @Input() svgIconName: string;
  @Input() limitParagraphWidth: boolean = false;
  control: AbstractControl;

  @Input() isFreightReference?: boolean;
  @Input() isServiceSelectionPage?: boolean;
  @Input() hasThreeFreights?: boolean;

  constructor(private controlContainer: ControlContainer, private readonly cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this.controlContainer && this.formControlName) {
      this.control = this.controlContainer.control.get(this.formControlName);
    }
  }

  onChange = (value: any) => {
  };

  onTouched = ($event) => {
    this.control.markAsTouched();
  };

  writeValue(obj: any): void {
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  submit(): void {
    if (!this.disable) {
      this.radioClicked.emit(this.value);
    }
  }
}
