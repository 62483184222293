<div class="col-12 px-0 table-container">
  <table class="products-table">
    <tr class="table-header">
      <th class="column-title pl-1 border-radius-header-left"><div></div></th>
      <th
        *ngFor="let column of columns; let i = index"
        class="column-title pl-1"
        [ngClass]="{
          'border-radius-header-right': i === columns.length - 1
        }"
      >
        <div>{{ column.name }}</div>
      </th>
    </tr>

    <tbody>
      <tr
        *ngFor="let product of productsList; let i = index"
        class="cursor-pointer products-row-container"
      >
        <td
          class="column-content pr-0 position-relative check-box-column border-radius-body-left product-row border-right-0"
          [style.width.px]="50"
        >
          <input
            type="checkbox"
            [checked]="isProductInSelectedProductsList(product)"
            (click)="
              selectOrDeselectProduct(
                $event,
                product?.productRegistrationNumberString
              )
            "
            class="product-checkbox"
          />
        </td>
        <td
          *ngFor="let column of columns; let i = index"
          class="column-content pr-0 position-relative product-row border-left-0"
          [style.width.px]="getColumnWidth(column)"
          [style.min-width.px]="getColumnWidth(column)"
          [routerLink]="getProductRegistrationRouterLink(product)"
          [ngClass]="{
            'border-radius-body-right': i === columns.length - 1,
            'border-right-0': i != columns.length - 1
          }"
        >
          <div *ngIf="getIsDateColumn(column)" class="pl-1">
            <div>
              {{
                getDatesFromProductRegistration(column, product) == null
                  ? "-"
                  : (getDatesFromProductRegistration(column, product)
                    | date: "dd/MM/YYYY")
              }}
            </div>
          </div>
          <div
            class="pl-1"
            *ngIf="!getIsDateColumn(column)"
            [ngStyle]="{
              color:
                column.name === columnNames.Status &&
                isRegistrationLicenseExpired(product)
                  ? '#f00'
                  : column.name === columnNames.Status
                  ? getSatusColor(product)
                  : ''
            }"
          >
            <div
              [placement]="i >= productsList?.length - 2 ? 'top' : 'bottom'"
              [ngbPopover]="cellContentTemplate"
              triggers="mouseenter:mouseleave"
              [disablePopover]="
                getColumnContent(column, product)?.length <=
                  maxLengthBeforeTruncation ||
                column.name === columnNames.Status ||
                column.name == columnNames.Category
              "
              class="cursor-pointer"
            >
              <span
                class="column-value"
                *ngIf="
                  column.name === columnNames.Status ||
                    column.name === columnNames.Category;
                  else nonTruncatedColumns
                "
                >{{
                  isRegistrationLicenseExpired(product)
                    ? "Registration License Expired"
                    : getColumnContent(column, product)
                }}
              </span>
              <ng-template #nonTruncatedColumns>
                <span class="column-value">
                  {{
                    getColumnContent(column, product)
                      | slice: 0:maxLengthBeforeTruncation
                  }}
                  {{
                    getColumnContent(column, product)?.length >
                    maxLengthBeforeTruncation
                      ? ".."
                      : ""
                  }}
                </span>
              </ng-template>
            </div>
            <ng-template #cellContentTemplate>
              <div class="row pop-over">
                <div class="col-4 px-0 pop-over-label">
                  {{ getColumnContent(column, product) }}
                </div>
              </div>
            </ng-template>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
