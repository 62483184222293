<div class="row p-3">
  <div class="col-lg-3 col-md-1 col-sm-1"></div>
  <div class="col-lg-6 col-md-10 col-sm-10 Card pb-1">
    <form [formGroup]="form" (ngSubmit)="save()">
      <h4>
        {{
          !isForAdd
            ? ("config.management.RegistrationActivity.Edit" | translate) +
              " " +
              registrationActivity?.name
            : ("config.management.RegistrationActivity.New" | translate)
        }}
      </h4>
      <div class="pl-3 row" *ngIf="diaplayedCategory">
        <svg-icon name="alertMark"></svg-icon>
        <div class="col-11 px-1">
          This activity affects the {{ diaplayedCategory }}, so the activity
          name must represents how it influences the workflow.
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <div class="row pb-4">
        <div class="col-12">
          <label for="app-input">Activity Name</label>
          <app-input
            [placeholder]="'Add Activity Name'"
            formControlName="name"
            [classList]="'field-bg'"
            [ngClass]="{
              'validation-error': isInvalidFormControl('name')
            }"
          ></app-input>
        </div>
      </div>

      <div class="row pb-4">
        <div class="col-12">
          <label for="linkedStatus">Linked Status</label>
          <ng-select
            [items]="productRegistrationStatuses"
            formControlName="productRegistrationStatusId"
            bindLabel="name"
            bindValue="id"
            [clearable]="false"
            [ngClass]="{
              error: isInvalidFormControl('productRegistrationStatusId')
            }"
            placeholder="Select Status"
          ></ng-select>
        </div>
      </div>

      <div class="row pb-4">
        <div class="col-12">
          <label for="linkedSystemStatus">Linked System Status</label>
          <ng-select
            [items]="systemStatuses"
            formControlName="productRegistrationSystemStatusId"
            bindLabel="name"
            bindValue="id"
            [clearable]="false"
            [ngClass]="{
              error: isInvalidFormControl('productRegistrationSystemStatusId')
            }"
            placeholder="Select System Status"
          ></ng-select>
        </div>
      </div>

      <div class="row pb-4">
        <div class="col-12">
          <label for="linkedProgressPonint">Linked Progress Point</label>
          <ng-select
            [items]="progressPoints"
            formControlName="progressPointId"
            bindLabel="name"
            bindValue="id"
            [clearable]="false"
            placeholder="Select Progress Point"
          ></ng-select>
        </div>
      </div>

      <div class="col-sm-12 form-group">
        <div ngPreserveWhitespaces class="float-right">
          <button (click)="cancel()" class="btn btn-danger">
            <i class="fa fa-times"></i>
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="isSaving || !form?.valid"
          >
            <i *ngIf="!isSaving" class="fa fa-save"></i>
            <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
            {{ isSaving ? "Saving" : "Save" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
