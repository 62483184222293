import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProductRegistrationCommentWithActionActors } from '../models/product-registration-comment-with-action-actors.model';
import { ProductRegistrationCommentService } from '../services/product-registration-comment.service';

@Injectable()
export class ProductRegistrationCommentsResolver
  implements Resolve<ProductRegistrationCommentWithActionActors> {
  constructor(private productRegistrationService: ProductRegistrationCommentService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<ProductRegistrationCommentWithActionActors> {
    let id = route.params.productId;

    return this.productRegistrationService
      .getProductRegistrationComment(id)
      .pipe(
        catchError(error => {
          return of(null);
        })
      );
  }
}
