<div class="no-data-container text-center">
  <svg-icon name="noItemsIcon"></svg-icon>

  <p class="no-data-message-bold">
    {{ boldText }}
  </p>

  <p class="no-data-message">
    {{ normalText }}
  </p>
</div>
