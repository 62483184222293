<div class="container">
  <div class="row form-header">
    <div class="form-title">
      Bulk Action
    </div>
  </div>

  <form class="text-center">
    <div
      class="mx-0 justify-content-between bulk-action-container-header expansion-panel-header w-100"
      [ngClass]="{ 'option-selected': isExport }"
      (click)="toggleExportSelection()"
    >
      <span class="bulk-action-title"> Export to Excel </span>
    </div>
    <hr class="export-line" />

    <div class="bulk-action-list" *ngIf="isCurrentModule(modules.Shipment)">
      <mat-expansion-panel class="bulk-action-container" #typeExpansionPanel>
        <mat-expansion-panel-header class="px-0 expansion-panel-header">
          <mat-panel-description
            class="mx-0 justify-content-between bulk-action-container-header"
          >
            <div class="w-100">
              <span class="bulk-action-title"> Update Status </span>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- list of T  status  -->
        <span>
          <div class="app-status-list-container">
            <app-status-list
              [statusList]="statuses"
              (selectedStatusChange)="statusSelected($event)"
            ></app-status-list>
          </div>
        </span>
      </mat-expansion-panel>
      <div
        [class]="typeExpansionPanel.expanded ? '' : 'line-separator m-auto'"
      ></div>
    </div>
  </form>
</div>
<div class="apply-button-container">
  <button
    type="submit"
    class="btn btn-primary w-100 apply-action"
    (click)="save()"
    [disabled]="isDisableApplyButton"
  >
    Apply
  </button>
</div>
