<div class="wrapper">
  <div class="upload-document-section">
    <form class="upload" [formGroup]="form">
      <div class="upload">
        <span class="document-type-title">
          Document Type
        </span>

        <div class="orange-wrapper mt-3">
          <div class="useful-info">
            <svg-icon name="timepiece-icon"></svg-icon>
            <span class="useful-info-text">
              Save time on the rest of the application by uploading these
              documents
            </span>
          </div>
        </div>
        <div class="document-radio-button">
          <mat-radio-group
            aria-label="Select an option"
            class="d-flex flex-wrap"
            formControlName="documentType"
          >
            <div
              class="radio-main"
              *ngFor="let type of documentTypes | bool: 'isPinned':true"
              [ngClass]="{
                uploading: type.uploading,
                'radio-button-error': isDocumentTypeErrorState,
                uploaded: type.uploaded
              }"
            >
              <mat-radio-button
                *ngIf="!type.uploaded"
                [value]="type"
                class="document-type-option"
              >
              </mat-radio-button>
              <span class="radio-button-lable">
                {{ type.name }}
                <span *ngIf="type.isRequired" class="required-sign">*</span>
              </span>

              <svg-icon
                class="mr-2"
                name="check-success"
                *ngIf="type.uploaded"
              ></svg-icon>
            </div>
          </mat-radio-group>
        </div>

        <div class="other-document-section ">
          <p class="document-page-field-title">
            <strong> Or</strong> choose other documents
          </p>
          <div class="select-type-ddl">
            <ng-select
              [placeholder]="'Select Document Type'"
              [searchable]="false"
              appearance="outline"
              [clearable]="false"
              class="documentType"
              formControlName="otherDocumentType"
              (change)="selectDocumentType($event)"
              [ngClass]="{ 'err-select': isDocumentTypeErrorState }"
            >
              <ng-option
                [value]="type"
                *ngFor="let type of documentTypes | boolNull: 'isPinned':false"
                [disabled]="type.uploaded || disableAnyAction"
                class="ddl-option"
              >
                <div class="d-flex">
                  <div
                    class="circle"
                    [ngClass]="{ circleActive: type.uploaded }"
                  ></div>
                  {{ type.name }}
                  <span *ngIf="type.isRequired" class="required-sign">*</span>
                  <div *ngIf="type.uploaded" class="uploaded-ddl success">
                    <svg-icon class="mr-2" name="check-success"></svg-icon>
                    Uploaded
                  </div>
                  <div class="d-flex uploaded-ddl" *ngIf="this.uploading">
                    <svg-icon name="uploading-spinner"></svg-icon>
                    Uploading
                  </div>
                </div>
              </ng-option>
            </ng-select>
          </div>
        </div>
        <div
          class="document-expire-date"
          *ngIf="!isNonExpiring && !isLinkExistingDocument"
        >
          <div class="expire-date-header">
            <span class="header">
              Document Expiry Date
            </span>
          </div>
          <div class="expire-date-input">
            <mat-form-field
              appearance="fill"
              class="custom-datepicker"
              [ngClass]="{
                error: isInvalidController('expirationDate')
              }"
            >
              <div class="d-flex">
                <div class="input-title mr-2">
                  <span> Before</span>
                </div>
                <input
                  matInput
                  [matDatepicker]="datepicker"
                  placeholder="dd/mm/yyyy"
                  [min]="currentDate"
                  formControlName="expirationDate"
                />
              </div>
              <mat-datepicker-toggle matSuffix [for]="datepicker">
                <svg-icon
                  matDatepickerToggleIcon
                  name="datePickerIcon"
                ></svg-icon>
              </mat-datepicker-toggle>
              <mat-datepicker
                #datepicker
                [panelClass]="'custom-today'"
                [calendarHeaderComponent]="matDateHeader"
              >
              </mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="add-file-section" *ngIf="!isLinkExistingDocument">
          <div class="add-title">
            <span class="title">Add File</span>
          </div>
          <div class="upload-types">
            <span class="file-type ">
              File types:
            </span>
            <span class="font-weight-bold">
              word, excel, pdf, image
            </span>
            <span class="ml-3 file-type">•&nbsp;Maximum file size:</span>
            <span class="font-weight-bold">10 MB</span>
          </div>

          <div class="upload-file-container">
            <div
              class="upload-file-box"
              (dragover)="onDragOver($event)"
              (drop)="onDropSuccess($event)"
              [ngClass]="{
                active: hasFile,
                'err-border': uploadError.isError,
                'dashed-error-border': isInvalidController('file')
              }"
            >
              <ng-container *ngIf="hasFile">
                <div>
                  <span class="name">
                    {{ form.get("file").value?.name }}
                  </span>
                  <span class="size">
                    {{
                      form.get("file").value?.size > 1024
                        ? form.get("file").value?.size / 1024 > 1024
                          ? (form.get("file").value?.size / 1024 / 1024
                              | number: "1.2-2") + "MB"
                          : (form.get("file").value?.size / 1024
                              | number: "1.2-2") + "KB"
                        : form.get("file").value?.size + "Byte"
                    }}
                  </span>
                </div>

                <button class="cancel" (click)="removeFile()">
                  <svg-icon [name]="'cancel'"></svg-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="!hasFile">
                <div class="upload-document-icon">
                  <svg-icon [name]="'uploadDocument'"></svg-icon>
                </div>

                <div class="drop-document">Drop your document here</div>
                <div
                  [class.cursor-disabled]="'false'"
                  class="underline cursor-pointer"
                  (click)="uploadInput.value = ''; browseFile(uploadInput)"
                >
                  <div class="browse-files">
                    or browse files
                  </div>
                </div>
              </ng-container>
            </div>
            <span
              class="my-2 text-error d-flex align-items-start my-2"
              *ngIf="uploadError.isError"
            >
              <span class="pb-1">
                <svg-icon [name]="'mark!'"></svg-icon>
              </span>
              <span class="mx-1"></span>
              {{ uploadError.msg }}
            </span>
          </div>
          <div>
            Or
            <a
              class="text-underline cursor-pointer hyper-link"
              (click)="onChangeFileUploadMethod(true)"
              >Link Existing Document</a
            >
          </div>
        </div>
        <div class="add-file-section" *ngIf="isLinkExistingDocument">
          <div class="add-title">
            <span class="title">Link Existing Document</span>
          </div>
          <div class="select-type-ddl">
            <ng-select
              class="link-existing-document-drop-down"
              placeholder="Select Product"
              formControlName="existingDocument"
              [ngClass]="{
                'err-border': isInvalidController('existingDocument')
              }"
              [items]="existingDocuments"
              bindLabel="partNumber"
              bindValue="selectedDocument"
              [loading]="isLoading"
              [typeahead]="existingDocuments$"
            >
              <ng-template ng-option-tmp let-item="item">
                <div class="drop-down-element d-flex">
                  <div class="circle drop-down-select-circle"></div>
                  {{ item?.partNumber }}
                </div>
              </ng-template>
            </ng-select>
          </div>

          <div>
            Or
            <a
              class="text-underline cursor-pointer hyper-link"
              (click)="onChangeFileUploadMethod(false)"
              >Add File</a
            >
          </div>
        </div>

        <div class="additional-notes">
          <div class=" additional-notes-header ">
            <span class="font-weight-bold">Additional Notes</span>
            <span class="additional-notes-optional"> (optional)</span>
          </div>

          <div class="input-section">
            <input
              type="text"
              placeholder="Type notes here…"
              class="note-input"
              maxlength="180"
              formControlName="notes"
            />
          </div>
        </div>
        <button
          class="add-document-button"
          type="submit"
          (click)="addDocument()"
        >
          Add Document
          <svg-icon class="arrow" name="blue-arrow-right"></svg-icon>
        </button>
      </div>
    </form>
  </div>
  <div class="uploaded-documents-section">
    <div class="w-100 pr-5 upload-document">
      <h4 class="title mb-2">Uploaded Documents</h4>
    </div>
    <div class="col">
      <ng-container *ngIf="uploadedFiles.length > 0; else emptyUploaded">
        <div class="uploaded-section">
          <ng-container
            *ngFor="let uploadedFile of uploadedFiles; let i = index"
          >
            <div
              class="uploadCard my-3"
              [ngClass]="{
                'is-canceled': uploadedFile.isCanceled,
                'is-error': uploadedFile.isError
              }"
            >
              <div class="title">
                {{ uploadedFile.typeName }}
              </div>
              <div
                class="progressCon"
                [ngClass]="{
                  'uploading-bar': uploadedFile.isUploading,
                  'canceled-bar': uploadedFile.isCanceled,
                  'error-bar': uploadedFile.isError
                }"
              >
                <mat-progress-bar
                  *ngIf="
                    uploadedFile.isUploading ||
                    uploadedFile.isCanceled ||
                    uploadedFile.isError
                  "
                  mode="determinate"
                  [value]="uploadedFile.percentageValue"
                ></mat-progress-bar>
                <button
                  class="cancel"
                  *ngIf="uploadedFile.isUploading"
                  (click)="
                    cancelUpload(
                      uploadedFile,
                      fileToUpload,
                      form.controls.notes.value
                    )
                  "
                >
                  <svg-icon [name]="'cancel'"></svg-icon>
                </button>
              </div>
              <div
                class="d-flex align-items-end justify-content-between"
                *ngIf="
                  (uploadedFile.isUploaded ||
                    uploadedFile.isCanceled ||
                    uploadedFile.isError) &&
                  !uploadedFile.isUploading
                "
              >
                <span class="filename flex-grow-1">
                  <div
                    class="d-flex align-items-end cursor-pointer"
                    (click)="viewFile(uploadedFile.path)"
                  >
                    <span
                      [ngbPopover]="infoTemplate"
                      placement="bottom-left"
                      [autoClose]="'outside'"
                      triggers="mouseenter:mouseleave"
                      class="truncated-text popup-container "
                      >{{ uploadedFile.fileName }}
                    </span>
                  </div>
                </span>

                <ng-template #infoTemplate>
                  <div class="file-name-template">
                    {{ uploadedFile.fileName }}
                  </div>
                </ng-template>
                <span class="note" *ngIf="uploadedFile?.expirationDate">
                  EXP:
                  {{ uploadedFile.expirationDate | date: "MM/dd/yy" }}
                </span>
                <button
                  [class.cursor-disabled]="disableAnyAction"
                  class="cancel"
                  (click)="deleteUploadedFile(uploadedFile)"
                >
                  <svg-icon [name]="'delete'"></svg-icon>
                </button>
              </div>
              <div
                class="note pt-3 pb-2"
                (dblclick)="toggleEditMode(uploadedFile)"
                *ngIf="
                  (uploadedFile.isUploaded ||
                    uploadedFile.isCanceled ||
                    uploadedFile.isError) &&
                  !uploadedFile.isUploading
                "
              >
                <div *ngIf="!uploadedFile.isUploading">
                  <div *ngIf="!uploadedFile.isEditing">
                    {{ uploadedFile.note || "No notes added." }}
                  </div>
                  <input
                    *ngIf="uploadedFile.isEditing"
                    type="text"
                    [(ngModel)]="uploadedFile.note"
                    (blur)="saveNote(uploadedFile)"
                  />
                </div>
              </div>
            </div>
            <div
              *ngIf="uploadedFile.isCanceled"
              class="d-flex align-items-center"
            >
              <span class="pb -1">
                <svg-icon [name]="'alertMark'"></svg-icon>
              </span>
              <span class="text-alert mx-1 font-size-14">
                Upload has been canceled.</span
              >
              <span color="red">
                <svg-icon [name]="'refresh'"></svg-icon>
              </span>
              <span
                [class.cursor-disabled]="disableAnyAction"
                (click)="reuploadFile(uploadedFile)"
                class="text-border font-size-14 mx-1 text-underline cursor-pointer"
                >Re-upload File
              </span>
            </div>
            <!-- is error case -->
            <div *ngIf="uploadedFile.isError" class="d-flex align-items-center">
              <svg-icon [name]="'mark!'">
                An error occurred while uploading .</svg-icon
              >

              <img src="assets/images/PNG/primary-refresh.png" />
              <span
                [class.cursor-disabled]="disableAnyAction"
                (click)="reuploadFile(uploadedFile)"
                class="text-primary font-size-13 mx-1 text-underline cursor-pointer"
                >Try again
              </span>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <ng-template #emptyUploaded>
      <div
        class="d-flex flex-column align-items-center p-5 border-top border-bottom mt-3"
      >
        <svg-icon [name]="'folder'"></svg-icon>
        <span class="my-2"></span>
        <div class="text-center d-flex flex-column empty-section">
          <span>All your uploaded documents</span>
          <span> will appear here</span>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<input
  type="file"
  class="invisible"
  #uploadInput
  (change)="upload($event.target)"
/>
