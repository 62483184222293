import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { PackageInformationTypeEnum } from '../../models/enums/package-information-type.enum';

@Component({
  selector: 'app-totals-detailed-packages-information-selection',
  templateUrl:
    './totals-detailed-packages-information-selection.component.html',
  styleUrls: ['./totals-detailed-packages-information-selection.component.scss']
})
export class TotalsDetailedPackagesInformationSelectionComponent
  implements OnInit, OnDestroy {
  ispackagesTotalsForm: UntypedFormGroup;
  @Input() ispackagesTotalsFormControl: UntypedFormControl;
  @Input() ispackagesTotals: boolean;
  @Input() controlName: string;
  @Input() hasDomesticService: boolean;
  @Output() ispackagesTotalsChange = new EventEmitter<boolean>();
  @Input() isDisableForm: boolean;

  packageInformationTypeEnum = PackageInformationTypeEnum;

  constructor() {}
  ngOnDestroy(): void {
    this.ispackagesTotalsFormControl.clearValidators();
    this.ispackagesTotalsFormControl.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.initForm();

    this.ispackagesTotalsForm.valueChanges.subscribe(data => {
      this.ispackagesTotals = data?.isPackageTotals;
      this.ispackagesTotalsChange.emit(data?.isPackageTotals);
    });
  }

  private initForm(): void {
    this.ispackagesTotalsForm = new FormGroup({});
    this.ispackagesTotalsFormControl.addValidators(Validators.required);

    this.ispackagesTotalsForm.addControl(
      this.controlName,
      this.ispackagesTotalsFormControl
    );

    this.ispackagesTotalsForm
      .get(this.controlName)
      .patchValue(this.ispackagesTotals);
  }
}
