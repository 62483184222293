import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmployeeDetails } from 'src/app/company/models/employee-details.model';
import { Employee } from 'src/app/company/models/employee.model';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  actorDetails: Employee;
  employeeDetails: EmployeeDetails;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.employeeDetails = data['employeeDetails'];
      this.actorDetails = this.employeeDetails?.employee;
    });
  }
}
