import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationActivityTransition } from 'src/app/admin-portal-configs/models/registration-activity-transition.model';
import { RegistrationActivityService } from 'src/app/admin-portal-configs/services/registration-activity.service';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-add-or-edit-registration-activity-transition',
  templateUrl: './add-or-edit-registration-activity-transition.component.html',
  styleUrls: ['./add-or-edit-registration-activity-transition.component.css']
})
export class AddOrEditRegistrationActivityTransitionComponent
  implements OnInit {
  transitionForm: UntypedFormGroup;
  Activities: RegistrationActivityTransition[];
  currentActivity: RegistrationActivityTransition;
  selectedActivities: RegistrationActivityTransition[];
  isSaving: boolean = false;
  isNewTransition: boolean = false;
  transitionToIsEmpty: boolean = false;
  duplicateSelection: boolean = false;
  fromActivitiesOptions: RegistrationActivityTransition[];
  toActivitiesOptions: RegistrationActivityTransition[];

  constructor(
    private fb: UntypedFormBuilder,
    private transitionService: RegistrationActivityService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.Activities = data['registrationActivityTransitions'];
      this.currentActivity = data['currentActivity'];
    });

    this.fromActivitiesOptions = this.Activities;
    this.toActivitiesOptions = this.Activities;
    this.initForm();
  }

  initForm() {
    this.isNewTransition = this.currentActivity == null;
    this.currentActivity =
      this.currentActivity || ({} as RegistrationActivityTransition);
    this.currentActivity.nextActivities =
      this.currentActivity.nextActivities || [];

    if (this.isNewTransition) {
      this.currentActivity.id = null;
    } else {
      this.toActivitiesOptions = this.Activities.filter(
        i => i.id != this.currentActivity.id
      );
    }

    this.transitionForm = this.fb.group({
      current: [
        { value: this.currentActivity.id, disabled: !this.isNewTransition },
        [Validators.required]
      ],
      next: null
    });

    const formVal = this.transitionForm.value;
  }

  save() {
    const formVal = this.transitionForm.value;
    if (!this.isNewTransition) {
      formVal.current = this.currentActivity.id;
    }
    this.transitionToIsEmpty = this.currentActivity.nextActivities.length == 0;
    const formValid =
      this.transitionForm.valid &&
      !this.transitionToIsEmpty &&
      !this.hasDuplicatedTransition(formVal.current);

    if (!formValid) {
      this.alertService.error('Invalid Form!');
      return;
    }

    if (this.isNewTransition) {
      this.addNewRequest(formVal);
    } else {
      this.updateRequest(formVal);
    }
  }

  private hasDuplicatedTransition(currentId: number): boolean {
    const index = this.currentActivity.nextActivities.findIndex(
      i => i.id == currentId
    );

    this.duplicateSelection = index > -1 ? true : this.duplicateSelection;
    return index > -1;
  }

  updateRequest(formVal: any) {
    const nextIds = this.currentActivity.nextActivities.map(i => i.id);

    this.transitionService.updateTransition(formVal.current, nextIds).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  addNewRequest(formVal: any) {
    const nextIds = this.currentActivity.nextActivities.map(i => i.id);

    this.transitionService.addNewTransition(formVal.current, nextIds).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  addTransitionOnSelect(event: any) {
    const nextId = event.target.value;
    const nextTransition = this.Activities.find(i => i.id == nextId);

    this.duplicateSelection = this.previouslySelectedTransition(
      nextTransition?.id
    );

    if (!this.duplicateSelection && nextTransition) {
      this.currentActivity.nextActivities.push(nextTransition);
      this.transitionToIsEmpty = false;
    }

    this.updateFromOptions(nextId);
  }

  deleteSingleTransition(transition: any) {
    this.currentActivity.nextActivities = this.currentActivity.nextActivities.filter(
      i => i != transition
    );
  }

  private saveSuccessHelper(transition?: RegistrationActivityTransition) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    if (this.isNewTransition) {
      this.alertService.showMessage(
        'Success',
        `Activity transition \"${transition.name}\" was created successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to activity transition \"${transition.name}\" were saved successfully`,
        MessageSeverity.success
      );
    }

    this.resetForm();
    this.router.navigate(['/settings/registration-activity-transition']);
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occured whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  cancel() {
    this.resetForm();

    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/registration-activity-transition']);
  }

  resetForm() {
    this.transitionForm.reset();
  }

  private previouslySelectedTransition(transitionId: number): boolean {
    return (
      (transitionId && this.currentActivity.id == transitionId) ||
      (this.currentActivity.nextActivities !== undefined &&
        this.hasDuplicatedTransition(transitionId))
    );
  }

  updateToOptions(event: any) {
    const fromOptionActivityId = event.target.value;
    this.toActivitiesOptions = this.Activities.filter(
      i => i.id != fromOptionActivityId
    );
  }

  updateFromOptions(toOptionsActivityId: number) {
    this.fromActivitiesOptions = this.Activities.filter(
      i => i.id != toOptionsActivityId
    );
  }

  get isDefaultFromActivityOption(): boolean {
    const formVal = this.transitionForm.value;
    return formVal.current === 'null';
  }

}
