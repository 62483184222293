<div class="summary-container">
  <div class="d-flex">
    <div class="col-2 section-title">Product Information</div>
    <div class="col-10 row product-information-container">
      <div class="product-data col-4">
        <div class="product-title">
          {{ productInformationSummary.ProductName }}
        </div>
        <div class="product-content">
          {{
            getProductInformationSummaryContent(
              productInformationSummary.ProductName
            ) || "-"
          }}
        </div>
      </div>
      <div class="product-data col-6">
        <div class="product-title">
          {{ productInformationSummary.ProductDescription }}
        </div>
        <div class="product-content">
          {{
            getProductInformationSummaryContent(
              productInformationSummary.ProductDescription
            ) || "-"
          }}
        </div>
      </div>

      <ng-container
        *ngFor="let key of getEnumKeysForProductInformation(); let i = index"
      >
        <div
          *ngIf="
            key !== 'ProductName' &&
            key !== 'ProductDescription' &&
            (isHealthCareOrPharmaceuticalsCategory == false
              ? key != 'Variants'
              : true)
          "
          class="product-data col-4"
        >
          <div class="product-title">
            {{
              isHealthCareOrPharmaceuticalsCategory
                ? getEnumValue(medicalProductInformationSummary, key)
                : getEnumValue(nonMedicalProductInformationSummary, key)
            }}
          </div>
          <div class="product-content">
            {{
              getProductInformationSummaryContent(
                isHealthCareOrPharmaceuticalsCategory
                  ? getEnumValue(medicalProductInformationSummary, key)
                  : getEnumValue(nonMedicalProductInformationSummary, key)
              ) || "-"
            }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="divider" *ngIf="hasVariants()"></div>

  <div class="d-flex" *ngIf="hasVariants()">
    <div class="col-2 section-title ">Product Variants</div>
    <div class="col-10 variant-information-container">
      <div class="product-data col-4">
        <div class="product-title">Part Number</div>
        <div
          class="product-title "
          *ngFor="let variant of variants; let last = last"
        >
          {{ variant.partNumber }}
          <br *ngIf="!last" />
          <br *ngIf="!last" />
        </div>
      </div>
      <div class="product-data col-4">
        <div class="product-title">Variant Description</div>
        <div
          class="product-title "
          *ngFor="let variant of variants; let last = last"
        >
          {{ variant.description }}
          <br *ngIf="!last" />
          <br *ngIf="!last" />
        </div>
      </div>
      <div class="product-data col-4">
        <div class="product-title">Actual Manufacturer</div>
        <div class="product-title" *ngFor="let variant of variants">
          {{ variant.actualManufacturer.companyName }}
          <br />
          {{ variant.actualManufacturer?.address }}
          <br />
          {{ variant.actualManufacturer.cityName }},
          {{ variant.actualManufacturer.countryName }}
        </div>
      </div>
    </div>
  </div>
</div>
