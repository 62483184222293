import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivityService } from '../../admin-portal-configs/services/activity.service';
import { Activity } from '../models/shipment/activity.model';
 
@Injectable({
  providedIn: 'root'
})
export class ActivityResolver
  implements Resolve<Activity[]> {
  id: number;
  constructor(
    private router: Router,
    private activityService: ActivityService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Activity[]> {
    this.id = JSON.parse(route.params.id) as number;
    return this.activityService.getById(this.id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
