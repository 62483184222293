import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ChargeTypeWithService } from "../../admin-portal-configs/models/charge-type-with-service.model";
import { ChargeTypeService } from "../../admin-portal-configs/services/charge-type.service";

@Injectable({
  providedIn: 'root'
})
export class ChargeTypeListResolver implements Resolve<ChargeTypeWithService[]> {
  constructor(private router: Router, private chargeTypeService: ChargeTypeService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ChargeTypeWithService[]> {
    return (
      this.chargeTypeService
        .getAllWithServices()
        .pipe(
          catchError((error) => {
            return of(null);
          })
        )
    );
  }
}
