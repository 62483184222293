import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LandRouteService } from 'src/app/admin-portal-configs/services/land-route.service';
import { Paginated } from 'src/app/shared/models/shipment/paginated.model';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { Utilities } from 'src/app/shared/services/utilities';

@Component({
  selector: 'app-land-routes-list',
  templateUrl: './land-routes-list.component.html',
  styleUrls: ['./land-routes-list.component.scss']
})
export class LandRoutesListComponent implements OnInit {
  paginatedLandRoutes: Paginated;
  columns: any;
  @ViewChild('actionTemplate', { static: true })
  actionTemplate: TemplateRef<any>;
  @ViewChild('routeNameTemplate', { static: true })
  routeNameTemplate: TemplateRef<any>;
  @ViewChild('originPortTemplate', { static: true })
  originPortTemplate: TemplateRef<any>;
  @ViewChild('transitPortTemplate', { static: true })
  transitPortTemplate: TemplateRef<any>;
  @ViewChild('deliveryPortTemplate', { static: true })
  deliveryPortTemplate: TemplateRef<any>;

  pageIndex: number = 0;
  pageSize: number = 10;

  constructor(
    private route: ActivatedRoute,
    private translationService: AppTranslationService,
    private landRouteService: LandRouteService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.paginatedLandRoutes = data['landRoutes'];
      this.initTable();
    });
  }

  initTable() {
    const gT = (key: string) => this.translationService.getTranslation(key);
    this.columns = [
      {
        prop: '',
        name: gT('config.management.Land.RouteName'),
        width: 80,
        cellTemplate: this.routeNameTemplate
      },
      {
        prop: '',
        name: gT('config.management.Land.OriginPort'),
        width: 140,
        cellTemplate: this.originPortTemplate
      },
      {
        prop: '',
        name: gT('config.management.Land.Transit'),
        width: 80,
        cellTemplate: this.transitPortTemplate
      },
      {
        prop: '',
        name: gT('config.management.Land.DeliveryPort'),
        width: 80,
        cellTemplate: this.deliveryPortTemplate
      },
      {
        prop: '',
        name: gT('customer.services.Action'),
        width: 10,
        cellTemplate: this.actionTemplate,
        canAutoResize: true
      }
    ];
  }

  getLandRoutes() {
    this.landRouteService
      .getLandRoutePaginated('', this.pageIndex, this.pageSize)
      .subscribe(landRoutes => {
        this.paginatedLandRoutes.items = [
          ...this.paginatedLandRoutes.items,
          ...landRoutes.items
        ];
      });
  }

  onSearchChanged(event: any) {
    this.landRouteService.getLandRoutePaginated(event).subscribe(data => {
      if (data.items.length > 0) {
        this.paginatedLandRoutes.items = data?.items;
      }
    });
  }

  onScroll(event) {
    const scrollTop = event.target?.scrollTop;
    const scrollHeight = event.target?.scrollHeight;
    const clientHeight = event.target?.clientHeight;
    if (scrollTop + 1 + clientHeight >= scrollHeight) {
      this.pageIndex++;
      this.getLandRoutes();
    }
  }

  get islandRouteExist(): boolean {
    return this.paginatedLandRoutes?.items?.length > 0;
  }

  deleteLandRoute(row: any) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' +
        row.pickupCountry +
        ' - ' +
        row.deliveryCountry +
        '" land route?',
      DialogType.confirm,
      () =>
        this.landRouteService.deleteLandRoute(row.id).subscribe(
          () => {
            this.paginatedLandRoutes.items = this.paginatedLandRoutes.items.filter(
              r => r.id != row.id
            );
          },
          error => {
            this.alertService.showStickyMessage(
              'Delete Error',
              `An error occured while deleting the land route.\r\nError: "${Utilities.getHttpResponseMessages(
                error
              )}"`,
              MessageSeverity.error,
              error
            );
          }
        )
    );
  }
}
