import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryDto } from 'src/app/admin-portal-configs/models/country-dto';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CountryService } from 'src/app/shared/services/country.service';

@Component({
  selector: 'app-add-or-edit-country',
  templateUrl: './add-or-edit-country.component.html',
  styleUrls: ['./add-or-edit-country.component.scss']
})
export class AddOrEditCountryComponent implements OnInit {
  pageTitle: string = 'Add Country Details';
  countryForm: UntypedFormGroup;
  isInvalidForm: boolean;
  country: CountryDto;
  isSaving: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private countryService: CountryService,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.country = data['country'];
    });
    this.initForm();
  }

  initForm() {
    this.countryForm = this.fb.group({
      country: [this.country?.country, [Validators.required]],
      code: [this.country?.code, [Validators.required]],
      lat: [this.country?.lat, [Validators.required]],
      lng: [this.country?.lng, [Validators.required]],
      isReference: [this.country?.isReference, [Validators.required]],
      isLandFreight: [this.country?.isLandFreight, [Validators.required]]
    });
  }

  saveORCancel(isSave: boolean) {
    if (isSave) {
      this.save();
    } else {
      this.cancel();
    }
  }

  save() {
    if (this.isSaving == true) {
      return;
    }

    this.isInvalidForm = false;
    this.countryForm.markAllAsTouched();
    if (this.countryForm.invalid) {
      this.isInvalidForm = true;
      return;
    }

    this.isSaving = true;
    let countryDto = this.countryForm.value;

    if (this.isEditing) {
      this.updateCountry(countryDto);
    } else {
      this.addCountry(countryDto);
    }
  }

  addCountry(countryDto: CountryDto) {
    this.countryService.addCountry(countryDto).subscribe(
      data => {
        this.isSaving = false;
        this.alertService.success('Country added successfully!');
        this.router.navigate(['../countries']);
      },
      error => {
        this.isSaving = false;
        this.alertService.error('Country already exists!');
      }
    );
  }

  updateCountry(countryDto: CountryDto): void {
    this.countryService.updateCountry(countryDto).subscribe(
      data => {
        this.isSaving = false;
        this.alertService.success('Country updated successfully');
        this.router.navigate(['../countries']);
      },
      error => {
        this.isSaving = false;
        this.alertService.error('Country already exists!');
      }
    );
  }

  cancel() {
    this.router.navigate(['../countries']);
  }

  validateLatLngInput(event: any): void {
    const pattern = /^[0-9.-]*$/;
    const input = event.target.value;

    if (!pattern.test(input)) {
      event.target.value = input.replace(/[^0-9.-]/g, '');
    }
  }

  get isEditing(): boolean {
    return this.router.url.includes('edit');
  }
  
}
