import { 
  Component, 
  HostListener,
  Input,
  OnInit, 
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputNumberComponent)
    }
  ]
})
export class InputNumberComponent implements OnInit, ControlValueAccessor {
  @Input() classList!: string;
  @Input() isGreyBorder: boolean = false;
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() readonly : boolean;
  @Input() maxLength :number ;

  value: any = '';
  private innerValue: any = '';

  constructor() {}

  ngOnInit(): void {
    if (this.isGreyBorder) {
      this.classList += ' grey';
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.innerValue = value;
    if (value) {
      this.value = value.toString();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: any): void {
    this.innerValue = this.removeCommas(value);
    this.onChange(this.innerValue);
    this.onTouched();
  }

  private removeCommas(value: string): string {
    return value.replace(/,/g, '');
  }
}
