import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ProductRegistrationLeadTime } from '../models/product-registration-lead-time.model';
import { ProductRegistrationKpisService } from '../services/product-registration-kpis.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductRegistrationKpiListResolver
  implements Resolve<ProductRegistrationLeadTime[]> {
  constructor(public kpiService: ProductRegistrationKpisService) {}

  resolve(): Observable<ProductRegistrationLeadTime[]> {
    return this.kpiService.getAll().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
