import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HelperService } from '../services/helper.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesResolver implements Resolve<boolean> {
  constructor(private helperService: HelperService) {}
  resolve(): Observable<boolean> {
    return this.helperService.getServices().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
