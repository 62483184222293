<ngx-doc-viewer
*ngIf="!isImage"
  style="width: 100%; height:100%"
  [url]="url"
  [viewer]="viewerType"
></ngx-doc-viewer>


<div class="image-container" *ngIf="isImage">
  <img [src]="url" alt="Image" />
</div>
