import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/shared/services/account.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DocumentService } from 'src/app/shared/services/document.service';

@Component({
  selector: 'app-change-profile-picture',
  templateUrl: './change-profile-picture.component.html',
  styleUrls: ['./change-profile-picture.component.scss']
})
export class ChangeProfilePictureComponent implements OnInit {
  file: File = null;
  formData: FormData;
  isSaving: boolean;
  constructor(
    private accountService: AccountService,
    private authService: AuthService,
    private alertService: AlertService,
    private documentService: DocumentService
  ) {}

  ngOnInit() {}

  onChange(event) {
    this.file = event.target.files[0];

    if (!this.file.type.match(`image.*|image/png|TIFF|PSD|SVG|PJP`)) {
      this.alertService.error(
        'File type not supported. Supported formats are: jpg, jpeg, png, tiff, psd, svg, bmp, webp, pjp.'
      );
      return;
    }
    if (this.file.size / 1024 / 1024 > 2) {
      this.alertService.error(
        'File size not supported. Supported size is up to 2MB.'
      );
      return;
    }

    this.prepareFormData(this.file);
    this.isSaving = true;
    this.documentService.UploadProfilePicture(this.formData).subscribe(data => {
      this.authService.updateProfilePictureUrl(data.documentPath);
      this.isSaving = false;
    });
  }

  onUploadClick() {
    document.getElementById('upload-input').click();
  }

  prepareFormData(file: File) {
    const formData = new FormData();
    formData.append('submittedFile', file);
    formData.append('employeeId', this.accountService.currentUser.employeeId);
    this.formData = formData;
  }

  get profilePictureUrl() {
    return this.accountService.currentUser.profilePictureUrl;
  }
}
