<div class="product-variants-container">
  <div class="headings col-12 px-0 d-flex w-100">
    <div class="column-title col-2">Variant(s)</div>
    <div class="column-title col-3 product-description">
      Product Description
    </div>
    <div class="col-2 px-0"></div>
    <div class="column-title col-2 country-of-origin">Country of Origin</div>
    <div class="column-title col-2 actual-manufacturer">
      Actual Manufacturer
    </div>
    <div class="col-1 px-0"></div>
  </div>

  <div *ngFor="let variant of variants" class="d-flex">
    <div class="column-content part-number col-2 p-0 text-left">
      {{ getVariantPartNumber(variant) }}
    </div>
    <div class="column-content col-3 p-0 text-left product-description">
      {{ getVariantDescription(variant) }}
    </div>
    <div class="col-2 p-0"></div>
    <div
      class="column-content country-content col-2 p-0 text-left country-of-origin"
    >
      {{ getVariantCountryOfOrigin(variant) }}
    </div>
    <div class="column-content col-2 p-0 text-left actual-manufacturer">
      <span class="country-content">Manufacturer Name</span>
      <div class="column-content manufacturer-name">
        {{ getActualManufacturerName(variant) }}
      </div>
    </div>
  </div>
</div>
