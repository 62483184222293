import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  Injectable,
  OnChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { QuoteServicesClass } from '../../constants/QuoteServicessClass';
import { ServiceTypes } from '../../models/shipment/service-types.enum';
import { ShipmentFreightType } from '../../models/enums/shipment-freight-type.enum';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-service-selection-card',
  templateUrl: './service-selection-card.component.html',
  styleUrls: ['./service-selection-card.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ServiceSelectionCardComponent),
      multi: true
    }
  ]
})
export class ServiceSelectionCardComponent implements OnInit, OnChanges {
  @Output() cardSelectedEmitter = new EventEmitter<string>();
  @Output() optionSelected = new EventEmitter<any>();

  value: string;
  title: string;
  description: string;
  iconPath: string;
  hasInputControls: boolean;
  cardSelected: boolean = false;
  errorMessage: string = '';

  @Input() service: any;
  @Input() isDisabled: boolean;
  @Input() hasError: boolean = false;
  @Input() classList: string;

  @Input() inputOptions: string[] = [];
  @Input() inputLabels: string[] = [];
  @Input() disabledOptions: string[] = [];
  @Input() canSelectMultipleOptions: boolean = false;

  formArray: any = [];

  @Input() isServiceSelectionPage?: boolean;

  serviceTypes: typeof ServiceTypes = ServiceTypes;

  constructor() {}

  ngOnInit(): void {
    this.hasInputControls = this.inputOptions.length > 0;
    this.getServiceConstants();
  }

  ngOnChanges(changes: any): void {
    if (changes.isDisabled) {
      this.cardSelected = false;
    }
  }

  elementChecked(event): void {
    if (event.target.checked) {
      if (!this.canSelectMultipleOptions) {
        this.formArray = [];
      }

      this.formArray.push(event.target.value);
    } else {
      this.formArray.forEach((option, i) => {
        if (option == event.target.value) {
          this.formArray.splice(i, 1);
          return;
        }
      });
    }

    if (!this.cardSelected) {
      this.cardSelected = true;
      this.cardSelectedEmitter.emit(this.value);
    }

    if (this.formArray.length == 0 && this.cardSelected) {
      this.cardSelected = false;
      this.cardSelectedEmitter.emit(this.value);
    }

    this.optionSelected.emit(this.formArray);
  }

  submit(): void {
    if (!this.isDisabled) {
      this.cardSelected = !this.cardSelected;
      if (!this.cardSelected) {
        this.formArray.forEach(element => {
          this.clearInput(element);
        });
        this.formArray = [];

        this.optionSelected.emit(this.formArray);
      }

      this.cardSelectedEmitter.emit(this.value);
    }
  }

  getInputLabelAtIndex(index: number): string {
    if (this.inputLabels.length <= index) {
      return '';
    }

    return this.inputLabels[index];
  }

  getServiceConstants(): void {
    const serviceConstant = QuoteServicesClass.find(
      s => s.service === this.service
    );

    this.title = serviceConstant.title;
    this.description = serviceConstant.description;
    this.iconPath = serviceConstant.image;
    this.errorMessage = serviceConstant.errorMessage;
  }

  clearInput(input: string): void {
    (document.getElementById(input) as HTMLInputElement).checked = false;
  }

  disableOption(option: string): boolean {
    if (this.disabledOptions.find(s => s == option) != undefined) {
      this.clearInput(option);

      return true;
    }

    return false;
  }

  hasAirFreightExtraPadding(option: string): boolean {
    if (this.service != ServiceTypes.Freight) {
      return false;
    }

    return (
      this.inputOptions?.length === 3 && option === ShipmentFreightType.Air
    );
  }
}
