import { Component, Input, OnInit } from '@angular/core';
import { ActivityLog } from '../../models/activity-log.model';

@Component({
  selector: 'app-progress-point-logs',
  templateUrl: './progress-point-logs.component.html',
  styleUrls: ['./progress-point-logs.component.scss']
})
export class ProgressPointLogsComponent implements OnInit {
  @Input() activityLogs: ActivityLog[];

  constructor() {}

  ngOnInit(): void {}
}
