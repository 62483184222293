import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Industry } from 'src/app/shared/models/industry.model';
import { environment } from 'src/environments/environment';
 
@Injectable({
  providedIn: 'root'
})
export class IndustryService {
  baseURL = environment.apiUrl + 'industry';

  constructor(private http: HttpClient) {}

  getAll(): Observable<Industry[]> {
    return this.http.get<Industry[]>(`${this.baseURL}`);
  }

  getById(id: number): Observable<Industry> {
    return this.http.get<Industry>(`${this.baseURL}/${id}`);
  }

  deleteIndustry(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseURL}/${id}`);
  }

  addNewIndustry(industry: any): Observable<Industry> {
    return this.http.post<Industry>(`${this.baseURL}`, industry);
  }

  updateIndustry(id: number, industry: any): Observable<Industry> {
    return this.http.put<Industry>(`${this.baseURL}/${id}`, industry);
  }

  getIndustryByCompanyId(companyId : number ): Observable<Industry> {
    return this.http.get<Industry>(`${this.baseURL}/company/${companyId}`);
  }
}
