import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-admin-page-details',
  templateUrl: './admin-page-details.component.html',
  styleUrls: ['./admin-page-details.component.scss']
})
export class AdminPageDetailsComponent implements OnInit {
  @Input() title: string;
  @Input() isShowErrorAlert: boolean = false;
  @Output() submitButtonClicked: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  constructor() {}

  ngOnInit(): void {}

  onSubmitButtonClicked(event) {
    this.submitButtonClicked.emit(event);
  }
}
