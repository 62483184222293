import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityTransition } from 'src/app/admin-portal-configs/models/activity-transition.model';
import { RegistrationActivityService } from 'src/app/admin-portal-configs/services/registration-activity.service';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Utilities } from 'src/app/shared/services/utilities';

@Component({
  selector: 'app-registration-activity-transition-list',
  templateUrl: './registration-activity-transition-list.component.html',
  styleUrls: ['./registration-activity-transition-list.component.scss']
})
export class RegistrationActivityTransitionListComponent implements OnInit {
  columns: any[] = [];
  rows: ActivityTransition[] = [];
  rowsCache: ActivityTransition[] = [];
  loadingIndicator: boolean;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private activityService: RegistrationActivityService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      var transitions = data['registrationActivityTransitions'];

      transitions = this.getIndecies(transitions);

      if (transitions?.length > 0) {
        this.rowsCache = [...transitions];
        this.rows = transitions;
      }
    });

    const gT = (key: string) => this.translationService.getTranslation(key);
    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      { prop: 'name', name: gT('config.management.Name'), width: 400 },
      {
        prop: 'nextActivities.length',
        name: gT('config.management.RegistrationActivity.NextTransitionsCount'),
        width: 100
      },
      {
        prop: 'options',
        name: gT('config.management.RegistrationActivity.ManageTransitions'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      }
    ];
  }

  getIndecies(array: any) {
    array?.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  deleteActivityTransition(row: ActivityTransition) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' +
        row.name +
        '" activity transitions? this will delete all next transitions for this activity',
      DialogType.confirm,
      () => this.deleteActivityTransitionHelper(row)
    );
  }

  deleteActivityTransitionHelper(row: ActivityTransition) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.activityService.deleteTransition(row.id).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache.find(i => i.id == row.id).nextActivities = [];
        this.rows = this.rowsCache;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the activity transition.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  get canUpdateRegistrationWorkflows(): boolean {
    return this.authService.canUpdateRegistrationWorkflows;
  }

  get canCreateRegistrationWorkflows(): boolean {
    return this.authService.canCreateRegistrationWorkflows;
  }

  get canDeleteRegistrationWorkflows(): boolean {
    return this.authService.canDeleteRegistrationWorkflows;
  }
}
