<div class="documents-summary-container row mr-0">
  <div class="section-title col-2">Documents</div>
  <div class="col-10">
    <div
      *ngIf="
        !quoteDocuments || quoteDocuments?.length <= 0;
        else documentsTemplate
      "
      class="no-documents-text"
    >
      No documents were uploaded
    </div>

    <ng-template #documentsTemplate>
      <div class="row">
        <div class="col-4 row" *ngFor="let document of quoteDocuments">
          <div class="title col-12">{{ document?.documentTypeName }}</div>
          <div
          class="file-name col-12 truncated-text"
          (click)="viewFile(document?.path)"
          [ngbPopover]="infoTemplate"
          placement="bottom-left"
          [autoClose]="'outside'"
          triggers="mouseenter:mouseleave"
        >
          <span>{{ document.fileName }} </span>
          <ng-template #infoTemplate>
            <div>
              {{ document.fileName }}
            </div>
          </ng-template>
        </div> 
           
        </div>
      </div>
    </ng-template>
  </div>
</div>
