import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ShipmentDetails } from 'src/app/shipments/models/shipment-details.model';
import { UpdateProgressExpectedDate } from 'src/app/shared/models/shipment/update-progress-expected-date.model';
import { Activity } from 'src/app/shared/models/shipment/activity.model';
import { UpdateActivityLog } from 'src/app/shared/models/shipment/update-activity-log.model';
import { Charge } from 'src/app/shared/models/charge.model';
import { SearchCriteria } from 'src/app/shared/models/shipment/search-criteria.model';
import { ColumnNames } from 'src/app/shipments/enums/shipments-lists-column-name.enum';
import { ShipmentServices } from 'src/app/shared/models/shipment/shipmentServices.model';
import { ShipmentComment } from 'src/app/shipments/models/shipment-comment-model';
import { Form4ActivityRequest } from '../shared/models/shipment/form4-activity-request.model';
import { UpdateForm4StatusRequest } from '../shared/models/shipment/update-form4-status-request.model';
import { TotalShipmentsInsights } from './models/total-shipments-insights.model';
import { IorEorServiceProviderAndEgyptIdResolver } from '../createShipment/resolvers/iorEor-service-provider-and-egypt-id.resolver';
import { LocalStoreManager } from '../shared/services/local-store-manager.service';
import { ExportExcel } from '../shared/models/shipment/export-excel-model';
import { ShipmentsBulkUpdateModel } from '../shared/models/shipment/bulk-update-query.model';
import { CardViewEnum } from './enums/card-view.enum';
import { FreePLUser } from '../shared/models/freepl-user.model';

@Injectable({
  providedIn: 'root'
})
export class ShipmentsService {
  baseURL = environment.apiUrl + 'Shipment';
  companyId: number;

  @Output() shipmentsBulkUpdated = new EventEmitter();

  @Output() onCommentsUpdate: EventEmitter<
    ShipmentComment
  > = new EventEmitter();

  @Output() shipmentUpdated = new EventEmitter<{
    hasUpdatedServices?: boolean;
  }>();

  @Output() chargeAddedEmitter: EventEmitter<{
    added: boolean;
    addedCharge: Charge;
  }> = new EventEmitter<{ added: boolean; addedCharge: Charge }>();

  @Output() chargeDeleted: EventEmitter<{
    chargeId: string;
  }> = new EventEmitter<{ chargeId: string }>();

  @Output() confirmUnSavedData = new EventEmitter();

  @Output() displayedColumnsChanged: EventEmitter<{
    columnNames: ColumnNames[];
  }> = new EventEmitter<{ columnNames: ColumnNames[] }>();

  @Output() onSearchCriteriaChange = new EventEmitter<SearchCriteria>();

  egyptId: number;

  @Output() onSaveDraft: EventEmitter<{
    label: string;
    currentPageName: string;
  }> = new EventEmitter<{ label: string; currentPageName: string }>();

  draftShipmentsMaxLimit = 10;

  @Output() onViewInChat = new EventEmitter<string>();
  @Output() leadTimeNotesUpdated: EventEmitter<{
    leadTimeId: number;
    leadTimeNotes: string;
  }> = new EventEmitter<{ leadTimeId: number; leadTimeNotes: string }>();

  private listOfShipmentsNumber: string[] = [];
  @Output() listOfShipmentsNumberChanged = new EventEmitter<string[]>();

  getListOfShipmentsNumberCount(): number {
    return this.listOfShipmentsNumber.length;
  }

  addToListOfShipments(shipmentNumber: string): void {
    this.addToListOfShipmentsNumber(shipmentNumber);
    this.listOfShipmentsNumberChanged.emit(this.getAllListOfShipmentsNumber());
  }

  removeFromListOfShipments(shipmentNumber: string): void {
    this.listOfShipmentsNumber = this.getAllListOfShipmentsNumber().filter(
      item => {
        return item != shipmentNumber;
      }
    );
    this.listOfShipmentsNumberChanged.emit(this.listOfShipmentsNumber);
  }

  addToListOfShipmentsNumber(shipmentNumber: string): void {
    this.listOfShipmentsNumber.push(shipmentNumber);
  }

  getAllListOfShipmentsNumber(): string[] {
    return this.listOfShipmentsNumber;
  }

  emptyListOfShipmentsNumber(): void {
    this.listOfShipmentsNumber = [];
    this.listOfShipmentsNumberChanged.emit(this.listOfShipmentsNumber);
  }

  isShipmentInList(shipmentNumber: string): boolean {
    let searchIndex = this.listOfShipmentsNumber.indexOf(shipmentNumber);
    return searchIndex != -1;
  }

  constructor(
    private http: HttpClient,
    private localStorage: LocalStoreManager
  ) {}

  getShipments(
    companyId,
    page = 0,
    pageSize = 10,
    searchTerm = ''
  ): Observable<any> {
    let url = this.baseURL + `/${companyId}` + `/${page}` + `/${pageSize}`;
    if (searchTerm) {
      url = url + `/?searchTerm=${searchTerm}`;
    }
    return this.http.get(url);
  }

  searchShipments(
    searchCriteria: SearchCriteria,
    companyId: string = ''
  ): Observable<any> {
    let url = `${this.baseURL}/Filter/Company/${companyId ?? ''}`;
    return this.http.post(url, searchCriteria);
  }

  getShipmentsOnDashboard(
    searchCriteria: SearchCriteria,
    companyId: string = ''
  ): Observable<any> {
    let url = `${this.baseURL}/Filter/Company/${companyId ?? ''}/Dashboard`;
    return this.http.post(url, searchCriteria);
  }

  exportSelectedShipments(
    searchCriteria: SearchCriteria,
    isAllSelected: boolean
  ): Observable<any> {
    let selectedColumns = this.isShipmentListView
      ? this.selectedManageColumns
      : null;
    let allSelectedColumns = selectedColumns
      ? [...this.fixedSelectedColumns, ...selectedColumns]
      : null;

    const exportExcel: ExportExcel = {
      searchCriteria: searchCriteria,
      listOfShipmentsNumber: this.listOfShipmentsNumber,
      isAllSelected: isAllSelected,
      selectedColumns: allSelectedColumns
    };
    return this.http.post(
      this.baseURL + `/${this.companyId}/ExportExcel`,
      exportExcel,
      {
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  getShipmentDetails(
    shipmentId,
    companyId: string = ''
  ): Observable<ShipmentDetails> {
    return this.http.get<ShipmentDetails>(
      `${this.baseURL}/Details/${shipmentId}/Company/${companyId}`
    );
  }

  duplicateShipment(shipmentId): Observable<any> {
    return this.http.post<any>(
      this.baseURL + `/Duplicate?id=${shipmentId}`,
      {}
    );
  }

  addActivityLog(updatedShipmentActivity: UpdateActivityLog) {
    return this.http.post(
      environment.apiUrl + `ActivityLog`,
      updatedShipmentActivity
    );
  }

  getShipmentActivities(
    currentActivity: string,
    freightReference: string,
    serviceNames?: string[]
  ): Observable<Activity[]> {
    return this.http.post<Activity[]>(
      environment.apiUrl +
        `Activity/Filtered/${freightReference}?currentActivity=${currentActivity}`,
      serviceNames
    );
  }

  updateProgressExpectedDate(updatedDate: UpdateProgressExpectedDate) {
    return this.http.put(
      environment.apiUrl + `ProgressDate/ExpectedDate`,
      updatedDate
    );
  }

  updateProgressActualDate(updatedDate: UpdateProgressExpectedDate) {
    return this.http.put(
      environment.apiUrl + `ProgressDate/ActualDate`,
      updatedDate
    );
  }

  deleteDocument(
    shipmentId,
    documentSqlId,
    deletionReason,
    parentDocumentSqlId
  ): Observable<any> {
    return this.http.delete(
      this.baseURL +
        `/DeleteDocument?shipmentId=${shipmentId}&documentSqlId=${documentSqlId}&deletionReason=${deletionReason}&parentDocumentSqlId=${parentDocumentSqlId}`
    );
  }

  removeDocument(shipmentId: string, documentSqlId: number): Observable<any> {
    return this.http.delete(
      this.baseURL +
        `/RemoveDocument?shipmentId=${shipmentId}&documentSqlId=${documentSqlId}`
    );
  }

  putOhHold(shipmentId, companyId, reason) {
    return this.http.post(
      environment.apiUrl +
        `ActivityLog/Shipment/PutOhHold?shipmentId=${shipmentId}`,
      {
        shipmentId: shipmentId,
        companyId: companyId,
        reason: reason
      }
    );
  }

  cancel(shipmentId, companyId, reason) {
    return this.http.post(
      environment.apiUrl +
        `ActivityLog/Shipment/Cancel?shipmentId=${shipmentId}`,
      {
        shipmentId: shipmentId,
        companyId: companyId,
        reason: reason
      }
    );
  }

  undo(shipmentId, companyId, reason) {
    return this.http.post(
      environment.apiUrl + `ActivityLog/Shipment/Undo?shipmentId=${shipmentId}`,
      {
        shipmentId: shipmentId,
        companyId: companyId,
        reason: reason
      }
    );
  }

  resume(shipmentId, companyId) {
    return this.http.post(
      environment.apiUrl +
        `ActivityLog/Shipment/Resume?shipmentId=${shipmentId}&companyId=${companyId}`,
      {}
    );
  }

  addCharge(shipmentId: string, charge: Charge): Observable<Charge> {
    return this.http.post<Charge>(
      this.baseURL + `/Charge?id=${shipmentId}`,
      charge
    );
  }

  updateCharge(shipmentId: string, charge: Charge): Observable<Charge> {
    return this.http.put<Charge>(
      this.baseURL + `/Charge?id=${shipmentId}`,
      charge
    );
  }

  deleteCharge(shipmentId: string, chargeId: string) {
    return this.http.delete(
      this.baseURL + `/Charge?id=${shipmentId}&chargeId=${chargeId}`
    );
  }

  deleteActivityLog(id) {
    return this.http.delete(environment.apiUrl + `ActivityLog/${id}`);
  }

  getShipmentServices(shipmentId: string): Observable<ShipmentServices> {
    return this.http.get<ShipmentServices>(
      this.baseURL + `/ShipmentServices/${shipmentId}`
    );
  }

  getShipmentComments(shipmentId: string): Observable<ShipmentComment[]> {
    return this.http.get<ShipmentComment[]>(
      `${this.baseURL}/Comments?shipmentId=${shipmentId}`
    );
  }

  addCommentToShipment(shipmentComment: ShipmentComment) {
    return this.http.post<any>(`${this.baseURL}/Comments`, shipmentComment);
  }

  addCommentPendingActionToShipment(shipmentComment: ShipmentComment) {
    return this.http.post<any>(
      `${this.baseURL}/Comments?isPendingAction=${true}`,
      shipmentComment
    );
  }

  getUserDraftShipmentsNumber(companyId: string): Observable<number> {
    return this.http.get<number>(
      `${this.baseURL}/UserDraftShipmentsNumber?companyId=${companyId}`
    );
  }

  saveDraftShipment(shipmentId, companyId, reason): Observable<any> {
    return this.http.post(
      environment.apiUrl +
        `ActivityLog/Shipment/SaveDraftShipment?shipmentId=${shipmentId}`,
      {
        shipmentId: shipmentId,
        companyId: companyId,
        reason: reason
      }
    );
  }

  deleteDraftShipment(shipmentId, companyId): Observable<any> {
    return this.http.post(
      environment.apiUrl +
        `ActivityLog/Shipment/DeleteDraftShipment?shipmentId=${shipmentId}`,
      {
        shipmentId: shipmentId,
        companyId: companyId
      }
    );
  }

  getIorEorServiceProviderAndEgyptId(
    companyId: number
  ): Observable<IorEorServiceProviderAndEgyptIdResolver> {
    return this.http.get<IorEorServiceProviderAndEgyptIdResolver>(
      `${this.baseURL}/${companyId}/IorEorServiceProviderAndEgyptId`
    );
  }

  updateShipmentMarker(shipmentId: string): Observable<any> {
    return this.http.put<any>(`${this.baseURL}/${shipmentId}/Marker`, null);
  }

  markCommentPendingActionAsFulfilled(shipmentId: string, commentId: number) {
    return this.http.post<any>(
      `${this.baseURL}/PendingActions/${shipmentId}/Fulfill/${commentId}`,
      null
    );
  }

  updateForm4Activity(form4ActivityRequest: Form4ActivityRequest) {
    return this.http.post(
      environment.apiUrl + `ActivityLog/Shipment/Form4ActivityRequest`,
      {
        shipmentId: form4ActivityRequest?.shipmentId,
        companyId: form4ActivityRequest?.companyId,
        activityName: form4ActivityRequest?.activityName
      }
    );
  }

  updateLeadTimeNotes(leadTimeId: number, notes?: string) {
    return this.http.put(
      environment.apiUrl +
        `LeadTimeLog/lead-time-log/${leadTimeId}/Notes?notes=${notes}`,
      null
    );
  }

  updateShipmentForm4Status(
    updateForm4StatusRequest: UpdateForm4StatusRequest
  ) {
    return this.http.post(
      environment.apiUrl + `ActivityLog/Form4Status`,
      updateForm4StatusRequest
    );
  }

  getTotalShipmentsInsights(): Observable<TotalShipmentsInsights> {
    return this.http.get<TotalShipmentsInsights>(
      this.baseURL + `/TotalShipmentsInsights`
    );
  }

  updateShipmentServicesPostCreation(
    shipmentId: string,
    shipmentServices: ShipmentServices
  ): Observable<any> {
    return this.http.put<any>(
      `${this.baseURL}/${shipmentId}/ShipmentServices`,
      shipmentServices
    );
  }

  getOrDeleteStoredQuoteId(isDelete: boolean): string {
    if (!isDelete) {
      return this.localStorage.getData('quoteId');
    } else {
      this.localStorage.deleteData('quoteId');
      return null;
    }
  }

  get selectedShipmentListView(): string {
    return this.localStorage?.getData('shipmentListView');
  }

  get isShipmentListView(): boolean {
    return this.selectedShipmentListView == CardViewEnum.Table;
  }

  get selectedManageColumns(): string[] {
    return this.localStorage?.getData('selectedShipmentView')?.map(x => x.name);
  }

  get fixedSelectedColumns(): string[] {
    return [
      ColumnNames.MoT,
      ColumnNames.ShipmentNo,
      ColumnNames.Status,
      ColumnNames.RefNo,
      ColumnNames.DateCreated
    ];
  }

  bulkUpdateShipmentsStatus(
    searchCriteria: SearchCriteria,
    statusName: string,
    companyId: number,
    isAllSelected: boolean
  ): Observable<any> {
    let bulkUpdateModel: ShipmentsBulkUpdateModel = {
      searchCriteria: searchCriteria,
      shipmentsNumbers: this.listOfShipmentsNumber,
      isAllSelected: isAllSelected
    };
    return this.http.put<any>(
      environment.apiUrl +
        `ActivityLog/BulkUpdateShipmentStatus/${statusName}/Company/${companyId}`,
      bulkUpdateModel
    );
  }

  updateShipmentRegulatoryAssociate(
    updateActivityLog: UpdateActivityLog
  ): Observable<FreePLUser> {
    return this.http.put<FreePLUser>(
      environment.apiUrl + `ActivityLog/RegulatoryAssociate`,
      updateActivityLog
    );
  }
}
