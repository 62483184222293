<div class="switch-container">
  <div class="switch-title">
    {{ title }}
  </div>
  <div class="switch-btn-container">
    <label class="switch-btn">
      <input type="checkbox" [(ngModel)]="isChecked" />
      <span class="switch-round"></span>
    </label>
  </div>
</div>

<div class="popup-container" [ngClass]="{ show: isChecked }">
  <div
    class="checkbox-section"
    *ngFor="let item of items?.categoryPermissions; index as i"
  >
    <div class="checkboxes-title-container">
      <div class="checkboxes-title">
        <app-checkbox
          class="permission-sub-input"
          (click)="changeParentValue(item)"
          [checked]="item.isSelected"
          [classes]="item.selectionType"
          [preventDefaultAction]="
            item.selectionType == 'dashmark' ? true : false
          "
        ></app-checkbox>
        <label class="permission-sub-label" for="">
          {{ item.permissionCategory.name }}
        </label>
      </div>
    </div>

    <div class="check-boxes-container">
      <div
        class="check-box-item"
        *ngFor="let subItem of item.permissions; index as j"
      >
        <app-checkbox
          class="permission-sub-input"
          [checked]="subItem.isSelected"
          (click)="changeChildValue(subItem, item)"
        ></app-checkbox>

        <label class="permission-sub-label">
          <span
            class="details-text"
            [ngbPopover]="subItem?.name"
            triggers="hover"
            [placement]="
              getPlacementValue(j, i === items?.categoryPermissions.length - 1)
            "
            *ngIf="subItem?.name?.length > 13; else normalValue"
          >
            {{ subItem?.name }}
          </span>
          <ng-template #normalValue>
            <span class="details-text">
              {{ subItem?.name }}
            </span>
          </ng-template>
        </label>
      </div>
    </div>
  </div>
</div>
