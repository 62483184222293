import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductRegistrationsActivityCategoriesEnum } from 'src/app/admin-portal-configs/enums/product-registrations-activity-categories.enum';
import { RegistrationActivity } from 'src/app/admin-portal-configs/models/registration-activity.model';
import { RegistrationActivityService } from 'src/app/admin-portal-configs/services/registration-activity.service';
import { ProductRegistrationStatus } from 'src/app/shared/models/product-registration-status.model';
import { ProductRegistrationSystemStatus } from 'src/app/shared/models/product-registration-system-status.model';
import { ProgressPoint } from 'src/app/shared/models/progress-point.model';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { HelperService } from 'src/app/shared/services/helper.service';

@Component({
  selector: 'app-add-or-edit-registration-activity',
  templateUrl: './add-or-edit-registration-activity.component.html',
  styleUrls: ['./add-or-edit-registration-activity.component.scss']
})
export class AddOrEditRegistrationActivityComponent implements OnInit {
  form: FormGroup;
  isForAdd: boolean;
  isSaving: boolean;

  registrationActivity: RegistrationActivity;
  progressPoints: ProgressPoint[];
  systemStatuses: ProductRegistrationSystemStatus[];
  productRegistrationStatuses: ProductRegistrationStatus[];

  constructor(
    private registrationActivityService: RegistrationActivityService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private helperService: HelperService,
    private formsHelperService: FormsHelperService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.registrationActivity = data['registrationActivity'];
    });
    this.getFormLookups();
    this.initForm();
  }

  initForm() {
    this.isForAdd = !this.registrationActivity ? true : false;
    this.form = this.fb.group({
      name: [this.registrationActivity?.name, [Validators.required]],
      productRegistrationSystemStatusId: [
        this.registrationActivity?.productRegistrationSystemStatusId,
        [Validators.required]
      ],
      productRegistrationStatusId: [
        this.registrationActivity?.productRegistrationStatusId,
        [Validators.required]
      ],
      progressPointId: [this.registrationActivity?.progressPointId]
    });
  }

  getFormLookups() {
    this.getProductRegistrationProgressPoints();
    this.getProductRegistrationStatuses();
    this.getProductRegistrationSystemStatuses();
  }

  getProductRegistrationProgressPoints() {
    this.helperService
      ?.getProductRegistrationProgressPoints()
      .subscribe(data => {
        this.progressPoints = data;
      });
  }

  getProductRegistrationStatuses() {
    this.helperService?.getProductRegistrationStatuses().subscribe(data => {
      this.productRegistrationStatuses = data;
    });
  }

  getProductRegistrationSystemStatuses() {
    this.helperService
      ?.getProductRegistrationSystemStatuses()
      .subscribe(data => {
        this.systemStatuses = data;
      });
  }

  isInvalidFormControl(controlName: string): boolean {
    return this.formsHelperService.isInvalidFormControl(this.form, controlName);
  }
  save() {
    if (this.form?.invalid) {
      return;
    }
    let registrationActivity: RegistrationActivity = this.form?.value;
    this.isSaving = true;
    if (this.isForAdd) {
      this.addNewRequest(registrationActivity);
    } else {
      this.updateRequest(registrationActivity);
    }
  }

  addNewRequest(registrationActivity: RegistrationActivity) {
    this.registrationActivityService
      ?.addRegistrationActivity(registrationActivity)
      ?.subscribe(() => {
        this.router.navigate(['/settings/registration-activities']);
      });
  }

  updateRequest(registrationActivity: RegistrationActivity) {
    registrationActivity.id = this.registrationActivity?.id;
    registrationActivity.productRegistrationActivityCategoryName = this.registrationActivity?.productRegistrationActivityCategoryName;
    this.registrationActivityService
      ?.updateRegistrationActivity(registrationActivity)
      ?.subscribe(() => {
        this.router.navigate(['/settings/registration-activities']);
      });
  }

  cancel() {
    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/registration-activities']);
  }

  get diaplayedCategory(): string {
    let categoryName = this.registrationActivity
      ?.productRegistrationActivityCategoryName;

    if (!categoryName) {
      return null;
    }

    switch (categoryName) {
      case ProductRegistrationsActivityCategoriesEnum.SubmissionRequest:
        return 'submission of requests';
      case ProductRegistrationsActivityCategoriesEnum.AcceptanceNumberObtained:
        return 'status of requests with acceptance number obtained';
      case ProductRegistrationsActivityCategoriesEnum.RegistrationLicenseObtained:
        return 'status of requests with licence obtained';
      case ProductRegistrationsActivityCategoriesEnum.OnHold:
        return 'pausing of requests';
      case ProductRegistrationsActivityCategoriesEnum.Cancelled:
        return 'cancellation of requests';
      case ProductRegistrationsActivityCategoriesEnum.Resumed:
        return 'resumption of requests';
    }
  }
}
