import { Injectable } from '@angular/core';
import { TruckingPricelist } from '../models/trucking-pricelist.model';
import { Supplier } from 'src/app/company/models/supplier.model';
import { RouteDetails } from '../models/route-details.model';
import { ServiceTypes } from 'src/app/shared/models/shipment/service-types.enum';
import { TruckingLocation } from '../models/trucking-location.model';
import { OceanFreightOptionsEnum } from 'src/app/shared/models/enums/ocean-freight.enum';
import { TransportTypeEnum } from 'src/app/shared/models/enums/transport-type.enum';
import { StatusesEnum } from 'src/app/company/enums/statuses.enum';
import { CustomsClearancePriceList } from '../models/customs-clearance-priceList';

@Injectable({
  providedIn: 'root'
})
export class SupplierHelperService {
  constructor() {}

  getTruckingPricelistStatusName(truckingPricelist: TruckingPricelist): string {
    const currentDate = new Date();

    if (currentDate > truckingPricelist?.contractualTerms?.contractEndDate) {
      return 'Expired';
    } else {
      return truckingPricelist?.status;
    }
  }

  isActiveTruckingPricelist(truckingPricelist: TruckingPricelist): boolean {
    let status = this.getTruckingPricelistStatusName(truckingPricelist);
    return status == StatusesEnum.Active;
  }

  getSupplierId(supplier: Supplier): string {
    return supplier.id;
  }

  isTruckingRoutePortToDoor(routeDetails: RouteDetails): boolean {
    return routeDetails?.routeDirection == ServiceTypes.PortToDoor;
  }

  getTruckingRoutePickUpOrDeliverTo(
    routeDetails: RouteDetails,
    isPickup: boolean
  ): TruckingLocation {
    return isPickup ? routeDetails?.pickupFrom : routeDetails?.deliverTo;
  }

  getTruckingPricelistId(truckingPricelist: TruckingPricelist): string {
    return truckingPricelist?.id;
  }

  getTruckingRouteDirection(routeDetails: RouteDetails): string {
    return routeDetails?.routeDirection;
  }

  isLCLLoadType(selectedLoadType: string): boolean {
    return selectedLoadType == OceanFreightOptionsEnum.LCL;
  }

  isBreakbulkLoadType(selectedLoadType: string): boolean {
    return selectedLoadType == OceanFreightOptionsEnum.Breakbulk;
  }

  isFCLLoadType(selectedLoadType: string): boolean {
    return selectedLoadType == OceanFreightOptionsEnum.FCL;
  }

  isAirFreight(selectedFreightType: string): boolean {
    return selectedFreightType == TransportTypeEnum.Air;
  }

  isLandFreight(selectedFreightType: string): boolean {
    return selectedFreightType == TransportTypeEnum.Land;
  }

  isDomesticRoute(truckingPricelist: TruckingPricelist): boolean {
    return truckingPricelist?.routeDetails?.routeDirection == null;
  }

  isActiveCustomsClearancePricelist(
    customsClearancePricelist: CustomsClearancePriceList
  ): boolean {
    return customsClearancePricelist?.status == StatusesEnum.Active;
  }

  isDeletedStatus(supplier: Supplier): boolean {
    return supplier?.status.name == StatusesEnum.Deleted;
  }
}
