import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LandRoute } from '../models/land-route.model';
import { Observable } from 'rxjs';
import { Paginated } from 'src/app/shared/models/shipment/paginated.model';
import { PortDto } from '../models/portDto.model';
@Injectable({
  providedIn: 'root'
})
export class LandRouteService {
  baseURL = environment.apiUrl + 'landroute';

  constructor(private http: HttpClient) {}

  addLandRoute(route: LandRoute): Observable<LandRoute> {
    return this.http.post<LandRoute>(this.baseURL, route);
  }

  getLandRoutePaginated(
    searchTerm: string = '',
    pageIndex: number = 0,
    pageSize: number = 10
  ): Observable<Paginated> {
    return this.http.get<Paginated>(
      `${this.baseURL}/PaginatedLandRoute?searchTerm=${searchTerm}&pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
  }

  deleteLandRoute(routeId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseURL}/${routeId}`);
  }

  updateLandRoute(route: LandRoute): Observable<LandRoute> {
    return this.http.put<LandRoute>(`${this.baseURL}`, route);
  }

  getLandRoute(routeId: number): Observable<LandRoute> {
    return this.http.get<LandRoute>(`${this.baseURL}/${routeId}`);
  }

  getLandPortsBasedOnOtherCountryPort(
    countryId: number,
    portId: number
  ): Observable<PortDto[]> {
    return this.http.get<PortDto[]>(
      `${this.baseURL}/LandPortBasedOnOtherCountryPort?countryId=${countryId}&portId=${portId}`
    );
  }
  getLandPortsBasedOnCountryId(countryId: number): Observable<PortDto[]> {
    return this.http.get<PortDto[]>(
      `${this.baseURL}/LandPortBasedOnCountryId?countryId=${countryId}`
    );
  }
}
