import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Carrier } from '../models/carrier.model';
import { CarrierService } from '../../admin-portal-configs/services/carrier.service';

@Injectable({
  providedIn: 'root'
})
export class CarrierResolver implements Resolve<Carrier> {
  id: number;
  constructor(private carrierService: CarrierService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Carrier> {
    this.id = JSON.parse(route.params.id) as number;
    return this.carrierService.getById(this.id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
