import { Component, Input, OnInit } from '@angular/core';
import { ProductRegistrationHelperService } from 'src/app/create-product-registration/service/product-registration-helper.service';
import { ProductVariant } from 'src/app/create-product/models/product-variant.model';

@Component({
  selector: 'app-product-variants-list',
  templateUrl: './product-variants-list.component.html',
  styleUrls: ['./product-variants-list.component.scss']
})
export class ProductVariantsListComponent implements OnInit {
  @Input() variants: ProductVariant[];

  constructor(
    private productRegistrationHelperService: ProductRegistrationHelperService
  ) {}

  ngOnInit() {}

  getVariantPartNumber(variant: ProductVariant): string {
    return this.productRegistrationHelperService.getVariantPartNumber(variant);
  }

  getVariantDescription(variant: ProductVariant): string {
    return this.productRegistrationHelperService.getVariantDescription(variant);
  }

  getVariantCountryOfOrigin(variant: ProductVariant): string {
    return this.productRegistrationHelperService.getVariantCountryOfOrigin(
      variant
    );
  }

  getActualManufacturerName(variant: ProductVariant): string {
    return this.productRegistrationHelperService.getActualManufacturerName(
      variant
    );
  }
}
