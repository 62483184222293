import { Component, Input, OnInit } from '@angular/core';
import { ProductDetailsApproval } from '../../../product-library/models/product-details-approval';
import { ActivatedRoute, Router } from '@angular/router';
import { FileViewerService } from '../file-viewer/file-viewer.service';

@Component({
  selector: 'app-product-details-approvals-summary',
  templateUrl: './product-details-approvals-summary.component.html',
  styleUrls: ['./product-details-approvals-summary.component.scss']
})
export class ProductDetailsApprovalsSummaryComponent implements OnInit {
  @Input() productDetailsApprovelList: ProductDetailsApproval[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fileViewerService: FileViewerService
  ) {}

  ngOnInit() {}

  onApprovalNumberClick(approvalId: string) {
    this.router.navigate(['../../../approvals', approvalId, 'details'], {
      relativeTo: this.route
    });
  }

  isDocumentExpired(date: Date): boolean {
    if (!date) {
      return true;
    }
    const uploadDate = new Date(date);
    const currentDate = new Date();
    return uploadDate <= currentDate;
  }

  viewFile(path: string) {
    this.fileViewerService.view(path);
  }
}
