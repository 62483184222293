import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RegistrationActivityService } from '../services/registration-activity.service';
import { RegistrationActivityTransition } from '../models/registration-activity-transition.model';

@Injectable()
export class RegistrationActivityTransitionListResolver
  implements Resolve<RegistrationActivityTransition[]> {
  constructor(
    public registrationActivityService: RegistrationActivityService
  ) {}

  resolve(): Observable<RegistrationActivityTransition[]> {
    return this.registrationActivityService
      .getAllActivitiesWithTransitions()
      .pipe(
        catchError(error => {
          return of(null);
        })
      );
  }
}
