export enum EmployeeType {
  AccountManager = 'Account Manager',
  CustomerEmployee = 'Customer Employee',
  OperationsCoordinator = 'Operations Coordinator',
  Runner = 'Runner',
  FinanceSpecialist = 'Finance Specialist',
  OperationsManager = 'Operations Manager',
  OperationsSpecialist = 'Operations Specialist',
  RegulatoryAffairsAssociate = 'Regulatory Affairs Associate',
  RegulatoryAffairsSpecialist = 'Regulatory Affairs Specialist',
  Admin = 'Admin',
}
