import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoxWidthTriangleComponent } from './box-width-triangle/box-width-triangle.component';



@NgModule({
  declarations: [
    BoxWidthTriangleComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BoxWidthTriangleComponent
  ]
})
export class BoxWithTriangleModule { }
