import { Component, Input, OnInit } from '@angular/core';
import { MedicalComplianceInformationSummary } from 'src/app/create-product/enums/medical-compliance-information-summary';
import { NonMedicalComplianceInformationSummary } from 'src/app/create-product/enums/non-medical-compliance-information-summary';
import { ComplianceInformationSummary } from 'src/app/create-product/enums/compliance-information-summary';
import { ProductHelperService } from 'src/app/create-product/services/product-helper.service';
import { ProductBase } from '../../models/product-library/product-base.model';
import { ProductCategoryEnum } from 'src/app/create-product/enums/product-category.enum';

@Component({
  selector: 'app-product-compliance-details-summary',
  templateUrl: './product-compliance-details-summary.component.html',
  styleUrls: ['./product-compliance-details-summary.component.scss']
})
export class ProductComplianceDetailsSummaryComponent implements OnInit {
  @Input() product: ProductBase;
  complianceInformationSummary = ComplianceInformationSummary;
  medicalComplianceInformationSummary = MedicalComplianceInformationSummary;
  nonMedicalComplianceInformationSummary = NonMedicalComplianceInformationSummary;

  constructor(private productHelperService: ProductHelperService) {}

  ngOnInit() {}

  getEnumKeysForCompliance(): string[] {
    return this.isHealthCareOrPharmaceuticalsCategory
      ? Object.keys(MedicalComplianceInformationSummary)
      : Object.keys(NonMedicalComplianceInformationSummary);
  }

  getEnumValue(enumName, key: string): string {
    return enumName[key];
  }

  getComplianceInformationSummaryContent(
    columnName: ComplianceInformationSummary
  ): string {
    switch (columnName) {
      case ComplianceInformationSummary.CategoryClassification:
        return this.productHelperService?.getCategoryClassification(
          this.product
        );

      case ComplianceInformationSummary.Entity:
        return this.productHelperService?.getEntity(this.product);

      case ComplianceInformationSummary.Sterility:
        return this.productHelperService?.getSterility(this.product);

      case ComplianceInformationSummary.Regulations:
        return this.productHelperService?.getRegulations(this.product);

      case ComplianceInformationSummary.ClassificationType:
        return this.productHelperService?.getClassificationType(this.product);

      case ComplianceInformationSummary.CertificationType:
        return this.productHelperService?.getCertificationType(this.product);

      case ComplianceInformationSummary.RequiredRegistration:
        return this.productHelperService?.getRequiredRegistration(this.product);

      case ComplianceInformationSummary.Tax:
        return this.productHelperService?.getImportTax(this.product);

      case ComplianceInformationSummary.VAT:
        return this.productHelperService?.getVAT(this.product);

      case ComplianceInformationSummary.RequiredApprovals:
        return this.productHelperService?.getRequiredApprovals(this.product);

      case ComplianceInformationSummary.RequiredInspections:
        return this.productHelperService?.getRequiredInspections(this.product);

      default:
        return '';
    }
  }

  get isHealthCareOrPharmaceuticalsCategory(): boolean {
    return (
      this.productCategory === ProductCategoryEnum.Healthcare ||
      this.productCategory === ProductCategoryEnum.Pharmaceuticals
    );
  }

  get productCategory(): string {
    return this.productHelperService.getProductCategory(this.product);
  }
}
