import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-badge',
  templateUrl: './error-badge.component.html',
  styleUrls: ['./error-badge.component.scss']
})
export class ErrorBadgeComponent implements OnInit {
  @Input() message: string;
  @Input() thinBar=false;
  @Input() hasBackGround=true
  constructor() { }

  ngOnInit() {
  }

}
