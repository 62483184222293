import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HelperService } from '../services/helper.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ChargeTypeLookups } from '../../admin-portal-configs/models/charge-type-lookups.model';

@Injectable({
  providedIn: 'root'
})
export class ChargeTypeLookupsResolver implements Resolve<ChargeTypeLookups> {
constructor(private helperService: HelperService) { }
  resolve(): Observable<ChargeTypeLookups> {
    return this.helperService.getChargeTypeLookups().pipe(
    catchError(error => {
      return of(null);
    })
  );
}
}
