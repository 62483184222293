import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noValueReplace'
})
export class NoValueReplacePipe implements PipeTransform {
  transform(value: string, args: string): string {
    if (!value) {
      return args;
    }
    return value;
  }
}
