<div
  [ngClass]="{ error: hasError, selected: isSelected }"
  [class]="isContainers ? 'containers-card-stylings' : 'card-container'"
  name="btn_submit"
  (click)="submit()"
>
  <div class="selected-icon d-none">
    <svg-icon class="check" name="white-check"></svg-icon>
  </div>
  <span>
    <input class="d-none" type="checkbox" />
  </span>
  <div class="mx-0 p-4 row">
    <div
      class="text-container"
      [class]="isContainers ? 'col-lg-6 col-sm-12 px-1' : 'col-lg-9 col-sm-12'"
    >
      <div class="title">{{ title }}</div>
      <div class="description">
        {{ text }}
      </div>
    </div>

    <div
      [class]="
        isContainers
          ? 'col-lg-6 col-sm-12 custom-container-icon-height'
          : 'col-lg-3 col-sm-6'
      "
    >
      <svg-icon [name]="icon"></svg-icon>
    </div>
  </div>
</div>
