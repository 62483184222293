import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Currency } from 'src/app/shared/models/currency.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  @Output() currenciesUpdated = new EventEmitter<Currency>();
  @Output() currenciesAdded = new EventEmitter<Currency>();

  baseURL = environment.apiUrl + 'Currency';
  constructor(private http: HttpClient) {}

  getAllCurrency(): Observable<any> {
    return this.http.get<Currency[]>(this.baseURL);
  }

  addNewCurrency(currency: Currency): Observable<Currency> {
    return this.http.post<Currency>(this.baseURL, currency);
  }

  deleteCurrency(id: string): Observable<Currency> {
    return this.http.delete<Currency>(this.baseURL + '/' + id);
  }

  updateCurrency(currency: Currency): Observable<Currency> {
    return this.http.put<Currency>(
      this.baseURL + '/' + currency['id'],
      currency
    );
  }

  convertCurrency(currencyCode: string): Observable<number> {
    return this.http.get<number>(
      this.baseURL + `/currency-conversion/${currencyCode}`
    );
  }
}
