<div
  class="row card-details"
  [ngClass]="{
    quotesListPage: isQuotesListPage,
    'dashboard-card-details': isInDashboard
  }"
>
  <div class="checkbox-container" *ngIf="isQuotesListPage">
    <input
      type="checkbox"
      (click)="selectOrDeselectQuote($event)"
      [checked]="isQuoteInList()"
      class="checkbox"
    />
  </div>
  <div class="emptyIcon" *ngIf="servicesIcons.length === 0"></div>
  <div
    class=" service-icons"
    *ngIf="servicesIcons.length !== 0"
    [ngClass]="{
      one: servicesIcons.length === 1,
      three: servicesIcons.length === 3,
      five: servicesIcons.length === 5
    }"
  >
    <svg-icon
      *ngFor="let icon of servicesIcons; let i = index"
      class="icon"
      [name]="icon"
    >
    </svg-icon>
  </div>

  <div class="quote-details col-6">
    <div class="request-details">
      <p class="request-details-value">
        Quote No. {{ quote?.quoteNumberSequence }}
      </p>
      <span class="dot" *ngIf="!isInDashboard"> &nbsp; . &nbsp; </span>
      <p class="request-details-value" *ngIf="!isInDashboard">
        Requested On {{ quote?.submittedDatetime | date: "dd/MM/YYYY" }}
      </p>
      <span class="dot" *ngIf="!isInDashboard"> &nbsp; . &nbsp; </span>
      <p class="request-details-value" *ngIf="!isInDashboard">
        Requested by {{ quote?.createdBy?.fullName }}
      </p>
    </div>
    <div class="origin-destination">
      <p class="request-details-value">
        {{ quote?.routingDetails?.pickupLocationDetails?.city }}
      </p>
      <div class="circles">
        <p class="small-circle-light"></p>
        <p class="circle-light"></p>
        <p class="circle"></p>
        <p class="circle-light"></p>
        <p class="small-circle-light"></p>
      </div>

      <p class="request-details-value">
        {{ quote?.routingDetails?.deliveryLocationDetails?.city }}
      </p>
    </div>
  </div>

  <div class="row quote-messages">
    <div
      class="vertical-line"
      [ngStyle]="{ backgroundColor: quote?.status?.colorCode }"
    ></div>
    <div class="values-container">
      <div
        class="total-value"
        *ngIf="
          (isPendingApprovalStatus || isRejected || isApproved) &&
          !isInDashboard
        "
      >
        <div
          *ngIf="
            quoteTotalValue?.length > 0 &&
            (!hasMultipleFreightOrCustomcClearanceRoutes ||
              (hasMultipleFreightOrCustomcClearanceRoutes &&
                isFreightRouteSelected))
          "
        >
          <div *ngFor="let item of quoteTotalValue; let i = index">
            <span class="text-bold">
              {{ item?.amount | number: "1.2-2" }}
            </span>
            <span class="currency">{{ item?.currencyCode }} </span>
          </div>
        </div>
        <div
          *ngIf="
            hasMultipleFreightOrCustomcClearanceRoutes &&
            !isFreightRouteSelected
          "
        >
          <span class="text-bold">Depends on selection(s) </span>
        </div>
      </div>
      <div
        class="message"
        *ngIf="!isPendingApprovalStatus && !isRejected && !isApproved"
      >
        <span>{{ statusMessage }}</span>
      </div>

      <div class="message">
        <span
          *ngIf="
            (isPendingApprovalStatus || isRejected || isApproved) &&
            !isInDashboard
          "
        >
          Issued on
          {{ submitationDate | date: "dd/MM/yyyy" }}
        </span>
        <span *ngIf="isRejected">
          Rejected on
          {{ rejectedDate | date: "dd/MM/yyyy" }}
        </span>
        <span *ngIf="isApproved">
          Approved on
          {{ approvedDate | date: "dd/MM/yyyy" }}
        </span>
        <span
          *ngIf="isPendingApprovalStatus"
          class="expire-span"
          [ngClass]="{ 'red-text': islessThanOneDay }"
        >
          {{ expirationDateFormat }}
        </span>
      </div>
    </div>
  </div>

  <div class="blue-arrow-icon col-1" *ngIf="isQuotesListPage && !isInDashboard">
    <svg-icon name="blueArrowIcon"></svg-icon>
  </div>
</div>
