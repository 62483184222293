import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardTwoComponent } from './card-two/card-two.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SvgIconsModule } from 'projects/svg-icons/src/lib/svg-icons.module';

@NgModule({
  declarations: [CardTwoComponent],
  imports: [CommonModule, ReactiveFormsModule, SvgIconsModule],
  exports: [CardTwoComponent]
})
export class CardTwoModule {}
