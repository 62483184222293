<!-- Add a search input -->
<div class="search-input-container">
  <input
    type="text"
    class="search-input"
    placeholder="Search"
    [(ngModel)]="searchText"
  />
  <svg-icon name="searchIcon" class="search-icon"></svg-icon>
</div>

<div *ngFor="let item of filteredStatusList; let i = index" class="radio-item">
  <mat-radio-group [(ngModel)]="selectedStatus" (change)="statusSelected($event)">
    <mat-radio-button [value]="item" class="radio-button status">{{
      item
    }}</mat-radio-button>
  </mat-radio-group>
</div>
