import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityCategory } from 'src/app/shared/models/shipment/activity-category.model';
import { Activity } from 'src/app/shared/models/shipment/activity.model';
import { Progress } from 'src/app/shared/models/shipment/progress.model';
import { Status } from 'src/app/shared/models/shipment/status.model';
import { environment } from 'src/environments/environment';
import { ActivityTransition } from '../models/activity-transition.model';
import { SelectedServices } from '../models/selected-services.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  baseURL = environment.apiUrl + 'activity';
  transitionsURL = this.baseURL + '/transitions';
  activityURL = this.baseURL + '/activities';
  helperURL = this.baseURL + '/helpers';

  constructor(private http: HttpClient) {}

  getAll(): Observable<ActivityTransition[]> {
    return this.http.get<ActivityTransition[]>(this.activityURL);
  }

  getAllWithTransitions(): Observable<ActivityTransition[]> {
    return this.http.get<ActivityTransition[]>(this.transitionsURL);
  }

  getWithTransitionById(id: number): Observable<ActivityTransition> {
    return this.http.get<ActivityTransition>(this.transitionsURL + '/' + id);
  }

  addNewTransition(
    currentId: number,
    nextIds: number[]
  ): Observable<ActivityTransition> {
    return this.http.post<ActivityTransition>(
      `${this.transitionsURL}/${currentId}`,
      nextIds
    );
  }

  updateTransition(
    currentId: number,
    nextIds: number[]
  ): Observable<ActivityTransition> {
    return this.http.put<ActivityTransition>(
      `${this.transitionsURL}/${currentId}`,
      nextIds
    );
  }

  deleteTransition(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.transitionsURL + '/' + id);
  }

  updateActivity(id: number, activity: Activity): Observable<Activity> {
    return this.http.put<Activity>(`${this.activityURL}/${id}`, activity);
  }

  deleteActivity(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.activityURL + '/' + id);
  }

  getById(id: number): Observable<Activity> {
    return this.http.get<Activity>(this.activityURL + '/' + id);
  }

  addNew(activity: Activity): Observable<Activity> {
    return this.http.post<Activity>(this.activityURL, activity);
  }

  getStatuses(): Observable<Status[]> {
    return this.http.get<Status[]>(this.helperURL + '/statuses');
  }

  getActivityCategories(): Observable<ActivityCategory[]> {
    return this.http.get<ActivityCategory[]>(this.baseURL + '/Category');
  }

  getProgresses(): Observable<Progress[]> {
    return this.http.get<Progress[]>(this.helperURL + '/progresses');
  }

  getForm4Activities(): Observable<Activity[]> {
    return this.http.get<Activity[]>(this.baseURL + '/Form4Activities');
  }

  getFreePlServices(serviceNames:string[]): Observable<SelectedServices[]>
  {
    return this.http.post<SelectedServices[]>(this.baseURL + '/freePlService',serviceNames);
  }
}
