import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  Injectable,
  Input,
  HostListener,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent)
    }
  ]
})
export class InputComponent implements ControlValueAccessor, OnInit {
  @Input() classList!: string;
  @Input() isGreyBorder: boolean = false;
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() disabled = false;
  @Input() digitsOnly = false;
  @Input() mask = null;
  @Input() dropSpecialCharacters = true;
  @Input() maxLength: number;
  @Input() min: number = 0;
  @Input() readonly: boolean;
  @Input() value: any = '';

  @HostListener('focusout', ['$event']) onFocus(event: Event): void {
    this.onTouched();
  }

  constructor(private readonly cdr: ChangeDetectorRef) {}
  ngOnInit(): void {
    if (this.isGreyBorder == true) {
      this.classList += ' grey';
    }
  }

  onChange = (value: any) => {};

  onTouched = () => {};

  writeValue(obj: any): void {
    this.value = obj;
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
