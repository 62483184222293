<div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <span *ngIf="!profilePictureUrl" class="profile-picture no-picture">
      <img src="assets/images/person.png" />
    </span>
    <span *ngIf="profilePictureUrl" class="profile-picture">
      <img [src]="profilePictureUrl" />
    </span>

    <button
      [disabled]="isSaving"
      (click)="onUploadClick()"
      class="primary-button"
    >
      <ng-template #cameraIcon>
        <div class="camera-icon">
          <svg-icon name="camera"></svg-icon>
        </div>
      </ng-template>
      <i *ngIf="isSaving; else cameraIcon" class="fa fa-spinner fa-spin"></i>
    </button>

    <input
      id="upload-input"
      type="file"
      (change)="onChange($event)"
      class="hidden"
    />
  </div>
</div>
