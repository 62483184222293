<div>
  <h4>{{ pageTitle }}</h4>
  <hr class="separator-hr" />

  <div class="row control-box">
    <div class="col-lg-8">
      <div class="form-group search-box">
        <app-search-box
          (searchChange)="onSearchChanged($event)"
          placeholder="{{ searchPlaceHolder | translate }}"
        ></app-search-box>
      </div>
    </div>
    <div class="col-lg-4" *ngIf="canCreateFreePLCustomers">
      <ul class="nav flex-column flex-lg-row justify-content-end">
        <li class="nav-item toolbaritem">
          <a
            *ngIf="parentCompanyId; else addNewServiceProviderButton"
            class="nav-link"
            [routerLink]="['/company/add/', parentCompanyId]"
          >
            <i class="fa fa-plus"></i> {{ "company.NewCustomer" | translate }}
          </a>
          <ng-template #addNewServiceProviderButton>
            <a
              hidden
              class="nav-link"
              [routerLink]="['/company/add-service-provider']"
            >
              <i class="fa fa-plus"></i> {{ "company.New" | translate }}
            </a>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <ngx-datatable
        class="material colored-header sm table table-striped table-hover"
        [loadingIndicator]="loadingIndicator"
        [rows]="rows"
        [rowHeight]="40"
        [headerHeight]="35"
        [footerHeight]="35"
        [columns]="columns"
        [scrollbarV]="true"
        [columnMode]="'force'"
      >
      </ngx-datatable>

      <ng-template #companyNameTemplate let-row="row" let-value="value">
        <a
          [routerLink]="
            isCustomersProfilePage
              ? ['/customer', row.id, 'details']
              : ['/company', row.id]
          "
          >{{ row.name }}</a
        >
      </ng-template>

      <ng-template #boolTemplate let-row="row" let-value="value" let-i="index">
        <span
          *ngIf="value; else falseLabel"
          class="user-role badge badge-pill badge-success"
        >
          {{ "forms.Yes" | translate }}
          <i class="fa fa-check" aria-hidden="true"></i>
        </span>

        <ng-template #falseLabel>
          <span class="user-role badge badge-pill badge-danger">
            {{ "forms.No" | translate }}
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </ng-template>
      </ng-template>

      <ng-template
        #actionsTemplate
        let-row="row"
        let-value="value"
        let-i="index"
      >
        <div>
          <a
            class="btn btn-link btn-sm"
            [routerLink]="['/company/edit/', row.id]"
            *ngIf="canEditFreePLCustomers"
            ><i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            {{ "company.Edit" | translate }}</a
          >{{ canDeleteCustomers && canEditFreePLCustomers ? "|" : "" }}
          <button
            (click)="onclickChangeCompanyStatus(row.id, true)"
            *ngIf="
              !row.isActive && canDeleteCustomers && canEditFreePLCustomers
            "
            style="font-size: 1rem"
            title="{{ 'company.Activate' | translate }}"
            class="btn btn-sm btn-success ml-1 mr-1"
          >
            {{ "company.Activate" | translate }}
            <i class="fa fa-unlock"></i>
          </button>
          <button
            (click)="onclickChangeCompanyStatus(row.id, false)"
            *ngIf="row.isActive && canDeleteCustomers && canEditFreePLCustomers"
            style="font-size: 1rem"
            title="{{ 'company.Deactivate' | translate }}"
            class="btn btn-sm btn-danger ml-1 mr-1"
          >
            {{ "company.Deactivate" | translate }}
            <i class="fa fa-lock"></i>
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
