import { NgModule, Injectable } from '@angular/core';
import {
  Routes,
  RouterModule,
  DefaultUrlSerializer,
  UrlSerializer,
  UrlTree
} from '@angular/router';

import { LoginComponent } from './employee-profile/components/login/login.component';
import { NotFoundComponent } from './shared/pages/not-found/not-found.component';
import { AuthService } from './shared/services/auth.service';
import { AuthGuard } from './shared/services/auth-guard.service';
import { Utilities } from './shared/services/utilities';
import { RegisterComponent } from './employee-profile/components/account/register/register.component';
import { ConfirmEmailComponent } from './employee-profile/components/account/confirm-email/confirm-email.component';
import { ResetPasswordComponent } from './employee-profile/components/account/reset-password/reset-password.component';
import { RecoverPasswordComponent } from './employee-profile/components/account/recover-password/recover-password.component';
import { AdminAuthGuard } from './shared/services/admin-auth-guard.service';
import { PreventLeavingPageGuard } from './shared/resolvers/prevent-leaving-page.guard';
import { CompaniesListComponent } from './company/pages/companies-list/companies-list.component';
import { FreePLCompaniesListResolver } from './company/resolvers/freepl-companies-list.resolver';
import { SuppliersListComponent } from './company/pages/suppliers-list/suppliers-list.component';
import { AddOrEditSupplierComponent } from './company/pages/add-or-edit-supplier/add-or-edit-supplier.component';
import { SuppliersListResolver } from './supplier/resolvers/suppliers-list.resolver';
import { SupplierResolver } from './supplier/resolvers/supplier.resolver';

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const possibleSeparators = /[?;#]/;
    const indexOfSeparator = url.search(possibleSeparators);
    let processedUrl: string;

    if (indexOfSeparator > -1) {
      const separator = url.charAt(indexOfSeparator);
      const urlParts = Utilities.splitInTwo(url, separator);
      urlParts.firstPart = urlParts.firstPart.toLowerCase();

      processedUrl = urlParts.firstPart + separator + urlParts.secondPart;
    } else {
      processedUrl = url.toLowerCase();
    }

    return super.parse(processedUrl);
  }
}

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  {
    path: 'register',
    component: RegisterComponent,
    data: { title: 'Register' }
  },
  {
    path: 'confirmemail',
    component: ConfirmEmailComponent,
    data: { title: 'Confirm Email' }
  },
  {
    path: 'recoverpassword',
    component: RecoverPasswordComponent,
    data: { title: 'Recover Password' }
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent,
    data: { title: 'Reset Password' }
  },
  {
    path: 'bulk-import',
    loadChildren: () =>
      import('./bulk-import/bulk-import.module').then(m => m.BulkImportModule)
  },
  {
    path: 'my-profile/:id',
    loadChildren: () =>
      import('./employee-profile/employee-profile.module').then(
        m => m.EmployeeProfileModule
      )
  },

  {
    // Company Routes should be here
    path: '',
    children: [
      {
        path: '',
        canActivate: [AdminAuthGuard],
        loadChildren: () =>
          import('./company/company.module').then(m => m.CompanyModule)
      },
      {
        path: 'company/:companyId',
        canActivate: [AuthGuard],
        canDeactivate: [PreventLeavingPageGuard],
        loadChildren: () =>
          import('./createShipment/createShipment.module').then(
            m => m.CreateShipmentModule
          ),
        data: { title: 'createShipment' }
      },
      {
        path: 'company/:companyId/shipments',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./shipments/shipments.module').then(m => m.ShipmentsModule)
      },
      {
        path: 'form4requests',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./shipments/shipments.module').then(m => m.ShipmentsModule)
      },
      {
        path: 'shipments',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./shipments/shipments.module').then(m => m.ShipmentsModule)
      },
      {
        path: 'company/:companyId/dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'customer',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./customer/customer.module').then(m => m.CustomerModule)
      },
      {
        path: 'settings',
        canActivate: [AdminAuthGuard, AuthGuard],
        loadChildren: () =>
          import('./admin-portal-configs/admin-portal-configs.module').then(
            m => m.AdminPortalConfigsModule
          )
      },
      {
        path: 'customer-company',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./customer-company/customer-company.module').then(
            m => m.CustomerCompanyModule
          )
      },
      {
        path: 'customers',
        canActivate: [AdminAuthGuard],
        component: CompaniesListComponent,
        resolve: { companies: FreePLCompaniesListResolver }
      },
      {
        path: 'customers-profile',
        canActivate: [AdminAuthGuard],
        component: CompaniesListComponent,
        resolve: { companies: FreePLCompaniesListResolver }
      },
      {
        path: 'suppliers',
        canActivate: [AdminAuthGuard],
        component: SuppliersListComponent,
        resolve: { allSuppliers: SuppliersListResolver }
      },
      {
        path: 'suppliers/add',
        component: AddOrEditSupplierComponent
      },
      {
        path: 'suppliers/:id/edit',
        component: AddOrEditSupplierComponent,
        resolve: { supplier: SupplierResolver }
      }
    ]
  },
  {
    path: 'company-profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./company2/company2.module').then(m => m.Company2Module)
  },
  {
    path: 'company/:companyId/quotes',
    canActivate: [AuthGuard],
    loadChildren: () => import('./quote/quote.module').then(m => m.QuoteModule)
  },
  {
    path: 'quotes',
    canActivate: [AuthGuard],
    loadChildren: () => import('./quote/quote.module').then(m => m.QuoteModule)
  },
  {
    path: 'company/:companyId/quotes-v2',
    canActivate: [AuthGuard],
    loadChildren: () => import('./quote/quote.module').then(m => m.QuoteModule)
  },
  {
    path: 'company/:companyId/quotes-v2',
    canActivate: [AuthGuard],
    canDeactivate: [PreventLeavingPageGuard],
    loadChildren: () =>
      import('./create-quote/create-quote.module').then(
        m => m.CreateQuoteModule
      )
  },
  {
    path: 'company/:companyId/quotes',
    canActivate: [AuthGuard],
    canDeactivate: [PreventLeavingPageGuard],
    loadChildren: () =>
      import('./create-quote/create-quote.module').then(
        m => m.CreateQuoteModule
      )
  },
  {
    path: 'company/:companyId/approvals',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./approvals/approval.module').then(m => m.ApprovalModule)
  },
  {
    path: 'company/:companyId/approvals',
    canActivate: [AuthGuard],
    canDeactivate: [PreventLeavingPageGuard],
    loadChildren: () =>
      import('./create-approval/create-approval.module').then(
        m => m.CreateApprovalModule
      )
  },
  {
    path: 'company/:companyId/registrations',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./product-registration/product-registration.module').then(
        m => m.ProductRegistrationModule
      )
  },
  {
    path: 'company/:companyId/registrations',
    canActivate: [AuthGuard],
    canDeactivate: [PreventLeavingPageGuard],
    loadChildren: () =>
      import(
        './create-product-registration/create-product-registration.module'
      ).then(m => m.CreateProductRegistrationModule)
  },
  {
    path: 'company/:companyId/products-library',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./product-library/product-library.module').then(
        m => m.ProductLibraryModule
      )
  },
  {
    path: 'company/:companyId/products-library',
    canActivate: [AuthGuard],
    canDeactivate: [PreventLeavingPageGuard],
    loadChildren: () =>
      import('./create-product/create-product.module').then(
        m => m.CreateProductModule
      )
  },
  {
    path: 'suppliers',
    canActivate: [AdminAuthGuard],
    loadChildren: () =>
      import('./supplier/supplier.module').then(m => m.SupplierModule)
  },
  {
    path: 'company/:companyId/warehousing',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./warehousing/warehousing.module').then(m => m.WarehousingModule)
  },
  { path: 'home', redirectTo: '/', pathMatch: 'full' },
  {
    path: '**',
    component: NotFoundComponent,
    data: { title: 'Page Not Found' }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule],
  providers: [
    AuthService,
    AuthGuard,
    FreePLCompaniesListResolver,
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer }
  ]
})
export class AppRoutingModule {}
