<div class="summary-container row-margin">
  <div class="row justify-content-between">
    <div class="section-title">Documents</div>
    <div class="col-10 ">
      <div class="row">
        <div class="col-4 row" *ngFor="let document of productDocuments">
          <div class="title col-12">{{ document?.documentTypeName }}</div>
          <div
            class="doc-link col-12 truncated-text"
            (click)="viewFile(document?.path)"
            [ngbPopover]="infoTemplate"
            placement="bottom-left"
            [autoClose]="'outside'"
            triggers="mouseenter:mouseleave"
          >
            <span>{{ document.fileName }} </span>
            <ng-template #infoTemplate>
              <div class="file-name-template">
                {{ document.fileName }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
