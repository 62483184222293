import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormsHelperService {
  addRequiredValidator(formGroup: UntypedFormGroup, controlName: string) {
    formGroup?.get(controlName).setValidators(Validators.required);
    formGroup?.get(controlName).updateValueAndValidity();
  }

  clearValidator(formGroup: UntypedFormGroup, controlName: string) {
    formGroup?.get(controlName).clearValidators();
    formGroup?.get(controlName).updateValueAndValidity();
  }

  setValidators(
    controlName: string,
    formGroup: UntypedFormGroup,
    otherValidators
  ) {
    if (otherValidators) {
      formGroup.controls[controlName].setValidators([
        Validators.required,
        otherValidators
      ]);
    } else {
      formGroup.controls[controlName].setValidators(Validators.required);
    }
  }

  isInvalidFormControl(
    form: UntypedFormGroup,
    formControlName: string
  ): boolean {
    return form?.get(formControlName)?.touched &&
      form?.get(formControlName)?.errors
      ? true
      : false;
  }

  clearValidatorsAndValues(form: UntypedFormGroup) {
    Object.keys(form.controls).forEach(controlName => {
      form.get(controlName).clearValidators();
      form.get(controlName).setValue(null);
      form.get(controlName).updateValueAndValidity();
    });
  }

  addRequiredValidators(form: UntypedFormGroup) {
    Object.keys(form.controls).forEach(controlName => {
      const validators = form.get(controlName).validator
        ? [form.get(controlName).validator, Validators.required]
        : Validators.required;
      form.get(controlName).setValidators(validators);
      form.get(controlName).updateValueAndValidity();
    });
  }

  addMinValidators(
    form: UntypedFormGroup,
    minValue: number,
    excludeFields: string[]
  ) {
    Object.keys(form.controls).forEach(controlName => {
      if (excludeFields.includes(controlName)) {
        return;
      }
      const validators = form.get(controlName).validator
        ? [form.get(controlName).validator, Validators.min(minValue)]
        : Validators.min(minValue);
      form.get(controlName).setValidators(validators);
      form.get(controlName).updateValueAndValidity();
    });
  }
}
