<app-admin-page-details
  [title]="pageTitle"
  (submitButtonClicked)="saveORCancel($event)"
>
  <ng-container pageBody>
    <div class="page-container" [formGroup]="countryForm">
      <app-error-badge
        class="warning-badge"
        [message]="
          'Required fields are either left empty or filled incorrectly. Check all fields highlighted in red and try again.'
        "
        *ngIf="isInvalidForm"
      ></app-error-badge>
      <div class="row" [class]="{ 'invalid-form-padding': isInvalidForm }">
        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title"
              >{{ "config.management.Country.CountryName" | translate
              }}<span class="text-error">*</span>
            </label>
            <input
              type="text"
              class="form-control field-input"
              maxlength="60"
              name="input-countryName"
              formControlName="country"
              [ngClass]="{
                'is-invalid':
                  countryForm.get('country').errors &&
                  countryForm.get('country').touched
              }"
            />
          </div>
        </div>
        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title"
              >{{ "config.management.Country.CountryCode" | translate
              }}<span class="text-error">*</span>
            </label>
            <input
              type="text"
              class="form-control field-input"
              maxlength="3"
              name="input-countryCode"
              formControlName="code"
              [ngClass]="{
                'is-invalid':
                  countryForm.get('code').errors &&
                  countryForm.get('code').touched
              }"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title"
              >{{ "config.management.Country.Latitude" | translate
              }}<span class="text-error">*</span>
            </label>
            <input
              type="text"
              class="form-control field-input"
              maxlength="11"
              name="input-latitude"
              formControlName="lat"
              [ngClass]="{
                'is-invalid':
                  countryForm.get('lat').errors &&
                  countryForm.get('lat').touched
              }"
              (input)="validateLatLngInput($event)"
            />
          </div>
        </div>
        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title"
              >{{ "config.management.Country.Longitude" | translate
              }}<span class="text-error">*</span>
            </label>
            <input
              type="text"
              class="form-control field-input"
              maxlength="11"
              name="longitude"
              formControlName="lng"
              [ngClass]="{
                'is-invalid':
                  countryForm.get('lng').errors &&
                  countryForm.get('lng').touched
              }"
              (input)="validateLatLngInput($event)"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title radiobuttons-title"
              >{{ "config.management.Country.ReferenceCountry" | translate
              }}<span class="text-error">*</span>
            </label>
            <div class="reference-country-container row">
              <mat-radio-group formControlName="isReference">
                <mat-radio-button
                  name="radio-referenceCountry"
                  [ngClass]="{
                    'radio-button-error':
                      countryForm.get('isReference').errors &&
                      countryForm.get('isReference').touched
                  }"
                  class="radiobutton-field"
                  [value]="true"
                >
                  {{ "forms.Yes" | translate }}
                </mat-radio-button>
                <mat-radio-button
                  name="radio-referenceCountry"
                  [ngClass]="{
                    'radio-button-error':
                      countryForm.get('isReference').errors &&
                      countryForm.get('isReference').touched
                  }"
                  class="radiobutton-field"
                  [value]="false"
                >
                  {{ "forms.No" | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="field-container">
          <div class="input-field-container">
            <label class="field-title radiobuttons-title"
              >{{ "config.management.Country.LandFreightAccessible" | translate
              }}<span class="text-error">*</span>
            </label>
            <div class="land-freight-container row">
              <div>
                <mat-radio-group formControlName="isLandFreight">
                  <mat-radio-button
                    name="radio-landFreight"
                    [ngClass]="{
                      'radio-button-error':
                        countryForm.get('isLandFreight').errors &&
                        countryForm.get('isLandFreight').touched
                    }"
                    class="radiobutton-field"
                    [value]="true"
                  >
                    {{ "forms.Yes" | translate }}
                  </mat-radio-button>
                  <mat-radio-button
                    name="radio-landFreight"
                    [ngClass]="{
                      'radio-button-error':
                        countryForm.get('isLandFreight').errors &&
                        countryForm.get('isLandFreight').touched
                    }"
                    class="radiobutton-field"
                    [value]="false"
                  >
                    {{ "forms.No" | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-admin-page-details>
