<div class="date-header">
  <svg-icon
    class="navigate-icons"
    name="left-blue-arrow"
    (click)="previousClicked()"
  ></svg-icon>
  <span class="date-header-label">{{ periodLabel }}</span>
  <svg-icon
    class="navigate-icons"
    name="right-blue-arrow"
    (click)="nextClicked()"
  ></svg-icon>
</div>
