<div class="section" [formGroup]="routeDetailsForm">
  <div class="header">ROUTE DETAILS</div>
  <div class="row fields-padding" *ngIf="!isDomesticRoute">
    <div class="col-12 d-flex">
      <div class="field" *ngIf="isForCustomerCompany">
        <label class="title">
          Supplier Name<span class="required">*</span>
        </label>
        <ng-select
          [clearable]="false"
          [readonly]="true"
          formControlName="supplierName"
        >
        </ng-select>
      </div>
      <div class="field">
        <label class="title">
          Route Direction<span class="required">*</span>
        </label>
        <ng-select
          [items]="routeDirectionLookup"
          formControlName="routeDirection"
          [ngClass]="isInvalid('routeDirection') ? 'error' : ''"
          [clearable]="false"
          (change)="onChangeRouteDirection()"
          [readonly]="isForEditing || isForCustomerCompany"
        >
          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-search="searchTerm"
            let-value="item"
          >
            <div class="d-flex">
              <div class="circle"></div>
              {{ item }}
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex">
      <div class="field">
        <label class="title">
          Pickup From<span class="required">*</span>
        </label>
        <ng-select
          [typeahead]="
            isDoorToPort || isDomesticRoute ? districtsPickUpFrom$ : ports$
          "
          formControlName="pickupFrom"
          blindValue="id"
          [clearable]="false"
          [ngClass]="
            (!isForCustomerCompany && isInvalid('pickupFrom')) || isSameRoute
              ? 'error'
              : ''
          "
          [items]="
            (isDoorToPort || isDomesticRoute
              ? districtsObservablePickUpFrom
              : portsObservable
            ) | async
          "
          [readonly]="isForEditing || isForCustomerCompany"
        >
          <ng-template ng-label-tmp let-item="item">
            <div>
              <div>
                <div *ngIf="item && item.id !== ''">
                  <ng-container *ngIf="!item.id && item.cityName">
                    {{ item.name }} , {{ item.cityName }}
                  </ng-container>
                  <ng-container *ngIf="item?.id">
                    {{
                      item.id
                        | valuArr
                          : (isDoorToPort || isDomesticRoute
                              ? lastDistrictsPickUpFrom
                              : lastPorts)
                          : "id"
                          : "name"
                    }},
                    {{
                      item.id
                        | valuArr
                          : (isDoorToPort || isDomesticRoute
                              ? lastDistrictsPickUpFrom
                              : lastPorts)
                          : "id"
                          : "cityName"
                    }}
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-search="searchTerm"
          >
            <div class="d-flex">
              <div class="circle"></div>
              {{ item.name }} , {{ item.cityName }}
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="field">
        <label class="title"> Deliver To<span class="required">*</span> </label>
        <ng-select
          [typeahead]="
            !isDoorToPort || isDomesticRoute ? districtsDeliverTo$ : ports$
          "
          formControlName="deliverTo"
          blindValue="id"
          [clearable]="false"
          [ngClass]="
            (!isForCustomerCompany && isInvalid('deliverTo')) || isSameRoute
              ? 'error'
              : ''
          "
          [items]="
            (!isDoorToPort || isDomesticRoute
              ? districtsObservableDeliverTo
              : portsObservable
            ) | async
          "
          [readonly]="isForEditing || isForCustomerCompany"
        >
          <ng-template ng-label-tmp let-item="item">
            <div>
              <div>
                <div *ngIf="item && item.id !== ''">
                  <ng-container *ngIf="!item.id && item.cityName">
                    {{ item.name }}, {{ item.cityName }}
                  </ng-container>
                  <ng-container *ngIf="item?.id">
                    {{
                      item.id
                        | valuArr
                          : (!isDoorToPort || isDomesticRoute
                              ? lastDistrictsDeliverTo
                              : lastPorts)
                          : "id"
                          : "name"
                    }},
                    {{
                      item.id
                        | valuArr
                          : (!isDoorToPort || isDomesticRoute
                              ? lastDistrictsDeliverTo
                              : lastPorts)
                          : "id"
                          : "cityName"
                    }}
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-search="searchTerm"
          >
            <div class="d-flex">
              <div class="circle"></div>
              {{ item.name }} , {{ item.cityName }}
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
</div>
