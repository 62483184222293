<div class="table-container">
  <table class="table-content">
    <tr>
      <th class="column-title px-3 border-radius-header-left"><div></div></th>
      <th
        *ngFor="let column of columns; let i = index"
        class="column-title px-3"
        [ngClass]="{
          'border-radius-header-right': i === columns.length - 1
        }"
      >
        <div>{{ column }}</div>
      </th>
    </tr>

    <tbody>
      <tr
        *ngFor="let row of rows; let i = index"
        class="cursor-pointer item-row-container"
        [routerLink]="row?.id + '/details'"
      >
        <td
          class="column-content px-3 position-relative check-box-column border-radius-body-left product-row border-right-0"
        >
          <input
            type="checkbox"
            class="product-checkbox"
            (click)="
              selectOrDeselect($event, row?.freePLApprovalNumberString)
            "
            [checked]="isInList(row?.freePLApprovalNumberString)"
          />
        </td>
        <td
          *ngFor="let column of columns; let i = index"
          class="column-content px-3 position-relative product-row border-left-0"
          [ngClass]="{
            'border-radius-body-right': i === columns.length - 1,
            'border-right-0': i != columns.length - 1
          }"
        >
          <ng-template #dateFormat>
            {{ getContent(column, row) | date: "dd/MM/YYYY" }}
          </ng-template>
          <div
            *ngIf="!isDate(getContent(column, row)); else dateFormat"
            class="column-value cursor-pointer"
            [placement]="i >= rows?.length - 2 ? 'top' : 'bottom'"
            [ngbPopover]="cellContentTemplate"
            triggers="mouseenter:mouseleave"
            [disablePopover]="
              getContent(column, row)?.length <= maxLengthBeforeTruncation
            "
            [ngStyle]="{
              color: isStatusField(column) ? getStatusColor(row) : ''
            }"
          >
            {{
              getContent(column, row) || "-"
                | slice: 0:maxLengthBeforeTruncation
            }}
            {{
              getContent(column, row)?.length > maxLengthBeforeTruncation
                ? ".."
                : ""
            }}
          </div>

          <ng-template #cellContentTemplate>
            <div class="row pop-over">
              <div class="col-4 px-0 pop-over-label">
                {{ getContent(column, row) }}
              </div>
            </div>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
