import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ProductRegistrationLeadTime } from '../models/product-registration-lead-time.model';
import { ProductRegistrationKpisService } from '../services/product-registration-kpis.service';

@Injectable({
  providedIn: 'root'
})
export class ProductRegistrationKpiResolver
  implements Resolve<ProductRegistrationLeadTime> {
  constructor(public kpiService: ProductRegistrationKpisService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<ProductRegistrationLeadTime> {
    let id = JSON.parse(route.params.id) as number;
    return this.kpiService.getById(id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
