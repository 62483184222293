<div class="recommended-img" *ngIf="isSelected">
  <svg-icon [name]="'recommended-flag'"></svg-icon>
  <span>Recommended </span>
</div>
<div
  [classList]="'d-flex position-relative radio-button-card ' + classList"
  [ngClass]="{ disable: disable, error: hasError }"
  [id]="isFreightReference === true ? 'freight-reference-wrapper' : 'wrapper'"
  (click)="submit()"
  name="btn_submit"
>
  <!--  -->
  <input
    class="d-none"
    type="radio"
    [formControl]="control"
    [name]="'radio_' + control"
  />
  <div
    class="container pt-4 pb-3"
    [ngClass]="{ 'margin-container': isSelected }"
  >
    <!-- Name and icon -->
    <div
      class="align-items-center justify-content-between my-1 pr-3 w-100"
      [class]="isServiceSelectionPage != true ? 'd-flex' : ''"
    >
      <div
        class="d-flex flex-column"
        [class]="
          isServiceSelectionPage === true
            ? 'col-9 float-right service-selection-font'
            : ''
        "
      >
        <span class="title">{{ title }}</span>
        <p
          class="description"
          [class]="limitParagraphWidth ? 'limited-width' : ''"
        >
          {{ description
          }}<strong [style]="{ color: descriptionBoldColor }">{{
            descriptionBold
          }}</strong>
        </p>
      </div>
      <div *ngIf="svgIconName"><svg-icon [name]="svgIconName"></svg-icon></div>
      <div
        *ngIf="iconPath"
        class="col-2"
        [ngClass]="[
          isServiceSelectionPage === true ? 'col-3' : '',
          hasThreeFreights ? 'col-2 mt-3' : ''
        ]"
      >
        <img [src]="iconPath" />
      </div>
    </div>
    <!--  -->

    <!-- More details -->
    <div class="d-flex flex-column" *ngIf="moreDetails">
      <ng-content></ng-content>

      <!--  -->
    </div>
  </div>
  <!-- Radio input circle -->
  <div class="circlCon">
    <span class="circle"></span>
  </div>
</div>
