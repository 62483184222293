import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SortFilter } from 'src/app/shared/models/shipment/sort-filter.enum';
import { ShipmentsService } from '../../../shipments/shipments.service';
import { ProductRegistrationService } from 'src/app/product-registration/services/product-registration.service';
import { QuoteService } from 'src/app/quote/services/quote.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-sort-filter',
  templateUrl: './sort-filter.component.html',
  styleUrls: ['./sort-filter.component.scss']
})
export class SortFilterComponent implements OnInit {
  searchCriteria: any;
  sortFilters: SortFilter[] = [];
  componentType: string;
  columnsForm: UntypedFormGroup;

  constructor(
    private shipmentsService: ShipmentsService,
    private productRegistrationService: ProductRegistrationService,
    private quoteService: QuoteService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.intializeForm();
  }

  intializeForm() {
    if (this.data['searchCriteria']) {
      this.searchCriteria = this.data['searchCriteria'];
    }
    if (this.data['sortFilters']) {
      this.sortFilters = this.data['sortFilters'];
    }
    if (this.data['componentType']) {
      this.componentType = this.data['componentType'];
    }

    switch (this.componentType) {
      case 'shipment': {
        if (!this.searchCriteria?.shipmentsSortBy) {
          this.searchCriteria.shipmentsSortBy = SortFilter.DateCreatedNewest;
        }
        this.columnsForm = this.fb.group({
          sortFilterColumn: [
            this.searchCriteria?.shipmentsSortBy,
            [Validators.required]
          ]
        });
        break;
      }
      case 'product': {
        if (!this.searchCriteria?.productsSortBy) {
          this.searchCriteria.productsSortBy = SortFilter.DateCreatedNewest;
        }
        this.columnsForm = this.fb.group({
          sortFilterColumn: [
            this.searchCriteria?.productsSortBy,
            [Validators.required]
          ]
        });
        break;
      }
      case 'quote': {
        if (!this.searchCriteria?.sortBy) {
          this.searchCriteria.sortBy = SortFilter.DateCreatedNewest;
        }
        this.columnsForm = this.fb.group({
          sortFilterColumn: [this.searchCriteria?.sortBy, [Validators.required]]
        });
        break;
      }
      default:
        break;
    }
  }

  save() {
    if (this.columnsForm.valid) {
      switch (this.componentType) {
        case 'shipment': {
          this.searchCriteria.shipmentsSortBy = this.columnsForm?.controls[
            'sortFilterColumn'
          ]?.value;
          this.shipmentsService.onSearchCriteriaChange.emit(
            this.searchCriteria
          );
          break;
        }

        case 'product': {
          this.searchCriteria.productsSortBy = this.columnsForm?.controls[
            'sortFilterColumn'
          ]?.value;
          this.productRegistrationService.productRegistrationSearch.emit(
            this.searchCriteria
          );
          break;
        }

        case 'quote': {
          this.searchCriteria.sortBy = this.columnsForm?.controls[
            'sortFilterColumn'
          ]?.value;
          this.quoteService.quoteSearch.emit(this.searchCriteria);
          break;
        }
      }
    }
    this.cancel();
  }

  cancel() {
    this.dialogService.close();
  }
}
