import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ApprovalLeadTime } from '../../../models/approval-lead-time.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ApprovalActivity } from '../../../models/approval-activity.model';
import { ApprovalActivityService } from '../../../services/approval-activity.service';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { FormsHelperService } from '../../../../shared/services/forms-helper.service';
import { ApprovalKpiService } from '../../../services/approval-kpi.service';

@Component({
  selector: 'app-add-or-edit-approval-kpis',
  templateUrl: './add-or-edit-approval-kpis.component.html',
  styleUrls: ['./add-or-edit-approval-kpis.component.scss']
})
export class AddOrEditApprovalKpisComponent implements OnInit {
  approvalLeadTimeForm: UntypedFormGroup;
  approvalLeadTimeModel: ApprovalLeadTime;
  isNewApprovalLeadTime: boolean = false;
  isDuplicateApprovalLeadTime: boolean = false;
  activities: ApprovalActivity[];
  endTriggers: ApprovalActivity[] = [];
  isSaving: boolean = false;
  activityForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private activityService: ApprovalActivityService,
    private router: Router,
    private alertService: AlertService,
    private formsHelperService: FormsHelperService,
    private approvalHttpService: ApprovalKpiService,
  ) {}





  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.approvalLeadTimeModel = data['approvalLeadTime'];
    });
    this.loadApprovalActivities();
    this.initForm();
  }


  initForm() {
    this.isNewApprovalLeadTime = !this.approvalLeadTimeModel ? true : false;

    this.approvalLeadTimeModel =
      this.approvalLeadTimeModel || ({} as ApprovalLeadTime);

    this.approvalLeadTimeForm = this.fb.group({
      name: [this.approvalLeadTimeModel?.name, [Validators.required]],
      leadTime: [
        this.approvalLeadTimeModel?.leadTime,
        [Validators.required, Validators.min(1)]
      ],
      startTriggerId: [
        this.approvalLeadTimeModel?.startTriggerId,
        [Validators.required]
      ],
      endTriggerId: [
        this.approvalLeadTimeModel?.endTriggerId,
        [Validators.required]
      ]
    });

  }


  loadApprovalActivities() {
    this.activityService.getAll().subscribe(res => {
      this.activities = res;
      this.onChangingStartTrigger();
    });
  }


  onChangingStartTrigger() {
    let startTriggerId = this.approvalLeadTimeForm.get('startTriggerId')?.value;
    this.endTriggers = this.activities.filter(a => a.id != startTriggerId);
  }


  isInvalidFormControl(controlName: string): boolean {
    return this.formsHelperService.isInvalidFormControl(this.approvalLeadTimeForm, controlName);
  }


  save() {
    const values = this.approvalLeadTimeForm.value;
    if (!this.approvalLeadTimeForm.valid) {
      this.alertService.error('Invalid Form!');
      return;
    }

    this.isSaving = true;
    if (this.isNewApprovalLeadTime) {
      this.addNewApprovalLeadTime(values);
    }
    else {
      this.updateApprovalLeadTime(values);
    }
  }


  addNewApprovalLeadTime(formVal: ApprovalLeadTime) {
    this.approvalHttpService.addApprovalLeadTime(formVal).subscribe(
      data => {
        this.saveSuccessHelper(data.name);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }


  updateApprovalLeadTime(formVal: ApprovalLeadTime) {
    this.approvalHttpService.updateApprovalLeadTime(this.approvalLeadTimeModel.id, formVal).subscribe(
      data => {
        this.saveSuccessHelper(data.name);
        },
        error => {
          this.saveFailedHelper(error);
        }
      );
  }


  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occured whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }


  private saveSuccessHelper(valueName: string) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    if (this.isNewApprovalLeadTime) {
      this.alertService.showMessage(
        'Success',
        `Approval lead time \"${valueName}\" was created successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to Approval lead time \"${valueName}\" were saved successfully`,
        MessageSeverity.success
      );
    }
    this.router.navigate(['/settings/approval-kpis']);
  }


  cancel() {
    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );
    this.router.navigate(['/settings/approval-kpis']);
  }


}
