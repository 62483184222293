import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivityTransition } from '../../admin-portal-configs/models/activity-transition.model';
import { ActivityService } from '../../admin-portal-configs/services/activity.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityTransitionListResolver
  implements Resolve<ActivityTransition[]> {
  constructor(
    private router: Router,
    private activityService: ActivityService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ActivityTransition[]> {
    return this.activityService.getAllWithTransitions().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
