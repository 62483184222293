import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApprovalActivity } from '../models/approval-activity.model';
import { ApprovalActivityService } from '../services/approval-activity.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApprovalActivityResolver implements Resolve<ApprovalActivity> {
  constructor(private approvalActivityService: ApprovalActivityService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ApprovalActivity> {
    let id = JSON.parse(route.params.id) as number;
    return this.approvalActivityService.getById(id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
