import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApprovalActivityTransition } from 'src/app/admin-portal-configs/models/approval-activity-transition.model';
import { ApprovalActivity } from 'src/app/admin-portal-configs/models/approval-activity.model';
import { ApprovalActivityService } from 'src/app/admin-portal-configs/services/approval-activity.service';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';

@Component({
  selector: 'app-add-or-edit-approval-activity-transition',
  templateUrl: './add-or-edit-approval-activity-transition.component.html',
  styleUrls: ['./add-or-edit-approval-activity-transition.component.scss']
})
export class AddOrEditApprovalActivityTransitionComponent implements OnInit {
  transitionForm: UntypedFormGroup;
  currentActivity: ApprovalActivityTransition;
  activities: ApprovalActivity[];
  fromActivitiesOptions: ApprovalActivity[];
  toActivitiesOptions: ApprovalActivity[];
  isSaving: boolean;

  constructor(
    private approvalActivityService: ApprovalActivityService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private translationService: AppTranslationService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.activities = data['activities'];
      this.currentActivity = data['currentActivity'];
    });

    this.fromActivitiesOptions = this.activities;
    this.toActivitiesOptions = this.activities;
    this.excludeActivity(this.currentActivity?.id);
    this.initForm();
  }

  initForm() {
    this.currentActivity =
      this.currentActivity || ({} as ApprovalActivityTransition);
    this.currentActivity.nextActivities =
      this.currentActivity.nextActivities || [];

    this.transitionForm = this.fb.group({
      current: [
        {
          value: this.currentActivity?.id || null,
          disabled: this.isForEditing
        },
        [Validators.required]
      ],
      next: null
    });
  }

  save() {
    const formValue = this.transitionForm.value;

    const formValid = this.transitionForm.valid && !this.isTransitionToEmpty;

    if (!formValid) {
      this.alertService.error('Invalid Form!');
      return;
    }

    if (!this.isForEditing) {
      this.addNewRequest(formValue);
    } else {
      this.updateActivity();
    }
  }
  get isForEditing(): boolean {
    return this.router.url.includes('edit');
  }

  addNewRequest(formValue) {
    const nextIds = this.currentActivity.nextActivities.map(i => i.id);

    this.approvalActivityService
      .addNewTransition(formValue.current, nextIds)
      .subscribe(
        data => {
          this.saveSuccessHelper(data);
        },
        error => {
          this.saveFailedHelper(error);
        }
      );
  }

  updateActivity() {
    const nextIds = this.currentActivity.nextActivities.map(i => i.id);

    this.approvalActivityService
      .updateTransition(this.currentActivity?.id, nextIds)
      .subscribe(
        data => {
          this.saveSuccessHelper(data);
        },
        error => {
          this.saveFailedHelper(error);
        }
      );
  }

  private saveSuccessHelper(transition?: ApprovalActivityTransition) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    if (this.isForEditing) {
      this.alertService.showMessage(
        'Success',
        `Changes to activity transition \"${transition.name}\" were saved successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Activity transition \"${transition.name}\" was created successfully`,
        MessageSeverity.success
      );
    }

    this.router.navigate(['/settings/approval-activity-transitions']);
  }

  cancel() {
    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/approval-activity-transitions']);
  }

  addTransitionOnSelect(event: any) {
    const nextId = event.target.value;
    const nextTransition = this.activities.find(i => i.id == nextId);

    let isDuplicateSelection = this.currentActivity?.nextActivities?.some(
      x => x.id == nextTransition.id
    );

    if (!isDuplicateSelection && nextTransition) {
      this.currentActivity.nextActivities.push(nextTransition);
    }

    this.updateFromOptions(nextId);
  }

  get isTransitionToEmpty(): boolean {
    return this.currentActivity?.nextActivities?.length <= 0;
  }

  updateToOptions(event: any) {
    const fromOptionActivityId = event.target.value;
    this.toActivitiesOptions = this.activities.filter(
      i => i.id != fromOptionActivityId
    );
  }

  updateFromOptions(toOptionsActivityId: number) {
    this.fromActivitiesOptions = this.activities.filter(
      i => i.id != toOptionsActivityId
    );
  }

  deleteSingleTransition(transition: any) {
    this.currentActivity.nextActivities = this.currentActivity.nextActivities.filter(
      i => i != transition
    );
  }

  excludeActivity(id: number) {
    if (id) {
      this.toActivitiesOptions = this.activities.filter(i => i.id != id);
    }
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occured whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  get isDisabledSaveButton(): boolean {
    return (
      this.isSaving ||
      !this.transitionForm.valid ||
      this.isTransitionToEmpty ||
      this.transitionForm?.value?.current == 'null'
    );
  }
}
