import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CompanyService } from '../services/company.service';
import { Company } from '../models/company.model';

@Injectable()
export class FreePLCompaniesListResolver implements Resolve<Company[]> {
  constructor(private companyService: CompanyService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Company[]> {
    return this.companyService.getFreePlCompanies().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
