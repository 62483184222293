import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LandRoute } from '../models/land-route.model';
import { LandRouteService } from '../services/land-route.service';

@Injectable({
  providedIn: 'root'
})
export class LandRouteResolver implements Resolve<LandRoute> {
  constructor(private landRouteService: LandRouteService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<LandRoute> {
    const routeId = route.params.id;
    return this.landRouteService.getLandRoute(routeId);
  }
}
