<mat-expansion-panel
  hideToggle="true"
  #progressActivityExpansionPanel
  class="progress-and-activity-log"
  [class]="isDisabled ? 'disabled-panal' : ''"
>
  <mat-expansion-panel-header
    class="activity-progress-header"
    [class]="isDisabled ? 'disabled' : ''"
  >
    <mat-panel-description class="progress-point-card">
      <span class="progress-name">{{ progressPoint.name }}</span>
      <div class="subtitle-container">
        <span class="progress-title">
          {{ subtitle }}
        </span>
        <div>
          <div *ngIf="!isDisabled && isCurrentProgress">
            <svg-icon class="check" name="check"></svg-icon>
            <span>{{ lastActvity }}</span>
          </div>

          <svg-icon
            class="expansion-arrow"
            name="ExpansionArrow"
            [class]="
              progressActivityExpansionPanel.expanded && !isDisabled
                ? 'expansion-arrow-expanded'
                : ''
            "
          ></svg-icon>
        </div>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <ng-template
    matExpansionPanelContent
    *ngIf="!isDisabled"
    class="expansion-panel-content"
  >
    <app-progress-point-logs
     [activityLogs]="activityLogs"
     ></app-progress-point-logs>

    <app-comments-pending-action
      [productRegistrationComments]="productRegistrationComments"
      [progressName]="progressPoint?.name"
      [commentsPendingActions]="null"
      [relatedEntityId]="productId"
    ></app-comments-pending-action>

  </ng-template>
</mat-expansion-panel>
