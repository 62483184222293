import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DistrictService } from '../../admin-portal-configs/services/district.service';
import { District } from '../models/district.models';
 
@Injectable({
  providedIn: 'root'
})
export class DistrictsListResolver implements Resolve<District[]> {
  constructor(
    private districtService: DistrictService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<District[]> {
    return this.districtService.getAll().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}