<div class="domestic-trucking-information">
  <div class="row">
    <div class="title">Route</div>
  </div>
  <div class="row">
    <div *ngIf="routeInformation">
      <span>
        {{ routeInformation.pickupDistrict }}
      </span>
      <span> , {{ routeInformation.pickupCity }} </span>
      ->
      <span>
        {{ routeInformation.deliveryDistrict }}
      </span>
      <span> , {{ routeInformation.deliveryCity }} </span>
    </div>
  </div>
  <div
    *ngFor="
      let truckDetails of landForm?.controls as controlsArray;
      let i = index
    "
  >
    <div [formGroup]="isLandServiceSelectedStandalone ? truckDetails : form">
      <div class="row titles">
        <div class="title truck-title" *ngIf="isLandServiceSelectedStandalone">
          Truck
        </div>
        <div class="title load-type-title">
          Load Type <span class="text-error">*</span>
        </div>
        <div class="title truck-type-title">
          Truck Type <span class="text-error">*</span>
        </div>
        <div class="title">Quantity <span class="text-error">*</span></div>
      </div>
      <div class="row load-type">
        <div
          class="fields-spacing ml-3 truck-count"
          *ngIf="isLandServiceSelectedStandalone"
        >
          {{ i + 1 }}
        </div>
        <div class="fields-spacing">
          <ng-select
            class="radio-select"
            formControlName="loadType"
            [readonly]="!isdomesticTruckingFromQuote"
            placeholder="Select Load Type"
            [clearable]="false"
            [searchable]="false"
            (change)="onChangeGetTruckTypes($event)"
            [ngClass]="[
              errorExistInFormField(truckDetails, form, 'loadType')
                ? 'error'
                : 'border',
              isLandServiceSelectedStandalone ? 'load-type-field' : ''
            ]"
          >
            <ng-option
              *ngFor="
                let item of isdomesticTruckingFromQuote
                  ? domesticTruckingLookups?.loadTypes
                  : []
              "
              [value]="item.name"
            >
              <div class="d-flex">
                {{ item.name }}
              </div>
            </ng-option>
          </ng-select>
        </div>

        <div class="fields-spacing">
          <ng-select
            class="radio-select"
            formControlName="truckType"
            placeholder="Select Truck Type"
            [readonly]="!isdomesticTruckingFromQuote"
            [clearable]="false"
            [searchable]="false"
            [class]="
              errorExistInFormField(truckDetails, form, 'truckType')
                ? 'error'
                : 'border'
            "
          >
            <ng-option
              *ngFor="let item of isdomesticTruckingFromQuote ? truckTypes : []"
              [value]="item.name"
            >
              <div class="d-flex">
                {{ item.name }}
              </div>
            </ng-option>
          </ng-select>
        </div>

        <div>
          <app-quantity-input
            class="col-2 px-0"
            [isGreyBorder]="true"
            [digitsOnly]="true"
            input
            formControlName="quantity"
            [classList]="
              errorExistInFormField(truckDetails, form, 'quantity')
                ? 'validation-error'
                : 'border'
            "
            [isDisabled]="isDisableForm"
          ></app-quantity-input>
        </div>
        <div class="ml-5 mt-1" *ngIf="isLandServiceSelectedStandalone">
          <button class="btn p-0" *ngIf="!isDeletable">
            <svg-icon name="gray-delete" class="delete-icon"></svg-icon>
          </button>
          <button class="btn p-0" *ngIf="isDeletable">
            <svg-icon
              name="delete"
              (click)="deleteTruckingRecord(i)"
              class="delete-icon"
            ></svg-icon>
          </button>
        </div>
      </div>

      <div
        class="row "
        [ngClass]="isLandServiceSelectedStandalone ? 'truck-sub-type' : ''"
      >
        <div class="title">Truck Subtype <span class="text-error">*</span></div>
      </div>
      <div
        class="row"
        [ngClass]="isLandServiceSelectedStandalone ? 'truck-sub-type' : ''"
      >
        <div>
          <ng-select
            class="radio-select"
            formControlName="truckSubType"
            [readonly]="!isdomesticTruckingFromQuote"
            [clearable]="false"
            placeholder="Select Truck Sub Type"
            [searchable]="false"
            [class]="
              errorExistInFormField(truckDetails, form, 'truckSubType')
                ? 'error'
                : 'border'
            "
          >
            <ng-option
              *ngFor="
                let item of isdomesticTruckingFromQuote
                  ? domesticTruckingLookups?.truckSubTypes
                  : []
              "
              [value]="item.name"
            >
              <div class="d-flex">
                {{ item.name }}
              </div>
            </ng-option>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <button
      class="underlined btn another-truck"
      *ngIf="isLandServiceSelectedStandalone"
      (click)="addTruckingRecord()"
    >
      + Add another truck
    </button>
  </div>
  <div class="row special-requirements">
    <div class="title">Special Requirements</div>
  </div>
  <div
    class="row"
    [formGroup]="isLandServiceSelectedStandalone ? landFormGroup : form"
  >
    <div>
      <app-input
        formControlName="specialRequirements"
        placeholder="Enter Special Requirements.."
        [classList]="isDisableForm ? 'field-bg field-disabled' : 'field-bg'"
        [readonly]="isDisableForm"
      ></app-input>
    </div>
  </div>
</div>
