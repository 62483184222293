import { Injectable } from '@angular/core';
import {
  Resolve,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Paginated } from 'src/app/shared/models/shipment/paginated.model';
import { LandRouteService } from '../services/land-route.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LandRoutesResolver implements Resolve<Paginated> {
  constructor(private landRouteService: LandRouteService) {}

  resolve(): Observable<Paginated> {
    return this.landRouteService.getLandRoutePaginated().pipe(
      catchError(() => {
        return of(null);
      })
    );
  }
}
