import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { loadTypesRenderingData } from '../../constants/load-type-rendering-data';

@Component({
  selector: 'app-load-type-radio-button-card',
  templateUrl: './load-type-radio-button-card.component.html',
  styleUrls: ['./load-type-radio-button-card.component.scss']
})
export class LoadTypeRadioButtonCardComponent implements OnInit {
  @Input() isPreSelected: boolean;
  @Input() classList: string;
  @Input() controlName: AbstractControl;
  @Input() disable: boolean;
  @Input() hasError: boolean;
  @Input() value: string; 
  
  loadTypesRenderingData: any[] = loadTypesRenderingData;
  loadTypeRenderingData;

  constructor() {}

  ngOnInit(): void {
    this.initiateCardData();
  }

  initiateCardData() {
    if (!this.value) {
      return;
    }
    this.loadTypeRenderingData = this.loadTypesRenderingData.find(s => s.value === this.value);
  }

  save() {
    if (!this.disable) {
      this.controlName.patchValue(this.value);
    }
  }

  get isPNGImage(){
    return this.loadTypeRenderingData?.image?.toLowerCase().includes('.png');
  }
}
