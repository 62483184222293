import { Component, Input, OnInit } from '@angular/core';
import { RouteDetails } from 'src/app/supplier/models/route-details.model';
import { TruckingLocation } from 'src/app/supplier/models/trucking-location.model';
import { SupplierHelperService } from 'src/app/supplier/services/supplier-helper.service';
import { District } from '../../models/district.models';
import { Port } from '../../models/port.model';
import { ServiceTypes } from 'src/app/shared/models/shipment/service-types.enum';
import { CustomerTruckingRouteDto } from 'src/app/customer/models/customer-trucking-route-dto.model';
import { ModuleName } from 'src/app/shared/models/enums/module-name.enum';

@Component({
  selector: 'app-trucking-route-details-summary',
  templateUrl: './trucking-route-details-summary.component.html',
  styleUrls: ['./trucking-route-details-summary.component.scss']
})
export class TruckingRouteDetailsSummaryComponent implements OnInit {
  @Input() routeDetails?: RouteDetails;
  @Input() isDomestic?: boolean;
  @Input() customerRouteDetails?: CustomerTruckingRouteDto;
  @Input() moduleName: string;
  module = ModuleName;

  constructor(private supplierHelperService: SupplierHelperService) {}

  ngOnInit(): void {}

  get customerRouteDetailsDistrict(): District {
    return this.customerRouteDetails.district;
  }
  get customerRouteDetailsPort(): Port {
    return this.customerRouteDetails.port;
  }
  get customerRouteDetailsDirection(): string {
    return this.customerRouteDetails.routeDirection;
  }
  get customerRouteDetailsSupplierName(): string {
    return this.customerRouteDetails.supplierName;
  }

  get isPortToDoor(): boolean {
    if (this.routeDetails != null) {
      return this.supplierHelperService.isTruckingRoutePortToDoor(
        this.routeDetails
      );
    } else {
      return this.customerRouteDetailsDirection == ServiceTypes.PortToDoor;
    }
  }

  getTruckingRoutePickUpOrDeliverTo(isPickup: boolean): TruckingLocation {
    return this.supplierHelperService.getTruckingRoutePickUpOrDeliverTo(
      this.routeDetails,
      isPickup
    );
  }

  get pickUpLocation(): TruckingLocation {
    return this.getTruckingRoutePickUpOrDeliverTo(true);
  }

  get deliveryLocation(): TruckingLocation {
    return this.getTruckingRoutePickUpOrDeliverTo(false);
  }

  get deliverToDecider(): string {
    if (this.moduleName == this.module.Supplier) {
      return this.isDomestic
        ? this.deliveryLocation?.name + ', ' + this.deliveryLocation?.cityName
        : !this.isPortToDoor
        ? this.deliveryLocation?.cityName +
          ', ' +
          this.deliveryLocation?.countryName +
          ' (' +
          this.deliveryLocation?.code +
          ')'
        : this.deliveryLocation?.name + ', ' + this.deliveryLocation?.cityName;
    } else {
      return !this.isPortToDoor
        ? this.customerRouteDetailsPort?.city.name +
            ', ' +
            this.customerRouteDetailsPort?.city.country.name +
            ' (' +
            this.customerRouteDetailsPort?.code +
            ')'
        : this.customerRouteDetailsDistrict?.name +
            ', ' +
            this.customerRouteDetailsDistrict?.city.name;
    }
  }

  get pickUpFromDecider(): string {
    if (this.moduleName == this.module.Supplier) {
      return this.isPortToDoor
        ? this.pickUpLocation?.cityName +
            ', ' +
            this.pickUpLocation?.countryName +
            ' (' +
            this.pickUpLocation?.code +
            ')'
        : this.pickUpLocation?.name + ', ' + this.pickUpLocation?.cityName;
    } else {
      return this.isPortToDoor
        ? this.customerRouteDetailsPort?.city.name +
            ', ' +
            this.customerRouteDetailsPort?.city.country.name +
            ' (' +
            this.customerRouteDetailsPort?.code +
            ')'
        : this.customerRouteDetailsDistrict?.name +
            ', ' +
            this.customerRouteDetailsDistrict?.city.name;
    }
  }

  get routeDirection(): string {
    let direction = this.supplierHelperService.getTruckingRouteDirection(
      this.routeDetails
    );
    return direction?.replace(/\s+/g, '-');
  }
}
