import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegistrationActivity } from 'src/app/admin-portal-configs/models/registration-activity.model';
import { RegistrationActivityService } from 'src/app/admin-portal-configs/services/registration-activity.service';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Utilities } from 'src/app/shared/services/utilities';

@Component({
  selector: 'app-registration-activity-list',
  templateUrl: './registration-activity-list.component.html',
  styleUrls: ['./registration-activity-list.component.scss']
})
export class RegistrationActivityListComponent implements OnInit {
  rows: RegistrationActivity[] = [];
  rowsCache: RegistrationActivity[] = [];
  columns: any[] = [];
  loadingIndicator: boolean;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  constructor(
    private registrationActivityService: RegistrationActivityService,
    private route: ActivatedRoute,
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      let registrationActivities = data['registrationActivities'];
      registrationActivities = this.getIndecies(registrationActivities);
      if (registrationActivities?.length > 0) {
        this.rowsCache = [...registrationActivities];
        this.rows = registrationActivities;
      }
    });

    this.initTable();
  }

  getIndecies(array: any) {
    array?.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }

  initTable() {
    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      { prop: 'name', name: gT('config.management.Name'), width: 360 },
      {
        prop: 'productRegistrationStatusName',
        name: gT('config.management.RegistrationActivity.Status'),
        width: 330
      },
      {
        prop: 'productRegistrationSystemStatusName',
        name: gT('config.management.RegistrationActivity.SystemStatus'),
        width: 300
      },
      {
        prop: 'progressPointName',
        name: gT('config.management.RegistrationActivity.Progress'),
        width: 300
      }
    ];

    if (
      this.canUpdateRegistrationActivities ||
      this.canDeleteRegistrationActivities
    ) {
      this.columns.push({
        prop: 'options',
        name: gT('config.management.RegistrationActivity.Actions'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      });
    }
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  deleteRegistrationActivity(row: RegistrationActivity) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' +
        row.name +
        '" registration activity ?',
      DialogType.confirm,
      () => this.deleteRegistrationActivityHelper(row)
    );
  }

  deleteRegistrationActivityHelper(row: RegistrationActivity) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.registrationActivityService
      .deleteRegistrationActivity(row.id)
      .subscribe(
        results => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;
          this.rowsCache = this.rowsCache.filter(item => item !== row);
          this.rowsCache = this.getIndecies(this.rowsCache);
          this.rows = this.rowsCache;
        },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage(
            'Delete Error',
            `An error occured whilst deleting the registration activity.\r\nError: "${Utilities.getHttpResponseMessages(
              error
            )}"`,
            MessageSeverity.error,
            error
          );
        }
      );
  }

  get canUpdateRegistrationActivities(): boolean {
    return this.authService.canUpdateRegistrationActivities;
  }

  get canCreateRegistrationActivities(): boolean {
    return this.authService.canCreateRegistrationActivities;
  }

  get canDeleteRegistrationActivities(): boolean {
    return this.authService.canDeleteRegistrationActivities;
  }

  hasCategory(row: RegistrationActivity): boolean {
    let categoryName = row?.productRegistrationActivityCategoryName;
    return !categoryName ? false : true;
  }
}
