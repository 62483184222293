import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UserLogin } from '../../../shared/models/user-login.model';
import {
  AlertService,
  MessageSeverity,
  DialogType
} from '../../../shared/services/alert.service';
import { AppTranslationService } from '../../../shared/services/app-translation.service';
import { AuthService } from '../../../shared/services/auth.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { Utilities } from '../../../shared/services/utilities';
import { invalidLoginServerMessage } from '../../../shared/constants/login-message';
import { freePlLogo } from 'src/app/shared/models/freePlLogo';
import { SignalRService } from 'src/app/shared/services/signal-r.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  userLogin = new UserLogin();
  isLoading = false;
  isExternalLogin = false;
  formResetToggle = true;
  modalClosedCallback: () => void;
  loginStatusSubscription: any;
  isInvalidLogin: boolean;
  freePlLogo = new freePlLogo();

  @Input()
  isModal = false;

  gT = (key: string | Array<string>, interpolateParams?: object) =>
    this.translationService.getTranslation(key, interpolateParams);

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private authService: AuthService,
    private configurations: ConfigurationService,
    private signalRService: SignalRService
  ) {}

  ngOnInit() {
    this.userLogin.rememberMe = this.authService.rememberMe;

    if (this.getShouldRedirect()) {
      this.authService.redirectLoginUser();
    } else {
      this.loginStatusSubscription = this.authService
        .getLoginStatusEvent()
        .subscribe(isLoggedIn => {
          if (this.getShouldRedirect()) {
            this.authService.redirectLoginUser();
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.loginStatusSubscription) {
      this.loginStatusSubscription.unsubscribe();
    }
  }

  getShouldRedirect() {
    return (
      !this.isModal &&
      this.authService.isLoggedIn &&
      !this.authService.isSessionExpired
    );
  }

  closeModal() {
    if (this.modalClosedCallback) {
      this.modalClosedCallback();
    }
  }

  changeLanguage(language: string) {
    this.configurations.globalLanguage = language;
    this.configurations.language = language;
  }

  login() {
    this.isLoading = true;
    this.isExternalLogin = false;

    this.authService
      .loginWithPassword(
        this.userLogin.userName,
        this.userLogin.password,
        this.userLogin.rememberMe
      )
      .subscribe(
        user => {
          setTimeout(() => {
            this.alertService.stopLoadingMessage();
            this.isLoading = false;
            this.reset();
            if (this.isModal) {
              this.alertService.showMessage(
                this.gT('login.alerts.Login'),
                this.gT('login.alerts.UserSessionRestored', {
                  username: user.userName
                }),
                MessageSeverity.success
              );
              setTimeout(() => {
                this.alertService.showStickyMessage(
                  this.gT('login.alerts.SessionRestored'),
                  this.gT('login.alerts.RetryLastOperation'),
                  MessageSeverity.default
                );
              }, 500);

              this.closeModal();
            }
          }, 500);
        },
        error => {
          this.alertService.stopLoadingMessage();

          if (Utilities.checkNoNetwork(error)) {
            this.alertService.showStickyMessage(
              this.gT('app.NoNetwork'),
              this.gT('app.ServerCannotBeReached'),
              MessageSeverity.error,
              error
            );
            this.offerAlternateHost();
          } else {
            const errorMessage = Utilities.getHttpResponseMessage(error);

            if (errorMessage) {
              if (errorMessage === invalidLoginServerMessage) {
                this.isInvalidLogin = true;
              } else {
                this.alertService.showStickyMessage(
                  this.gT('login.alerts.UnableToLogin'),
                  this.mapLoginErrorMessage(errorMessage),
                  MessageSeverity.error,
                  error
                );
              }
            } else {
              this.alertService.showStickyMessage(
                this.gT('login.alerts.UnableToLogin'),
                this.gT('login.alerts.LoginErrorOccured', {
                  error: Utilities.getResponseBody(error)
                }),
                MessageSeverity.error,
                error
              );
            }
          }

          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        }
      );
  }

  loginWithGoogle() {
    this.isLoading = true;
    this.isExternalLogin = true;
    this.alertService.startLoadingMessage(
      '',
      this.gT('login.alerts.RedirectingToGoogle')
    );

    this.authService.initLoginWithGoogle(this.userLogin.rememberMe);
  }

  loginWithFacebook() {
    this.isLoading = true;
    this.isExternalLogin = true;
    this.alertService.startLoadingMessage(
      '',
      this.gT('login.alerts.RedirectingToFacebook')
    );

    this.authService.initLoginWithFacebook(this.userLogin.rememberMe);
  }

  offerAlternateHost() {
    if (
      Utilities.checkIsLocalHost(location.origin) &&
      Utilities.checkIsLocalHost(this.configurations.baseUrl)
    ) {
      this.alertService.showDialog(
        this.gT('login.alerts.DeveloperDemoApiNotice'),
        DialogType.prompt,
        (value: string) => {
          this.configurations.baseUrl = value;
          this.configurations.tokenUrl = value;
          this.alertService.showStickyMessage(
            this.gT('login.alerts.ApiChanged'),
            this.gT('login.alerts.ApiChangedTo', { API: value }),
            MessageSeverity.warn
          );
        },
        null,
        null,
        null,
        this.configurations.fallbackBaseUrl
      );
    }
  }

  mapLoginErrorMessage(error: string) {
    if (error === 'invalid_grant') {
      return this.gT('login.alerts.AccountDisabled');
    }

    return error;
  }

  reset() {
    this.formResetToggle = false;

    setTimeout(() => {
      this.formResetToggle = true;
    });
  }
}
