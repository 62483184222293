export enum ProductRegistrationColumns {
  RegistrationNumber = 'Registration #',
  Status = 'Status',
  ProductName = 'Product Name',
  Created = 'Created',
  LastUpdate = 'Last Update',
  AcceptanceNumber = 'Acceptance #',
  LicenseNumber = 'License #',
  ValidUntil = 'Valid Until',
  Category = 'Category'
}
