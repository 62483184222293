<div class="row p-3">
    <div class="col-lg-3 col-md-1 col-sm-1"></div>
    <div class="col-lg-6 col-md-10 col-sm-10 Card pb-1">
      <form [formGroup]="industryForm" id="industryForm" (ngSubmit)="save()">
        <h4>
          {{
            !isNewIndustry
              ? ("config.management.Industry.Edit" | translate) +
                " " +
                industry.name
              : ("config.management.Industry.New" | translate)
          }}
        </h4>
        <div class="row">
          <div class="col-md-12">
            <hr class="edit-separator-hr" />
          </div>
        </div>
  
        <fieldset class="nested-fieldset">
          <div *ngIf="duplicateIndustry" class="error-message">
            <app-error-badge
              [thinBar]="true"
              message="{{
                'config.management.Industry.Editor.DuplicateEntry' | translate
              }}"
              class="warning-badge"
            ></app-error-badge>
          </div>
          <div class="form-group mb-3">
            <label for="name">{{ "config.management.Name" | translate }}</label>
            <input
              formControlName="name"
              [ngClass]="{
                'is-invalid':
                  industryForm.get('name').errors &&
                  industryForm.get('name').touched
              }"
              type="text"
              class="form-control"
              id="name"
              placeholder="{{
                'config.management.Industry.NamePlaceHolder' | translate
              }}"
            />
            <small
              *ngIf="
                industryForm.get('name').touched &&
                industryForm.get('name').hasError('required')
              "
              class="form-text text-danger"
              >{{
                "config.management.Industry.Editor.Industry.NameRequired"
                  | translate
              }}</small
            >
          </div>
        </fieldset>

        <div class="row">
          <div class="col-md-12">
            <hr class="edit-separator-hr" />
          </div>
        </div>
  
        <div class="col-sm-12 form-group">
          <div ngPreserveWhitespaces class="float-right">
            <button (click)="cancel()" class="btn btn-danger">
              <i class="fa fa-times"></i>
              {{ "config.management.Industry.Editor.Cancel" | translate }}
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="isSaving || !industryForm.valid"
            >
              <i *ngIf="!isSaving" class="fa fa-save"></i>
              <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
              {{
                isSaving
                  ? ("config.management.Industry.Editor.Saving" | translate)
                  : ("config.management.Industry.Editor.Save" | translate)
              }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  