import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProductCategory } from 'src/app/shared/models/product-category.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoryService {
  baseURL = environment.apiUrl + 'ProductCategory';

  constructor(private http: HttpClient) {}

  getById(id: number): Observable<ProductCategory> {
    return this.http.get<ProductCategory>(`${this.baseURL}/${id}`);
  }

  createProductCategory(
    productCategory: ProductCategory
  ): Observable<ProductCategory> {
    return this.http.post<ProductCategory>(`${this.baseURL}`, productCategory);
  }

  updateProductCategory(
    id: number,
    productCategory: ProductCategory
  ): Observable<ProductCategory> {
    return this.http.put<ProductCategory>(
      `${this.baseURL}/${id}`,
      productCategory
    );
  }

  deleteProductCategory(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseURL}/${id}`);
  }

  getAll(): Observable<ProductCategory[]> {
    return this.http.get<ProductCategory[]>(`${this.baseURL}`);
  }
}
