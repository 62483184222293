import { Component, Input, OnInit } from '@angular/core';
import { CargoHandling } from 'src/app/shared/models/enums/cargo-handling.enum';
import { QuoteCargoDetails } from 'src/app/shared/quote/models/quote-cargo-details.model';
import { QuoteCargoProduct } from 'src/app/shared/quote/models/quote-cargo-product.model';

@Component({
  selector: 'app-quote-product-details',
  templateUrl: './quote-product-details.component.html',
  styleUrls: ['./quote-product-details.component.scss']
})
export class QuoteProductDetailsComponent implements OnInit {
  @Input() cargoDetails: QuoteCargoDetails;
  @Input() isLandStandaloneService: boolean = false;
  cargoHandling = CargoHandling;

  constructor() {}

  ngOnInit(): void {}

  get quoteCargoProduct(): QuoteCargoProduct {
    return this.cargoDetails?.quoteCargoProduct;
  }

  get hsCodes(): string[] {
    return this.quoteCargoProduct?.hsCodes;
  }

  get isEnableCargoHandling() {
    return this.cargoDetails?.hasPackage || this.cargoDetails?.hasPallet;
  }

  get isDomesticTruckingCargo(): boolean {
    return this.cargoDetails.domesticTruckingInformation != null;
  }
}
