<div class="notifications-container">
  <div class="row header-container">
    <div class="col-6 pl-0">
      <h6>
        Notifications
      </h6>
    </div>
    <div class="col-6 pr-0" *ngIf="unreadNotifications?.length > 0">
      <button
        class="btn-link mark-as-read-btn float-right pb-0"
        (click)="markAllNotificationsAsRead()"
      >
        Mark all as read
      </button>
    </div>
  </div>
  <div class="notifications-list-container h-100">
    <div class="notifications-list row" (scroll)="onScroll($event)">
      <div
        *ngFor="let item of notifications; let i = index"
        (click)="onClickNotification(item)"
        class="notification w-100"
        [ngClass]="{ unread: !item.isViewed, 'pt-0': i === 0 }"
      >
        <div class="flex">
          <div class="author-img" *ngIf="item.author != null && 
          !notificationHelper.isAssignOrUnassignAccountManagerNotification(item); else freePLLogo">
            <span
              *ngIf="!item.author?.profilePictureUrl"
              class="default-user-picture"
            >
              {{
                item.author?.firstName + " " + item.author?.lastName
                  | userNameInitials
              }}
            </span>
            <img
              *ngIf="item.author?.profilePictureUrl"
              [src]="item.author?.profilePictureUrl"
              alt=""
            />
          </div>
          
          <ng-template #freePLLogo>
            <div class="author-img">
              <img
              *ngIf="notificationHelper.isAssignOrUnassignAccountManagerNotification(item)"
              [src]="'assets/images/PNG/FreePLLogo.png'"
              alt=""
              />
            </div>
          </ng-template>

          <div class="notification-body d-flex w-100">
            <div class="w-75">
              <span *ngIf="!notificationHelper.isAssignOrUnassignAccountManagerNotification(item)" class="author-name">
                {{ item.author?.firstName }} {{ item.author?.lastName }}
              </span>
              <span> {{ item?.text }} </span>
              <br />
              <span>
                {{ item.urlLabel }}
              </span>
            </div>

            <span class="time pt-1 w-25">
              {{ item.creationTime | fromutc | relativeTime }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
