import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderDashedUnderlineComponent} from './header-dashed-underline.component';


@NgModule({
  declarations: [HeaderDashedUnderlineComponent],
  exports: [HeaderDashedUnderlineComponent],
  imports: [
    CommonModule
  ]
})
export class HeaderDashedModule {
}
