<div [classList]="'default-border ' + classList">
  <input
    [type]="type"
    [ngModel]="value"
    (ngModelChange)="onChange($event)"
    [placeholder]="placeholder"
    ngDefaultControl
    [disabled]="disabled"
    *ngIf="!digitsOnly"
    [dropSpecialCharacters]="dropSpecialCharacters"
    [mask]="mask"
    [maxlength]="maxLength"
    [min]="min"
    [readonly]="readonly"
  />

  <input
    [type]="type"
    [ngModel]="value"
    (ngModelChange)="onChange($event)"
    [placeholder]="placeholder"
    ngDefaultControl
    [disabled]="disabled"
    *ngIf="digitsOnly"
    pattern="[1-9][0-9]*"
    appOnlyDigitsDirective
    [readonly]="readonly"
  />
</div>
