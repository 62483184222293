import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuccessSnackBarComponent } from './success-snack-bar.component';
import { SvgIconsRegistery } from 'projects/svg-icons/src/lib/svg-icons.registery';
import { svgIconCheckIcon, SvgIconsModule, svgIconTimesIcon } from 'projects/svg-icons/src/lib/svg-icons';

@NgModule({
  imports: [
    CommonModule,
    SvgIconsModule
  ],
  declarations: [SuccessSnackBarComponent]
})
export class SuccessSnackBarModule {
  constructor(private svgIconRegistery: SvgIconsRegistery) {
    this.svgIconRegistery.registerIcons([
      svgIconCheckIcon,
      svgIconTimesIcon
    ]);
  }
 }
