import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss']
})
export class SectionTitleComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  @Input() body: TemplateRef<any>;

  active = true;

  constructor() { }

  ngOnInit(): void {
  }

  toggle() {
    this.active = !this.active;
  }
}
