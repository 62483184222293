import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators'; 
import { ChargeTypeService } from '../../admin-portal-configs/services/charge-type.service';

@Injectable({
  providedIn: 'root'
})
export class ChargeTypeResolver implements Resolve<boolean> {
  id: number;
  constructor(
    private chargeTypeService: ChargeTypeService,
    private router: Router
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.id = JSON.parse(route.params.id) as number;
    return this.chargeTypeService.getById(this.id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
