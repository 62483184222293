import { Component, Input, OnInit } from '@angular/core';
import { CustomerTruckingRouteDto } from 'src/app/customer/models/customer-trucking-route-dto.model';
import { TruckDetails } from 'src/app/supplier/models/truck-details.model';
import { ModuleName } from '../../models/enums/module-name.enum';
import { LoadType } from '../../models/load-type.model';
import { TruckSubType } from 'src/app/company/models/truck-sub-type.model';
import { TruckType } from '../../models/truck-type.model';
import { Currency } from '../../models/currency.model';

@Component({
  selector: 'app-trucking-truck-details-summary',
  templateUrl: './trucking-truck-details-summary.component.html',
  styleUrls: ['./trucking-truck-details-summary.component.scss']
})
export class TruckingTruckDetailsSummaryComponent implements OnInit {
  @Input() truckDetails?: TruckDetails[];
  @Input() customerTruckingRoute: CustomerTruckingRouteDto;
  @Input() moduleName: ModuleName;

  module = ModuleName;

  constructor() {}

  ngOnInit(): void {}

  get loadType(): LoadType {
    return this.customerTruckingRoute.loadType;
  }
  get truckSubType(): TruckSubType {
    return this.customerTruckingRoute.truckSubType;
  }
  get truckType(): TruckType {
    return this.customerTruckingRoute.truckType;
  }
  get transportPrice(): number {
    return this.customerTruckingRoute.transportPrice;
  }

  get currencyCode(): Currency {
    return this.customerTruckingRoute.currency;
  }

  get excludeDeletedTruckDetails(): TruckDetails[] {
    return this.truckDetails.filter(t => !t.isDeleted);
  }
}
