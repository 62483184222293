import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Supplier } from 'src/app/company/models/supplier.model';
import { SupplierService } from '../services/supplier.service';

@Injectable({ providedIn: 'root' })
export class SupplierResolver implements Resolve<Supplier> {
  constructor(private supplierService: SupplierService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Supplier> {
    const id = route.params.id;

    return this.supplierService.getSupplierById(id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
