import { Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { ProductRegistrationCommentService } from '../../services/product-registration-comment.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GroupedProductComments } from '../../models/grouped-product-comments.model';
import { ProductRegistrationCommentWithActionActors } from '../../models/product-registration-comment-with-action-actors.model';
import { Weekdays } from 'src/app/shared/constants/week-days';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ProductRegistrationComment } from '../../models/product-registration-comment.model';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-product-registration-comment-section',
  templateUrl: './product-registration-comment-section.component.html',
  styleUrls: ['./product-registration-comment-section.component.scss']
})
export class ProductRegistrationCommentSectionComponent
  implements OnInit, OnChanges {
  noCommentsFeed: boolean = true;
  openSection: boolean = false;
  @Input() productRegistrationId: string;
  @Input()
  commentWithActionActors: ProductRegistrationCommentWithActionActors[];
  @Input() newCommentAdded: ProductRegistrationCommentWithActionActors;

  dateGroups: GroupedProductComments[];
  weekday = Weekdays;
  commentForm: UntypedFormGroup;
  currentUserId: string;
  @ViewChild('commentsSidenav') public sidenav: MatSidenav;

  constructor(
    private productRegistrationCommentService: ProductRegistrationCommentService,
    private authService: AuthService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.commentWithActionActors =
      this.commentWithActionActors ||
      ([] as ProductRegistrationCommentWithActionActors[]);
    this.noCommentsFeed = this.commentWithActionActors.length == 0;
    this.currentUserId = this.authService.currentUser.id;
    this.groupCommentsByDates();
    this.scrollToBottom();
    this.initForm();
  }

  ngOnChanges(): void {
    if (this.newCommentAdded && this.newCommentAdded.isFulFill) {
      this.commentFulfilled(this.newCommentAdded);
    } else if (this.newCommentAdded) {
      this.addNewCommentToCommentSection(this.newCommentAdded);
    }
    this.groupCommentsByDates();
    this.scrollToBottom();
  }

  ngAfterViewInit(): void {
    this.productRegistrationCommentService.onViewInChat.subscribe(commentId => {
      this.sidenav.open();
      let commentToScrollTo = document.getElementById(commentId);
      commentToScrollTo?.scrollIntoView();
    });
  }

  groupCommentsByDates() {
    if (
      this.commentWithActionActors &&
      this.commentWithActionActors.length == 0
    ) {
      this.noCommentsFeed = true;
      return;
    }
    this.dateGroups = [];
    this.commentWithActionActors.forEach(item => {
      this.noCommentsFeed = false;
      var groupObject = this.populateGroupObject(item.createdAt);

      let isExistDateGroup =
        this.dateGroups.findIndex(i => i.date === groupObject.date) != -1;
      if (!isExistDateGroup) {
        this.dateGroups.push(groupObject);
      }
    });
  }

  get today(): string {
    return this.formatDate(new Date());
  }

  get yesterday(): string {
    const todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 1);
    return this.formatDate(todayDate);
  }
  initForm() {
    this.commentForm = this.fb.group({
      comment: ['', [Validators.required]],
      isPendingAction: false
    });
  }

  get canSendPendingActionToRegistration(): boolean {
    return this.authService.canSendPendingActionToRegistration;
  }

  filterCommentsByDate(
    groupDate: string
  ): ProductRegistrationCommentWithActionActors[] {
    return this.commentWithActionActors.filter(i => {
      const date: Date = new Date(i.createdAt);
      return groupDate == this.formatDate(date);
    });
  }

  save() {
    const formVal = this.commentForm.value;
    if (
      formVal.comment.trim().length === 0 ||
      formVal.comment.trim().length > 250
    ) {
      return;
    }
    var productComment: ProductRegistrationComment = {
      content: formVal.comment,
      createdAt: new Date(),
      relatedEntityId: this.productRegistrationId,
      isActive: true,
      isPendingAction: formVal.isPendingAction ?? false
    };
    this.addComment(productComment);

    this.resetForm();
  }
  addComment(comment: ProductRegistrationComment) {
    this.productRegistrationCommentService
      .addNewComment(comment)
      .subscribe(result => {
        this.productRegistrationCommentService.onCommentsUpdate.emit(result);
        this.groupCommentsByDates();
        this.scrollToBottom();
      });
  }

  resetForm() {
    this.commentForm.reset();
  }

  commentFulfilled(
    fulfilledComment: ProductRegistrationCommentWithActionActors
  ) {
    var groupObject = this.populateGroupObject(fulfilledComment?.createdAt);

    let commentToFulfillIndex = this.commentWithActionActors.findIndex(
      c => c.id === fulfilledComment?.id
    );
    this.commentWithActionActors[commentToFulfillIndex] = fulfilledComment;

    let groupObjectCommentToFulfillIndex = groupObject.comments.findIndex(
      c => c.id === fulfilledComment?.id
    );
    groupObject.comments[groupObjectCommentToFulfillIndex] = fulfilledComment;

    const index = this.dateGroups.findIndex(i => i.date === groupObject.date);
    if (index != -1) {
      let commentInDateGroupIndex = this.dateGroups[index].comments.findIndex(
        c => c.id === fulfilledComment?.id
      );
      this.dateGroups[index].comments[
        commentInDateGroupIndex
      ] = fulfilledComment;
    }

    this.noCommentsFeed = false;
    this.newCommentAdded = null;
  }

  scrollToBottom(): void {
    setTimeout(() => {
      const element = document.getElementById('comments-feed');
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    });
  }
  onCommentsRefresh() {
    this.productRegistrationCommentService.onCommentsUpdate.emit();
  }

  markCommentAsFulfilled(commentPendingAction: any) {
    this.productRegistrationCommentService
      .fulFillPendingActionComment(
        this.productRegistrationId,
        commentPendingAction.id
      )
      .subscribe(response => {
        this.productRegistrationCommentService.onCommentsUpdate.emit(response);
        this.groupCommentsByDates();
        this.scrollToBottom();
      });
  }
  addNewCommentToCommentSection(newComment) {
    var groupObject = this.populateGroupObject(newComment.createdAt);

    this.commentWithActionActors.push(newComment);

    const index = this.dateGroups?.findIndex(i => i.date === groupObject.date);
    groupObject.comments.push(newComment);
    this.noCommentsFeed = false;

    if (index != -1) {
      this.dateGroups = this.dateGroups || [];
      this.dateGroups[index]?.comments.push(newComment);
    } else {
      this.dateGroups.push(groupObject);
    }
    this.newCommentAdded = null;
  }

  get canSendCommentsToRegistration(): boolean {
    return this.authService.canSendCommentsToRegistration;
  }

  private formatDate(date: Date): string {
    let day = this.weekday[date.getDay()];
    return `${day}, ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
  }

  private populateGroupObject(currentDate): GroupedProductComments {
    const date = new Date(currentDate);
    const dayDate = this.formatDate(date);
    var groupObject = new GroupedProductComments();
    groupObject.date = dayDate;
    groupObject.dateCategoryName = this.convertDateToCategroyName(dayDate);
    groupObject.comments = this.filterCommentsByDate(dayDate);
    return groupObject;
  }

  private convertDateToCategroyName(dayDate): string {
    if (dayDate == this.today) {
      return 'Today';
    } else if (dayDate == this.yesterday) {
      return 'Yesterday';
    } else {
      return dayDate;
    }
  }
}
