import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from '../../../../shared/services/alert.service';
import { AppTranslationService } from '../../../../shared/services/app-translation.service';
import { Utilities } from '../../../../shared/services/utilities';
import { ActivatedRoute } from '@angular/router';
import { ActivityTransition } from '../../../models/activity-transition.model';
import { ActivityService } from '../../../services/activity.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-activity-transition-list',
  templateUrl: './activity-transition-list.component.html',
  styleUrls: ['./activity-transition-list.component.scss']
})
export class ActivityTransitionListComponent implements OnInit {
  columns: any[] = [];
  rows: ActivityTransition[] = [];
  rowsCache: ActivityTransition[] = [];
  loadingIndicator: boolean;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private activityService: ActivityService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      var transitions = data['activityTransitions'];

      transitions = this.getIndecies(transitions);

      this.rowsCache = [...transitions];
      this.rows = transitions;
    });

    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      { prop: 'name', name: gT('config.management.Name'), width: 400 },
      {
        prop: 'nextActivities.length',
        name: gT('config.management.Activity.NextTransitionsCount'),
        width: 100
      }
    ];

    if (this.canUpdateShipmentWorkflow || this.canDeleteShipmentWorkflow) {
      this.columns.push({
        prop: 'options',
        name: gT('config.management.Activity.ManageTransitions'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      });
    }
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  deleteActivityTransition(row: ActivityTransition) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' +
        row.name +
        '" activity transitions? this will delete all next transitions for this activity',
      DialogType.confirm,
      () => this.deleteActivityTransitionHelper(row)
    );
  }

  deleteActivityTransitionHelper(row: ActivityTransition) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.activityService.deleteTransition(row.id).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache.find(i => i.id == row.id).nextActivities = [];
        this.rows = this.rowsCache;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the activity transition.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  getIndecies(array: any) {
    array.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }

  get canCreateShipmentWorkflow(): boolean {
    return this.authService.canCreateShipmentWorkflow;
  }

  get canUpdateShipmentWorkflow(): boolean {
    return this.authService.canUpdateShipmentWorkflow;
  }

  get canDeleteShipmentWorkflow(): boolean {
    return this.authService.canDeleteShipmentWorkflow;
  }
}
