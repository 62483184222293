<div class="shipments-list-filter-container">
  <div class="row form-header">
    <div class="form-title">
      Filter By
    </div>
    <a class="clear-all cursor-pointer" (click)="clearFilters()">Clear All</a>
  </div>

  <form [formGroup]="filtersForm" (ngSubmit)="save()" class="text-center">
    <div class="filter-list">
      <!-- state -->
      <mat-expansion-panel class="filter-container" #stateExpansionPanel>
        <mat-expansion-panel-header class="px-0 expansion-panel-header">
          <mat-panel-description
            class="mx-0 justify-content-between filter-container-header"
          >
            <div class="w-100">
              <span class="filter-title"> State </span>
              <span
                class="number-of-selections ml-4"
                *ngIf="getNumberofSelectedFilters(states) > 0"
              >
                {{ getNumberofSelectedFilters(states) }} selection<span
                  *ngIf="getNumberofSelectedFilters(states) > 1"
                  >s</span
                >
              </span>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <div class="filters-content">
            <div
              class="form-group pr-3 pt-4 mb-0"
              *ngFor="let state of states; let i = index"
              [class]="i == states?.length - 1 ? 'pb-4' : ''"
            >
              <div class="row column-row">
                <div class="pr-2 pl-3 checkbox-container">
                  <input
                    class="filter-checkbox"
                    [formControlName]="state"
                    type="checkbox"
                  />
                </div>
                <div
                  for="state"
                  class="ml-1"
                  [placement]="
                    i >= states?.length - 2 ? 'top-left' : 'bottom-left'
                  "
                  [ngbPopover]="stateFilterContent"
                  triggers="mouseenter:mouseleave"
                >
                  {{ state }}
                </div>
                <ng-template #stateFilterContent>
                  <div class="row pop-over">
                    {{ state }}
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>
      <div
        [class]="stateExpansionPanel.expanded ? '' : 'line-separator m-auto'"
      ></div>

      <!-- Progress -->
      <mat-expansion-panel class="filter-container" #progressExpansionPanel>
        <mat-expansion-panel-header class="px-0 expansion-panel-header">
          <mat-panel-description
            class="mx-0 justify-content-between filter-container-header"
          >
            <div class="w-100">
              <span class="filter-title"> Progress </span>
              <span
                class="number-of-selections ml-4"
                *ngIf="getNumberofSelectedFilters(progresses) > 0"
              >
                {{ getNumberofSelectedFilters(progresses) }} selection<span
                  *ngIf="getNumberofSelectedFilters(progresses) > 1"
                  >s</span
                >
              </span>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <div class="filters-content">
            <div
              class="form-group pr-3 pt-4 mb-0"
              *ngFor="let progress of progresses; let i = index"
              [class]="i == progresses?.length - 1 ? 'pb-4' : ''"
            >
              <div class="row column-row">
                <div class="pr-2 pl-3 checkbox-container">
                  <input
                    class="filter-checkbox"
                    [formControlName]="progress"
                    type="checkbox"
                  />
                </div>
                <div
                  for="progress"
                  class="ml-1"
                  [placement]="
                    i >= progresses?.length - 2 ? 'top-left' : 'bottom-left'
                  "
                  [ngbPopover]="progressFilterContent"
                  triggers="mouseenter:mouseleave"
                >
                  {{ progress }}
                </div>
                <ng-template #progressFilterContent>
                  <div class="row pop-over">
                    {{ progress }}
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>
      <div
        [class]="progressExpansionPanel.expanded ? '' : 'line-separator m-auto'"
      ></div>

      <!-- Status -->
      <mat-expansion-panel class="filter-container" #statusExpansionPanel>
        <mat-expansion-panel-header class="px-0 expansion-panel-header">
          <mat-panel-description
            class="mx-0 justify-content-between filter-container-header"
          >
            <div class="w-100">
              <span class="filter-title"> Status </span>
              <span
                class="number-of-selections ml-4"
                *ngIf="getNumberofSelectedFilters(statuses) > 0"
              >
                {{ getNumberofSelectedFilters(statuses) }} selection<span
                  *ngIf="getNumberofSelectedFilters(statuses) > 1"
                  >s</span
                >
              </span>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <div class="filters-content">
            <div
              class="form-group pr-3 pt-4 mb-0"
              *ngFor="let status of statuses; let i = index"
              [class]="i == statuses?.length - 1 ? 'pb-4' : ''"
            >
              <div class="row column-row">
                <div class="pr-2 pl-3 checkbox-container">
                  <input
                    class="filter-checkbox"
                    [formControlName]="status"
                    type="checkbox"
                  />
                </div>
                <div
                  for="status"
                  class="ml-1"
                  [placement]="
                    i >= statuses?.length - 2 ? 'top-left' : 'bottom-left'
                  "
                  [ngbPopover]="statusFilterContent"
                  triggers="mouseenter:mouseleave"
                >
                  {{ status }}
                </div>
                <ng-template #statusFilterContent>
                  <div class="row pop-over">
                    {{ status }}
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>
      <div
        [class]="statusExpansionPanel.expanded ? '' : 'line-separator m-auto'"
      ></div>

      <!-- Mode of Transport -->
      <mat-expansion-panel
        class="filter-container"
        #modeOfTransportExpansionPanel
      >
        <mat-expansion-panel-header class="px-0 expansion-panel-header">
          <mat-panel-description
            class="mx-0 justify-content-between filter-container-header"
          >
            <div class="w-100">
              <span class="filter-title"> Mode of Transport </span>
              <span
                class="number-of-selections ml-4"
                *ngIf="getNumberofSelectedFilters(modesOfTransport) > 0"
              >
                {{ getNumberofSelectedFilters(modesOfTransport) }}
                selection<span
                  *ngIf="getNumberofSelectedFilters(modesOfTransport) > 1"
                  >s</span
                >
              </span>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <div class="filters-content">
            <div
              class="form-group pr-3 pt-4 mb-0"
              *ngFor="let mode of modesOfTransport; let i = index"
              [class]="i == modesOfTransport?.length - 1 ? 'pb-4' : ''"
            >
              <div class="row column-row">
                <div class="pr-2 pl-3 checkbox-container">
                  <input
                    class="filter-checkbox"
                    [formControlName]="mode"
                    type="checkbox"
                  />
                </div>
                <div
                  for="mode"
                  class="ml-1"
                  [placement]="
                    i >= modesOfTransport?.length - 2
                      ? 'top-left'
                      : 'bottom-left'
                  "
                  [ngbPopover]="modeFilterContent"
                  triggers="mouseenter:mouseleave"
                >
                  {{ mode }}
                </div>
                <ng-template #modeFilterContent>
                  <div class="row pop-over">
                    {{ mode }}
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>
      <div
        [class]="
          modeOfTransportExpansionPanel.expanded ? '' : 'line-separator m-auto'
        "
      ></div>

      <!-- Type -->
      <mat-expansion-panel class="filter-container" #typeExpansionPanel>
        <mat-expansion-panel-header class="px-0 expansion-panel-header">
          <mat-panel-description
            class="mx-0 justify-content-between filter-container-header"
          >
            <div class="w-100">
              <span class="filter-title"> Type </span>
              <span
                class="number-of-selections ml-4"
                *ngIf="getNumberofSelectedFilters(types) > 0"
              >
                {{ getNumberofSelectedFilters(types) }} selection<span
                  *ngIf="getNumberofSelectedFilters(types) > 1"
                  >s</span
                >
              </span>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <div class="filters-content">
            <div
              class="form-group pr-3 pt-4 mb-0"
              *ngFor="let type of types; let i = index"
              [class]="i == types?.length - 1 ? 'pb-4' : ''"
            >
              <div class="row column-row">
                <div class="pr-2 pl-3 checkbox-container">
                  <input
                    class="filter-checkbox"
                    [formControlName]="type"
                    type="checkbox"
                  />
                </div>
                <div
                  for="type"
                  class="ml-1"
                  [placement]="
                    i >= types?.length - 2 ? 'top-left' : 'bottom-left'
                  "
                  [ngbPopover]="typeFilterContent"
                  triggers="mouseenter:mouseleave"
                >
                  {{ type }}
                </div>
                <ng-template #typeFilterContent>
                  <div class="row pop-over">
                    {{ type }}
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>
      <div
        [class]="typeExpansionPanel.expanded ? '' : 'line-separator m-auto'"
      ></div>

      <!-- Marker -->
      <mat-expansion-panel class="filter-container">
        <mat-expansion-panel-header class="px-0 expansion-panel-header">
          <mat-panel-description
            class="mx-0 justify-content-between filter-container-header"
          >
            <div class="w-100">
              <span class="filter-title">Marker</span>
              <span
                class="number-of-selections ml-4"
                *ngIf="getNumberofSelectedFilters(markers) > 0"
              >
                {{ getNumberofSelectedFilters(markers) }} selection<span
                  *ngIf="getNumberofSelectedFilters(markers) > 1"
                  >s</span
                >
              </span>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <div class="filters-content">
            <div
              class="form-group pr-3 pt-4 mb-0"
              *ngFor="let marker of markers; let i = index"
              [class]="i == markers?.length - 1 ? 'pb-4' : ''"
            >
              <div class="row column-row">
                <div class="pr-2 pl-3 checkbox-container">
                  <input
                    class="filter-checkbox"
                    [formControlName]="marker"
                    type="checkbox"
                  />
                </div>
                <div
                  for="marker"
                  class="ml-1"
                  [placement]="
                    i >= markers?.length - 2 ? 'top-left' : 'bottom-left'
                  "
                  [ngbPopover]="markerFilterContent"
                  triggers="mouseenter:mouseleave"
                >
                  {{ marker }}
                </div>
                <ng-template #markerFilterContent>
                  <div class="row pop-over">
                    {{ marker }}
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>

      <app-shipment-actors-view-filter
        *ngIf="isOperationsOversightView"
        (onSelectionChange)="customFiltersChanged($event)"
        [shipmentActorsViewFilterData]="shipmentActorsViewFilterData"
        [isClearCustomFilters]="isClearCustomFilters"
      ></app-shipment-actors-view-filter>
    </div>

    <div class="apply-button-container">
      <button type="submit" class="btn btn-primary w-100 apply-filters">
        Apply
        <span *ngIf="totalNumberOfSelectedFilters > 0">
          ({{ totalNumberOfSelectedFilters }})
        </span>
      </button>
    </div>
  </form>
</div>
