<h4>{{ "config.management.TruckType.Manage" | translate }}</h4>
<hr />
<a class="btn btn-primary mb-3" routerLink="../" fragment="config">
  <i class="fa fa-arrow-left" aria-hidden="true"></i> {{ "back" | translate }}
</a>
<div>
  <div class="row mx-0">
    <div class="col-lg-8 p-0">
      <div class="form-group search-box">
        <app-search-box
          (searchChange)="onSearchChanged($event)"
          placeholder="{{ 'config.management.TruckType.Search' | translate }}"
        ></app-search-box>
      </div>
    </div>
    <div class="col-lg-4 p-0">
      <ul class="nav flex-column flex-lg-row justify-content-end">
        <li>
          <a class="btn btn-link" [routerLink]="['./add']">
            <i class="fa fa-plus"></i>
            {{ "config.management.TruckType.New" | translate }}
          </a>
        </li>
      </ul>
    </div>
  </div>

  <ngx-datatable
    class="material colored-header sm table table-striped table-hover"
    [loadingIndicator]="loadingIndicator"
    [rows]="rows"
    [rowHeight]="35"
    [headerHeight]="35"
    [footerHeight]="35"
    [columns]="columns"
    [scrollbarV]="true"
    [columnMode]="'force'"
  >
    <ng-template
      #loadTypeTemplate
      let-row="row"
      let-value="value"
      let-i="index"
    >
      <ng-container *ngIf="value && value.length > 0">
        <ng-container *ngFor="let obj of value; let last = last">
          {{ obj.name }}
          <ng-container *ngIf="!last">,</ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  </ngx-datatable>

  <ng-template #actionsTemplate let-row="row" let-value="value" let-i="id">
    <a class="btn btn-link btn-sm" [routerLink]="['./' + row.id + '/edit']">
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
      {{ "config.management.TruckType.Edit" | translate }}
    </a>
    |
    <button class="btn btn-link btn-sm" (click)="deleteTruckType(row)">
      <i class="fa fa-trash-o" aria-hidden="true"></i>
      {{ "config.management.TruckType.Delete" | translate }}
    </button>
  </ng-template>
</div>
