<div class="recommended-img" *ngIf="isPreSelected">
  <svg-icon [name]="'recommended-flag'"></svg-icon>
  <span>Recommended </span>
</div>
<div
  [classList]="'d-flex position-relative radio-button-card ' + classList"
  [ngClass]="{ disable: disable, error: hasError && !disable }"
  id="wrapper"
  (click)="save()"
  name="btn_save"
>
  <input class="d-none" type="radio" />
  <div
    class="container card-container"
    [ngClass]="{ 'margin-container': isPreSelected, disable: disable }"
  >
    <div
      class="d-flex align-items-center justify-content-between my-1 pr-3 w-100"
    >
      <div class="d-flex flex-column">
        <span class="title">{{ loadTypeRenderingData.title }}</span>
        <p class="description">
          {{ loadTypeRenderingData.description }}
        </p>
      </div>
      <div *ngIf="loadTypeRenderingData?.image">
        <img
          [src]="loadTypeRenderingData?.image"
          *ngIf="isPNGImage; else isSvgIcon"
        />
        <ng-template #isSvgIcon>
          <svg-icon [name]="loadTypeRenderingData.image"></svg-icon>
        </ng-template>
      </div>
    </div>

    <div class="d-flex flex-column ">
      <div class="d-flex mt-3 mb-2 align-items-center">
        <svg-icon name="orange-plus-icon"></svg-icon>
        <span class="subtext mx-2 ">
          {{ loadTypeRenderingData?.subTitleOne }}
        </span>
      </div>
      <div class="d-flex align-items-center">
        <img class="icon-minus pt-1" src="assets/images/PNG/icon-minus.png" />
        <span class="subtext mx-2 ">
          {{ loadTypeRenderingData?.subTitleTwo }}
        </span>
      </div>
    </div>
  </div>
  <div class="circle-container">
    <span class="circle"></span>
  </div>
</div>
