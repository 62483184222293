import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-action-author',
  templateUrl: './action-author.component.html',
  styleUrls: ['./action-author.component.scss']
})
export class ActionAuthorComponent implements OnInit {
  @Input() actionLabel: string;
  @Input() autherName: string;
  @Input() companyName: string;
  @Input() date: Date;
  @Input() reason: string;

  constructor() {}

  ngOnInit() {}
}
