<div class="row p-3">
  <div class="col-lg-3 col-md-1 col-sm-1"></div>
  <div class="col-lg-6 col-md-10 col-sm-10 Card pb-1">
    <form [formGroup]="cityForm" id="cityForm" (ngSubmit)="save()">
      <h4>
        {{
          !isNewCity
            ? ("config.management.City.Edit" | translate) +
              " " +
              cityWithLocation.city
            : ("config.management.City.New" | translate)
        }}
      </h4>
      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <fieldset class="nested-fieldset">
        <div *ngIf="duplicateCity" class="error-message">
          <app-error-badge
            [thinBar]="true"
            message="{{
              'config.management.City.Editor.DuplicateEntry' | translate
            }}"
            class="warning-badge"
          ></app-error-badge>
        </div>
        <fieldset class="nested-fieldset">
          <legend>{{ "Location" | translate }}</legend>
          <div class="row">
            <div class="col-6 form-group">
              <label for="countryId">{{ "route.Country" | translate }}</label>
              <select
                class="form-control"
                id="countryId"
                formControlName="countryId"
                [value]="cityWithLocation.countryId"
              >
                <option [value]="null">
                  {{ "route.SelectCountry" | translate }}
                </option>
                <option *ngFor="let item of countries" [value]="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <small
            *ngIf="
              cityForm.get('countryId').touched &&
              cityForm.get('countryId').hasError('required')
            "
            class="form-text text-danger"
            >{{
              "config.management.City.Editor.CountryNameRequired" | translate
            }}</small
          >
        </fieldset>

        <div class="form-group mb-3">
          <label for="city">{{ "config.management.Name" | translate }}</label>
          <input
            formControlName="city"
            [ngClass]="{
              'is-invalid':
                cityForm.get('city').errors && cityForm.get('city').touched
            }"
            type="text"
            class="form-control"
            id="city"
            placeholder="{{
              'config.management.City.NamePlaceHolder' | translate
            }}"
          />
          <small
            *ngIf="
              cityForm.get('city').touched &&
              cityForm.get('city').hasError('required')
            "
            class="form-text text-danger"
            >{{
              "config.management.City.Editor.CityNameRequired" | translate
            }}</small
          >
        </div>

        <div class="form-group mb-3">
          <label for="cityAscii">{{
            "config.management.City.CityAscii" | translate
          }}</label>
          <input
            formControlName="cityAscii"
            [ngClass]="{
              'is-invalid':
                cityForm.get('cityAscii').errors &&
                cityForm.get('cityAscii').touched
            }"
            type="text"
            class="form-control"
            id="cityAscii"
            placeholder="{{
              'config.management.City.AsciiPlaceHolder' | translate
            }}"
          />
          <small
            *ngIf="
              cityForm.get('cityAscii').touched &&
              cityForm.get('cityAscii').hasError('required')
            "
            class="form-text text-danger"
            >{{
              "config.management.City.Editor.CityAsciiNameRequired" | translate
            }}</small
          >
        </div>

        <div class="form-group mb-3">
          <label for="lat">{{
            "config.management.City.Latitude" | translate
          }}</label>
          <input
            formControlName="lat"
            [ngClass]="{
              'is-invalid':
                cityForm.get('lat').errors && cityForm.get('lat').touched
            }"
            type="number"
            max="180"
            min="-180"
            class="form-control"
            id="lat"
            placeholder="{{ 'config.management.City.Latitude' | translate }}"
          />
          <small
            *ngIf="
              cityForm.get('lat').touched &&
              cityForm.get('lat').hasError('required')
            "
            class="form-text text-danger"
            >{{
              "config.management.City.Editor.LatitudeRequired" | translate
            }}</small
          >
        </div>

        <div class="form-group mb-3">
          <label for="lng">{{
            "config.management.City.Longtitude" | translate
          }}</label>
          <input
            formControlName="lng"
            [ngClass]="{
              'is-invalid':
                cityForm.get('lng').errors && cityForm.get('lng').touched
            }"
            type="number"
            max="180"
            min="-180"
            class="form-control"
            id="lng"
            placeholder="{{ 'config.management.City.Longtitude' | translate }}"
          />          <small
          *ngIf="
            cityForm.get('lng').touched &&
            cityForm.get('lng').hasError('required')
          "
          class="form-text text-danger"
          >{{
            "config.management.City.Editor.LongtitudeRequired" | translate
          }}</small
        >
        </div>
      </fieldset>

      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <div class="col-sm-12 form-group">
        <div ngPreserveWhitespaces class="float-right">
          <button (click)="cancel()" class="btn btn-danger">
            <i class="fa fa-times"></i>
            {{ "config.management.City.Editor.Cancel" | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="isSaving || !cityForm.valid"
          >
            <i *ngIf="!isSaving" class="fa fa-save"></i>
            <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
            {{
              isSaving
                ? ("config.management.City.Editor.Saving" | translate)
                : ("config.management.City.Editor.Save" | translate)
            }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
