import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TruckType } from '../../shared/models/truck-type.model';
import { TruckTypeService } from 'src/app/admin-portal-configs/services/truck-type.service';

@Injectable({
  providedIn: 'root'
})
export class TruckTypeListResolver implements Resolve<TruckType[]> {
  id: number;
  constructor(private truckTypeService: TruckTypeService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<TruckType[]> {
    return this.truckTypeService.getAll().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
  
}
