import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProductRegistrationViewColumn } from '../models/product-registration-view-column.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductRegistrationColumnsService {
  baseURL = environment.apiUrl + 'ProductRegistrationColumns';
  @Output() proudctRegistrationDisplayedColumnsEmitter: EventEmitter<{
    columns: ProductRegistrationViewColumn[];
  }> = new EventEmitter<{ columns: ProductRegistrationViewColumn[] }>();

  constructor(private http: HttpClient) {}

  getColumns(): Observable<ProductRegistrationViewColumn[]> {
    return this.http.get<ProductRegistrationViewColumn[]>(
      `${this.baseURL}/Columns`
    );
  }

  getDefaultColumns(): Observable<ProductRegistrationViewColumn[]> {
    return this.http.get<ProductRegistrationViewColumn[]>(
      `${this.baseURL}/DefaultColumns`
    );
  }

  getFixedAndDefaultColumns(): Observable<ProductRegistrationViewColumn[]> {
    return this.http.get<ProductRegistrationViewColumn[]>(
      `${this.baseURL}/FixedAndDefaultColumns`
    );
  }

  saveSelectedColumns(
    productRegistrationColumns: ProductRegistrationViewColumn[]
  ): Observable<any> {
    return this.http.post(
      `${this.baseURL}/SaveSelectedColumns`,
      productRegistrationColumns
    );
  }

  getEmployeePreferredSelectedColumns(): Observable<
    ProductRegistrationViewColumn[]
  > {
    return this.http.get<ProductRegistrationViewColumn[]>(
      `${this.baseURL}/EmployeePreferredSelectedColumns`
    );
  }
}
