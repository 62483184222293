import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEventType,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  invalidLoginServerMessage,
  resettingPasswordFailedForUser
} from '../constants/login-message';
import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private alertService: AlertService
  ) {}
  // tslint:disable-next-line: typedef
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token: string = this.authService.accessToken;
 
    if (token) {
      const tokenHeader = 'Bearer ' + this.authService.accessToken;
      req = req.clone({
        headers: req.headers.set('Authorization', tokenHeader)
      });
     }

    req = req.clone({
      headers: req.headers.set('Accept', 'application/json,text/plain,*/*')
    });

    next.handle(req).pipe(
      tap(event => {
        if (event.type === HttpEventType.Response) {
          // this.spinner.hide();
        }
      })
    );

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          this.authService.logout();
          this.authService.redirectForLogin();
        } else if (error.status == 403) {
          this.authService.gotoHomePage();
        } else if (error.status >= 400 && error.status < 500) {
          if (error.status == 409) {
            return throwError(error.status);
          }
          if (error?.error.error_description == invalidLoginServerMessage) {
            return throwError(invalidLoginServerMessage);
          }
          if (error?.error['resettingPasswordFailedForUser']) {
            return throwError(resettingPasswordFailedForUser);
          }
          this.alertService.httpError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }

  protected get requestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.accessToken,
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*'
    });

    return { headers };
  }
}
