<div class="row p-3">
  <div class="col-lg-3 col-md-1 col-sm-1"></div>
  <div class="col-lg-6 col-md-10 col-sm-10 Card pb-1">
    <form [formGroup]="leadTimeForm" id="leadTimeForm" (ngSubmit)="save()">
      <h4>
        {{
          !isNewProductLeadTime
            ? ("config.management.ShipmentLeadTime.Edit" | translate) +
              " " +
              productLeadTime.name
            : ("config.management.ShipmentLeadTime.New" | translate)
        }}
      </h4>
      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <fieldset class="nested-fieldset">
        <div *ngIf="isDuplicateProductLeadTime" class="error-message">
          <app-error-badge
            [thinBar]="true"
            message="{{
              'config.management.ShipmentLeadTime.Editor.DuplicateEntry'
                | translate
            }}"
            class="warning-badge"
          ></app-error-badge>
        </div>
        <div class="form-group mb-3">
          <label for="name">{{
            "config.management.ShipmentLeadTime.KpiName" | translate
          }}</label>
          <input
            formControlName="name"
            [ngClass]="{
              'is-invalid':
                leadTimeForm.get('name').errors &&
                leadTimeForm.get('name').touched
            }"
            type="text"
            class="form-control"
            id="name"
            placeholder="{{
              'config.management.ShipmentLeadTime.NamePlaceHolder' | translate
            }}"
          />
          <small
            *ngIf="
              leadTimeForm.get('name').touched &&
              leadTimeForm.get('name').hasError('required')
            "
            class="form-text text-danger"
            >{{
              "config.management.ShipmentLeadTime.Editor.KpiNameRequired"
                | translate
            }}</small
          >
        </div>

        <div class="form-group mb-3">
          <label for="leadTime">{{
            "config.management.ShipmentLeadTime.LeadTime" | translate
          }}</label>
          <input
            formControlName="leadTime"
            [ngClass]="{
              'is-invalid':
                leadTimeForm.get('leadTime').errors &&
                leadTimeForm.get('leadTime').touched
            }"
            type="number"
            class="form-control"
            id="leadTime"
            placeholder="{{
              'config.management.ShipmentLeadTime.LeadTimePlaceHolder'
                | translate
            }}"
            appOnlyDigitsDirective
          />
          <small
            *ngIf="
              leadTimeForm.get('leadTime').touched &&
              leadTimeForm.get('leadTime').hasError('required')
            "
            class="form-text text-danger"
            >{{
              "config.management.ShipmentLeadTime.Editor.LeadTimeRequired"
                | translate
            }}
          </small>
        </div>

        <div class="row">
          <div class="col-6 form-group">
            <label for="startTrigger">{{
              "config.management.ShipmentLeadTime.StartTrigger" | translate
            }}</label>
            <select
              class="form-control"
              formControlName="startTriggerId"
              id="startTriggerId"
              (change)="onChangingStartTrigger($event)"
            >
              <option [value]="null">
                {{
                  "config.management.ShipmentLeadTime.StartTriggerPlaceHolder"
                    | translate
                }}
              </option>
              <option *ngFor="let item of activities" [value]="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="col-6 form-group">
            <label for="endTrigger">{{
              "config.management.ShipmentLeadTime.EndTrigger" | translate
            }}</label>
            <select
              class="form-control"
              formControlName="endTriggerId"
              id="endTriggerId"
            >
              <option [value]="null">
                {{
                  "config.management.ShipmentLeadTime.EndTriggerPlaceHolder"
                    | translate
                }}
              </option>
              <option *ngFor="let item of endTriggers" [value]="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
      </fieldset>

      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <div class="col-sm-12 form-group">
        <div ngPreserveWhitespaces class="float-right">
          <button (click)="cancel()" class="btn btn-danger">
            <i class="fa fa-times"></i>
            {{ "config.management.ShipmentLeadTime.Editor.Cancel" | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="isSaving || !leadTimeForm.valid"
          >
            <i *ngIf="!isSaving" class="fa fa-save"></i>
            <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
            {{
              isSaving
                ? ("config.management.ShipmentLeadTime.Editor.Saving"
                  | translate)
                : ("config.management.ShipmentLeadTime.Editor.Save" | translate)
            }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
