import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators'; 
 import { DistrictService } from '../../admin-portal-configs/services/district.service';
import { District } from '../models/district.models';

@Injectable({
  providedIn: 'root'
})
export class DistrictResolver implements Resolve<District> {
  id: number;
  constructor(
    private districtService: DistrictService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<District> {
    this.id = JSON.parse(route.params.id) as number;
    return this.districtService.getById(this.id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
