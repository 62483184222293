<!--Start currency matrix-->
<div class="d-flex justify-content-center flex-nowrap">
  <div class="currency-matrix text-center">
    <table class="table table-bordered ">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" *ngFor="let currency of currencies">
            {{ currency.code }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr scope="col">
          <td>
            {{ "config.management.Currencies.EGPCurrencyCode" | translate }}
          </td>
          <td scope="col" *ngFor="let currency of currencies">
            {{ currency.egpExchangeRate ? currency.egpExchangeRate : "-" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!--End currency matrix-->
