import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Employee } from 'src/app/company/models/employee.model';
import { CompanyService } from 'src/app/company/services/company.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';

@Component({
  selector: 'app-employee-profile-info',
  templateUrl: './employee-profile-info.component.html',
  styleUrls: ['./employee-profile-info.component.scss']
})
export class EmployeeProfileInfoComponent implements OnInit {
  @Input() actorDetails: Employee = {} as Employee;
  profileForm: UntypedFormGroup;
  editableFields: boolean[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private companyService: CompanyService,
    private translationService: AppTranslationService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.profileForm = this.fb.group({
      firstName: [
        this.actorDetails.firstName,
        [Validators.required, Validators.minLength(2)]
      ],
      lastName: [
        this.actorDetails.lastName,
        [Validators.required, Validators.minLength(2)]
      ],
      department: [this.actorDetails.department],
      jobTitle: [this.actorDetails.jobTitle],
      phoneNumber: [
        this.actorDetails.phoneNumber,
        [
          Validators.pattern(/^[0-9]+$/),
          Validators.minLength(2),
          Validators.required
        ]
      ],
      email: [this.actorDetails.email, [Validators.required]]
    });
  }

  save() {
    if (!this.profileForm.valid) {
      return;
    }

    for (const key in this.profileForm.value) {
      this.actorDetails[key] = this.profileForm.value[key];
    }
    this.companyService
      .updateEmployeeProfile(this.actorDetails)
      .subscribe(result => {
        this.actorDetails = result;
        this.alertService.success('Changes saved successfully!');
      });
  }

  reset() {
    this.profileForm.patchValue(this.actorDetails);
    this.alertService.success('Changes reset successfully!');
  }
}
