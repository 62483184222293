import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CompanyService } from 'src/app/company/services/company.service';
@Injectable({
  providedIn: 'root'
})
export class DocumentsListResolver implements Resolve<Document[]> {
  companyId: number;
  constructor(private companyService: CompanyService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Document[]> {
    this.companyId = JSON.parse(route.params.companyId) as number;
    return this.companyService.getDocuments(this.companyId).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
