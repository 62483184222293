<form [formGroup]="filtersForm" class="text-center">
  <div class="line-separator m-auto"></div>
  <!-- customers -->
  <mat-expansion-panel class="filter-container" #customersExpansionPanel>
    <mat-expansion-panel-header class="px-0 expansion-panel-header">
      <mat-panel-description
        class="mx-0 justify-content-between filter-container-header"
      >
        <div class="w-100">
          <span class="filter-title"> Customer Name </span>
          <span
            class="number-of-selections ml-4"
            *ngIf="this.selectedCustomers?.length > 0"
          >
            {{ this.selectedCustomers?.length }}
            selection<span *ngIf="this.selectedCustomers?.length > 1">s</span>
          </span>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div class="filters-content">
        <div class="search-bar-container form-group pr-3 pt-4 mb-0 pb-3">
          <input
            type="text"
            formControlName="customerNameOrEmail"
            class="form-control search-bar col-10 border-0"
            placeholder="Search for a specific customer"
          />
          <svg-icon name="searchIcon" class="search-icon"></svg-icon>
        </div>

        <div *ngIf="isNoCustomersAvailable" class="pr-3 pt-4 mb-0 pb-3">
          No results matching the entered criteria
        </div>

        <div
          class="form-group pr-3 pt-4 mb-0"
          *ngFor="let customer of customers; let i = index"
          [class]="i == customers?.length - 1 ? 'pb-4' : ''"
        >
          <div class="row column-row">
            <div class="pr-2 pl-3 checkbox-container">
              <input
                class="filter-checkbox"
                [formControlName]="customer?.name + '_' + customer?.id"
                type="checkbox"
                (change)="
                  onChangeCustomerSelection(
                    customer?.name + '_' + customer?.id,
                    customer
                  )
                "
              />
            </div>
            <div
              for="customer"
              class="ml-1"
              [placement]="
                i >= customers?.length - 2 ? 'top-left' : 'bottom-left'
              "
              [ngbPopover]="customersFilterContent"
              triggers="mouseenter:mouseleave"
            >
              {{ customer?.name }}
            </div>
            <ng-template #customersFilterContent>
              <div class="row pop-over">
                {{ customer?.name }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-expansion-panel>
  <div
    [class]="customersExpansionPanel.expanded ? '' : 'line-separator m-auto'"
  ></div>

  <!-- accountManager -->
  <mat-expansion-panel class="filter-container" #accountManagersExpansionPanel>
    <mat-expansion-panel-header class="px-0 expansion-panel-header">
      <mat-panel-description
        class="mx-0 justify-content-between filter-container-header"
      >
        <div class="w-100">
          <span class="filter-title"> Account Manager Name </span>
          <span
            class="number-of-selections ml-4"
            *ngIf="selectedAccountManagers?.length > 0"
          >
            {{ selectedAccountManagers?.length }}
            selection<span *ngIf="selectedAccountManagers?.length > 1">s</span>
          </span>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div class="filters-content">
        <div class="search-bar-container form-group pr-3 pt-4 mb-0 pb-3">
          <input
            type="text"
            formControlName="accountManagerNameOrEmail"
            class="form-control search-bar col-12 border-0"
            placeholder="Search for a specific account manager"
          />
          <svg-icon name="searchIcon" class="search-icon"></svg-icon>
        </div>

        <div *ngIf="isNoAccountManagersAvailable" class="pr-3 pt-4 mb-0 pb-3">
          No results matching the entered criteria
        </div>

        <div
          class="form-group pr-3 pt-4 mb-0"
          *ngFor="let accountManager of accountManagers; let i = index"
          [class]="i == accountManagers?.length - 1 ? 'pb-4' : ''"
        >
          <div class="row column-row">
            <div class="pr-2 pl-3 checkbox-container">
              <input
                class="filter-checkbox"
                [formControlName]="
                  accountManager?.name + '_' + accountManager?.id
                "
                type="checkbox"
                (change)="
                  onChangeSelection(
                    accountManager?.name + '_' + accountManager?.id,
                    accountManager,
                    selectedAccountManagers,
                    'AccountManager'
                  )
                "
              />
            </div>
            <div
              for="accountManager"
              class="ml-1"
              [placement]="
                i >= accountManagers?.length - 2 ? 'top-left' : 'bottom-left'
              "
              [ngbPopover]="accountManagerFilterContent"
              triggers="mouseenter:mouseleave"
            >
              {{ accountManager?.name }}
            </div>
            <ng-template #accountManagerFilterContent>
              <div class="row pop-over">
                {{ accountManager?.name }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-expansion-panel>
  <div
    [class]="
      accountManagersExpansionPanel.expanded ? '' : 'line-separator m-auto'
    "
  ></div>

  <!-- shipmentSubmitters -->
  <mat-expansion-panel
    class="filter-container"
    #shipmentSubmitterExpansionPanel
  >
    <mat-expansion-panel-header class="px-0 expansion-panel-header">
      <mat-panel-description
        class="mx-0 justify-content-between filter-container-header"
      >
        <div class="w-100">
          <span class="filter-title"> Shipment Submitter </span>
          <span
            class="number-of-selections ml-4"
            *ngIf="selectedShipmentSubmitters?.length > 0"
          >
            {{ selectedShipmentSubmitters?.length }}
            selection<span *ngIf="selectedShipmentSubmitters?.length > 1"
              >s</span
            >
          </span>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div class="filters-content">
        <div class="search-bar-container form-group pr-3 pt-4 mb-0 pb-3">
          <input
            type="text"
            formControlName="shipmentSubmitternameOrEmail"
            class="form-control search-bar col-12 border-0"
            placeholder="Search for a specific shipment submitter"
          />
          <svg-icon name="searchIcon" class="search-icon"></svg-icon>
        </div>

        <div
          *ngIf="isNoShipmentSubmittersAvailable"
          class="pr-3 pt-4 mb-0 pb-3"
        >
          No results matching the entered criteria
        </div>

        <div
          class="form-group pr-3 pt-4 mb-0"
          *ngFor="let shipmentSubmitter of shipmentSubmitters; let i = index"
          [class]="i == shipmentSubmitters?.length - 1 ? 'pb-4' : ''"
        >
          <div class="row column-row">
            <div class="pr-2 pl-3 checkbox-container">
              <input
                class="filter-checkbox"
                [formControlName]="
                  shipmentSubmitter?.name + '_' + shipmentSubmitter?.id
                "
                type="checkbox"
                (change)="
                  onChangeSelection(
                    shipmentSubmitter?.name + '_' + shipmentSubmitter?.id,
                    shipmentSubmitter,
                    selectedShipmentSubmitters,
                    'ShipmentSubmitter'
                  )
                "
              />
            </div>
            <div
              for="shipmentSubmitter"
              class="ml-1"
              [placement]="
                i >= shipmentSubmitters?.length - 2 ? 'top-left' : 'bottom-left'
              "
              [ngbPopover]="shipmentSubmitterFilterContent"
              triggers="mouseenter:mouseleave"
            >
              {{ shipmentSubmitter?.name }}
            </div>
            <ng-template #shipmentSubmitterFilterContent>
              <div class="row pop-over">
                {{ shipmentSubmitter?.name }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-expansion-panel>
</form>
