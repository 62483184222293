<div class="row p-3">
  <div class="col-lg-3 col-md-1 col-sm-1"></div>
  <div class="col-lg-6 col-md-10 col-sm-10 Card pb-1">
    <form [formGroup]="activityForm" id="activityForm" (ngSubmit)="save()">
      <h4>
        {{
          !isNewActivity
            ? ("config.management.Activity.Edit" | translate) +
              " " +
              activity.name
            : ("config.management.Activity.New" | translate)
        }}
      </h4>
      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <fieldset class="nested-fieldset">
        <div *ngIf="duplicateActivity" class="error-message">
          <app-error-badge
            [thinBar]="true"
            message="{{
              'config.management.Activity.Editor.DuplicateEntry' | translate
            }}"
            class="warning-badge"
          ></app-error-badge>
        </div>
        <div class="form-group mb-3">
          <label for="name">{{ "config.management.Name" | translate }}</label>
          <input
            formControlName="name"
            [ngClass]="{
              'is-invalid':
                activityForm.get('name').errors &&
                activityForm.get('name').touched
            }"
            type="text"
            class="form-control"
            id="name"
            placeholder="{{
              'config.management.Activity.NamePlaceHolder' | translate
            }}"
          />
          <small
            *ngIf="
              activityForm.get('name').touched &&
              activityForm.get('name').hasError('required')
            "
            class="form-text text-danger"
            >{{
              "config.management.Activity.Editor.ActivityNameRequired"
                | translate
            }}</small
          >
        </div>
        <div class="col-sm-6 mb-3 form-check">
          <input
            type="checkbox"
            class="form-check-input"
            formControlName="isVoid"
            name="isVoid"
            id="isVoid"
            (change)="onVoidActivityChange($event)"
          />
          <label class="form-check-label" for="isVoid">
            {{ "config.management.Activity.Void" | translate }}
          </label>
        </div>

        <ng-template #voidActivityTemplate>
          <div class="row">
            <div class="col-6">
              <label for="categoryId">{{
                "config.management.Activity.Category" | translate
              }}</label>
              <select
                class="form-control"
                formControlName="categoryId"
                id="categoryId"
                [value]="activity.categoryId"
              >
                <option [value]="null" [disabled]="true">
                  {{
                    "config.management.Activity.CategoryPlaceHolder" | translate
                  }}
                </option>
                <option
                  *ngFor="let item of activityCategories"
                  [value]="item.id"
                 >
                  {{ item.name }}
                </option>
              </select>
              <small
                *ngIf="
                  activityForm.get('categoryId').touched &&
                  activityForm.get('categoryId').hasError('required')
                "
                class="form-text text-danger"
                >{{
                  "config.management.Activity.Editor.CategoryRequired"
                    | translate
                }}</small
              >
            </div>
          </div>
        </ng-template>

        <span *ngIf="isNonVoidActivity; else voidActivityTemplate">
          <div *ngIf="showErrorMessages" class="error-message">
            <app-error-badge
              [thinBar]="true"
              message="{{
                'config.management.Activity.Editor.RequiredFields' | translate
              }}"
              class="warning-badge"
            ></app-error-badge>
          </div>
          <div class="row mb-3">
            <div class="col-6 form-group">
              <label for="statusId">{{
                "config.management.Activity.Status" | translate
              }}</label>
              <select
                class="form-control"
                formControlName="statusId"
                id="statusId"
                [value]="activity.statusId"
              >
                <option [value]="0">
                  {{
                    "config.management.Activity.StatusPlaceHolder" | translate
                  }}
                </option>
                <option
                  *ngFor="let item of statuses"
                  [value]="item.id"
                  selected="{{ item.id == activity.statusId }}"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div *ngIf="activityTypeError" class="error-message">
            <app-error-badge
              [thinBar]="true"
              message="{{
                'config.management.Activity.Editor.SelectOne' | translate
              }}"
              class="warning-badge"
            ></app-error-badge>
          </div>
          <div class="col-sm-6 mb-3 form-check">
            <input
              type="checkbox"
              class="form-check-input"
              formControlName="isForOcean"
              name="isForOcean"
              id="isForOcean"
            />
            <label class="form-check-label" for="isForOcean">
              {{ "config.management.Activity.isForOcean" | translate }}
            </label>
          </div>
          <div class="col-sm-6 mb-3 form-check">
            <input
              type="checkbox"
              class="form-check-input"
              formControlName="isForAir"
              name="isForAir"
              id="isForAir"
            />
            <label class="form-check-label" for="isForAir">
              {{ "config.management.Activity.isForAir" | translate }}
            </label>
          </div>
          <div  *ngFor="let item of selectedServices; let i = index" class="col-sm-6 mb-3 form-check">
            <input
            type="checkbox"
            [value]="item.id"
            (change)="onChangeSelectedServices(i,$event)"
            class="form-check-input"
            name="{{ item.name }}"
            [checked]="isServiceSelected(item)"
            />
            <label class="form-check-label">
            {{item.name}}
            </label>
          </div>

          <div class="row mb-3">
            <div class="col-6 form-group">
              <label for="progressId">{{
                "config.management.Activity.Progress" | translate
              }}</label>
              <select
                class="form-control"
                formControlName="progressId"
                id="progressId"
                [value]="activity.progressId"
              >
                <option [value]="0">
                  {{
                    "config.management.Activity.ProgressPlaceHolder" | translate
                  }}
                </option>
                <option *ngFor="let item of progresses" [value]="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </span>
      </fieldset>

      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <div class="col-sm-12 form-group">
        <div ngPreserveWhitespaces class="float-right">
          <button (click)="cancel()" class="btn btn-danger">
            <i class="fa fa-times"></i>
            {{ "config.management.Activity.Editor.Cancel" | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="isSaving || !activityForm.valid"
          >
            <i *ngIf="!isSaving" class="fa fa-save"></i>
            <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
            {{
              isSaving
                ? ("config.management.Activity.Editor.Saving" | translate)
                : ("config.management.Activity.Editor.Save" | translate)
            }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
