export enum SortFilter {
  MostRecentlyUpdated = 'Most recently updated',
  DateCreatedNewest = 'Date created (newest)',
  DateCreatedOldest = 'Date created (oldest)',
  PickupDateEarliest = 'Pickup Date (earliest)',
  PickupDateLatest = 'Pickup Date (latest)',
  DeliveryDateEarliest = 'Delivery Date (earliest)',
  DeliveryDateLatest = 'Delivery Date (latest)',
  ExpiryDateEarliest = 'Expiry Date (earliest)',
  OldestUpdated = 'Oldest updated'
}
