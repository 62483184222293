import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Stepper } from '../../models/stepper.model';

@Component({
  selector: 'app-page-stepper',
  templateUrl: './page-stepper.component.html',
  styleUrls: ['./page-stepper.component.scss']
})
export class PageStepperComponent implements OnInit {
  @Input() steps: Stepper[];

  constructor(public router: Router) {}

  ngOnInit(): void {}

  get stepsBeforeSummary() {
    return this.steps.filter(i => i.name != 'Summary');
  }
}
