import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CityLocation } from '../../admin-portal-configs/models/city-location.model';
import { CityService } from '../../admin-portal-configs/services/city.service';

@Injectable({
  providedIn: 'root'
})
export class CityResolver implements Resolve<CityLocation> {
  id: number;
  constructor(private cityService: CityService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<CityLocation> {
    this.id = JSON.parse(route.params.id) as number;
    return this.cityService.getById(this.id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
