import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class NumericValidators {
  constructor() {}

  public static decimalPerentage(
    control: UntypedFormControl
  ): { [s: string]: boolean } {
    if (control.value) {
      const percentage = +control.value;

      if (percentage < 0 || percentage > 100) {
        return { invalidDate: true };
      }
    }
    return null;
  }

  public static integerPerentage(
    control: UntypedFormControl
  ): { [s: string]: boolean } {
    if (control.value) {
      const percentage = +control.value;

      if (percentage < 0 || percentage > 100 || !Number.isInteger(percentage)) {
        return { invalidDate: true };
      }
    }
    return null;
  }

  public static greaterThanZeroIntegerPerentage(
    control: UntypedFormControl
  ): { [s: string]: boolean } {
    if (control.value) {
      const percentage = +control.value;

      if (
        percentage <= 0 ||
        percentage > 100 ||
        !Number.isInteger(percentage)
      ) {
        return { invalidDate: true };
      }
    }
    return null;
  }

  public static greaterThanZeroPerentage(
    control: UntypedFormControl
  ): { [s: string]: boolean } {
    if (control.value) {
      const percentage = +control.value;

      if (percentage <= 0 || percentage > 100) {
        return { invalidDate: true };
      }
    }
    return null;
  }

  public static integerAmount(
    control: UntypedFormControl
  ): { [s: string]: boolean } {
    if (control.value) {
      const number = +control.value;

      if (number < 0 || !Number.isInteger(number)) {
        return { invalidDate: true };
      }
    }
    return null;
  }

  public static decimalAmount(
    control: UntypedFormControl
  ): { [s: string]: boolean } {
    if (control.value) {
      const number = +control.value;

      if (number < 0) {
        return { invalidDate: true };
      }
    }
    return null;
  }

  public static positiveInteger(
    control: UntypedFormControl
  ): { [s: string]: boolean } {
    if (control.value) {
      const number = +control.value;

      if (number < 0 || !Number.isInteger(number)) {
        return { invalidDate: true };
      }
    }
    return null;
  }

  public static positiveValue(
    control: UntypedFormControl
  ): { [s: string]: boolean } {
    if (control.value) {
      const number = +control.value;

      if (number <= 0) {
        return { invalidValue: true };
      }
    }
    return null;
  }

  public static positiveNumber(
    control: UntypedFormControl
  ): { [s: string]: boolean } {
    if (control.value) {
      const number = +control.value;

      if (number <= 0 || Number.isNaN(number)) {
        return { invalidValue: true };
      }
    }
    return null;
  }

  public static notNaNValidator(
    control: UntypedFormControl
  ): { [key: string]: any } | null {
    if (control.value) {
      return isNaN(control.value)
        ? { notANumber: { value: control.value } }
        : null;
    }
    return null;
  }
}
