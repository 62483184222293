import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-uploaded-documents-details-loader',
  templateUrl: './uploaded-documents-details-loader.component.html',
  styleUrls: ['./uploaded-documents-details-loader.component.scss']
})
export class UploadedDocumentsDetailsLoaderComponent implements OnInit {
  @Input() documentType: string;
  constructor() { }

  ngOnInit(): void {
  }

}

