import { Component, isDevMode, OnInit } from '@angular/core';
import { NonAdminSidebarEnum } from '../../models/enums/non-admin-sidebar.enum';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ShipmentHelpersService } from 'src/app/shipments/services/shipment-helpers.service';
import { CreateQuoteHelperService } from 'src/app/create-quote/services/create-quote-helper.service';

@Component({
  selector: 'app-non-admin-sidebar',
  templateUrl: './non-admin-sidebar.component.html',
  styleUrls: ['./non-admin-sidebar.component.scss']
})
export class NonAdminSidebarComponent implements OnInit {
  nonAdminSidebarEnum = NonAdminSidebarEnum;

  constructor(
    private router: Router,
    private authService: AuthService,
    private shipmentHelpersService: ShipmentHelpersService,
    private quoteHelperService: CreateQuoteHelperService
  ) {}

  ngOnInit() {}

  isSelectedTab(tab: string): boolean {
    return this.router.url.includes(tab);
  }

  onChangeShipmentsListView(link: string, queryParams?: string): void {
    this.shipmentHelpersService.setLastSelectedShipmentsList(queryParams);
    this.shipmentHelpersService.clearShipmentsStoredFiltersInBrowser();

    this.navigateToPage(link, queryParams);
  }

  navigateToPage(link: string, queryParams: string): void {
    let indexOfQueryParam = this.router?.url?.indexOf('?');
    let url = this.router?.url;
    if (indexOfQueryParam > -1) {
      url = this.router?.url?.slice(0, indexOfQueryParam);
    }

    let navigation = this.router.navigate([link], {
      queryParams: {
        view: queryParams
      }
    });

    let isSameRoute = url === link;

    if (isSameRoute) {
      navigation.then(() => {
        window.location.reload();
      });
    }
  }

  onChangeQuotesListView(link: string, queryParams?: string): void {
    this.quoteHelperService.setLastSelectedQuotesList(queryParams);
    this.quoteHelperService.clearQuoteFilters();
    this.navigateToPage(link, queryParams);
  }

  get canCreateProduct(): boolean {
    return this.authService.canCreateProduct;
  }

  get canViewProductsList(): boolean {
    return this.authService.canViewProductsList;
  }

  get canCreateShipment(): boolean {
    return this.authService.canCreateShipment;
  }

  get canViewRegistrationListAccess(): boolean {
    return this.authService.canViewRegistrationListAccess;
  }

  get companyId(): string {
    return this.authService.currentUser.defaultCustomerCompany;
  }

  get canViewQuotes(): boolean {
    return this.authService.canViewQuotes;
  }

  get canCreateQuotes(): boolean {
    return this.authService.canCreateQuote;
  }

  get isMedicalOrPharmaceuticalIndustry(): boolean {
    return this.authService.isMedicalOrPharmaceuticalIndustry;
  }

  get canViewShipments(): boolean {
    return this.authService.canViewShipments;
  }

  get canViewShipmentsForSelectedCustomer(): boolean {
    return this.authService.canViewShipmentsForSelectedCustomer;
  }

  get currentShipmentRoute(): string {
    if (this.canViewShipmentsForSelectedCustomer) {
      return 'company/' + this.companyId + '/' + NonAdminSidebarEnum.Shipments;
    } else if (this.canViewShipments) {
      return '/' + NonAdminSidebarEnum.Shipments;
    }
  }

  get canManageForm4Requests(): boolean {
    return this.authService.canManageForm4Requests;
  }

  get canViewReports(): boolean {
    return this.authService.canViewReports;
  }

  get canManageShipmentsOperationalOversight(): boolean {
    return this.authService.canManageShipmentsOperationalOversight;
  }

  get canManageAllQuotesOperationalOversight(): boolean {
    return this.authService.canManageAllQuotesOperationalOversight;
  }

  get canCreateProductRegistration(): boolean {
    return this.authService.canCreateProductRegistration;
  }

  get isDev(): boolean {
    return isDevMode();
  }
}
