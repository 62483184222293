<div
  class="pending-actions-container"
  *ngIf="commentsForCurrentProgress?.length > 0"
>
  <div class="title" [ngClass]="{ 'title-in-dashboard': isInDashboard }">
    Pending Action
  </div>
  <div
    class="activity-log column row px-3"
    *ngFor="let action of commentsForCurrentProgress"
  >
    <div
      class="action-content-container px-0"
      [ngClass]="{
        'col-6': !isInDashboard,
        'col-12': isInDashboard
      }"
    >
      {{ action?.content }}
      <a (click)="onViewOnChat(action?.id)" class="ml-2 view-in-chat"
        >View in chat</a
      >
    </div>
    <div class="action-date col-3" *ngIf="!isInDashboard">
      <span
        >{{ action?.createdAt | fromutc | date: "dd/MM/YYYY hh:mm a" }}
      </span>
    </div>

    <div
      class="col-3 px-0"
      *ngIf="action?.isPendingAction == true && !isInDashboard"
    >
      <span class="pending-action-title">Pending</span>

      <div
        *ngIf="canSendPendingActionToShipment"
        class="fulfill-action-request float-right"
        (click)="markCommentAsFulfilled(action)"
      >
        <svg-icon name="small-white-check"></svg-icon>
      </div>
    </div>

    <div class="col-3 px-0" *ngIf="action?.isPendingAction == false">
      <app-comment-pending-action-fulfill-information
        [fulfillActor]="action?.fulfillActor"
        [fulfillDate]="action?.fulFillDate"
        [displayedText]="'Fulfilled'"
      ></app-comment-pending-action-fulfill-information>
    </div>
  </div>
</div>
