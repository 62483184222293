import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromutc'
})
export class FromUTCToLocalPipe implements PipeTransform {
  transform(value: Date): any {
    if (value) {
      let utcString1 = value.toString().split('T')[0];
      let utcString2 = value.toString().split('T')[1];
      let utcString = utcString1 + ' ' + utcString2 + ' UTC';

      let date = new Date(utcString);

      return date;
    }
  }
}
