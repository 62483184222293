<div class="row p-3">
  <div class="col-lg-3 col-md-1 col-sm-1"></div>
  <div class="col-lg-6 col-md-10 col-sm-10 Card pb-1">
    <form [formGroup]="activityForm" id="activityForm" (ngSubmit)="save()">
      <h4>
        {{
          !isNewApprovalActivity
            ? ("config.management.ApprovalActivity.Edit" | translate) +
              " " +
              activity?.name
            : ("config.management.ApprovalActivity.New" | translate)
        }}
      </h4>
      <div class="pl-3 row" *ngIf="diaplayedCategory">
        <svg-icon name="alertMark"></svg-icon>
        <div class="col-11 px-1">
          This activity affects the {{ diaplayedCategory }}, so the activity
          name must represents how it influences the workflow.
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <div *ngIf="isDuplicateActivity" class="error-message">
        <app-error-badge
          [thinBar]="true"
          message="{{
            'config.management.Activity.Editor.DuplicateEntry' | translate
          }}"
          class="warning-badge"
        ></app-error-badge>
      </div>

      <div class="row pb-4">
        <div class="col-12">
          <label for="app-input">Activity Name</label>
          <app-input
            formControlName="name"
            [placeholder]="'Add Activity Name'"
            [classList]="'field-bg'"
            [ngClass]="{
              error: isInvalidFormControl('name')
            }"
          ></app-input>
        </div>
      </div>

      <div class="row pb-4">
        <div class="col-12">
          <label for="linkedStatus">Linked Status</label>
          <ng-select
            formControlName="statusId"
            [ngClass]="{
              error: isInvalidFormControl('statusId')
            }"
            bindLabel="name"
            bindValue="id"
            [clearable]="false"
            placeholder="Select Status"
            [items]="lookups?.approvalStatuses"
          ></ng-select>
        </div>
      </div>

      <div class="row pb-4">
        <div class="col-12">
          <label for="linkedSystemStatus">Linked System Status</label>
          <ng-select
            formControlName="systemStatusId"
            [ngClass]="{
              error: isInvalidFormControl('systemStatusId')
            }"
            bindLabel="name"
            bindValue="id"
            [clearable]="false"
            [items]="lookups?.approvalSystemStatuses"
            placeholder="Select System Status"
          ></ng-select>
        </div>
      </div>

      <div class="row pb-4">
        <div class="col-12">
          <label for="linkedProgressPonint">Linked Progress Point</label>
          <ng-select
            formControlName="progressPointId"
            bindLabel="name"
            bindValue="id"
            [clearable]="false"
            [items]="lookups?.approvalProgressPoints"
            placeholder="Select Progress Point"
          ></ng-select>
        </div>
      </div>

      <div class="col-sm-12 form-group">
        <div ngPreserveWhitespaces class="float-right">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="isSaving || !activityForm.valid"
          >
            <i *ngIf="!isSaving" class="fa fa-save"></i>
            <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
            {{
              isSaving
                ? ("config.management.Activity.Editor.Saving" | translate)
                : ("config.management.Activity.Editor.Save" | translate)
            }}
          </button>
          <button (click)="cancel()" class="btn btn-danger">
            <i class="fa fa-times"></i>
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
