<div class="products-list-filter-container">
  <div class="row form-header">
    <div class="form-title">
      Filter By
    </div>
    <a class="clear-all cursor-pointer" (click)="clearFilters()">Clear All</a>
  </div>
  <form [formGroup]="filtersForm" (ngSubmit)="apply()" class="text-center">
    <div class="filter-list">
      <div
        *ngFor="
          let filterFormGroup of filtersFormArray?.controls;
          let index = index
        "
      >
        <mat-expansion-panel class="filter-container" #entityExpansionPanel>
          <mat-expansion-panel-header class="px-0 expansion-panel-header">
            <mat-panel-description
              class="mx-0 justify-content-between filter-container-header"
            >
              <div class="w-100">
                <span class="filter-title">
                  {{ listFilters[index]?.entityName | titlecase }}
                </span>
                <span
                  class="number-of-selections ml-4"
                  *ngIf="
                    getNumberofSelectedFilters(
                      listFilters[index]?.listItems,
                      listFilters[index]?.controlName
                    ) > 0
                  "
                >
                  {{
                    getNumberofSelectedFilters(
                      listFilters[index]?.listItems,
                      listFilters[index]?.controlName
                    )
                  }}
                  selection<span
                    *ngIf="
                      getNumberofSelectedFilters(
                        listFilters[index]?.listItems,
                        listFilters[index]?.controlName
                      ) > 1
                    "
                    >s</span
                  >
                </span>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <div
              class="filters-content"
              [formGroup]="filterFormGroup?.get(listFilters[index]?.controlName)"
            >
              <div
                class="form-group pr-3 pt-4 mb-0"
                *ngFor="let item of listFilters[index]?.listItems ;let i=index"
                [class]="
                  i == listFilters[index]?.listItems?.length - 1 ? 'pb-4' : ''
                "
              >
                <div class="row column-row">
                  <div class="pr-2 pl-3 checkbox-container">
                    <input
                      class="filter-checkbox"
                      [formControlName]="item"
                      type="checkbox"
                    />
                  </div>
                  <div
                    [for]="listFilters[index]?.entityName"
                    class="ml-1"
                    [placement]="
                      i >= listFilters[index]?.listItems?.length - 2
                        ? 'top-left'
                        : 'bottom-left'
                    "
                    [ngbPopover]="itemFilterContent"
                    triggers="mouseenter:mouseleave"
                  >
                    {{ item }}
                  </div>
                  <ng-template #itemFilterContent>
                    <div class="row pop-over">
                      {{ item }}
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
        <div
          [class]="entityExpansionPanel.expanded ? '' : 'line-separator m-auto'"
        ></div>
      </div>
    </div>

    <div class="apply-button-container">
      <button type="submit" class="btn btn-primary w-100 apply-filters">
        Apply
        <span *ngIf="totalNumberOfSelectedFilters > 0">
          ({{ totalNumberOfSelectedFilters }})
        </span>
      </button>
    </div>
  </form>
</div>
