import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators'; 
import { CurrencyService } from '../../admin-portal-configs/services/currency.service';
import { Currency } from '../models/currency.model';

@Injectable({
  providedIn: 'root'
})
export class CurrencyResolver implements Resolve<Currency[]> {
  constructor(
    private router: Router,
    private currencyService: CurrencyService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Currency[]> {
    return this.currencyService.getAllCurrency().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
