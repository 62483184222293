import { Component, Input, OnInit } from '@angular/core';
import { CategoryAndPermissions } from '../../../admin-portal-configs/models/category-and-permissions';
import { ParentPermissionCategory } from '../../../admin-portal-configs/models/parent-permission-category.model';
import { RoleAndPermissionsDTO } from '../../../admin-portal-configs/models/role-and-permissions-dto';

@Component({
  selector: 'app-collapsible-permission-category',
  templateUrl: './collapsible-permission-category.component.html',
  styleUrls: ['./collapsible-permission-category.component.scss']
})
export class CollapsiblePermissionCategoryComponent implements OnInit {
  @Input() title: string;
  @Input() items: ParentPermissionCategory;
  @Input() roleAndPermissions: RoleAndPermissionsDTO;
  isChecked: boolean;
  constructor() {}

  ngOnInit(): void {}

  changeParentValue(element: any) {
    var categoryPermission = this.items.categoryPermissions.find(
      x => x.permissionCategory.id == element.permissionCategory.id
    );

    if (categoryPermission.isSelected && !categoryPermission.selectionType) {
      categoryPermission.isSelected = !categoryPermission.isSelected;
      this.applyCheckActionToChild(categoryPermission, false);
    } else if (
      categoryPermission.isSelected &&
      categoryPermission.selectionType == 'dashmark'
    ) {
      categoryPermission.selectionType = '';
      this.applyCheckActionToChild(categoryPermission, true);
    } else {
      categoryPermission.isSelected = !categoryPermission.isSelected;
      categoryPermission.selectionType = '';
      this.applyCheckActionToChild(categoryPermission, true);
    }
  }

  changeChildValue(element: any, category?: any) {
    element.isSelected = !element.isSelected;
    this.applyCheckActionToParent(category);
  }

  private applyCheckActionToParent(category: any) {
    if (category.permissions.every(x => x.isSelected == true)) {
      category.isSelected = true;
      category.selectionType = '';
    } else if (category.permissions.some(x => x.isSelected == true)) {
      category.isSelected = true;
      category.selectionType = 'dashmark';
    } else {
      category.isSelected = false;
      category.selectionType = '';
    }
  }

  private applyCheckActionToChild(
    permissionCategory: CategoryAndPermissions,
    value: boolean
  ) {
    permissionCategory.permissions.forEach(x => {
      x.isSelected = value;
    });
  }

  getPlacementValue(index: number, isInLastCategory: boolean): string {
    if ((index / 4).toString().includes('.75')) {
      return 'left';
    } else if (isInLastCategory) {
      return 'top';
    }
    return 'bottom';
  }
}
