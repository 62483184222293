import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContainerType } from './container-type.enum';

@Component({
  selector: 'app-container-card',
  templateUrl: './container-card.component.html',
  styleUrls: ['./container-card.component.scss'],
})
export class ContainerCardComponent implements OnInit {
  @Input() set selected(value: boolean) {
    this._selected = value;
  }

  get selected(): boolean {
    return this._selected;
  }

  _selected = false;
  @Output() selectedChange = new EventEmitter<boolean>();
  @Input() type;
  @Input() isClickable = true;
  containers = {
    Container20: {
      title: '20’ Container',
      kgMax: '25,000 kg max',
      img: '20_container.PNG',
    },
    Container40: {
      title: '40’ HC Container',
      kgMax: '28,560 kg max',
      img: '40_container.PNG',
    },
    Container40hc: {
      title: '40’ Container',
      kgMax: '27,600 kg max',
      img: '40_hc_container.PNG',
    },
  };

  ngOnInit(): void {
  }

  emitSelect(): void {
    if (this.isClickable) {
      this.selected = !this.selected;
      this.selectedChange.emit(this.selected);
    }
  }
}
