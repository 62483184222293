export enum ComplianceInformationSummary {
  CategoryClassification = 'Category Classification',
  Entity = 'Entity',
  Sterility = 'Sterility',
  Regulations = 'Regulations',
  ClassificationType = 'Classification Type',
  CertificationType = 'Certification Type',
  RequiredRegistration = 'Registration Status',
  Tax = 'Import Tax (%)',
  VAT = 'VAT (%)',
  RequiredApprovals = 'Required Approvals',
  RequiredInspections = 'Required Inspections'
}
