<div class="term-conditiont-container" [formGroup]="form">
  <div class="row m-0 padding-between-row">
    <div
      class="field"
      [ngClass]="{ 'supplier-port-trucking-field': !isForCustomerCompany }"
    >
      <div class="text-label">
        Free Hours Loading <span *ngIf="isForCustomerCompany">(FHL)</span>
      </div>
      <input
        placeholder="0.00"
        formControlName="freeHoursLoading"
        class="input-field pl-3"
        [ngClass]="{
          error: isInvalidFormControl('freeHoursLoading'),
          'disabled-input': isForCustomerCompany
        }"
        appThousandSeparator
        maxLength="6"
        mask="0*.00*"
        [dropSpecialCharacters]="false"
      />
    </div>

    <div class="field">
      <div class="text-label">
        Free Hours Offloading <span *ngIf="isForCustomerCompany">(FHO)</span>
      </div>
      <input
        placeholder="0.00"
        formControlName="freeHoursOffloading"
        class="input-field pl-3"
        [ngClass]="{
          error: isInvalidFormControl('freeHoursOffloading'),
          'disabled-input': isForCustomerCompany
        }"
        appThousandSeparator
        maxLength="6"
        mask="0*.00*"
        [dropSpecialCharacters]="false"
      />
    </div>
  </div>

  <div class="row m-0 padding-between-row">
    <div
      class="field"
      [ngClass]="{ 'supplier-port-trucking-field': !isForCustomerCompany }"
    >
      <div class="text-label" *ngIf="isForCustomerCompany">
        Detention price after FH
      </div>
      <div class="text-label" *ngIf="!isForCustomerCompany">
        Detention price after Free Hours
      </div>
      <div class="combined-fields d-flex">
        <input
          class="rate pl-3"
          placeholder="0.00"
          formControlName="detentionPriceAfterFreeHours"
          maxLength="6"
          (keyup)="
            isForCustomerCompany ? calculateDetentionPriceDifference() : ''
          "
          mask="0*.00*"
          [dropSpecialCharacters]="false"
          [ngClass]="{
            error: isInvalidFormControl('detentionPriceAfterFreeHours')
          }"
        />

        <ng-select
          placeholder="EGP"
          [clearable]="false"
          class="drop-down-list enabled-ddl"
          [searchable]="false"
          formControlName="currencyCode"
          [items]="currencies"
          bindLabel="code"
          (change)="onChangeSelectedCurrency($event)"
          [readonly]="isForCustomerCompany"
          [ngClass]="{
            error: isInvalidFormControl('detentionPriceAfterFreeHours')
          }"
        >
        </ng-select>
      </div>
    </div>

    <div class="field padding-between-row" *ngIf="isForCustomerCompany">
      <div class="text-label">
        FreePL Detention price after FH
      </div>
      <div class="combined-fields d-flex">
        <input
          class="rate disabled-input pl-3"
          placeholder="0.00"
          formControlName="freePLDetentionPriceAfterFreeHours"
        />

        <ng-select
          placeholder="EGP"
          class="dropdown-list"
          [readonly]="true"
          bindLabel="code"
          formControlName="currencyCode"
        >
        </ng-select>
      </div>
    </div>

    <div class="smaller-field" *ngIf="isForCustomerCompany">
      <div class="text-label">
        Difference
      </div>
      <div class="input-field">
        <span class="percent-wrapper">
          <input
            class="rate disabled-input pl-3 input-field w-100"
            placeholder="0.00"
            type="text"
            formControlName="detentionPriceDifference"
        /></span>
      </div>
    </div>

    <div class="field">
      <div class="text-label">Extra destination Price</div>
      <div class="combined-fields d-flex">
        <input
          placeholder="0.00"
          class="rate pl-3"
          formControlName="extraDestinationPrice"
          class="rate"
          appThousandSeparator
          maxLength="6"
          (keyup)="
            isForCustomerCompany ? calculateDestinationPriceDifference() : ''
          "
          mask="0*.00*"
          [dropSpecialCharacters]="false"
          [ngClass]="{
            error: isInvalidFormControl('extraDestinationPrice')
          }"
        />

        <ng-select
          placeholder="EGP"
          class="drop-down-list"
          [clearable]="false"
          [items]="currencies"
          bindLabel="code"
          formControlName="currencyCode"
          (change)="onChangeSelectedCurrency($event)"
          [searchable]="false"
          [readonly]="isForCustomerCompany"
          [ngClass]="{
            error: isInvalidFormControl('extraDestinationPrice')
          }"
        >
        </ng-select>
      </div>
    </div>

    <div class="field" *ngIf="isForCustomerCompany">
      <div class="text-label">
        FreePL extra destination price
      </div>
      <div class="combined-fields d-flex">
        <input
          class="rate disabled-input pl-3"
          placeholder="0.00"
          formControlName="freePLExtraDestinationPrice"
        />

        <ng-select
          placeholder="EGP"
          class="dropdown-list-field"
          formControlName="currencyCode"
          bindLabel="code"
          [readonly]="true"
        >
        </ng-select>
      </div>
    </div>

    <div class="smaller-field" *ngIf="isForCustomerCompany">
      <div class="text-label">
        Difference
      </div>
      <div class="input-field">
        <span class="percent-wrapper">
          <input
            class="rate disabled-input pl-3 input-field w-100"
            placeholder="0.00"
            type="text"
            formControlName="destinationPriceDifference"
        /></span>
      </div>
    </div>
  </div>
</div>
