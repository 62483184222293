import {Injectable} from '@angular/core';
import {SvgIcon} from './svg-icons';

@Injectable({
  providedIn: 'root',
})
export class SvgIconsRegistery {
  private registery = new Map<string, string>();

  public registerIcons(icons: SvgIcon[]): void {
    icons.forEach((icon: SvgIcon) => this.registery.set(icon.name, icon.data));
  }

  public getIcon(iconName: string): string | undefined {
    if (!this.registery.has(iconName)) {
      console.warn(
        `We could not find the svg icon with the same name ${iconName}, did you add it to the icon registery?`
      );
    }
    return this.registery.get(iconName);
  }
}
