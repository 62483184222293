import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ListView } from '../models/list-view.model';
import { ShipmentView } from '../models/shipment-view.model';
import { ViewColumn } from '../models/view-column.model';
import { ApprovalView } from 'src/app/approvals/models/approval-view.model';

@Injectable({ providedIn: 'root' })
export class EmployeeViewPreferenceService {
  baseUrl = environment.apiUrl + 'EmployeeViewPreference';
  constructor(private http: HttpClient) {}

  getApprovalListViewPreference(employeeId: number): Observable<ApprovalView> {
    return this.http.get<ApprovalView>(
      this.baseUrl + `/Employee/${employeeId}/ApprovalView`
    );
  }

  updateApprovalListViewPreference(
    employeeId: number,
    listView: ListView
  ): Observable<ApprovalView> {
    return this.http.put<ApprovalView>(
      this.baseUrl + `/Employee/${employeeId}/ApprovalView`,
      listView
    );
  }

  getQuoteListViewPreference(employeeId: number): Observable<ListView> {
    return this.http.get<ListView>(
      this.baseUrl + `/QuoteListView/${employeeId}`
    );
  }

  UpdateQuoteViewListViewPreference(
    employeeId: number,
    listView: ListView
  ): Observable<ListView> {
    return this.http.put<ListView>(
      this.baseUrl + `/QuoteView/${employeeId}`,
      listView
    );
  }
  updateShipmentViewListViewPreference(
    employeeId: number,
    listView: ListView
  ): Observable<ShipmentView> {
    return this.http.put<ShipmentView>(
      `${this.baseUrl}/Employee/${employeeId}`,
      listView
    );
  }

  getShipmentView(employeeId: number): Observable<ShipmentView> {
    return this.http.get<ShipmentView>(
      `${this.baseUrl}/Employee/${employeeId}`
    );
  }

  updateShipmentSelectedColumn(
    employeeId: number,
    columnIds: number[]
  ): Observable<ViewColumn[]> {
    return this.http.put<ViewColumn[]>(
      `${this.baseUrl}/Employee/${employeeId}/SelectColumn`,
      columnIds
    );
  }

  getShipmentViewColumns(): Observable<ViewColumn[]> {
    return this.http.get<ViewColumn[]>(`${this.baseUrl}/ShipmentViewColumn`);
  }

  getShipmentSelectedColumns(employeeId: number): Observable<ViewColumn[]> {
    return this.http.get<ViewColumn[]>(
      `${this.baseUrl}/SelectedEmployee/${employeeId}/ShipmentViewColumn`
    );
  }
}
