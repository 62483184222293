<div class="summary-container">
  <div class="d-flex">
    <div class="section-title">Product Compliance</div>
    <div class="col-9 row">
      <div
        *ngFor="let key of getEnumKeysForCompliance()"
        class="product-data col-4"
      >
        <div class="product-title">
          {{
            isHealthCareOrPharmaceuticalsCategory
              ? getEnumValue(medicalComplianceInformationSummary, key)
              : getEnumValue(nonMedicalComplianceInformationSummary, key)
          }}
        </div>
        <div class="product-content">
          {{
            getComplianceInformationSummaryContent(
              isHealthCareOrPharmaceuticalsCategory
                ? getEnumValue(medicalComplianceInformationSummary, key)
                : getEnumValue(nonMedicalComplianceInformationSummary, key)
            ) || "-"
          }}
        </div>
      </div>
    </div>
  </div>
</div>
