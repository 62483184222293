<div class="row w-100">
  <div class="col-2 title">Shipment Products</div>
  <div class="col-10 product-details">
    <div
      class="row px-0 shipment-details-padding"
      *ngIf="isLandStandaloneService"
    >
      <div class="field-label col-3">
        Product Description
      </div>
      <div class="col-3 field-label">
        COO
      </div>
      <div class="col-5 field-label">
        HS code(s)
      </div>
    </div>
    <div class="row px-0 land-hs-code-value" *ngIf="isLandStandaloneService">
      <div class="field-value col-3">
        {{ quoteCargoProduct?.productDescription || "-" }}
      </div>
      <div class="col-3 field-value">
        {{ quoteCargoProduct?.originCountry || "-" }}
      </div>
      <div class="col-5 field-value land-standalone-hs-codes">
        <span *ngFor="let hsCode of hsCodes; let i = index">
          <span *ngIf="i > 0">, </span>
          {{ hsCode | hsCode }}
        </span>
        <span *ngIf="!hsCodes || hsCodes?.length == 0">-</span>
      </div>
    </div>
    <div class="row px-0 shipment-details-padding">
      <div
        class="field-label col-4 product-description"
        *ngIf="!isLandStandaloneService"
      >
        Product Description
      </div>
      <div class="col-2 field-label  ">
        Total Shipment Value
      </div>
      <div class="col-2 field-label">Dangerous Materials</div>
      <div class="col-2 field-label">Special Handling</div>
      <div class="col-2 field-label" *ngIf="isEnableCargoHandling">
        Cargo Handling
      </div>
    </div>

    <div class="row px-0 shipment-details-padding">
      <div
        class="col-4 field-value product-description"
        *ngIf="!isLandStandaloneService"
      >
        {{ quoteCargoProduct?.productDescription || "-" }}
      </div>
      <div class="col-2  field-value">
        {{ quoteCargoProduct?.totalShipmentValue }}
        {{ quoteCargoProduct?.currencyCode }}
      </div>
      <div class="col-2  field-value">
        {{ quoteCargoProduct?.hasDangerousGood ? "Yes" : "No" }}
      </div>
      <div class="col-2  field-value">
        {{ quoteCargoProduct?.isNeedSpecialHandling ? "Yes" : "No" }}
      </div>
      <div class="col-2  field-value" *ngIf="isEnableCargoHandling">
        {{
          cargoDetails?.isStackable
            ? cargoHandling.Stackable
            : cargoHandling.NonStackable
        }}
      </div>
    </div>
  </div>
</div>
