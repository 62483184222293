import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IndustryService } from '../../admin-portal-configs/services/industry.service';
import { Industry } from '../models/industry.model';

@Injectable({
  providedIn: 'root'
})
export class IndustryResolver implements Resolve<Industry> {
  id: number;
  constructor(
    private industryService: IndustryService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Industry> {
    this.id = JSON.parse(route.params.id) as number;
    return this.industryService.getById(this.id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
