import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OnlyDigitsDirective } from './only-digits.directive';

@NgModule({
  imports: [],
  declarations: [OnlyDigitsDirective],
  exports: [OnlyDigitsDirective],
})
export class OnlyDigitModule {}
