<div class="header-margin">
  <app-page-header
    name="card-deleteProductDocumentHeader"
    [title]="'Delete Document'"
  ></app-page-header>
</div>

<div class="row mx-0 delete-linked-document-container">
  <div class="col-7 pl-0">
    <div [formGroup]="form">
      <div class="field-label" name="lbl-documentType">
        Document Type
      </div>
      <ng-select
        [items]="[selectedDocumentType]"
        bindValue="id"
        bindLabel="name"
        formControlName="documentTypeId"
        class="document-type-field ddl-field"
        readonly="true"
        name="ddl-documentType"
      ></ng-select>
      <div class="warning-message" name="txt-warningMessage">
        {{
          parentDocumentSqlId != null
            ? "This document version will be permanently deleted for all selected products."
            : "You are about to delete this document. Instead, you can replace it with
        another version if needed."
        }}
      </div>
      <div class="document-removal-note-title" name="lbl-deletionReasonLabel">
        Why would you like to delete this document?
        <span class="required-symbol">*</span>
      </div>
      <textarea
        formControlName="notes"
        type="text"
        placeholder="Add note"
        class="deletion-reason w-100"
        maxlength="180"
        [ngClass]="{ error: isInvalidFormControl('notes') }"
        name="txa-deletionReason"
      >
      </textarea>
    </div>
  </div>
  <div class="col-5">
    <div class="bulk-delete-container h-100">
      <app-documents-bulk-product-list
        [title]="'Bulk Delete'"
        [subTitle]="'Select Products to apply this deletion'"
        [parentId]="parentId"
        [documentPath]="documentPath"
        (onSelectingProducts)="onSelectingProducts($event)"
        [isOlderVersion]="parentDocumentSqlId != null"
        name="card-productsList"
      ></app-documents-bulk-product-list>
    </div>
  </div>
</div>

<div class="row mx-0 buttons-container">
  <button
    name="btn-cancel"
    class="cancel-button col-3 ml-auto"
    (click)="closeDialog()"
  >
    Cancel
  </button>
  <span class="mx-2"></span>
  <button
    name="btn-delete"
    class="delete-button col-3 border-0"
    (click)="delete()"
    [disabled]="form.invalid || isDeleting"
  >
    Delete
  </button>
</div>
