import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dangerous-good-class',
  templateUrl: './dangerous-good-class.component.html',
  styleUrls: ['./dangerous-good-class.component.scss']
})
export class DangerousGoodClassComponent implements OnInit {
@Input() classList ;
@Input() dangerousGoodClass;
@Input() index ;

  constructor() { }

  ngOnInit(): void {
  }

}
