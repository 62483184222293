import { Component, Input, OnInit } from '@angular/core';
import { TruckingPricelistTermsAndConditions } from '../../../supplier/models/trucking-pricelist-termsandconditions.model';
import { CustomerTruckingRouteDto } from 'src/app/customer/models/customer-trucking-route-dto.model';
import { ModuleName } from '../../models/enums/module-name.enum';
import { CustomerServicesHelperService } from 'src/app/customer/services/customer-services-helper.service';

@Component({
  selector: 'app-trucking-terms-and-conditions-summary',
  templateUrl: './trucking-terms-and-conditions-summary.component.html',
  styleUrls: ['./trucking-terms-and-conditions-summary.component.scss']
})
export class TruckingTermsAndConditionsSummaryComponent implements OnInit {
  @Input() termsAndConditions?: TruckingPricelistTermsAndConditions;
  @Input() customerTruckingRoute?: CustomerTruckingRouteDto;
  @Input() moduleName?: string;
  module = ModuleName;

  constructor(
    private customerServicesHelperService: CustomerServicesHelperService
  ) {}

  ngOnInit(): void {}

  get freeHoursLoading(): number {
    return this.customerTruckingRoute?.freeHoursLoading;
  }
  get freeHoursOffLoading(): number {
    return this.customerTruckingRoute?.freeHoursOffLoading;
  }
  get detentionPriceAfterFreeHours(): number {
    return this.customerTruckingRoute?.detentionPriceAfterFreeHours;
  }
  get currencyCode(): string {
    return this.customerTruckingRoute?.currency?.code;
  }
  get extraDestinationPrice(): number {
    return this.customerTruckingRoute?.extraDestinationPrice;
  }
  get freePLDetentionPriceAfterFreeHours(): number {
    return this.customerTruckingRoute?.freePLDetentionPriceAfterFreeHours;
  }
  get freePLExtraDestinationPrice(): number {
    return this.customerTruckingRoute?.freePLExtraDestinationPrice;
  }

  calculateDifference(numberA: number, numberB: number): string {
    return this.customerServicesHelperService.calculatePercentageDifference(
      numberA,
      numberB
    );
  }
}
