<div class="container-fluid">
  <div class="row mx-0 header-container">
    <app-page-header [title]="companyTabs.Details"></app-page-header>
    <div
      class="status px-5"
      [ngClass]="company.isActive == true ? 'active-status' : 'inactive-status'"
    >
      {{ company.isActive ? "Active" : "Inactive" }}
    </div>
    <button
      type="submit"
      class="btn edit-btn ml-auto"
      [routerLink]="
        isCustomerModule
          ? ['/customer', companyId, 'edit']
          : ['/company/edit', companyId]
      "
    >
      Edit
    </button>
  </div>

  <div class="row logo-container">
    <div class="col-lg-12 col-md-12 col-sm-12 px-0">
      <span *ngIf="!company.logo" class="company-logo no-picture">
        <div class="no-logo">Logo</div>
      </span>
      <div *ngIf="company.logo" class="company-logo uploded-logo">
        <div><img [src]="company.logo" /></div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="text-style label">Name</div>
      <div
        class="text-style value"
        [ngbPopover]="company?.name"
        placement="bottom-left"
        triggers="mouseenter:mouseleave"
        [disablePopover]="company?.name?.length < 18"
      >
        {{ company?.name | slice: 0:18 || "-"
        }}<span *ngIf="company?.name?.length >= 18">...</span>
      </div>
    </div>
    <div class="col-4">
      <div class="text-style label">
        Official Name
      </div>
      <div
        class="text-style value"
        [ngbPopover]="company?.companyOfficialName"
        placement="bottom-left"
        triggers="mouseenter:mouseleave"
        [disablePopover]="company?.companyOfficialName?.length < 18"
      >
        {{ company?.companyOfficialName | slice: 0:18 || "-" }}
        <span *ngIf="company?.companyOfficialName?.length >= 18">...</span>
      </div>
    </div>
    <div class="col-4">
      <div class="text-style label">Registration No.</div>
      <div class="text-style value">
        {{ company?.companyRegistrationNo || "-" }}
      </div>
    </div>
  </div>

  <div class="row row-margin">
    <div class="col-4">
      <div class="text-style label">VAT No.</div>
      <div
        class="text-style value"
        [ngbPopover]="company?.vatNumber"
        placement="bottom-left"
        triggers="mouseenter:mouseleave"
        [disablePopover]="company?.vatNumber?.length < 18"
      >
        {{ company?.vatNumber ? (company?.vatNumber | slice: 0:18) : "-" }}
        <span *ngIf="company?.vatNumber?.length >= 18">...</span>
      </div>
    </div>
    <div class="col-4">
      <div class="text-style label">Phone</div>
      <div class="text-style value">
        {{ company?.phoneNumber || "-" }}
      </div>
    </div>
    <div class="col-4">
      <div class="text-style label">Email</div>
      <div class="text-style value">
        {{ company?.email || "-" }}
      </div>
    </div>
  </div>

  <div class="row row-margin">
    <div class="col-12">
      <div class="text-style label">Address</div>
      <div class="text-style value">{{ company?.address || "-" }}</div>
    </div>
  </div>

  <div class="row row-margin">
    <div class="col-4">
      <div class="text-style label">Country</div>
      <div class="text-style value">
        {{ company?.city?.country?.name || "-" }}
      </div>
    </div>
    <div class="col-4">
      <div class="text-style label">City</div>
      <div class="text-style value">
        {{ company?.city?.name || "-" }}
      </div>
    </div>
    <div class="col-4">
      <div class="text-style label">Website</div>
      <div class="text-style value">
        {{ company?.website || "-" }}
      </div>
    </div>
  </div>

  <div class="row row-margin">
    <div class="col-12">
      <div class="text-style label">Industry</div>
      <div class="text-style value">{{ company?.industry?.name || "-" }}</div>
    </div>
  </div>
</div>
