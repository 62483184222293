import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountEndpoint } from 'src/app/shared/services/account-endpoint.service';
import { RoleAndPermissionsDTO } from '../models/role-and-permissions-dto';

@Injectable({
  providedIn: 'root'
})
export class RoleResolver implements Resolve<RoleAndPermissionsDTO> {
  constructor(private AccountEndpoint: AccountEndpoint) {}

  resolve(route: ActivatedRouteSnapshot): Observable<RoleAndPermissionsDTO> {
    const id = route.params.id;
    return this.AccountEndpoint.getRoleAndPermissionsByIdEndpoint(id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
