<div class="section-details">
  <div *ngIf="hasDomesticService" class="domestic-section-padding">
    <div class="row w-100">
      <div class="col-2 title">Cargo Details</div>
      <div class="col-10 product-details">
        <div class="row px-0 shipment-details-padding">
          <div class="col-2 field-label  ">
            Cargo Type
          </div>
          <div class="col-2 field-label">
            Cargo Handling
          </div>
        </div>

        <div class="row px-0 hs-code-value">
          <div class="col-4 field-value  ">
            <span>
              {{ quote.cargoDetails.cargoType }}
            </span>
          </div>
          <div class="col-4 field-value">
            <span>
              {{
                quote.cargoDetails?.isStackable
                  ? cargoHandling.Stackable
                  : cargoHandling.NonStackable
              }}
            </span>
          </div>
        </div>
      </div>
    </div> 
  </div>

  <div *ngIf="isDisplayPackagePalletSection">
    <app-package-pallet-details [quote]="quote"></app-package-pallet-details>
    <div class="row divider-section">
      <div class="divider"></div>
    </div>
  </div>

  <div *ngIf="isDisplayOceanFreight">
    <app-ocean-freight-details [quote]="quote"> </app-ocean-freight-details>
    <div class="row divider-section">
      <div class="divider"></div>
    </div>
  </div>

  <div *ngIf="isDisplayTruckingSection">
    <app-summary-trucking-section [quote]="quote">
    </app-summary-trucking-section>
    <div class="row divider-section">
      <div class="divider"></div>
    </div>
  </div>

  <app-quote-product-details
    [cargoDetails]="quote?.cargoDetails"
    [isLandStandaloneService]="isLandStandaloneService"
  ></app-quote-product-details>
</div>
