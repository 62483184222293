export const imageExtensions: string[] = [
  'jpeg',
  'jpg',
  'png',
  'gif',
  'bmp',
  'tiff',
  'svg',
  'raw',
  'webp',
  'heic',
  'heif',
  'psd'
];
