import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(private dialog: MatDialog) {}
  private dialogRef: MatDialogRef<any>;

  open(
    component: any,
    data?: any,
    width?: string,
    height?: string,
    panelClass?: string[],
    disableClose?: boolean,
    position?: any,
    backdropClass?: string,
    enablePageScrolling?: boolean
  ): MatDialogRef<any> {
    this.dialogRef = this.dialog.open(component, {
      hasBackdrop: true,
      width: width ?? 'auto',
      maxHeight: 'fit-content',
      maxWidth: '90vw',
      data: data,
      panelClass: panelClass,
      disableClose: disableClose,
      position: position,
      backdropClass: backdropClass
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.close();
    });
    if (!enablePageScrolling) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';

      const overlayDialogWrapper = document.querySelector(
        '.cdk-global-overlay-wrapper'
      );
      overlayDialogWrapper?.classList.add('options-list-overlay');

      const overlayContainer = document.querySelector('.cdk-overlay-container');
      overlayContainer?.classList.add('options-container-overlay');
    }
    return this.dialogRef;
  }

  close(data?: any): void {
    const overlayDialogWrapper = document.querySelector(
      '.cdk-global-overlay-wrapper'
    );
    overlayDialogWrapper?.classList.remove('options-list-overlay');

    const overlayContainer = document.querySelector('.cdk-overlay-container');
    overlayContainer?.classList.remove('options-container-overlay');

    document.body.style.overflow = 'auto';
    this.dialogRef.close(data);
  }

  closeAll(): void {
    this.dialog.closeAll();
  }
}
