import { Component, Input, OnInit } from '@angular/core';
import { ShipmentsService } from '../../../shipments/shipments.service';
import { LeadTimeLog } from 'src/app/shipments/models/lead-time-log.model';
import { ProductRegistrationLeadTimeLog } from 'src/app/product-registration/models/product-registration-lead-time-log.model';
import { ProductRegistrationService } from 'src/app/product-registration/services/product-registration.service';
import { ApprovalLeadTimeLog } from 'src/app/approvals/models/approval-lead-time-logs.model';
import { ApprovalService } from 'src/app/create-approval/services/approval.service';

@Component({
  selector: 'app-lead-times',
  templateUrl: './lead-times.component.html',
  styleUrls: ['./lead-times.component.scss']
})
export class LeadTimesComponent implements OnInit {
  @Input() shipmentLeadTimes: LeadTimeLog[];
  @Input() approvalLeadTimes: ApprovalLeadTimeLog[];
  @Input() productRegistrationLeadTimes: ProductRegistrationLeadTimeLog[];
  showInput: boolean[] = [];
  insertedNotes: string[] = [];
  leadTimeLogNoteLimit = 20;

  constructor(
    private shipmentsServcie: ShipmentsService,
    private approvalService: ApprovalService,
    private productRegistrationService: ProductRegistrationService
  ) {}

  ngOnInit() {
    this.initNotes();
  }

  toggleShowInput(inputIndex: number) {
    this.showInput[inputIndex] = !this.showInput[inputIndex];
  }

  initNotes() {
    for (let i = 0; i < this.leadTimes?.length; i++) {
      this.insertedNotes[i] = this.leadTimes[i]?.notes;
    }
  }

  onNotesChange(leadTimeIndex: number) {
    let leadTimeId = this.leadTimes[leadTimeIndex]?.id;
    if (this.shipmentLeadTimes?.length > 0) {
      this.shipmentsServcie
        .updateLeadTimeNotes(leadTimeId, this.insertedNotes[leadTimeIndex])
        .subscribe(data => {
          this.toggleShowInput(leadTimeIndex);
        });

      this.shipmentsServcie.leadTimeNotesUpdated.emit({
        leadTimeId: leadTimeId,
        leadTimeNotes: this.insertedNotes[leadTimeIndex]
      });
    } else if (this.productRegistrationLeadTimes?.length > 0) {
      this.productRegistrationService
        .updateProductLeadTimeLogs(
          leadTimeId,
          this.insertedNotes[leadTimeIndex]
        )
        .subscribe(data => {
          this.toggleShowInput(leadTimeIndex);
        });
      this.productRegistrationService.productLeadTimeNotesUpdated.emit({
        leadTimeId: leadTimeId,
        leadTimeNotes: this.insertedNotes[leadTimeIndex]
      });
    } else if (this.approvalLeadTimes?.length > 0) {
      this.approvalService
        .updateLeadTimeNotes(leadTimeId, this.insertedNotes[leadTimeIndex])
        .subscribe(data => {
          this.toggleShowInput(leadTimeIndex);
        });
      this.approvalService.approvalLeadTimeNotesUpdated.emit({
        leadTimeId: leadTimeId,
        leadTimeNotes: this.insertedNotes[leadTimeIndex]
      });
    }
  }

  get leadTimes():
    | LeadTimeLog[]
    | ProductRegistrationLeadTimeLog[]
    | ApprovalLeadTimeLog[] {
    if (this.shipmentLeadTimes?.length > 0) {
      return this.shipmentLeadTimes;
    } else if (this.productRegistrationLeadTimes?.length > 0) {
      return this.productRegistrationLeadTimes;
    } else if (this.approvalLeadTimes?.length > 0) {
      return this.approvalLeadTimes;
    }

    return [];
  }
}
