import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductRegistration } from 'src/app/create-product-registration/models/product-registration.model';
import { Paginated } from 'src/app/shared/models/shipment/paginated.model';
import { StatusChangeRequest } from 'src/app/shared/models/status-change-request.model';
import { environment } from 'src/environments/environment';
import { ProductRegistrationDetails } from '../models/product-registration-details.model';
import { ProductRegistrationLeadTimeLog } from '../models/product-registration-lead-time-log.model';
import { ProductRegistrationExportExcelViewModel } from '../models/product-registration-export-excel-view-model.model';
import { ProductRegistrationSearchCriteria } from '../models/product-registration-search-criteria.model';
import { LicenseNumberSearchCriteria } from '../models/license-number-search-criteria.model';

@Injectable({
  providedIn: 'root'
})
export class ProductRegistrationService {
  baseURL = environment.apiUrl + 'ProductRegistration';
  @Output() productRegistrationRequestUpdated = new EventEmitter();
  @Output() productLeadTimeNotesUpdated: EventEmitter<{
    leadTimeId: number;
    leadTimeNotes: string;
  }> = new EventEmitter<{ leadTimeId: number; leadTimeNotes: string }>();
  @Output() productRegistrationSearch: EventEmitter<
    ProductRegistrationSearchCriteria
  > = new EventEmitter<ProductRegistrationSearchCriteria>();
  private listOfProductsSelectedNumbers: string[] = [];
  @Output() listOfProductsSelectedNumbersChanged = new EventEmitter();
  @Output() onListScroll = new EventEmitter();
  @Output() onProductRegistrationFilesUpdated = new EventEmitter();

  constructor(private http: HttpClient) {}

  getProductRegistrationDetails(
    productId: string
  ): Observable<ProductRegistrationDetails> {
    return this.http.get<ProductRegistrationDetails>(
      `${this.baseURL}/Details/${productId}`
    );
  }

  updateProductRegistrationActivity(
    productId: string,
    activityId: number,
    acceptanceNumber?: string,
    registrationLicenseNumber?: string
  ): Observable<ProductRegistration> {
    let url = `${this.baseURL}/${productId}/UpdateActivity/${activityId}`;

    if (acceptanceNumber) {
      url += `?acceptanceNumber=${acceptanceNumber}`;
    }
    if (registrationLicenseNumber) {
      if (this.isUrlAlreadyHaveQueryString(url)) {
        url += `&registrationLicenseNumber=${registrationLicenseNumber}`;
      } else {
        url += `?registrationLicenseNumber=${registrationLicenseNumber}`;
      }
    }

    return this.http.put<ProductRegistration>(url, null);
  }

  private isUrlAlreadyHaveQueryString(url: string) {
    return url.includes('?');
  }

  getProducts(
    productSearchCriteria: ProductRegistrationSearchCriteria,
    companyId: string
  ): Observable<Paginated> {
    return this.http.post<Paginated>(
      this.baseURL + `/Company/${companyId}`,
      productSearchCriteria
    );
  }

  cancelProductRegistration(
    statusChangeRequest: StatusChangeRequest
  ): Observable<ProductRegistration> {
    return this.http.put<ProductRegistration>(
      this.baseURL + `/Cancel`,
      statusChangeRequest
    );
  }

  pauseProductRegistration(
    statusChangeRequest: StatusChangeRequest
  ): Observable<ProductRegistration> {
    return this.http.put<ProductRegistration>(
      this.baseURL + `/Pause`,
      statusChangeRequest
    );
  }

  resumeProductRegistration(
    productId: string
  ): Observable<ProductRegistration> {
    return this.http.put<ProductRegistration>(
      this.baseURL + `/Resume?productId=${productId}`,
      null
    );
  }

  deleteOldDocument(
    productRegistrationId: string,
    documentSqlId: number,
    deletionReason: string,
    documentTypeName: string
  ): Observable<ProductRegistration> {
    return this.http.delete<ProductRegistration>(
      this.baseURL +
        `/DeleteOldDocument?productRegistrationId=${productRegistrationId}&documentSqlId=${documentSqlId}&deletionReason=${deletionReason}&documentTypeName=${documentTypeName}`
    );
  }

  updateProductLeadTimeLogs(
    id: number,
    notes?: string
  ): Observable<ProductRegistrationLeadTimeLog> {
    return this.http.put<ProductRegistrationLeadTimeLog>(
      this.baseURL + `/ProductLeadTimeLog/${id}/Notes?notes=${notes}`,
      null
    );
  }

  exportProductRegistrationToExcel(
    productSearchCriteria: ProductRegistrationSearchCriteria,
    isAllSelected: boolean
  ): Observable<any> {
    const excelViewModel: ProductRegistrationExportExcelViewModel = {
      listOfProductsSelectedNumbers: this.listOfProductsSelectedNumbers,
      searchCriteria: productSearchCriteria,
      isAllSelected: isAllSelected
    };
    return this.http.post(this.baseURL + `/ExportExcel`, excelViewModel, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  updateProductNotes(
    productRegistrationId: string,
    productId: string,
    notes: string
  ): Observable<ProductRegistration> {
    return this.http.put<ProductRegistration>(
      this.baseURL +
        `/${productRegistrationId}/ProductNotes/${productId}?notes=${notes}`,
      null
    );
  }

  addToListOfProductSelectedNumber(productRegistrationNumber: string): void {
    this.listOfProductsSelectedNumbers.push(productRegistrationNumber);
  }

  getAllListOfProductsNumber(): string[] {
    return this.listOfProductsSelectedNumbers;
  }

  addToListOfSelectedProducts(productRegistrationNumber: string): void {
    this.addToListOfProductSelectedNumber(productRegistrationNumber);
    this.listOfProductsSelectedNumbersChanged.emit(
      this.getAllListOfProductsNumber()
    );
  }
  removeFromListOfSelectedProducts(productRegistrationNumber: string): void {
    this.listOfProductsSelectedNumbers = this.getAllListOfProductsNumber().filter(
      item => {
        return item != productRegistrationNumber;
      }
    );
    this.listOfProductsSelectedNumbersChanged.emit(
      this.listOfProductsSelectedNumbers
    );
  }

  removeAllFromListOfSelectedProducts(): void {
    this.listOfProductsSelectedNumbers = [];
    this.listOfProductsSelectedNumbersChanged.emit(
      this.listOfProductsSelectedNumbers
    );
  }

  isProductInSelectedProductsList(productRegistrationNumber: string): boolean {
    return this.getAllListOfProductsNumber().includes(
      productRegistrationNumber
    );
  }

  getListOfProductsSelectedNumberCount(): number {
    return this.getAllListOfProductsNumber().length;
  }

  deleteProduct(
    productRegistrationId: string,
    productId: string
  ): Observable<ProductRegistration> {
    return this.http.delete<ProductRegistration>(
      `${this.baseURL}/${productRegistrationId}/DeleteProduct/${productId}`
    );
  }

  getProductsLicenseNumber(
    searchCriteria: LicenseNumberSearchCriteria
  ): Observable<Paginated> {
    return this.http.post<Paginated>(
      `${this.baseURL}/LicenseNumber`,
      searchCriteria
    );
  }
}
