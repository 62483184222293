import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProductColumn } from '../models/product-column.model';

@Injectable({ providedIn: 'root' })
export class ProductColumnsService {
  baseUrl = environment.apiUrl + 'ProductColumns';
  @Output() selectedColumnsChanged = new EventEmitter<ProductColumn[]>();

  constructor(private http: HttpClient) {}

  getColumns(
    isMedicalOrPharmaceuticalIndustry: boolean
  ): Observable<ProductColumn[]> {
    return this.http.get<ProductColumn[]>(
      this.baseUrl +
        `/Columns/isMedicalOrPharmaceuticalIndustry/${isMedicalOrPharmaceuticalIndustry}`
    );
  }

  getEmployeeSelectedColumns(
    isMedicalOrPharmaceuticalIndustry: boolean
  ): Observable<ProductColumn[]> {
    return this.http.get<ProductColumn[]>(
      this.baseUrl +
        `/SelectedColumn/isMedicalOrPharmaceuticalIndustry/${isMedicalOrPharmaceuticalIndustry}`
    );
  }

  updateEmployeeSelectedColumns(
    isMedicalOrPharmaceuticalIndustry: boolean,
    productColumnsNames: string[]
  ): Observable<ProductColumn[]> {
    return this.http.put<ProductColumn[]>(
      this.baseUrl +
        `/SelectedColumn/isMedicalOrPharmaceuticalIndustry/${isMedicalOrPharmaceuticalIndustry}`,
      productColumnsNames
    );
  }

  updateEmployeeColumnOrders( 
    productColumns: ProductColumn[]
  ): Observable<ProductColumn[]> {
    return this.http.put<ProductColumn[]>(
      this.baseUrl +
        `/order`,
      productColumns
    );
  }
}
