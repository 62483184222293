<div class="role-container">
  <div class="d-flex justify-content-between">
    <app-page-header
      [title]="
        (isEditRoute
          ? 'roles.management.EditRoleOnly'
          : 'roles.management.AddRole'
        ) | translate
      "
    ></app-page-header>
  </div>
  <div class="mt-4">
    <app-error-badge
      [message]="errorMesage"
      *ngIf="isFailedToSave"
    ></app-error-badge>
  </div>
  <form [formGroup]="permissionForm" (ngSubmit)="save()">
    <div class="role-info-container mt-5 ml-3">
      <div class="row">
        <div class="col-2 pop-over-label p-0 header role-info">ROLE INFO</div>
        <div class="col-3 pop-over-field p-0">
          <input
            formControlName="name"
            class="role-name-input"
            placeholder="Role Name"
            id="name"
            type="text"
            [ngClass]="{
              error: isInvalidFormControl('name')
            }"
          />
        </div>
        <div class="col-3 pop-over-field p-0">
          <ng-select
            [items]="permissionGroups"
            formControlName="permissionGroup"
            placeholder="Permission Group"
            [clearable]="false"
            [closeOnSelect]="false"
            [searchable]="false"
            [ngClass]="{
              error: isInvalidFormControl('permissionGroup')
            }"
            (change)="filterPermissionCategoriesByPermissionGroup()"
          >
            <ng-template
              ng-option-tmp
              let-item="item"
              let-item$="item$"
              let-index="index"
            >
              <div class="d-flex align-items-center" (click)="selectAllTab()">
                <mat-radio-button
                  id="item-{{ index }}"
                  [checked]="item$.selected"
                ></mat-radio-button>
                <div class="title d-flex align-items-center">
                  {{ item }}
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row mt-4 p-0">
        <div class="col-2 pop-over-label p-0"></div>
        <div class="col-6 pop-over-label mb-4 p-0 role-description-field">
          <input
            formControlName="description"
            class="role-description-input"
            placeholder="Role Description"
            id="description"
            type="text"
            [ngClass]="{
              error: isInvalidFormControl('description')
            }"
          />
        </div>
      </div>
    </div>

    <div class="tabs-container">
      <mat-tab-group
        animationDuration="0ms"
        [(selectedIndex)]="selectedTabIndex"
      >
        <mat-tab label="All">
          <div
            class="colalpsabile-container"
            *ngFor="
              let parentPermissionCategory of parentPermissionCategories;
              let i = index
            "
          >
            <app-collapsible-permission-category
              [title]="parentPermissionCategory?.name"
              [items]="parentPermissionCategory"
              [roleAndPermissions]="roleAndPermissions"
            >
            </app-collapsible-permission-category>
          </div>
        </mat-tab>
        <mat-tab
          [label]="parentPermissionCategory?.name"
          *ngFor="
            let parentPermissionCategory of parentPermissionCategories;
            let i = index
          "
        >
          <div class="colalpsabile-container">
            <app-collapsible-permission-category
              [title]="parentPermissionCategory?.name"
              [items]="parentPermissionCategory"
              [roleAndPermissions]="roleAndPermissions"
            >
            </app-collapsible-permission-category>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="mt-2 mb-2 d-flex justify-content-end">
      <button class="cancel-btn" [routerLink]="['/settings']" fragment="roles">
        <span>Cancel</span>
      </button>

      <button class="save-edit-btn">
        <span>Save</span>
      </button>
    </div>
  </form>
</div>
