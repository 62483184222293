import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/company/models/company.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyTabs } from 'src/app/company2/enum/company-tabs.enum';
import { Company2Service } from 'src/app/company2/services/company2.service';

@Component({
  selector: 'app-company-profile-details',
  templateUrl: './company-profile-details.component.html',
  styleUrls: ['./company-profile-details.component.scss']
})
export class CompanyProfileDetailsComponent implements OnInit {
  companyTabs = CompanyTabs;
  company: Company;

  constructor(
    private company2Service: Company2Service,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.company = data['company'];
      this.company2Service.company = this.company;
    });
  }

  get companyId(): number {
    return this.company?.id;
  }

  get isCustomerModule(): boolean {
    return this.router.url.includes('customer');
  }
}
