<div class="title-position">
  <app-page-header [title]="'My Profile'"></app-page-header>
</div>
<div class="page-container">
  <div class="profile-picture-section">
    <app-section-with-header
      [icon]="profilePictureSVG"
      [title]="'PHOTO'"
      [body]="profilePictureBody"
      [titleColor]="'#ff7f27'"
    ></app-section-with-header>
    <ng-template #profilePictureBody>
      <app-change-profile-picture
        class="profile-picture-body"
      ></app-change-profile-picture>
    </ng-template>
    <ng-template #profilePictureSVG>
      <div>
        <svg-icon name="profile-picture"></svg-icon>
      </div>
    </ng-template>
  </div>
  <br />
  <app-section-with-header
    [icon]="profileInfoSVG"
    [title]="'PERSONAL INFO'"
    [body]="profileInfoBody"
    [titleColor]="'#ff7f27'"
  ></app-section-with-header>
  <ng-template #profileInfoBody>
    <app-employee-profile-info
      class="w-100"
      [actorDetails]="actorDetails"
    ></app-employee-profile-info>
  </ng-template>
  <ng-template #profileInfoSVG>
    <div>
      <svg-icon name="personal-info"></svg-icon>
    </div>
  </ng-template>
  <br />
  <app-section-with-header
    [icon]="passwordSVG"
    [title]="'PASSWORD'"
    [body]="changePasswordBody"
    [titleColor]="'#ff7f27'"
  ></app-section-with-header>
  <ng-template #changePasswordBody>
    <app-change-password
      class="w-100"
      [actorDetails]="actorDetails"
    ></app-change-password>
  </ng-template>
  <ng-template #passwordSVG>
    <div>
      <svg-icon name="password"></svg-icon>
    </div>
  </ng-template>
  <br />
  <app-section-with-header
    [icon]="profileNotificationSVG"
    [title]="'NOTIFICATION SETTINGS'"
    [body]="profileNotificationBody"
    [titleColor]="'#ff7f27'"
  ></app-section-with-header>
  <ng-template #profileNotificationBody>
    <app-notification-settings
      class="w-100"
      [employeeId]="actorDetails.id"
    ></app-notification-settings>
  </ng-template>
  <ng-template #profileNotificationSVG>
    <div>
      <svg-icon name="profile-notification"></svg-icon>
    </div>
  </ng-template>
</div>
