import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Employee } from 'src/app/company/models/employee.model';
import { UserEdit } from 'src/app/shared/models/user-edit.model';
import { User } from 'src/app/shared/models/user.model';
import { AccountService } from 'src/app/shared/services/account.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @Input() actorDetails: Employee;
  userDetails: User;
  changePasswordForm: UntypedFormGroup;
  isNewPasswordMatching: boolean = true;
  hasCurrentPasswordError: boolean = false;

  gT = (key: string | Array<string>, interpolateParams?: object) =>
    this.translationService.getTranslation(key, interpolateParams);

  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private alertService: AlertService,
    private authService: AuthService,
    private translationService: AppTranslationService
  ) {}

  ngOnInit(): void {
    if (this.actorDetails) {
      this.getUser();
    }

    this.initForms();

    this.changePasswordForm
      ?.get('currentPassword')
      .valueChanges.subscribe(data => {
        if (data == '') {
          this.hasCurrentPasswordError = false;
        }
      });
  }

  getUser() {
    this.accountService.getUser(this.actorDetails.userId).subscribe(user => {
      this.userDetails = user;
    });
  }

  initForms() {
    this.changePasswordForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]]
    });
  }

  changePassword() {
    const formValue = this.changePasswordForm.value as UserEdit;
    this.changePasswordForm.markAllAsTouched();
    if (this.changePasswordForm.invalid) {
      return;
    }

    if (formValue.newPassword !== formValue.confirmPassword) {
      this.isNewPasswordMatching = false;
      return;
    }

    this.isNewPasswordMatching = true;
    this.hasCurrentPasswordError = false;
    Object.assign(formValue, this.userDetails);

    this.accountService.updateUser(formValue).subscribe(
      () => {
        this.alertService.stopLoadingMessage();
        this.alertService.success('Password reset successfully!');
        this.authService.logout();
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.hasCurrentPasswordError = true;
      }
    );
  }
}
