<!--Package-->
<div *ngIf="hasPackages && packages?.length > 0">
  <div class="row w-100">
    <div class="col-2 title">{{ sectionTitle }}</div>
    <div class="col-10 packing-details">
      <div class="row px-0 ">
        <div class="col-2 field-label">Package</div>
        <div class="col-2 field-label">Dimensions (cm)</div>
        <div class="col-2 field-label">Unit Weight (kg)</div>
        <div class="col-2 field-label">Quantity</div>
        <div class="col-2 field-label">Total Weight (kg)</div>
        <div class="col-2 field-label ">Total Volume (cbm)</div>
      </div>
      <div
        class="row  px-0 padding-values"
        *ngFor="let package of packages; let i = index"
      >
        <div class="col-2 field-value">{{ i + 1 }}</div>
        <div class="col-2 field-value">
          {{ package.length }} x {{ package.width }} x {{ package.height }}
        </div>
        <div class="col-2 field-value">{{ package.weight }}</div>
        <div class="col-2 field-value ">{{ package.quantity }}</div>
        <div class="col-2  field-value">
          {{ package.quantity * package.weight }}
        </div>
        <div class="col-2 field-value ">
          {{ getPackageOrPalletVolume(package) }}
        </div>
      </div>
    </div>
  </div>
</div>

<!--Pallets-->
<div *ngIf="hasPallets && pallets?.length > 0">
  <div class="row w-100" [ngClass]="{ 'pallet-details-padding': hasPackages }">
    <div class="col-2 title ">
      <span *ngIf="!hasPackages"> {{ sectionTitle }}</span>
    </div>
    <div class="col-10 packing-details">
      <div class="row px-0">
        <div class="col-2 field-label">Pallet</div>
        <div class="col-2 field-label">Dimensions (cm)</div>
        <div class="col-2 field-label">Unit Weight (kg)</div>
        <div class="col-2 field-label">Quantity</div>
        <div class="col-2 field-label">Total Weight (kg)</div>
        <div class="col-2 field-label ">Total Volume (cbm)</div>
      </div>

      <div
        class="row px-0 padding-values"
        *ngFor="let pallet of pallets; let i = index"
      >
        <div class="col-2 field-value">{{ i + 1 }}</div>
        <div class="col-2 field-value">
          {{ pallet.length }} x {{ pallet.width }} x {{ pallet.height }}
        </div>
        <div class="col-2 field-value">{{ pallet.weight }}</div>
        <div class="col-2 field-value ">{{ pallet.quantity }}</div>
        <div class="col-2  field-value">
          {{ pallet.quantity * pallet.weight }}
        </div>
        <div class="col-2 field-value">
          {{ getPackageOrPalletVolume(pallet) }}
        </div>
      </div>
    </div>
  </div>
</div>

<!--BreakbulkPackages-->
<div *ngIf="hasBreakbulkPackages">
  <div class="row w-100">
    <div class="col-2 title">{{ sectionTitle }}</div>
    <div class="col-10 packing-details">
      <div class="row px-0">
        <div class="col-2 field-label">Breakbulk</div>
        <div class="col-2 field-label">Dimensions (cm)</div>
        <div class="col-2 field-label">Unit Weight (kg)</div>
        <div class="col-2 field-label">Quantity</div>
        <div class="col-2 field-label">Total Weight (kg)</div>
        <div class="col-2 field-label chargeable">Total Volume (cbm)</div>
      </div>

      <div
        class="row px-0 padding-values"
        *ngFor="let package of breakbulkPackages; let i = index"
      >
        <div class="col-2 field-value">{{ i + 1 }}</div>
        <div class="col-2 field-value">
          {{ package.length }} x {{ package.width }} x {{ package.height }}
        </div>
        <div class="col-2 field-value">{{ package.weight }}</div>
        <div class="col-2 field-value ">{{ package.quantity }}</div>
        <div class="col-2  field-value">
          {{ package.quantity * package.weight }}
        </div>
        <div class="col-2 field-value chargeable">
          {{ getPackageOrPalletVolume(package) }}
        </div>
      </div>
    </div>
  </div>
</div>

<!--Total Values-->
<div
  class="packages-total-values"
  [ngClass]="{
    'detailed-total-values': !isTotalsPackagesPallets
  }"
>
  <div [ngClass]="{ 'total-values': !isTotalsPackagesPallets }">
    <div class="row">
      <div class="col-2 title">
        <span *ngIf="isTotalsPackagesPallets"> {{ sectionTitle }}</span>
      </div>

      <div class="col-4 field-label  pl-0">
        Total Shipment Weight (kg)
      </div>
      <div class="col-4 field-label pl-3 total-volume">
        Total Shipment Volume (cbm)
      </div>
      <div class="col-3 field-label chargeable-weight">
        Chargeable Weight (kg)
      </div>
    </div>

    <div class="row">
      <div class="col-2 title"></div>
      <div class="col-4 field-value  pl-0">
        {{
          isTotalsPackagesPallets
            ? packagesTotals?.totalWeight
            : totalShipmentWeight
        }}
      </div>
      <div class="col-4 field-value pl-3 total-volume ">
        {{
          isTotalsPackagesPallets
            ? packagesTotals?.totalvolume
            : totalShipmentVolume
        }}
      </div>
      <div class="col-3 field-value chargeable-weight">
        {{ chargeableWeight }}
      </div>
    </div>
  </div>
</div>

<!--Is Totals Packages Pallets-->
<div *ngIf="isTotalsPackagesPallets">
  <div class="row total-packages-pallets">
    <div class="col-2 title"></div>
    <div class=" col-10">
      <div class="row">
      <div class="col-4 field-label total-number pl-0">
        No. of packages
      </div>
      <div
        class="col-4 field-label total-number"
        *ngIf="packagesTotals?.palletsNumber"
      >
        No. of pallets
      </div>
    </div>
  </div>
  </div>
</div>
<div class="row padding-values">
  <div class="col-2 title"></div>
  <div class=" col-10 ">
    <div class="row">
    <div class="col-4 field-value total-number pl-0">
      {{ packagesTotals?.packagesNumber }}
    </div>
    <div
      class="col-4 field-value total-number"
      *ngIf="packagesTotals?.palletsNumber"
    >
      {{ packagesTotals?.palletsNumber }}
    </div>
  </div>
  </div>
</div>
