import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CityLocation } from '../models/city-location.model';
import { Paginated } from 'src/app/shared/models/shipment/paginated.model';
 
@Injectable({
  providedIn: 'root'
})
export class CityService {
  baseURL = environment.apiUrl + 'city';

  constructor(private http: HttpClient) {}

  searchCities(searchKeyword: string, pageIndex = 0, pageSize = 10) {
    return this.http.get<Paginated>(
      this.baseURL +
        `/Search?searchKeyword=${searchKeyword}&pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
  }

  getById(id: number): Observable<CityLocation> {
    return this.http.get<CityLocation>(`${this.baseURL}/${id}`);
  }

  deleteCity(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseURL}/${id}`);
  }

  addNewCity(CityLocation: any): Observable<CityLocation> {
    return this.http.post<CityLocation>(`${this.baseURL}`, CityLocation);
  }

  updateCity(id: number, CityLocation: any): Observable<CityLocation> {
    return this.http.put<CityLocation>(`${this.baseURL}/${id}`, CityLocation);
  }
}
