import { NgModule } from '@angular/core';
import { SvgIconsComponent } from './svg-icons.component';



@NgModule({
  declarations: [SvgIconsComponent],
  imports: [
  ],
  exports: [SvgIconsComponent]
})
export class SvgIconsModule { }
