import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Paginated } from 'src/app/shared/models/shipment/paginated.model';
import { ProductRegistrationService } from '../services/product-registration.service';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { LocalStoreManager } from 'src/app/shared/services/local-store-manager.service';
import { ProductRegistrationSearchCriteria } from '../models/product-registration-search-criteria.model';

@Injectable()
export class ProductListResolver implements Resolve<Paginated> {
  searchCriteria: ProductRegistrationSearchCriteria = {
    pageIndex: 0,
    pageSize: 10
  };

  constructor(
    private productRegistrationService: ProductRegistrationService,
    private localStorage: LocalStoreManager
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Paginated> {
    let filters: ProductRegistrationSearchCriteria = this.localStorage.getData(
      'productsFilters'
    );
    if (filters) {
      this.searchCriteria.systemStatuses = filters.systemStatuses;
      this.searchCriteria.statuses = filters.statuses;
      this.searchCriteria.progresses = filters.progresses;
      this.searchCriteria.productsSortBy = filters.productsSortBy;
      this.searchCriteria.categories = filters.categories;
    }

    this.searchCriteria.companyId = JSON.parse(
      route.params.companyId
    ) as string;
    return this.productRegistrationService
      .getProducts(this.searchCriteria, this.searchCriteria?.companyId)
      .pipe(
        catchError(error => {
          return of(null);
        })
      );
  }
}
