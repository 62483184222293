import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'valuArr'
})
export class ValuArrPipe implements PipeTransform {

  transform(value: string, arr, key, returnkey): unknown {
    if (arr) {
      const obj = arr.find(($value) => $value[key] === parseInt(value, 10));
      return obj?.[returnkey];
    }
    return '';
  }

}
