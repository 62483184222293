<div class="mt-4 mb-3">
  <legend>
    <i class="fa fa-shield"></i>
    {{
      isForEditing
        ? ("config.management.Ports.Edit" | translate) + " " + port.name
        : ("config.management.Ports.New" | translate)
    }}
  </legend>
  <div *ngIf="isDuplicatedPort">
    <app-error-badge
      [thinBar]="true"
      message="An entry with the same Code already exists."
      class="warning-badge"
    ></app-error-badge>
  </div>
  <form [formGroup]="portForm" id="portForm" (ngSubmit)="save()" class="px-2">
    <div class="form-group">
      <label for="name">{{ "config.management.Name" | translate }}</label>
      <input
        formControlName="name"
        [ngClass]="{
          'is-invalid':
            portForm.get('name').errors && portForm.get('name').touched
        }"
        type="text"
        class="form-control"
        id="name"
        placeholder="{{
          'config.management.Ports.NamePlaceHolder' | translate
        }}"
      />
    </div>

    <div class="form-group">
      <label for="code">{{ "config.management.Ports.Code" | translate }}</label>
      <input
        formControlName="code"
        [ngClass]="{
          'is-invalid':
            portForm.get('code').errors && portForm.get('code').touched
        }"
        type="text"
        class="form-control"
        id="code"
        placeholder="{{
          'config.management.Ports.CodePlaceHolder' | translate
        }}"
      />
    </div>

    <div class="form-group">
      <div class="col-sm-6 form-check">
        <input
          formControlName="isAirPort"
          [ngClass]="{
            'is-invalid':
              portForm.get('isAirPort').errors &&
              portForm.get('isAirPort').touched
          }"
          type="checkbox"
          class="form-check-input"
          id="isAirPort"
        />
        <label for="isAirPort" class="form-check-label">{{
          "config.management.Ports.IsAirPort" | translate
        }}</label>
      </div>

      <div class="col-sm-6 form-check">
        <input
          formControlName="isDryPort"
          [ngClass]="{
            'is-invalid':
              portForm.get('isDryPort').errors &&
              portForm.get('isDryPort').touched
          }"
          type="checkbox"
          class="form-check-input"
          id="isDryPort"
        />
        <label for="isDryPort" class="form-check-label">{{
          "config.management.Ports.IsDryPort" | translate
        }}</label>
      </div>

      <div class="col-sm-6 form-check">
        <input
          formControlName="isLandPort"
          [ngClass]="{
            'is-invalid':
              portForm.get('isLandPort').errors &&
              portForm.get('isLandPort').touched
          }"
          type="checkbox"
          class="form-check-input"
          id="isLandPort"
        />
        <label for="isLandPort" class="form-check-label">{{
          "config.management.Ports.IsLandPort" | translate
        }}</label>
      </div>

      <div class="col-sm-6 form-check">
        <input
          formControlName="isOceanPort"
          [ngClass]="{
            'is-invalid':
              portForm.get('isOceanPort').errors &&
              portForm.get('isOceanPort').touched
          }"
          type="checkbox"
          class="form-check-input"
          id="isOceanPort"
        />
        <label for="isOceanPort" class="form-check-label">{{
          "config.management.Ports.IsOceanPort" | translate
        }}</label>
      </div>
    </div>

    <fieldset class="nested-fieldset">
      <legend>{{ "Location" | translate }}</legend>
      <div class="row">
        <div class="col-6 form-group">
          <label for="countryName">{{ "route.Country" | translate }}</label>
          <select
            class="form-control"
            (change)="onChangeCountry($event)"
            formControlName="countryName"
            id="countryName"
            [value]="countryName"
          >
            <option [value]="null">
              {{ "route.SelectCountry" | translate }}
            </option>
            <option
              *ngFor="let item of countries"
              [value]="item.name"
              selected="{{ country?.name == item.name ? 'selected' : '' }}"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-6 form-group">
          <label for="cityID">{{ "route.City" | translate }}</label>
          <select
            class="form-control"
            formControlName="cityID"
            id="cityID"
            (change)="onChangeCity($event)"
            [value]="cityID"
          >
            <option *ngIf="!countryName">
              {{ "route.SelectCountryFirst" | translate }}
            </option>
            <option *ngIf="countryName">
              {{ "route.SelectCity" | translate }}
            </option>
            <option
              *ngFor="let item of country?.states"
              [value]="item.id"
              selected="{{ item.name == cityID ? 'selected' : '' }}"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
    </fieldset>

    <div class="col-sm-12 mt-5">
      <div ngPreserveWhitespaces class="float-right">
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="isSaving || !portForm.valid"
        >
          <i *ngIf="!isSaving" class="fa fa-save"></i>
          <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
          {{
            isSaving
              ? ("config.management.Ports.Editor.Saving" | translate)
              : ("config.management.Ports.Editor.Save" | translate)
          }}
        </button>
        <button
          type="button"
          class="btn btn-danger float-left mr-2"
          (click)="cancel()"
        >
          <i class="fa fa-times"></i>
          {{ "config.management.Ports.Editor.Cancel" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
