import { HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ProductRegistrationCommentWithActionActors } from '../models/product-registration-comment-with-action-actors.model';
import { ProductRegistrationComment } from '../models/product-registration-comment.model';

@Injectable({
  providedIn: 'root'
})
export class ProductRegistrationCommentService {
  baseURL = environment.apiUrl + 'ProductRegistrationComment';
  @Output() onCommentsUpdate: EventEmitter<ProductRegistrationCommentWithActionActors> = new EventEmitter();

  @Output() onViewInChat = new EventEmitter<string>();

  constructor(private http: HttpClient) {}

  getProductRegistrationComment(
    productId: string
  ): Observable<ProductRegistrationCommentWithActionActors[]> {
    return this.http.get<ProductRegistrationCommentWithActionActors[]>(
      ` ${this.baseURL}/${productId}`
    );
  }

  addNewComment(
    productRegistrationComment: ProductRegistrationComment
  ): Observable<ProductRegistrationCommentWithActionActors> {
    return this.http.post<ProductRegistrationCommentWithActionActors>(
      this.baseURL,
      productRegistrationComment
    );
  }

  fulFillPendingActionComment(
    productId: string,
    commentId: number
  ): Observable<ProductRegistrationCommentWithActionActors> {
    return this.http.post<ProductRegistrationCommentWithActionActors>(
      `${this.baseURL}/PendingActions/${productId}/Fulfill/${commentId}`,
      null
    );
  }
}
