import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProductSearchCriteria } from '../models/product-search-criteria.model';
import { Paginated } from 'src/app/shared/models/shipment/paginated.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ProductStatus } from 'src/app/create-product/models/product-status.model';
import { ProductBase } from 'src/app/shared/models/product-library/product-base.model';
import { ProductExcelViewModel } from '../models/product-excel-view-model.model';
import { ProductIdNameAndArticleNumber } from '../models/product-id-name-and-article-number.model';
import { ProductDocument } from 'src/app/shared/models/product-document.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  baseUrl = environment.apiUrl + 'Product';
  private selectedProductsCodeNumbers: string[] = [];
  @Output() selectedProductsNumbersChanged = new EventEmitter<string[]>();
  @Output() onSearchCriteriaChange = new EventEmitter<ProductSearchCriteria>();
  @Output() productLibraryRequestUpdated = new EventEmitter<ProductBase>();
  private redirectionModule: string;
  private redirectionParentId: string;

  setRedirectionModule(module: string, parentId: string) {
    this.redirectionModule = module;
    this.redirectionParentId = parentId;
  }

  getRedirectionModule(): string {
    return this.redirectionModule;
  }

  getRedirectionParentId(): string {
    return this.redirectionParentId;
  }

  resetRedirectionModule() {
    this.redirectionModule = null;
    this.redirectionParentId = null;
  }

  constructor(private http: HttpClient) {}

  getAllProducts(searchCriteria: ProductSearchCriteria): Observable<Paginated> {
    return this.http.post<Paginated>(
      this.baseUrl + `/AllProducts`,
      searchCriteria
    );
  }

  isProductInSelectedList(codeNumber: string): boolean {
    let searchIndex = this.selectedProductsCodeNumbers.indexOf(codeNumber);
    return searchIndex != -1;
  }

  addToSelectedProductList(productCodeNumber: string) {
    this.selectedProductsCodeNumbers.push(productCodeNumber);
    this.selectedProductsNumbersChanged.emit(this.selectedProductsCodeNumbers);
  }

  removeFromSelectedProductList(productCodeNumber: string) {
    this.selectedProductsCodeNumbers = this.selectedProductsCodeNumbers?.filter(
      item => {
        return item != productCodeNumber;
      }
    );
    this.selectedProductsNumbersChanged.emit(this.selectedProductsCodeNumbers);
  }

  removeAllFromSelectedProductsList(): void {
    this.selectedProductsCodeNumbers = [];
    this.selectedProductsNumbersChanged.emit(this.selectedProductsCodeNumbers);
  }

  getProductStatuses(): Observable<ProductStatus[]> {
    return this.http.get<ProductStatus[]>(this.baseUrl + `/ProductStatuses`);
  }

  updateProductStatus(
    productId: string,
    statusName: string
  ): Observable<ProductBase> {
    return this.http.put<ProductBase>(
      this.baseUrl +
        `/UpdateProductStatus/${productId}?statusName=${statusName}`,
      null
    );
  }

  deleteProduct(productId: string): Observable<ProductBase> {
    return this.http.delete<ProductBase>(
      this.baseUrl + `/DeleteProduct/${productId}`
    );
  }

  exportProductsToExcel(
    productSearchCriteria: ProductSearchCriteria,
    isAllSelected: boolean
  ): Observable<any> {
    const excelViewModel: ProductExcelViewModel = {
      selectedProductCodeNumbers: this.selectedProductsCodeNumbers,
      searchCriteria: productSearchCriteria,
      isAllSelected: isAllSelected
    };
    return this.http.post(
      this.baseUrl + `/ExportProductsToExcel`,
      excelViewModel,
      {
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  getApprovalProductsByCategoryClassification(
    productSearchCriteria: ProductSearchCriteria
  ): Observable<Paginated> {
    return this.http.post<Paginated>(
      this.baseUrl + `/ApprovalProductsByCategoryClassification`,
      productSearchCriteria
    );
  }

  deleteDocument(
    productId: string,
    documentSqlId: number,
    deletionReason: string,
    productIds: string[],
    parentDocumentSqlId?: number
  ): Observable<ProductBase> {
    const queryString = productIds
      .map(id => `productIds=${encodeURIComponent(id)}`)
      .join('&');

    let url = `/${productId}/DeleteDocument?${queryString}&documentSqlId=${documentSqlId}&deletionReason=${deletionReason}`;
    if (parentDocumentSqlId) {
      url += `&parentDocumentSqlId=${parentDocumentSqlId}`;
    }

    return this.http.delete<ProductBase>(this.baseUrl + url);
  }

  getProductsByArticleNumber(
    searchCriteria: ProductSearchCriteria
  ): Observable<Paginated> {
    return this.http.post<Paginated>(
      this.baseUrl + `/ProductsWithArticleNumber`,
      searchCriteria
    );
  }

  getListOfProductsWithSharedDocument(
    documentPath: string,
    isOlderVersion?: boolean
  ): Observable<ProductIdNameAndArticleNumber[]> {
    let path = encodeURIComponent(documentPath);
    return this.http.get<ProductIdNameAndArticleNumber[]>(
      `${this.baseUrl}/ProductWithSharedDocument?documentPath=${path}&isOlderVersion=${isOlderVersion}`
    );
  }

  replaceProductDocumentWithExistingDocument(
    productId: string,
    document: ProductDocument,
    productIds: string[]
  ): Observable<ProductBase> {
    let replaceWithExistingDocumentParameters = {
      productIds: productIds,
      document: document
    };
    return this.http.post<ProductBase>(
      `${this.baseUrl}/${productId}/DocumentReplacement`,
      replaceWithExistingDocumentParameters
    );
  }
}
