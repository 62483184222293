import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-section-with-header',
  templateUrl: './section-with-header.component.html',
  styleUrls: ['./section-with-header.component.scss']
})
export class SectionWithHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() titleColor: string;
  @Input() icon: TemplateRef<any>;
  @Input() body: TemplateRef<any>;
  active = true;

  constructor() {}

  ngOnInit(): void {}

  toggle() {
    this.active = !this.active;
  }
}
