<div class="section-container">
  <div class="product-information-container">
    <div class="title-section d-flex w-100 justify-content-between">
      <div class="title-container d-flex">
        <svg-icon name="shipmentProducts"></svg-icon>
        <span class="title">PRODUCT DETAILS</span>
      </div>
      <button
        *ngIf="!isEditRoute || canEditProductDetailsManagement"
        class="edit-section-button cursor-pointer bg-transparent border-0"
        (click)="handleProductInformationRouting()"
      >
        <svg-icon name="editQuote"></svg-icon>
        Edit
      </button>
    </div>
    <app-product-information-summary
      [product]="product"
    ></app-product-information-summary>
  </div>

  <div class="compliance-details-container" *ngIf="!isEditRoute">
    <div class="title-section d-flex w-100 justify-content-between">
      <div class="title-container d-flex">
        <svg-icon name="shipmentProducts"></svg-icon>
        <span class="title">COMPLIANCE DETAILS</span>
      </div>
      <button
        class="edit-section-button cursor-pointer bg-transparent border-0"
        (click)="handleComplianceInformationRouting()"
      >
        <svg-icon name="editQuote"></svg-icon>
        Edit
      </button>
    </div>
    <app-product-compliance-details-summary
      [product]="product"
    ></app-product-compliance-details-summary>
  </div>
</div>
