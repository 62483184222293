<div class="timeline-container">
  <div class="line">
    <div
      *ngFor="let step of stepsBeforeSummary"
      class="sub-line"
      [ngClass]="{
        active: step.isSubmitted
      }"
    ></div>
  </div>
  <div
    *ngFor="let step of steps"
    class="timeline-group"
    [routerLink]="'./' + step.url"
    routerLinkActive="active"
    [ngClass]="{
      activated: step.isActivated,
      submitted: step.isSubmitted,
      'disable-link': step.isDisabled
    }"
  >
    <div class="point-group">
      <div class="timeline-point">
        <div class="point">
          <div class="sub-point"></div>
        </div>
      </div>
    </div>
    <div class="timeline-title">{{ step.name }}</div>
    <div class="timeline-destination" *ngIf="step.isOptional">(optional)</div>
  </div>
</div>
