import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FromUtcToAfricaCairoPipe } from './from-utc-to-africa-cairo.pipe';

@NgModule({
  declarations: [FromUtcToAfricaCairoPipe],
  imports: [CommonModule],
  exports: [FromUtcToAfricaCairoPipe]
})
export class FromUtcToAfricaCairoPipeModule {}
