import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductIdNameAndArticleNumber } from '../../models/product-id-name-and-article-number.model';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-documents-bulk-product-list',
  templateUrl: './documents-bulk-product-list.component.html',
  styleUrls: ['./documents-bulk-product-list.component.scss']
})
export class DocumentsBulkProductListComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() parentId: string;
  @Input() documentPath: string;
  @Input() isOlderVersion: boolean;

  @Output() onSelectingProducts = new EventEmitter<string[]>();

  products: ProductIdNameAndArticleNumber[];
  selectedProducts: string[] = [];

  constructor(private productService: ProductService) {}

  ngOnInit(): void {
    this.getProductsForBulkReplace();
    this.selectedProducts = [this.parentId];
    this.onSelectingProducts.emit(this.selectedProducts);
  }

  isChecked(product: ProductIdNameAndArticleNumber): boolean {
    return this.selectedProducts.findIndex(p => p === product.productId) != -1;
  }

  changeChildValue(product: ProductIdNameAndArticleNumber): void {
    let isExist =
      this.selectedProducts.findIndex(p => p === product.productId) != -1;
    if (isExist) {
      this.selectedProducts = this.selectedProducts.filter(
        p => p != product.productId
      );
    } else {
      this.selectedProducts.push(product.productId);
    }

    this.onSelectingProducts.emit(this.selectedProducts);
  }

  getProductsForBulkReplace(): void {
    this.productService
      .getListOfProductsWithSharedDocument(
        this.documentPath,
        this.isOlderVersion
      )
      .subscribe(res => {
        this.products = res;
      });
  }

  get productSelectionType(): string {
    return this.selectedProducts?.length === this.products?.length
      ? ''
      : 'dashmark';
  }
}
