import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userNameInitials'
})
export class UserNameInitialsPipe implements PipeTransform {
  transform(value: string): any {
    if (value) {
      if (value != null) {
        var firstLetter = value.substring(0, 1).toUpperCase();
        var index = value.indexOf(' ');
        var secondLetter = value[index + 1];
        return firstLetter + secondLetter;
      }

      return '';
    }
  }
}
