import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductDetailsProductRegistration } from 'src/app/product-library/models/product-details-product-registration';
import { FileViewerService } from '../file-viewer/file-viewer.service';

@Component({
  selector: 'app-product-details-registration-summary',
  templateUrl: './product-details-registration-summary.component.html',
  styleUrls: ['./product-details-registration-summary.component.scss']
})
export class ProductDetailsRegistrationSummaryComponent implements OnInit {
  @Input()
  productDetailsProductRegistrationList: ProductDetailsProductRegistration[];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fileViewerService: FileViewerService
  ) {}

  ngOnInit() {}
  onRegistrationNumberClick(registrationId: string) {
    this.router.navigate(
      ['../../../registrations', registrationId, 'details'],
      {
        relativeTo: this.route
      }
    );
  }

  isDocumentExpired(date: Date): boolean {
    if (!date) {
      return true;
    }
    const uploadDate = new Date(date);
    const currentDate = new Date();
    return uploadDate <= currentDate;
  }

  viewFile(path: string) {
    this.fileViewerService.view(path);
  }
}
