import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-warning-badge',
  templateUrl: './warning-badge.component.html',
  styleUrls: ['./warning-badge.component.scss']
})
export class WarningBadgeComponent implements OnInit {
  @Input() message: string;
  @Input() thinBar = false;
  constructor() {}

  ngOnInit() {}
}
