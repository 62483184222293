<div class="loader-card">
  <div class="document-type">
    <div class="title">
      {{documentType}}
    </div>
  </div>
  <div class="file">
    <div class="skeleton-loader-item file-item-name"></div>
    <div class="skeleton-loader-item file-item-created-by"></div>
    <div class="skeleton-loader-item file-item-expiration"></div>
  </div>
  <div class="document-notes">
    <div class=" skeleton-loader-item"></div>
  </div>
  <div class="actions">
    <div class="skeleton-loader-item action-btn"></div>
    <div class="skeleton-loader-item action-btn"></div>
    <div class="skeleton-loader-item action-btn"></div>
  </div>
</div>

