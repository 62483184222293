<div class="wrapper">
  <form [formGroup]="changePasswordForm" class="fields-container">
    <div class="fields-column">
      <div class="field-wrapper">
        <label class="field-title" for="currentPassword">{{
          "users.editor.CurrentPassword" | translate
        }}</label>

        <input
          formControlName="currentPassword"
          [ngClass]="{
            'is-invalid value-error':
              (changePasswordForm.get('currentPassword').errors &&
                changePasswordForm.get('currentPassword').touched) ||
              hasCurrentPasswordError
          }"
          type="password"
          class="field-input"
          id="currentPassword"
        />

        <div class="error-container">
          <svg-icon
            name="mark!"
            *ngIf="
              (changePasswordForm.get('currentPassword').errors &&
                changePasswordForm.get('currentPassword').touched) ||
              hasCurrentPasswordError
            "
          ></svg-icon>

          <small
            *ngIf="
              changePasswordForm.get('currentPassword').errors &&
              changePasswordForm.get('currentPassword').touched
            "
            class="form-text danger-color"
            >{{ "users.editor.CurrentPasswordRequired" | translate }}
          </small>

          <small *ngIf="hasCurrentPasswordError" class="form-text danger-color"
            >{{ "users.editor.CurrentPasswordMismatch" | translate }}
          </small>
        </div>
      </div>

      <div class="field-wrapper">
        <label class="field-title" for="newPassword">{{
          "users.editor.NewPassword" | translate
        }}</label>

        <input
          formControlName="newPassword"
          [ngClass]="{
            'is-invalid value-error':
              changePasswordForm.get('newPassword').errors &&
              changePasswordForm.get('newPassword').touched
          }"
          type="password"
          class="field-input"
          id="newPassword"
        />
        <div class="error-container">
          <svg-icon
            name="mark!"
            *ngIf="
              changePasswordForm.get('newPassword').errors &&
              changePasswordForm.get('newPassword').touched
            "
          ></svg-icon>
          <small
            *ngIf="
              changePasswordForm.get('newPassword').errors &&
              changePasswordForm.get('newPassword').touched
            "
            class="form-text danger-color"
            >{{ "users.editor.NewPasswordRequired" | translate }}
          </small>
        </div>
      </div>

      <div class="field-wrapper">
        <label class="field-title" for="confirmPassword">{{
          "users.editor.ConfirmPassword" | translate
        }}</label>

        <input
          formControlName="confirmPassword"
          [ngClass]="{
            'is-invalid value-error':
              changePasswordForm.get('confirmPassword').errors &&
              changePasswordForm.get('confirmPassword').touched
          }"
          type="password"
          class="field-input"
          id="confirmPassword"
        />

        <div class="error-container">
          <svg-icon
            name="mark!"
            *ngIf="
              (changePasswordForm.get('confirmPassword').errors &&
                changePasswordForm.get('confirmPassword').touched) ||
              !isNewPasswordMatching
            "
          ></svg-icon>
          <small
            *ngIf="
              changePasswordForm.get('confirmPassword').errors &&
              changePasswordForm.get('confirmPassword').touched
            "
            class="form-text danger-color"
            >{{ "users.editor.ConfirmationPasswordRequired" | translate }}
          </small>
          <small *ngIf="!isNewPasswordMatching" class="form-text danger-color"
            >{{ "users.editor.PasswordMismatch" | translate }}
          </small>
        </div>
      </div>

      <div class="field-wrapper field-wrapper-center">
        <button class="field-submit-button" (click)="changePassword()">
          Change Password
        </button>
      </div>
    </div>
  </form>
</div>
