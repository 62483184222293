import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LandRoute } from 'src/app/admin-portal-configs/models/land-route.model';
import { PortDto } from 'src/app/admin-portal-configs/models/portDto.model';
import { LandRouteService } from 'src/app/admin-portal-configs/services/land-route.service';
import { PortService } from 'src/app/admin-portal-configs/services/port.service';
import { CountryMaster } from 'src/app/shared/models/country.model';
import { Port } from 'src/app/shared/models/port.model';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { CountryService } from 'src/app/shared/services/country.service';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { Utilities } from 'src/app/shared/services/utilities';

@Component({
  selector: 'app-add-edit-land-route',
  templateUrl: './add-edit-land-route.component.html',
  styleUrls: ['./add-edit-land-route.component.scss']
})
export class AddEditLandRouteComponent implements OnInit {
  landRouteForm: UntypedFormGroup;
  isInvalidForm: boolean;
  isSaving: boolean;
  landRoute: LandRoute;

  pickupCountries: CountryMaster[] = [];
  deliveryCountries: CountryMaster[] = [];
  countries: CountryMaster[] = [];
  transitPorts: PortDto[] = [];
  pickupPorts: Port[] = [];
  deliveryPorts: Port[] = [];

  constructor(
    private fb: FormBuilder,
    private countryService: CountryService,
    private alertService: AlertService,
    private portService: PortService,
    private landRouteService: LandRouteService,
    private formHelperService: FormsHelperService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initEdit();
    this.getCountries();
    this.getTransitPorts();
    this.initForm();
  }

  initEdit(): void {
    if (!this.isEditRoute) {
      return;
    }
    this.activatedRoute.data.subscribe(data => {
      this.landRoute = data['landRoute'];
    });

    this.initializePortsAndCountries(true, this.landRoute?.originPort?.city?.countryID);
    this.initializePortsAndCountries(false, this.landRoute?.deliveryPort?.city?.countryID);
  }

  initForm(): void {
    this.landRouteForm = this.fb.group({
      id: [this.landRoute?.id],
      pickupCountryId: [
        this.landRoute?.originPort?.city?.countryID,
        [Validators.required]
      ],
      deliveryCountryId: [
        this.landRoute?.deliveryPort?.city?.countryID,
        [Validators.required]
      ],
      originPortId: [this.landRoute?.originPortId, [Validators.required]],
      deliveryPortId: [this.landRoute?.deliveryPortId, [Validators.required]],
      transitPortId: [this.landRoute?.transitPortId]
    });
  }

  saveORCancel(isSave: boolean) {
    if (isSave) {
      this.save();
    } else {
      this.cancel();
    }
  }

  getCountries() {
    this.countryService.getCountries(true).subscribe(
      data => {
        this.countries = data;
        this.pickupCountries = data;
        this.deliveryCountries = data;
      },
      error => {
        this.alertService.showStickyMessage(
          'Error',
          `An error occured whilst retreiving a list of countries.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  getTransitPorts() {
    this.portService.getPortsInLandCountries('').subscribe(
      data => {
        this.transitPorts = data;
      },
      error => {
        this.alertService.showStickyMessage(
          'Error',
          `An error occured whilst retreiving a list of ports.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }
  save(): void {
    if (this.isSaveInvalid()) {
      return;
    }
    this.isInvalidForm = false;
    const formVal = {
      ...this.landRouteForm.value
    };
    delete formVal.deliveryCountryId;
    delete formVal.pickupCountryId;
    const routeToAdd = formVal as LandRoute;

    this.isEditRoute
      ? this.editRoute(routeToAdd)
      : this.addNewRoute(routeToAdd);
  }

  isSaveInvalid(): boolean {
    if (this.isSaving) {
      return true;
    }
    this.landRouteForm.markAllAsTouched();
    if (this.landRouteForm.invalid) {
      this.isInvalidForm = true;
      return true;
    }
    return false;
  }

  addNewRoute(landRoute: LandRoute) {
    this.landRouteService.addLandRoute(landRoute).subscribe(() => {
      this.alertService.success('Route added successfully!');
      this.router.navigate(['../land-routes']);
    });
  }

  editRoute(landRoute: LandRoute) {
    this.landRouteService.updateLandRoute(landRoute).subscribe(() => {
      this.alertService.success('Route Updated successfully!');
      this.router.navigate(['../land-routes']);
    });
  }

  cancel() {
    this.router.navigate(['../land-routes']);
  }

  isInvalidFormControl(formControlName: string): boolean {
    return this.formHelperService?.isInvalidFormControl(
      this.landRouteForm,
      formControlName
    );
  }

  private initializePortsAndCountries(
    isPickup: boolean,
    countryId: number
  ): void {
    if (isPickup) {
      this.pickupPorts = [];
      this.deliveryCountries = this.countries.filter(
        country => country.id !== countryId
      );
      this.landRouteForm?.get('originPortId')?.setValue(null);
    } else {
      this.deliveryPorts = [];
      this.pickupCountries = this.countries.filter(
        country => country.id !== countryId
      );
      this.landRouteForm?.get('deliveryPortId')?.setValue(null);
    }

    this.portService.getPorts(countryId, false).subscribe(data => {
      if (isPickup) {
        this.pickupPorts = data;
      } else {
        this.deliveryPorts = data;
      }
    });
  }


  onPickupCountrySelected(event: any): void {
    const countryId = event.id;
    this.initializePortsAndCountries(true, countryId);
  }

  onDeliveryCountrySelected(event: any): void {
    const countryId = event.id;
    this.initializePortsAndCountries(false, countryId);
  }

  get isEditRoute(): boolean {
    return this.router.url.includes('edit');
  }
}
