import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ShipmentLeadTimeResponse } from '../models/shipment-lead-time-response.model';
import { ShipmentLeadTimeService } from '../services/shipment-lead-time.service';

@Injectable()
export class ShipmentLeadTimeResolver
  implements Resolve<ShipmentLeadTimeResponse> {
  constructor(public shipmentLeadTimeService: ShipmentLeadTimeService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ShipmentLeadTimeResponse> {
    let id = JSON.parse(route.params.id) as number;

    return this.shipmentLeadTimeService.getById(id).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
