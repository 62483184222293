import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DocumentType } from 'src/app/shared/models/document-type.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProductRegistrationsActivityCategoriesEnum } from '../../../admin-portal-configs/enums/product-registrations-activity-categories.enum';
import { DocumentTypeService } from '../../../admin-portal-configs/services/document-type.service';
import { RegistrationActivityService } from '../../../admin-portal-configs/services/registration-activity.service';
import { ProductRegistrationHelperService } from '../../../create-product-registration/service/product-registration-helper.service';
import { ProductRegistrationDetails } from '../../../product-registration/models/product-registration-details.model';
import { UploadDocumentComponent } from '../upload-document/upload-document/upload-document.component';
import { CancelationDetails } from '../../models/cancelation-details';
import { DocumentParentType } from '../../models/enums/document-parent-type.model';
import { DocumentTypeEnum } from '../../models/enums/document-type.enum';
import { UploadDocumentPopUpPurpose } from '../../models/enums/upload-document-pop-up-purpose.enum';
import { PauseDetails } from '../../models/pause-details';
import { AlertService } from '../../services/alert.service';
import { ProductRegistrationService } from '../../../product-registration/services/product-registration.service';
import { ModuleName } from '../../models/enums/module-name.enum';
import { ProductBase } from '../../models/product-library/product-base.model';
import { ProductHelperService } from 'src/app/create-product/services/product-helper.service';
import { ProductService } from 'src/app/product-library/services/product.service';
import { ProductStatusEnum } from 'src/app/product-library/enums/product-status-enum';
import { ProductStatus } from 'src/app/create-product/models/product-status.model';
import { DialogService } from '../../services/dialog.service';
import { ApprovalBase } from 'src/app/approvals/models/approval-base.model';
import { ApprovalHelperService } from 'src/app/approvals/services/approval-helper.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  @Input() productRegistrationDetails: ProductRegistrationDetails;
  @Input() productDetails: ProductBase;
  @Input() moduleName: ModuleName;
  @Output() deleteClicked = new EventEmitter();
  @Output() pauseResumeClicked = new EventEmitter();
  @Input() approvalDetails: ApprovalBase;

  nextActivities: any[] = [];
  selectedActivityValue: number;
  isShown: boolean;
  isSaving: boolean = false;
  inputActivityValue: string;
  productDocumentTypes: DocumentType[] = [];
  selectedActivityValueName: string;
  profilePictureUrl: string;
  createdBy: string;
  deleteBtnText: string;
  backLinkText: string;
  productStatuses: ProductStatus[];
  @ViewChild('dropdown') dropdown: NgbDropdown;

  constructor(
    private authService: AuthService,
    private productRegistrationHelperService: ProductRegistrationHelperService,
    private registrationActivityService: RegistrationActivityService,
    private alertService: AlertService,
    private productRegistrationService: ProductRegistrationService,
    private productHelperService: ProductHelperService,
    private documenTypeService: DocumentTypeService,
    private productService: ProductService,
    private dialogService: DialogService,
    private approvalHelperService: ApprovalHelperService
  ) {}

  ngOnInit(): void {
    if (this.isProductLibraryModule) {
      this.productService.getProductStatuses().subscribe(statuses => {
        this.productStatuses = statuses.filter(
          e =>
            e.name == ProductStatusEnum.Live ||
            e.name == ProductStatusEnum.Obsolete
        );
        this.getNextActivities();
      });
    } else {
      this.getNextActivities();
    }
    this.processModuleData();
  }

  processModuleData(): void {
    if (this.isRegistrationModule) {
      this.backLinkText = this.productRegistrationHelperService.getProductRegistrationNumberString(
        this.productRegistrationDetails?.productRegistration
      );
      this.profilePictureUrl = this.productRegistrationHelperService.getSubmittedByProfileUrl(
        this.productRegistrationDetails
      );

      this.createdBy = this.productRegistrationHelperService.getSubmittedBy(
        this.productRegistrationDetails?.productRegistration
      );
      this.deleteBtnText = 'Cancel Request';
    } else if (this.isProductLibraryModule) {
      this.backLinkText = this.productHelperService.getProductNo(
        this.productDetails
      );
      this.createdBy = this.productHelperService.getCreatedByFullName(
        this.productDetails
      );
      this.deleteBtnText = 'Delete Product';
    } else if (this.isApprovalModule) {
      this.backLinkText = this.approvalHelperService.getApprovalNumber(
        this.approvalDetails
      );
      this.deleteBtnText = 'Cancel Request';
      this.createdBy = this.approvalHelperService.getCreatedBy(
        this.approvalDetails
      );
    }
  }

  ngAfterContentChecked(): void {
    this.isShown = this.dropdown?.isOpen();
  }

  getNextActivities(): void {
    if (this.isRegistrationModule) {
      this.registrationActivityService
        .getByName(
          this.productRegistrationDetails?.productRegistration
            ?.lastActivityLogViewModel?.activityName
        )
        .subscribe(res => {
          this.nextActivities = res.nextActivities.filter(
            activity =>
              activity.name !==
                ProductRegistrationsActivityCategoriesEnum.Cancelled &&
              activity.name !==
                ProductRegistrationsActivityCategoriesEnum.OnHold
          );
          if (
            this.nextActivities.find(
              x =>
                x.name ==
                ProductRegistrationsActivityCategoriesEnum.RegistrationLicenseObtained
            )
          ) {
            this.getRegistrationLicenseDocumentType();
          }
        });
    } else if (this.isProductLibraryModule) {
      if (this.statusName === ProductStatusEnum.Draft) {
        this.nextActivities = this.productStatuses;
      } else if (this.statusName === ProductStatusEnum.Live) {
        this.nextActivities = this.productStatuses.filter(
          status => status.name == ProductStatusEnum.Obsolete
        );
      } else if (this.statusName === ProductStatusEnum.Obsolete) {
        this.nextActivities = [];
      }
    }
  }

  updateActivity(): void {
    this.isSaving = true;
    if (this.isRegistrationModule) {
      this.applyInputActivityValues();
      this.productRegistrationService
        .updateProductRegistrationActivity(
          this.productRegistrationDetails.productRegistration?.id,
          this.selectedActivityValue,
          this.productRegistrationDetails.productRegistration?.acceptanceNumber,
          this.productRegistrationDetails.productRegistration
            ?.registrationLicenseNumber
        )
        .subscribe(
          res => {
            this.isSaving = false;
            this.alertService.success('Request has been updated.');
            this.productRegistrationDetails.productRegistration = res;
            this.dropdown.close();
            this.resetValues();
            this.getNextActivities();
          },
          error => {
            this.isSaving = false;
            this.alertService.error('Something went wrong!');
          }
        );
    } else if (this.isProductLibraryModule) {
      this.productService
        .updateProductStatus(
          this.productDetails.id,
          this.selectedActivityValueName
        )
        .subscribe(res => {
          this.isSaving = false;
          this.alertService.success('Request has been updated.');
          this.productDetails = res;
          this.dropdown.close();
          this.resetValues();
          this.getNextActivities();
        });
      error => {
        this.isSaving = false;
        this.alertService.error('Something went wrong!');
      };
    }
  }

  applyInputActivityValues(): void {
    if (this.inputActivityValue) {
      this.isUploadLicense
        ? (this.productRegistrationDetails.productRegistration.registrationLicenseNumber = this.inputActivityValue)
        : (this.productRegistrationDetails.productRegistration.acceptanceNumber = this.inputActivityValue);
    }
  }

  resetValues(): void {
    this.inputActivityValue = null;
    this.selectedActivityValue = null;
  }

  uploadLicense(): void {
    this.isSaving = true;

    const dialogRef = this.dialogService.open(
      UploadDocumentComponent,
      {
        popUpPurpose: UploadDocumentPopUpPurpose.AddNewDocument,
        parentTypeId: this.productRegistrationDetails.productRegistration.id,
        parentType: DocumentParentType.ProductRegistration,
        productDocumentTypes: this.productDocumentTypes,
        formTitle: 'Upload Required Document',
        isUploadLicense: true
      },
      '600px',
      '95vh'
    );
    dialogRef.afterClosed().subscribe((output: any) => {
      if (output) {
        this.updateActivity();
      } else {
        this.isSaving = false;
        this.resetValues();
      }
    });
  }

  getRegistrationLicenseDocumentType(): void {
    this.documenTypeService
      .getDocumentTypeByName(DocumentTypeEnum.RegistrationLicense)
      .subscribe(resp => {
        this.productDocumentTypes = [];
        this.productDocumentTypes.push(resp);
      });
  }

  sendClickedValueName(selectedValueName: string): void {
    this.selectedActivityValueName = selectedValueName;
  }

  onDelete(): void {
    this.deleteClicked.emit();
  }

  onPauseResume(): void {
    this.pauseResumeClicked.emit();
  }

  get isRegistrationModule(): boolean {
    return this.moduleName === ModuleName.Registration;
  }

  get isProductLibraryModule(): boolean {
    return this.moduleName === ModuleName.ProductLibrary;
  }
  get isApprovalModule(): boolean {
    return this.moduleName === ModuleName.Approval;
  }

  get isReadonlyField(): boolean {
    return (
      !this.nextActivities.length ||
      this.isProductPaused ||
      this.isCancelledStatus ||
      !this.canManageRegistrationStatus
    );
  }

  get currentColorStatus(): string {
    if (this.isRegistrationModule) {
      return this.productRegistrationHelperService?.getStatusColor(
        this.productRegistrationDetails?.productRegistration
      );
    } else if (this.isProductLibraryModule) {
      return this.productHelperService?.getStatusColor(this.productDetails);
    }
    else if(this.isApprovalModule){
      return this.approvalHelperService.getStatusColor(this.approvalDetails);
    }
  }

  get canManageRegistrationStatus(): boolean {
    return this.authService.canManageRegistrationStatus;
  }

  get canPauseResumeProductRegistration(): boolean {
    return this.authService.canPauseResumeProductRegistration;
  }

  get canCancelProductRegistration(): boolean {
    return this.authService.canCancelProductRegistration;
  }
  get canManageApprovals(): boolean {
    return this.authService.canManageRegistrationAndApprovalServices;
  }

  get canManageProductStatus(): boolean {
    return !this.isObseleteStatus && this.canUpdateProductStatus;
  }
  get canDeleteProduct(): boolean {
    if (this.authService.canDeleteProduct) {
      return true;
    } else {
      return (
        this.productHelperService.getStatus(this.productDetails) ==
        ProductStatusEnum.Draft
      );
    }
  }
  get isCancelledStatus(): boolean {
    return this.productRegistrationHelperService?.isCancelledStatus(
      this.productRegistrationDetails?.productRegistration
    );
  }
  get isObseleteStatus(): boolean {
    return (
      this.productHelperService.getStatus(this.productDetails) ==
      ProductStatusEnum.Obsolete
    );
  }
  get isProductPaused(): boolean {
    return this.productRegistrationHelperService.isPausedStatus(
      this.productRegistrationDetails?.productRegistration
    );
  }

  get statusName(): string {
    if (this.isRegistrationModule) {
      return this.productRegistrationHelperService.getStatusName(
        this.productRegistrationDetails?.productRegistration
      );
    } else if (this.isProductLibraryModule) {
      return this.productHelperService.getStatus(this.productDetails);
    }
    else if(this.isApprovalModule){
      return this.approvalHelperService.getStatus(this.approvalDetails).name;
    }
  }

  get activityName(): string {
    if (this.isRegistrationModule) {
      return this.productRegistrationHelperService.getLastActivityName(
        this.productRegistrationDetails?.productRegistration
      );
    } else if (this.isProductLibraryModule) {
      return this.statusName;
    }
  }
  get isNewSystemStatus(): boolean {
    return this.productRegistrationHelperService?.isNewSystemStatus(
      this.productRegistrationDetails?.productRegistration
    );
  }

  get isLagrWord(): boolean {
    return this.activityName?.length > 23;
  }

  get isDisabledActionBtn(): boolean {
    return (
      !this.selectedActivityValue ||
      (this.hasInputField && !this.inputActivityValue)
    );
  }

  get hasInputField(): boolean {
    return (
      this.selectedActivityValue &&
      (this.selectedActivityValue == this.acceptanceNoObtainedActivityId ||
        this.selectedActivityValue ==
          this.registrationLicenseObtainedActivityId)
    );
  }

  get isUploadLicense(): boolean {
    return (
      this.selectedActivityValue == this.registrationLicenseObtainedActivityId
    );
  }

  get pauseDetails(): PauseDetails {
    return this.productRegistrationHelperService.getPauseDetails(
      this.productRegistrationDetails?.productRegistration
    );
  }

  get cancelationDetails(): CancelationDetails {
    return this.productRegistrationHelperService.getCancelationDetails(
      this.productRegistrationDetails?.productRegistration
    );
  }

  get pausedResumeBtnTxt(): string {
    return this.isProductPaused ? 'Resume Request' : 'Pause Request';
  }

  get canManageStatus(): boolean {
    if (this.isRegistrationModule) {
      return !this.isReadonlyField;
    } else if (this.isProductLibraryModule) {
      return this.canManageProductStatus;
    }
  }

  get canUpdateProductStatus(): boolean {
    return this.authService.canUpdateProductStatus;
  }

  private get acceptanceNoObtainedActivityId(): number {
    return this.nextActivities?.find(
      x =>
        x.name ==
        ProductRegistrationsActivityCategoriesEnum.AcceptanceNumberObtained
    )?.id;
  }

  private get registrationLicenseObtainedActivityId(): number {
    return this.nextActivities?.find(
      x =>
        x.name ==
        ProductRegistrationsActivityCategoriesEnum.RegistrationLicenseObtained
    )?.id;
  }
}
