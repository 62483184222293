<div class="row p-3">
  <div class="col-lg-3 col-md-1 col-sm-1"></div>
  <div class="col-lg-6 col-md-10 col-sm-10 Card pb-1">
    <form
      [formGroup]="documentTypeForm"
      id="documentTypeForm"
      (ngSubmit)="save()"
    >
      <fieldset>
        <h4>
          {{
            !isNewDocumentType
              ? ("config.management.DocumentTypes.Edit" | translate) +
                " " +
                documentTypeEdit.name
              : ("config.management.DocumentTypes.New" | translate)
          }}
        </h4>
        <hr />
        <div class="form-group">
          <label for="name" class="col-form-label">{{
            "config.management.Name" | translate
          }}</label>
          <input
            formControlName="name"
            [ngClass]="{
              'is-invalid':
                documentTypeForm.get('name').errors &&
                documentTypeForm.get('name').touched
            }"
            type="text"
            class="form-control"
            id="name"
            placeholder="{{
              'config.management.DocumentTypes.NamePlaceHolder' | translate
            }}"
          />
        </div>

        <div class="form-group form-check">
          <input
            formControlName="isPinned"
            type="checkbox"
            id="isPinned"
            class="form-check-input"
          />
          <label for="isPinned" class="form-check-label">{{
            "config.management.isPinned" | translate
          }}</label>
        </div>

        <div class="form-group">
          <label for="maxFileSize" class="col-form-label">{{
            "config.management.MaxFileSize" | translate
          }}</label>
          <div class="mb-3">
            <input
              formControlName="maxFileSize"
              [ngClass]="{
                'is-invalid':
                  documentTypeForm.get('maxFileSize').errors &&
                  documentTypeForm.get('maxFileSize').touched
              }"
              type="number"
              id="maxFileSize"
              class="form-control"
            />
          </div>
        </div>

        <div class="form-group">
          <label for="parentType" class="col-form-label">
            {{ "config.management.parentType" | translate }}
          </label>
          <select
            formControlName="parentType"
            [ngClass]="{
              'is-invalid':
                documentTypeForm.get('parentType').errors &&
                documentTypeForm.get('parentType').touched
            }"
            (change)="changeParentType($event)"
            class="form-control"
            id="parentType"
          >
            <option *ngFor="let item of parentTypes" [value]="item.value">
              {{ item.name | translate }}
            </option>
          </select>
        </div>

        <div class="form-group" *ngIf="isCompanyProfileOrProduct">
          <div class="form-group form-check">
            <input
              formControlName="isNonExpiring"
              type="checkbox"
              id="isNonExpiring"
              class="form-check-input"
            />
            <label for="isNonExpiring" class="form-check-label"
              >Non-Expiring Document</label
            >
          </div>

          <div *ngIf="isProduct">
          <label for="parentType" class="col-form-label">
            {{ "config.management.Category" | translate }}
          </label>
          <select
            formControlName="category"
            [ngClass]="{
              'is-invalid':
                documentTypeForm.get('category').errors &&
                documentTypeForm.get('category').touched
            }"
            class="form-control"
            id="category"
          >
            <option *ngFor="let item of categoryTypes" [value]="item">
              {{ item | translate }}
            </option>
          </select>
          </div>

        </div>

        <div class="form-group" *ngIf="isProduct">
          <label for="Condition" class="col-form-label">
            {{ "config.management.Condition" | translate }}
          </label>
          <select
            formControlName="isRequired"
            [ngClass]="{
              'is-invalid':
                documentTypeForm.get('isRequired').errors &&
                documentTypeForm.get('isRequired').touched
            }"
            class="form-control"
          >
            <option
              *ngFor="let item of documentTypeConditions"
              [value]="item.value"
            >
              {{ item.name | translate }}
            </option>
          </select>
        </div>

        <div class="col-sm-12 form-check" [hidden]="isCompanyProfileOrProduct">
          <div class="col-sm-6 form-check">
            <input
              formControlName="isForAir"
              type="checkbox"
              id="isForAir"
              class="form-check-input"
            />
            <label for="isForAir" class="form-check-label">
              {{ "config.Air" | translate }}
            </label>
          </div>
          <div class="col-sm-6 form-check">
            <input
              formControlName="isForOcean"
              type="checkbox"
              id="isForOcean"
              class="form-check-input"
            />
            <label for="isForOcean" class="form-check-label">
              {{ "config.Ocean" | translate }}
            </label>
          </div>
          <div class="col-sm-6 form-check">
            <input
              formControlName="isForLand"
              type="checkbox"
              id="isForLand"
              class="form-check-input"
            />
            <label for="isForLand" class="form-check-label">
              {{ "config.Land" | translate }}
            </label>
          </div>
        </div>

        <div class="form-group" [hidden]="isCompanyProfileOrProduct">
          <div class="row">
            <div class="col-md-12">
              <hr class="edit-separator-hr" />
            </div>
          </div>
          <label for="serviceDocumentType" class="col-form-label">
            {{ "company.Services" | translate }}
          </label>
          <div
            *ngFor="let service of serviceTypes; let i = index"
            class="col-sm-6 form-check"
          >
            <input
              type="checkbox"
              [value]="service.id"
              (change)="selectService($event)"
              class="form-check-input"
              name="{{ service.name }}"
              id="{{ service.name }}"
              [checked]="checkIfPicked(service)"
            />
            <label class="form-check-label" [for]="service.name">
              {{ service.name }}
            </label>
          </div>
        </div>

        <div class="form-group" *ngIf="isProductMedical">
          <div class="row mx-0">
            <div
              *ngFor="let certificationType of certificationTypes"
              class="col-4 certification-type"
            >
              <input
                type="checkbox"
                class="form-check-input"
                name="{{ certificationType.name }}"
                id="{{ certificationType.name }}"
                [checked]="isCertificationTypeSelected(certificationType.name)"
                (change)="
                  selectCertificationType($event, certificationType.name)
                "
              />
              <label
                class="form-check-label certification-label"
                [for]="certificationType.name"
              >
                {{ certificationType.name }}
              </label>
              <div
                *ngFor="
                  let classificationType of classificationTypes;
                  let i = index
                "
                class="form-check pl-4 classification-type"
              >
                <input
                  type="checkbox"
                  class="form-check-input "
                  name="{{ certificationType.name + classificationType.name }}"
                  id="{{ certificationType.name + classificationType.name }}"
                  (change)="
                    selectClassificationType(
                      $event,
                      classificationType.name,
                      certificationType.name
                    )
                  "
                  [checked]="
                    isClassificationTypeSelected(
                      classificationType.name,
                      certificationType.name
                    )
                  "
                />
                <label
                  class="form-check-label classification-label"
                  [class]="i === classificationTypes?.length - 1 ? 'mb-0' : ''"
                  [for]="certificationType.name + classificationType.name"
                >
                  {{ classificationType.name }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <hr class="edit-separator-hr" />
          </div>
        </div>

        <div class="col form-group">
          <div ngPreserveWhitespaces class="float-right">
            <button (click)="cancel()" class="btn btn-danger">
              <i class="fa fa-times"></i>
              {{ "config.management.DocumentTypes.Editor.Cancel" | translate }}
            </button>
            <button
              type="submit"
              class="btn btn-success"
              [disabled]="
                isSaving || !documentTypeForm.valid || !validateOptionProduct()
              "
            >
              <i *ngIf="!isSaving" class="fa fa-save"></i>
              <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
              {{
                isSaving
                  ? ("config.management.DocumentTypes.Editor.Saving"
                    | translate)
                  : ("config.management.DocumentTypes.Editor.Save" | translate)
              }}
            </button>
          </div>
        </div>
        <div class="clearfix"></div>
      </fieldset>
    </form>
  </div>
</div>
