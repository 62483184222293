<div class="snack-container">
  <span>
    <span>
      <svg-icon name="check"></svg-icon>
    </span>
    <span class="label">{{ data.message }}</span>
    <a
      *ngIf="data.hyperLinkName && data.link"
      (click)="onClick($event)"
      target="_blank"
      class="label link"
      >{{ data.hyperLinkName }}</a
    >
  </span>
  <span mat-raised-button (click)="snackBarRef.dismiss()">
    <svg-icon name="times"></svg-icon>
  </span>
</div>
