import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { Employee } from "../../company/models/employee.model";
import { HelperService } from "../services/helper.service";

@Injectable()
export class AccountManagersListResolver implements Resolve<Employee[]> {
  constructor(
    private helperService: HelperService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Employee[]> {
    return this.helperService
      .getAccountManagers()
      .pipe(
        catchError(error => {
          return of(null);
        })
      );
  }
}
