<div class="wrapper">
  <form [formGroup]="profileForm" class="fields-container">
    <div class="fields-column">
      <div class="field-wrapper">
        <label class="field-title" for="firstName">{{
          "employee.FirstName" | translate
        }}</label>
        <input
          class="field-input"
          type="text"
          name="firstName"
          formControlName="firstName"
          id="firstName"
          [ngClass]="{
            'is-invalid':
              profileForm.get('firstName').errors &&
              profileForm.get('firstName').touched
          }"
        />

        <div class="error-container">
          <svg-icon
            name="mark!"
            *ngIf="
              profileForm.get('firstName').touched &&
              profileForm.get('firstName').hasError('required')
            "
          ></svg-icon>
          <small
            *ngIf="
              profileForm.get('firstName').touched &&
              profileForm.get('firstName').hasError('required')
            "
            class="form-text danger-color"
            >{{ "employee.FirstNameRequired" | translate }}
          </small>
        </div>
      </div>

      <div class="field-wrapper">
        <label class="field-title" for="email">{{
          "employee.Email" | translate
        }}</label>
        <input
          disabled
          class="field-input"
          type="text"
          name="email"
          formControlName="email"
          id="email"
          [ngClass]="{
            'is-invalid':
              profileForm.get('email').errors &&
              profileForm.get('email').touched
          }"
        />

        <div class="error-container">
          <svg-icon
            name="mark!"
            *ngIf="
              profileForm.get('email').touched &&
              profileForm.get('email').hasError('required')
            "
          ></svg-icon>
          <small
            *ngIf="
              profileForm.get('email').touched &&
              profileForm.get('email').hasError('required')
            "
            class="form-text danger-color"
            >{{ "employee.EmailRequired" | translate }}
          </small>
        </div>
      </div>

      <div class="field-wrapper">
        <label class="field-title" for="jobTitle">{{
          "employee.JobTitle" | translate
        }}</label>
        <input
          class="field-input"
          type="text"
          name="jobTitle"
          formControlName="jobTitle"
          id="jobTitle"
          [ngClass]="{
            'is-invalid':
              profileForm.get('jobTitle').errors &&
              profileForm.get('jobTitle').touched
          }"
        />
      </div>
    </div>

    <div class="fields-column">
      <div class="field-wrapper">
        <label class="field-title" for="lastName">{{
          "employee.LastName" | translate
        }}</label>
        <input
          class="field-input"
          type="text"
          name="lastName"
          formControlName="lastName"
          id="lastName"
          [ngClass]="{
            'is-invalid':
              profileForm.get('lastName').errors &&
              profileForm.get('lastName').touched
          }"
        />

        <div class="error-container">
          <svg-icon
            name="mark!"
            *ngIf="
              profileForm.get('lastName').touched &&
              profileForm.get('lastName').hasError('required')
            "
          ></svg-icon>
          <small
            *ngIf="
              profileForm.get('lastName').touched &&
              profileForm.get('lastName').hasError('required')
            "
            class="form-text danger-color"
            >{{ "employee.LastNameRequired" | translate }}
          </small>
        </div>
      </div>

      <div class="field-wrapper">
        <label class="field-title" for="phoneNumber">{{
          "employee.PhoneNumber" | translate
        }}</label>
        <input
          class="field-input"
          type="text"
          name="phoneNumber"
          formControlName="phoneNumber"
          id="phoneNumber"
          [ngClass]="{
            'is-invalid':
              profileForm.get('phoneNumber').errors &&
              profileForm.get('phoneNumber').touched
          }"
        />

        <div class="error-container">
          <svg-icon
            name="mark!"
            *ngIf="
              profileForm.get('phoneNumber').touched &&
              profileForm.get('phoneNumber').hasError('required')
            "
          ></svg-icon>
          <small
            *ngIf="
              profileForm.get('phoneNumber').touched &&
              profileForm.get('phoneNumber').hasError('required')
            "
            class="form-text danger-color"
            >{{ "employee.PhoneNumberRequired" | translate }}
          </small>
        </div>
      </div>

      <div class="field-wrapper">
        <label class="field-title" for="department">{{
          "employee.Department" | translate
        }}</label>
        <input
          class="field-input"
          type="text"
          name="department"
          formControlName="department"
          id="department"
          [ngClass]="{
            'is-invalid':
              profileForm.get('department').errors &&
              profileForm.get('department').touched
          }"
        />
      </div>

      <div class="field-submit-contrainer">
        <button class="field-submit-button" (click)="reset()">Reset</button>
        <button class="field-submit-button" (click)="save()">Save</button>
      </div>
    </div>
  </form>
</div>
