import { Component, Input, OnInit } from '@angular/core';
import { QuoteBase } from 'src/app/shared/quote/models/quote-base.model';
import { CreateQuoteHelperService } from '../../../../create-quote/services/create-quote-helper.service';

@Component({
  selector: 'app-ocean-freight-details',
  templateUrl: './ocean-freight-details.component.html',
  styleUrls: ['./ocean-freight-details.component.scss']
})
export class OceanFreightDetailsComponent implements OnInit {
  @Input() quote: QuoteBase;
  constructor(private createQuoteHelperService: CreateQuoteHelperService) {}

  ngOnInit(): void {}
//
  get oceanFreightDescription() {
    if (this.isLCLLoadType) {
      return 'Less than Container Load (LCL)';
    }
    if (this.isFCLLoadType) {
      return 'Full Container Load (FCL)';
    }
    if (this.isFTLLoadType) {
      return 'Full Truckload (FTL)';
    }
  }

  get isAirOCeanLCLLoadType() {
    return this.isLCLLoadType && this.isAirAndOcean;
  }

  get packingDetailsDescription() {
    if (this.isAirOCeanLCLLoadType) {
      return 'Same as air freight package details';
    }
  }

  get isLCLLoadType() {
    return this.createQuoteHelperService.isLCLLoadType(this.quote);
  }

  get isFCLLoadType() {
    return this.createQuoteHelperService.isFCLLoadType(this.quote);
  }

  get isFTLLoadType() {
    return this.createQuoteHelperService.isFTLLoadType(this.quote);
  }

  get isAirAndOcean() {
    return this.createQuoteHelperService.isAirAndOcean(this.freightReference);
  }

  get freightReference() {
    return this.createQuoteHelperService.getFreightReference(this.quote);
  }

  get has20Container() {
    return this.quote.cargoDetails.has20Container;
  }

  get has40Container() {
    return this.quote.cargoDetails.has40Container;
  }

  get has40HcContainer() {
    return this.quote.cargoDetails.has40HCContainer;
  }

  get containers20() {
    if (this.has20Container) {
      return this.quote.cargoDetails.containers_20;
    }
  }

  get containers40() {
    if (this.has40Container) {
      return this.quote.cargoDetails.containers_40;
    }
  }

  get containers40Hc() {
    if (this.has40HcContainer) {
      return this.quote.cargoDetails.containers_40_HC;
    }
  }

  get sectionTitle() {
    if ((this.isFCLLoadType || this.isFTLLoadType) && !this.isAirAndOcean) {
      return 'Cargo';
    } else {
      return 'Ocean Freight';
    }
  }

  get isDisplayContainersDetails() {
    return this.isFCLLoadType || this.isFTLLoadType;
  }
}
