<div class="d-flex">
  <div class="search-container">
    <input
      class="search-input"
      [ngModel]="''"
      [placeholder]="placeholder"
      (ngModelChange)="onValueChange($event)"
    /><svg-icon name="searchIcon" class="search-icon"></svg-icon>
  </div>
</div>
