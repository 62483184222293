import { Component, Inject, Input, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { ProductIdNameAndArticleNumber } from '../../models/product-id-name-and-article-number.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentParentType } from 'src/app/shared/models/enums/document-parent-type.model';
import { UploadDocumentPopUpPurpose } from 'src/app/shared/models/enums/upload-document-pop-up-purpose.enum';
import { BaseNamedEntity } from 'src/app/shared/models/shipment/base-named-entity.model';
import { ProductWithSimilarDocumentType } from 'src/app/shared/models/product-library/product-with-similar-document-type.model';
import { concat, Observable, of, Subject } from 'rxjs';
import { CreateProductService } from 'src/app/create-product/services/create-product.service';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  tap
} from 'rxjs/operators';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DocumentService } from 'src/app/shared/services/document.service';
import { ProductDocument } from 'src/app/shared/models/product-document.model';

@Component({
  selector: 'app-replace-linked-document',
  templateUrl: './replace-linked-document.component.html',
  styleUrls: ['./replace-linked-document.component.scss']
})
export class ReplaceLinkedDocumentComponent implements OnInit {
  @Input() currentDocumentType: string;
  @Input() parentId: string;
  @Input() documentPath: string;
  @Input() selectedSingleDocumentTypeId: number;
  @Input() documentToReplaceSqlId: number;
  @Input() selectedSingleDocumentTypeNames: string;

  products: ProductIdNameAndArticleNumber[];
  documentParentType = DocumentParentType;
  uploadDocumentPopUpPurpose = UploadDocumentPopUpPurpose;

  isLinkExistingDocument: boolean = false;
  existingDocuments: ProductWithSimilarDocumentType[];
  isLoading: boolean = false;

  existingDocumentsObservable: Observable<any> = new Observable<any>();
  existingDocuments$ = new Subject<string>();

  form: UntypedFormGroup;
  selectedDocumentType: BaseNamedEntity;

  selectedProducts: string[] = [];
  isReplacingDocument = false;
  hoveredItem: ProductWithSimilarDocumentType;

  constructor(
    private productService: ProductService,
    private createProductService: CreateProductService,
    private dialogService: DialogService,
    private fb: FormBuilder,
    private alertService: AlertService,
    private documentService: DocumentService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (!this.currentDocumentType) {
      this.currentDocumentType = this.data['currentDocumentType'];
    }
    if (!this.parentId) {
      this.parentId = this.data['parentId'];
    }
    if (!this.documentPath) {
      this.documentPath = this.data['documentPath'];
    }

    if (!this.selectedSingleDocumentTypeId) {
      this.selectedSingleDocumentTypeId = this.data[
        'selectedSingleDocumentTypeId'
      ];
    }
    if (!this.documentToReplaceSqlId) {
      this.documentToReplaceSqlId = this.data['documentToReplaceSqlId'];
    }
    if (!this.selectedSingleDocumentTypeNames) {
      this.selectedSingleDocumentTypeNames = this.data[
        'selectedSingleDocumentTypeNames'
      ];
    }
  }

  onExistingDocumentHover(item) {
    this.hoveredItem = item;
  }
  onExistingDocumentOutHover() {
    this.hoveredItem = null;
  }

  listenToChanges() {
    this.existingDocumentsObservable.subscribe(res => {
      this.existingDocuments = res;
    });
  }

  getProductsWithRecentlyUploadedDocumentType() {
    this.isLoading = true;
    this.createProductService
      .getProductsWithRecentlyUploadedDocumentType(this.currentDocumentType)
      .subscribe(res => {
        this.existingDocuments = res;
        this.isLoading = false;
      });
  }

  loadProductsWithSelectedDocumentType() {
    this.existingDocumentsObservable = concat(
      of([]),
      this.existingDocuments$.pipe(
        filter(res => {
          return res !== null && res.length >= 2;
        }),
        distinctUntilChanged(),
        debounceTime(500),
        tap(_ => (this.isLoading = true)),
        switchMap(textOfSearch => {
          return this.createProductService
            .getProductsWithDocumentTypeAndPartNumber(
              this.currentDocumentType,
              textOfSearch
            )
            .pipe(
              catchError(() => of([])),
              tap((value: ProductWithSimilarDocumentType[]) => {
                this.isLoading = false;
                this.existingDocuments = value;
              })
            );
        })
      )
    );
  }

  initForm() {
    this.form = this.fb.group({
      documentTypeId: this.selectedSingleDocumentTypeId,
      existingDocument: [null, Validators.required],
      notes: null
    });
  }

  onChangeFileUploadMethod(value: boolean) {
    this.isLinkExistingDocument = value;
    if (value) {
      this.setSelectedDocumentType();
      this.initForm();
      this.listenToChanges();
      this.getProductsWithRecentlyUploadedDocumentType();
      this.loadProductsWithSelectedDocumentType();
    } else {
      this.clearForm();
    }
  }

  clearForm() {
    this.form.reset();
    this.form.clearValidators();
  }

  isInvalid(formControlName: string): boolean {
    let control = this.form.get(formControlName);
    return control.touched && control.errors != null;
  }

  setSelectedDocumentType() {
    this.selectedDocumentType = {
      id: this.selectedSingleDocumentTypeId,
      name: this.currentDocumentType
    } as BaseNamedEntity;
  }

  closeDialog() {
    this.dialogService.close();
  }

  replaceDocument() {
    this.isReplacingDocument = true;
    let document = this.form.get('existingDocument')?.value as ProductDocument;
    let notes = this.form.get('notes')?.value;
    if (notes) {
      document.notes = notes;
    }

    this.productService
      .replaceProductDocumentWithExistingDocument(
        this.parentId,
        document,
        this.selectedProducts
      )
      .subscribe(res => {
        this.isReplacingDocument = false;
        this.alertService.success('Document successfully replaced');
        this.productService.productLibraryRequestUpdated.emit(res);
        this.closeDialog();
      });
  }

  onUploadingDocumentToMultipleProducts(formData: FormData) {
    this.isReplacingDocument = true;
    this.documentService
      .replaceMultipleProductDocuments(formData, this.selectedProducts)
      .subscribe(res => {
        this.isReplacingDocument = false;
        this.alertService.success('Document successfully replaced');
        this.productService.productLibraryRequestUpdated.emit(res);
        this.closeDialog();
      });
  }

  onSelectingProducts(selectedProducts: string[]): void {
    this.selectedProducts = selectedProducts;
  }
}
