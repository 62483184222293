<div class="notifications-container">
  <div
    class="notifiction-category-group"
    *ngFor="let currentCategory of notificationSettings2D; let index = index"
  >
    <label
      class="notifiction-category-group-title"
      *ngIf="!rowsWithoutLabel.includes(index) && currentCategory?.length > 0"
      >{{
        findCategoryName(
          currentCategory[0]?.notificationType?.notificationTypeCategoryId
        )
      }}</label
    >
    <ng-container *ngFor="let item of currentCategory">
      <div
        *ngIf="
          !notificationHelper.isAssignOrUnassignAccountManagerNotification(item)
        "
        class="notification-option"
      >
        <input
          class="checkbox"
          (click)="onToggleClick(item)"
          [(ngModel)]="item.isEmailNotificationActive"
          type="checkbox"
          [id]="item.id"
        />
        <label class="notification-label" [for]="item.id">
          {{ item.notificationType.name }}
        </label>
      </div>
    </ng-container>
  </div>
  <label class="notification-selectall-label" (click)="selectAction()">
    Select/Unselect All
  </label>
</div>
