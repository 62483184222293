import { Inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileViewerComponent } from './file-viewer.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { FileViewerService } from './file-viewer.service';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [CommonModule, MatDialogModule, NgxDocViewerModule],
  exports: [FileViewerComponent],
  declarations: [FileViewerComponent],
  providers: [FileViewerService]
})
export class FileViewerModule {}
