import { Directive, ElementRef, Renderer2, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appErrorHighlight]'
})
export class ErrorHighlightDirective {
  @Input('appErrorHighlight') errorClass: string;

  constructor(private el: ElementRef, private renderer: Renderer2, private control: NgControl) {}

  @HostListener('ngModelChange')
  @HostListener('input')
  onInputChange(): void {
    this.applyErrorClass();
  }

  @HostListener('blur')
  onBlur(): void {
    this.applyErrorClass();
  }

  private applyErrorClass(): void {
    if (this.control.invalid && this.control.touched) {
      this.renderer.addClass(this.el.nativeElement, this.errorClass);
    } else {
      this.renderer.removeClass(this.el.nativeElement, this.errorClass);
    }
  }
}
