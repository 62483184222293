export enum UserPermissions {
  ViewRole = 'Role.View',
  CreateRole = 'Role.Create',
  UpdateRole = 'Role.Update',
  DeleteRole = 'Role.Delete',
  ViewShipmentActivities = 'ShipmentActivities.View',
  CreateShipmentActivities = 'ShipmentActivities.Create',
  UpdateShipmentActivities = 'ShipmentActivities.Update',
  DeleteShipmentActivities = 'ShipmentActivities.Delete',
  ViewRegistrationActivities = 'RegistrationActivities.View',
  CreateRegistrationActivities = 'RegistrationActivities.Create',
  UpdateRegistrationActivities = 'RegistrationActivities.Update',
  DeleteRegistrationActivities = 'RegistrationActivities.Delete',
  ViewProductRegistrationKPIs = 'ProductRegistrationKPIs.View',
  CreateProductRegistrationKPIs = 'ProductRegistrationKPIs.Create',
  UpdateProductRegistrationKPIs = 'ProductRegistrationKPIs.Update',
  DeleteProductRegistrationKPIs = 'ProductRegistrationKPIs.Delete',
  ViewRegistrationWorkflows = 'RegistrationWorkflows.View',
  CreateRegistrationWorkflows = 'RegistrationWorkflows.Create',
  UpdateRegistrationWorkflows = 'RegistrationWorkflows.Update',
  DeleteRegistrationWorkflows = 'RegistrationWorkflows.Delete',
  ViewCarriers = 'Carriers.View',
  CreateCarriers = 'Carriers.Create',
  UpdateCarriers = 'Carriers.Update',
  DeleteCarriers = 'Carriers.Delete',
  ViewChargeType = 'ChargeType.View',
  CreateChargeType = 'ChargeType.Create',
  UpdateChargeType = 'ChargeType.Update',
  DeleteChargeType = 'ChargeType.Delete',
  ViewCities = 'Cities.View',
  CreateCities = 'Cities.Create',
  UpdateCities = 'Cities.Update',
  DeleteCities = 'Cities.Delete',
  ViewShipmentWorkflow = 'ShipmentWorkflow.View',
  CreateShipmentWorkflow = 'ShipmentWorkflow.Create',
  UpdateShipmentWorkflow = 'ShipmentWorkflow.Update',
  DeleteShipmentWorkflow = 'ShipmentWorkflow.Delete',
  DeleteCustomers = 'Customers.Delete',
  ViewShipmentKPIs = 'ShipmentKpis.View',
  CreateShipmentKPIs = 'ShipmentKpis.Create',
  UpdateShipmentKPIs = 'ShipmentKpis.Update',
  DeleteShipmentKPIs = 'ShipmentKpis.Delete',
  ViewCurrency = 'Currency.View',
  CreateCurrency = 'Currency.Create',
  UpdateCurrency = 'Currency.Update',
  DeleteCurrency = 'Currency.Delete',
  ViewDistricts = 'Districts.View',
  CreateDistricts = 'Districts.Create',
  UpdateDistricts = 'Districts.Update',
  DeleteDistricts = 'Districts.Delete',
  ViewDocumentType = 'DocumentType.View',
  CreateDocumentType = 'DocumentType.Create',
  UpdateDocumentType = 'DocumentType.Update',
  DeleteDocumentType = 'DocumentType.Delete',
  ViewUsers = 'Users.View',
  CreateUsers = 'Users.Create',
  UpdateUsers = 'Users.Update',
  DeleteUsers = 'Users.Delete',
  ViewProductCategories = 'ProductCategories.View',
  CreateProductCategories = 'ProductCategories.Create',
  UpdateProductCategories = 'ProductCategories.Update',
  DeleteProductCategories = 'ProductCategories.Delete',
  ViewLeadTimes = 'LeadTimes.View',
  UpdateLeadTimes = 'LeadTimes.Update',
  ViewProductRegistrationLeadTimes = 'ProductRegistrationLeadTimes.View',
  UpdateProductRegistrationLeadTimes = 'ProductRegistrationLeadTimes.Update',
  ViewFreePLProfile = 'FreePLProfile.View',
  UpdateFreePLProfile = 'FreePLProfile.Update',
  ViewEmployees = 'Employees.View',
  UpdateEmployees = 'Employees.Update',
  CreateEmployees = 'Employees.Create',
  ViewReports = 'Reports.View',
  ViewShippersConsignees = 'ShippersConsignees.View',
  CreateShippersConsignees = 'ShippersConsignees.Create',
  UpdateShippersConsignees = 'ShippersConsignees.Update',
  DeleteShippersConsignees = 'ShippersConsignees.Delete',
  CreateFreePLCustomers = 'FreePLCustomers.Create',
  ViewFreePLCustomers = 'FreePLCustomers.View',
  UpdateFreePLCustomers = 'FreePLCustomers.Update',
  ManageMedicalOperationalOversight = 'MedicalOperationalOversight.View',
  ManageNonMedicalOperationalOversight = 'NonMedicalOperationalOversight.View',
  ViewOperationalActivites = 'OngoingShipmentActivities.OperationalActivities.View',
  ViewForm4Activities = 'OngoingShipmentActivities.Form4Activities.View',
  ViewFreightActivities = 'OngoingShipmentActivities.FreightActivities.View',
  ViewCustomsClearanceActivities = 'OngoingShipmentActivities.CustomsClearanceActivities.View',
  ViewTruckingActivities = 'OngoingShipmentActivities.TruckingActivities.View',
  ViewInvoicingOperationsActivities = 'OngoingShipmentActivities.InvoicingOperationsActivities.View',
  ViewInvoicingFinanceActivities = 'OngoingShipmentActivities.InvoicingFinanceActivities.View',
  ViewAgricultureInspectionActivities = 'OngoingShipmentActivities.AgricultureInspectionActivities.View',
  ViewNTRAInspectionActivities = 'OngoingShipmentActivities.NTRAInspectionActivities.View',
  ViewGOIECInspectionActivities = 'OngoingShipmentActivities.GOIECInspectionActivities.View',
  ViewChemicalInspectionActivities = 'OngoingShipmentActivities.ChemicalInspectionActivities.View',
  ViewNationalSecurityInspectionActivities = 'OngoingShipmentActivities.NationalSecurityInspectionActivities.View',
  ViewScaleAndBalanceInspectionActivities = 'OngoingShipmentActivities.ScaleAndBalanceInspectionActivities.View',
  ManageNonMedicalCompanies = 'NonMedicalCompanies.Manage',
  ManageMedicalCompanies = 'MedicalCompanies.Manage',
  CreateQuote = 'Quotes.Create',
  CreateQuoteProposal = 'QuoteProposals.Create',
  ViewQuoteProposal = 'QuoteProposals.View',
  AcceptAndRejectQuoteProposal = 'QuoteProposals.AcceptAndReject',
  EditShipmentReferenceNumbers = 'Shipments.EditReferenceNumbers',
  DuplicateShipment = 'Shipments.Duplicate',
  ViewShipmentsForSelectedCustomer = 'Shipments.ViewSelectedCustomer',
  ViewShipmentsForMedicalCustomers = 'Shipments.ViewMedical',
  ViewShipmentsForNonMedicalCustomers = 'Shipments.ViewNonMedical',
  ViewReadyForInvoicePreparationShipments = 'Shipments.ViewReadyForInvoicePreparation',
  ViewReadyForInvoiceIssuanceShipments = 'Shipments.ViewReadyForInvoiceIssuance',
  PauseResumeShipment = 'Shipments.PauseResume',
  ViewActiveShipmentsOnDashboard = 'Shipments.ViewActiveShipmentsOnDashboard',
  ViewAssignedShipments = 'Shipments.ViewAssigned',
  CancelShipments = 'Shipments.Cancel',
  AddShipmentCharge = 'Shipments.AddCharge',
  EditShipmentCharge = 'Shipments.EditCharge',
  DeleteShipmentCharge = 'Shipments.DeleteCharge',
  ViewShipmentActivityLog = 'Shipments.ViewActivityLog',
  ViewShipmentServicesAndCharges = 'Shipments.ViewServicesAndCharges',
  ViewShipmentDocuments = 'Shipments.ViewDocuments',
  ViewShipmentDetails = 'Shipments.ViewDetails',
  ViewShipmentLeadTimes = 'Shipments.ViewLeadTimes',
  EditAdditionalShipmentDetails = 'Shipments.EditAdditionalDetails',
  ViewShipmentProgressCard = 'Shipments.ViewProgressCard',
  ViewAndEditTruckingDetails = 'Shipments.ViewAndEditTruckingDetails',
  EditShipmentsActualDates = 'Shipments.EditActualDates',
  EditShipmentsExpectedDates = 'Shipments.EditExpectedDates',
  CreateShipment = 'Shipments.Create',
  ChangeShipmentServices = 'Shipments.ChangeServices',
  EditShipmentOriginDestination = 'Shipments.EditOriginDestination',
  EditTheInvolvedPartiesSection = 'Shipments.EditTheInvolvedPartiesSection',
  UpdateShipmentStatus = 'Shipments.UpdateStatus',
  UndoShipmentStatusUpdate = 'Shipments.UndoStatusUpdate',
  PostShipmentActivity = 'Shipments.PostActivity',
  DeletePostedShipmentActivity = 'Shipments.DeletePostedActivity',
  UploadShipmentDocument = 'Shipments.UploadDocument',
  DownloadShipmentDocument = 'Shipments.DownloadDocument',
  ReplaceShipmentDocument = 'Shipments.ReplaceDocument',
  DeleteShipmentDocument = 'Shipments.DeleteDocument',
  InitiateForm4Process = 'Form4.InitiateForm4Process',
  UpdateForm4Status = 'Form4.UpdateForm4Status',
  EditShipmentPackingDetails = 'Shipments.EditPackingDetails',
  ViewForm4List = 'form4Requests.manage',
  SendCommentsToShipment = 'Shipments.SendComments',
  EditShipmentProducts = 'Shipments.EditProducts',
  SendPendingActionToShipment = 'Shipments.SendPendingAction',
  AssignShiperConsignee = 'ShipperConsignee.Assign',
  ViewQuoteDocuments = 'QuoteDocument.View',
  EditQuoteDocuments = 'QuoteDocument.Edit',
  ViewQuoteProposalTab = 'Quotes.ViewProposal',
  ViewQuoteSummaryTab = 'Quotes.ViewSummary',
  ViewQuoteListReceivedTab = 'QuoteListAccess.Received',
  ViewQuoteListPendingApprovalTab = 'QuoteListAccess.PendingApproval',
  ViewQuoteListApprovedTab = 'QuoteListAccess.Approved',
  ViewQuoteListArchivedTab = 'QuoteListAccess.Archived',
  ViewQuoteSummaryRoutingSection = 'Quotes.ViewRoutingDetails',
  EditQuoteSummaryRoutingSection = 'Quotes.EditRoutingDetails',
  SendCommentToQuote = 'Quotes.Comment',
  SetOrFulfillQuotePendingAction = 'Quotes.SetOrFulfillPendingAction',
  ViewQuoteSummaryCargoDetailsSection = 'Quotes.ViewCargoDetails',
  EditQuoteSummaryCargoDetailsSection = 'Quotes.EditCargoDetails',
  CancelQuote = 'Quotes.Cancel',
  ViewTruckingServiceQuoteProposalCard = 'QuoteProposal.ViewTruckingService',
  EditTruckingServiceQuoteProposalCard = 'QuoteProposal.EditTruckingService',
  ViewQuoteProposalIorOrEorService = 'QuoteProposal.ViewIORorEORService',
  EditQuoteProposalIorOrEorService = 'QuoteProposal.EditIORorEORService',
  ViewQuoteProposalFreightService = 'QuoteProposal.ViewFreightService',
  EditQuoteProposalFreightService = 'QuoteProposal.EditFreightService',
  ViewQuoteProposalClearanceService = 'QuoteProposal.ViewClearanceService',
  EditQuoteProposalClearanceService = 'QuoteProposal.EditClearanceService',
  EnableRegistrationListAccess = 'ProductRegistration.EnableRegistrationListAccess',
  ViewActivityLogTab = 'ProductRegistration.ViewActivityLogTab',
  ViewLeadTimesTab = 'ProductRegistration.ViewLeadTimesTab',
  ViewOverviewTab = 'ProductRegistration.ViewOverviewTab',
  ViewProductsTab = 'ProductRegistration.ViewProductsTab',
  ManageAllQuotesOperationalOversight = 'AllQuotesOperationalOversight.View',
  SubmitQuoteProposal = 'QuoteProposal.SubmitQuoteProposal',
  EnableRegistrationStatusManagment = 'RegistrationStatusManagement.enable',
  PauseResumeProductRegistration = 'ProductRegistration.PauseResumeRegistration',
  CancelProductRegistration = 'ProductRegistration.CancelRegistration',
  DeleteProductFromRegistration = 'ProductRegistration.DeleteProductFromRegistration',
  CreateProductRegistration = 'ProductRegistration.CreateRegistration',
  CreateProduct = 'ProductManagment.CreateProduct',
  DeleteProduct = 'ProductManagment.DeleteProduct',
  ProductListView = 'ProductsListAccess.Enable',
  SetOrFulfillRegistrationPendingAction = 'ProductRegistration.SetOrFulfillPendingAction',
  SendCommentsToRegistration = 'ProductRegistration.AddComment',
  EditProductRegistration = 'ProductRegistration.EditRegistration',
  UploadProductDocument = 'DocumentsManagement.UploadDocument',
  DownloadProductDocument = 'DocumentsManagement.DownloadDocument',
  ReplaceProductDocument = 'DocumentsManagement.ReplaceDocument',
  DeleteProductDocument = 'DocumentsManagement.DeleteDocument',
  ViewProductOverviewTab = 'ProductDetailsManagement.ViewTab',
  ViewComplianceDetailsManagement = 'ComplianceDetailsManagement.ViewTab',
  ViewProductDocumentsManagement = 'ProductDocumentsManagement.ViewTab',
  UpdateProductStatus = 'ProductStatusManagement.Enable',
  EditProductDetailsManagement = 'ProductDetailsManagement.Edit',
  EditComplianceDetailsManagement = 'ComplianceDetailsManagement.Edit',
}
