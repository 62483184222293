import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {
  @Input() maxLength: number = 50; // Set a default max length

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: Event) {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue = inputElement.value;

    // Remove non-numeric characters except the dot
    inputValue = inputValue.replace(/[^0-9.]/g, '');

    // Enforce max length
    if (inputValue.length > this.maxLength) {
      inputValue = inputValue.substring(0, this.maxLength);
    }

    // Update the input value
    inputElement.value = inputValue;
  }

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    const charCode = event.which || event.keyCode;
    const inputValue = this.el.nativeElement.value;

    // Allow only numeric characters and dot
    if (
      (charCode < 48 || charCode > 57) &&
      charCode !== 46 && // Dot
      !(charCode === 45 && inputValue.indexOf('-') === -1) 
    ) {
      event.preventDefault();
    }
  }
}
