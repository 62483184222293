import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorBadgeComponent } from './error-badge.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ErrorBadgeComponent],
  exports:[ErrorBadgeComponent]
})
export class ErrorBadgeModule { }
