<div class="module-widget pb-0">
  <div class="d-flex justify-content-between widget-header">
    <app-page-header [title]="title"></app-page-header>
    <button
      class="create-quote"
      [routerLink]="redirectionLinkAdd"
      *ngIf="isAddButtonEnabled"
    >
      <div class="d-flex">
        <span class="plus">+</span>
        <span class="create-quote-btn ">{{ buttonTitle }}</span>
      </div>
    </button>
  </div>
  <div class="tabs-container">
    <mat-tab-group
      animationDuration="0ms"
      class="pt-2"
      (selectedTabChange)="onTabChange($event)"
    >
      <mat-tab *ngFor="let tab of tabsList; let i = index" [label]="tab.name">
        <ng-template mat-tab-label>
          <div>
            {{ tab.name }}
            <i
              *ngIf="tab.hasRedDot"
              class="fa fa-circle ml-2 about-to-expire-icon"
              aria-hidden="true"
            ></i>
          </div>
        </ng-template>
        <app-add-empty-data-msg
          *ngIf="
            (!cardData || cardData?.items?.length == 0) && isLoading == false;
            else dataContainer
          "
          [boldText]="boldText[i]"
          [normalText]="normalText.length > 1 ? normalText[i] : normalText[0]"
        ></app-add-empty-data-msg>
        <ng-template #dataContainer>
          <div
            class="data-container-body"
            *ngIf="module == moduleName.Quote"
            (scroll)="onScroll($event)"
          >
            <div *ngFor="let item of cardData?.items">
              <app-quote-card
                [quote]="item"
                [routerLink]="getQuoteRouterLink(item?.id)"
              ></app-quote-card>
            </div>
          </div>
          <div
            *ngIf="module == moduleName.Registration"
            class="data-container-body"
            (scroll)="onScroll($event)"
          >
            <div *ngFor="let productRegistration of cardData?.items">
              <app-product-registration-card
                class="cursor-pointer"
                [routerLink]="
                  '../registrations/' + productRegistration?.id + '/details'
                "
                [productRegistration]="productRegistration"
                [isInDashboard]="true"
                [isBlueArrowHidden]="true"
              ></app-product-registration-card>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<div class="card-footer text-center text-body-secondary footer">
  <button
    class="arrow-button"
    [routerLink]="redirectionLinklist"
    [disabled]="isViewAllButtonDisabled"
    [class]="isViewAllButtonDisabled ? 'opacity-5' : ''"
  >
    {{ "View all " + module + "s " }}
    <svg-icon name="right-arrow-head"></svg-icon>
  </button>
</div>
