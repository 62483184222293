import { Component, Input, OnInit } from '@angular/core';
import { BaseNamedEntity } from 'src/app/shared/models/shipment/base-named-entity.model';
import { EmployeeNotificationSetting } from 'src/app/shared/models/user-notification-setting.model';
import { HelperService } from 'src/app/shared/services/helper.service';
import { NotificationHelperService } from 'src/app/shared/services/notification-helper.service';
import { NotificationSettingService } from 'src/app/shared/services/notification-setting.service';
@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {
  notificationSettings: EmployeeNotificationSetting[];
  notificationSettings2D: EmployeeNotificationSetting[][] = [];
  rowsWithoutLabel: number[] = [];
  rowWithoutLabelIndexArray: number[] = [];
  maxRowsPerColumn: number = 14;
  notificationTypeCategories: BaseNamedEntity[];

  @Input() employeeId: number;
  constructor(
    private notificationSettingService: NotificationSettingService,
    private notificationHelper: NotificationHelperService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.notificationSettingService
      .getNotificationSettings(this.employeeId)
      .subscribe(data => {
        this.notificationSettings = data;
        this.initNotificationSettings();
      });

    this.helperService.getNotificationTypeCategories().subscribe(res => {
      this.notificationTypeCategories = res;
    });
  }

  initNotificationSettings(): void {
    for (const item of this.notificationSettings) {
      let notificationType = item.notificationType;

      if (
        !this.notificationSettings2D[
          notificationType.notificationTypeCategoryId
        ]
      ) {
        this.notificationSettings2D[
          notificationType.notificationTypeCategoryId
        ] = [];
      }

      this.notificationSettings2D[
        notificationType.notificationTypeCategoryId
      ].push(item);
    }

    this.notificationSettings2D.shift();

    this.notificationSettings2D = this.flattenSplittedArray(
      this.splitArraysInRows()
    );

    this.rowWithoutLabelIndexArray = this.createArrayWithoutRowLabel();
  }

  splitArraysInRows(): EmployeeNotificationSetting[] {
    let result = [];
    for (const row of this.notificationSettings2D) {
      let currentRow = [];
      for (let i = 0; i < row?.length; i += this.maxRowsPerColumn) {
        let subArray = row.slice(i, i + this.maxRowsPerColumn);
        currentRow.push(subArray);
      }
      result.push(currentRow);
    }
    return result;
  }

  flattenSplittedArray(arr): EmployeeNotificationSetting[][] {
    const result = [];
    for (const currentOuter of arr) {
      for (let i = 0; i < currentOuter.length; i++) {
        if (i > 0) {
          this.rowsWithoutLabel.push(i);
        }
        result.push(currentOuter[i]);
      }
    }
    return result;
  }

  createArrayWithoutRowLabel(): number[] {
    let modifiedArray = Array.from(
      { length: this.notificationSettings2D.length },
      (_, index) => index
    );
    modifiedArray = modifiedArray.filter(
      (_, index) => !this.rowsWithoutLabel.includes(index)
    );
    return modifiedArray;
  }

  findCategoryName(index: number): string {
    let category = this.notificationTypeCategories.find(c => c.id === index);
    return category?.name;
  }

  onToggleClick(notificationSetting: EmployeeNotificationSetting) {
    if (!notificationSetting.isEmailNotificationActive) {
      this.notificationSettingService
        .activateNotificationSetting(
          notificationSetting.employeeId,
          notificationSetting.notificationType.name
        )
        .subscribe();
    } else {
      this.notificationSettingService
        .deactivateNotificationSetting(
          notificationSetting.employeeId,
          notificationSetting.notificationType.name
        )
        .subscribe();
    }
  }

  activateAllNotificationSettings(): void {
    this.notificationSettingService
      .activateAllNotificationSettings(this.employeeId)
      .subscribe(() => {
        this.resetAllNotificationSettingsView(true);
      });
  }

  deactivateAllNotificationSettings(): void {
    this.notificationSettingService
      .deactivateAllNotificationSettings(this.employeeId)
      .subscribe(() => {
        this.resetAllNotificationSettingsView(false);
      });
  }

  selectAction(): void {
    if (this.isAtLeastOneNotificationNotSelected) {
      this.activateAllNotificationSettings();
    } else {
      this.deactivateAllNotificationSettings();
    }
  }

  get isAtLeastOneNotificationNotSelected(): boolean {
    for (const currentNotificationCategoryGroup of this
      .notificationSettings2D) {
      for (const currentNotification of currentNotificationCategoryGroup) {
        if (!currentNotification.isEmailNotificationActive) {
          return true;
        }
      }
    }
    return false;
  }

  resetAllNotificationSettingsView(isSelected: boolean) {
    for (const currentNotificationCategoryGroup of this
      .notificationSettings2D) {
      for (const currentNotification of currentNotificationCategoryGroup) {
        currentNotification.isEmailNotificationActive = isSelected;
      }
    }
  }
}
