import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { HSCode } from '../models/shipment/hsCodes.model';
import { HSCodeService } from '../../admin-portal-configs/services/hsCode.service';

@Injectable({
  providedIn: 'root'
})
export class HSCodeResolver implements Resolve<HSCode[]> {
  constructor(private router: Router, private hsCodeService: HSCodeService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<HSCode[]> {
    return this.hsCodeService.getHSCodes().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
