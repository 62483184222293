<div class="w-100">
  <div class="devider"></div>
  <div class="row w-100">
    <div class="title col-2">
      {{ hasDomesticService ? "Trucking details" : "Trucking" }}
    </div>
    <div
      class="col-10 trucking-details"
      *ngIf="hasDomesticService; else hasNoDomesticType"
    >
      <div class="section">
        <div class="row px-0 trucking-details-padding">
          <div class="field-label ">Route</div>
        </div>
        <div class="row px-0 trucking-details-padding">
          <div class="field-value row-value ">{{ domesticTruckingRoute }}</div>
        </div>
      </div>

      <div class="section">
        <div class="row  px-0 trucking-details-padding">
          <div class="col-2 field-label">Load Type</div>
          <div class="col-2 field-label">Truck Type</div>
          <div class="col-2 field-label">Truck Subtype</div>
          <div class="col-2 field-label">Quantity</div>
        </div>
        <div class="row px-0 trucking-details-padding">
          <div class="col-2 field-value">
            {{ domesticTruckingInformation?.loadType || "-" }}
          </div>
          <div class="col-2 field-value">
            {{ domesticTruckingInformation?.truckType || "-" }}
          </div>
          <div class="col-2 field-value">
            {{ domesticTruckingInformation?.truckSubType || "-" }}
          </div>
          <div class="col-2 field-value">
            {{ domesticTruckingInformation?.quantity || "-" }}
          </div>
        </div>
      </div>
      <div class="row px-0 trucking-details-padding">
        <div class="field-label ">Special Requests</div>
      </div>
      <div class="row px-0 trucking-details-padding">
        <div class="field-value row-value">
          {{ domesticTruckingInformation?.specialRequirements || "-" }}
        </div>
      </div>
    </div>
    <div class="col-10 trucking-details" *ngIf="isLandStandaloneService">
      <div *ngIf="quoteLandTruckingDetails?.landTruckingInformation.length > 0">
        <div class="section">
          <div class="row  px-0 trucking-details-padding">
            <div class="col-2 field-label">Truck</div>
            <div class="col-2 field-label">Truck Load</div>
            <div class="col-2 field-label">Truck Type</div>
            <div class="col-2 field-label">Truck Subtype</div>
            <div class="col-2 field-label">Quantity</div>
            <div class="col-2 field-label"></div>
          </div>

          <div
            class="row  px-0 trucking-details-padding"
            *ngFor="
              let item of quoteLandTruckingDetails?.landTruckingInformation;
              let i = index
            "
          >
            <div class="col-2 field-value">{{ i + 1 }}</div>
            <div class="col-2 field-value">
              {{ item?.loadType || "-" }}
            </div>
            <div class="col-2 field-value">
              {{ item?.truckType || "-" }}
            </div>
            <div class="col-2 field-value">
              {{ item?.truckSubType || "-" }}
            </div>
            <div class="col-2 field-value">
              {{ domesticTruckingInformation?.quantity || "-" }}
            </div>
            <div class="col-2 field-value"></div>
          </div>
        </div>
        <div class="section">
          <div class="row px-0 trucking-details-padding">
            <div class="field-label ">Special Requests</div>
          </div>
          <div class="row px-0 trucking-details-padding">
            <div class="field-value row-value">
              {{ domesticTruckingInformation?.specialRequirements || "-" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #hasNoDomesticType>
      <div class="trucking-details" *ngIf="!isLandStandaloneService">
        <div class="row px-0 trucking-details-padding">
          <div class="field-label ">Truck Type</div>
        </div>
        <div class="row px-0 trucking-details-padding">
          <div class="field-value row-value">
            To be provided by account manager
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
