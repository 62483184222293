import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HSCode } from 'src/app/shared/models/shipment/hsCodes.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HSCodeService {
  @Output() hsCodesUpdated = new EventEmitter<HSCode>();
  @Output() hsCodesAdded = new EventEmitter<HSCode[]>();
  baseURL = environment.apiUrl + 'HSCode';
  constructor(private http: HttpClient) {}

  getHSCodes(
    searchKeyword: string = '',
    pageIndex = 0,
    pageSize = 10
  ): Observable<HSCode[]> {
    return this.http.get<HSCode[]>(
      this.baseURL +
        `/?searchKeyword=${searchKeyword}&pageIndex=${pageIndex}&pageSize=${pageSize}`
    );
  }

  addNewHSCode(hsCode: HSCode): Observable<HSCode[]> {
    return this.http.post<HSCode[]>(this.baseURL, hsCode);
  }

  deleteHSCode(id: string): Observable<HSCode> {
    return this.http.delete<HSCode>(this.baseURL + '/' + id);
  }

  updateHSCode(hsCode: HSCode): Observable<HSCode> {
    return this.http.put<HSCode>(this.baseURL + '/' + hsCode['id'], hsCode);
  }
}
