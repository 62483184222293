import { UserPermissions } from 'src/app/shared/models/enums/user-permission.enum';
import { ShipmentListViewEnum } from '../enums/shipment-list-view.enum';
import { ShipmentsListViews } from '../models/shipments-list-views.model';

export const ShipmentListViews: ShipmentsListViews[] = [
  {
    view: null,
    permission: [
      UserPermissions.ViewShipmentsForSelectedCustomer,
      UserPermissions.ViewShipmentsForMedicalCustomers,
      UserPermissions.ViewShipmentsForNonMedicalCustomers,
      UserPermissions.ViewReadyForInvoicePreparationShipments,
      UserPermissions.ViewReadyForInvoiceIssuanceShipments
    ],
    pageTitle: 'Shipments'
  },
  {
    view: ShipmentListViewEnum.Form4Requests,
    permission: [UserPermissions.ViewForm4List],
    pageTitle: 'Form 4 Shipments'
  },
  {
    view: ShipmentListViewEnum.OperationsOversight,
    permission: [
      UserPermissions.ManageMedicalOperationalOversight,
      UserPermissions.ManageNonMedicalOperationalOversight
    ],
    pageTitle: 'Shipments'
  }
];
