export const DocumentTypeConditions: any[] = [
  {
    value: true,
    name: 'Mandatory'
  },
  {
    value: false,
    name: 'Optional'
  }
];
