import { Component, Input, OnInit } from '@angular/core';
import { ProgressPoint } from '../../models/progress-point-model';
import { ActivityLog } from '../../models/activity-log.model';
import { ProductRegistrationCommentWithActionActors } from '../../models/product-registration-comment-with-action-actors.model';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Input() isCurrentProgress: boolean;
  @Input() progressPoint: ProgressPoint;
  @Input() activityLogs: ActivityLog[];
  @Input() productRegistrationComments: ProductRegistrationCommentWithActionActors[];
  @Input() productId: string;

  get subtitle() {
    // out of scope for now
    return '';
  }

  constructor() {}

  get lastActvity() {
    if (this.activityLogs.length != 0) {
      return this.activityLogs[this.activityLogs.length - 1]
        ?.registrationActivity?.name;
    }
  }

  ngOnInit(): void {

  }
}
