import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityCategory } from 'src/app/shared/models/shipment/activity-category.model';
import { Activity } from 'src/app/shared/models/shipment/activity.model';
import { Progress } from 'src/app/shared/models/shipment/progress.model';
import { Status } from 'src/app/shared/models/shipment/status.model';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { ActivityService } from '../../../services/activity.service';
import { SelectedServices } from 'src/app/admin-portal-configs/models/selected-services.model';
import { ServiceTypes } from 'src/app/shared/models/shipment/service-types.enum';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'app-add-or-edit-activity',
  templateUrl: './add-or-edit-activity.component.html',
  styleUrls: ['./add-or-edit-activity.component.scss']
})
export class AddOrEditActivityComponent implements OnInit {
  activityForm: UntypedFormGroup;
  activity: Activity;
  statuses: Status[];
  activityCategories: ActivityCategory[];
  progresses: Progress[];
  isSaving: boolean = false;
  isNewActivity: boolean = false;
  duplicateActivity: boolean = false;
  activityTypeError: boolean = false;
  showErrorMessages: boolean = false;
  selectedServices: SelectedServices[] = [];
  constructor(
    private fb: UntypedFormBuilder,
    private transitionService: ActivityService,
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private router: Router,
    private route: ActivatedRoute,
    private formsHelperService: FormsHelperService
  ) {}

  ngOnInit(): void {
    this.loadActivityCategories();

    this.route.data.subscribe(data => {
      this.activity = data['activity'];
      this.statuses = data['statuses'];
      this.progresses = data['progresses'];
    });
    this.getFreePlServices();
    this.initForm();
    this.addSelectedServices();
  }

  initForm() {
    this.isNewActivity = this.activity == null;
    this.activity = this.activity || ({} as Activity);
    this.activity.progressId = this.activity.progressId || 0;
    this.activity.statusId = this.activity.statusId || 0;

    this.activityForm = this.fb.group({
      name: [this.activity.name, [Validators.required]],
      isVoid: [this.activity.isVoid],
      isForOcean: [this.activity.isForOcean],
      isForAir: [this.activity.isForAir],
      statusId: [this.activity.statusId],
      progressId: [this.activity.progressId],
      categoryId: [this.activity.categoryId],
      services: this.fb.array([])
    });
  }

  loadActivityCategories() {
    this.transitionService
      .getActivityCategories()
      .subscribe(activityCategories => {
        this.activityCategories = activityCategories;
      });
  }

  save() {
    const formVal = this.activityForm.value;

    this.processForm(formVal);

    if (!this.validateForm(formVal)) {
      this.alertService.error('Invalid Form!');
      return;
    }

    if (this.isNewActivity) {
      this.addNewRequest(formVal);
    } else {
      this.updateRequest(formVal);
    }
  }

  private validateForm(formVal: any): boolean {
    this.activityForm.markAllAsTouched();
    this.resetErrorsFlags();

    const isForOceanSelected = formVal.isForOcean;
    const isForAirSelected = formVal.isForAir;
    const isServiceChosen = formVal.services.length > 0;

    if (
      !formVal.isVoid &&
      !isForOceanSelected &&
      !isForAirSelected &&
      !isServiceChosen
    ) {
      this.activityTypeError = true;
    }

    if (formVal.statusId === 0 && !formVal.isVoid) {
      this.showErrorMessages = true;
    }

    if (this.showErrorMessages || this.activityTypeError) {
      return false;
    }

    return true;
  }

  private processForm(formVal: any) {
    formVal.isVoid = formVal.isVoid || false;
    if (formVal.isVoid) {
      formVal.isForAir = null;
      formVal.isForOcean = null;
      formVal.statusId = null;
      formVal.progressId = null;
      return formVal;
    }
    formVal.isForAir = formVal.isForAir || false;
    formVal.isForOcean = formVal.isForOcean || false;
    if (formVal.progressId == 0) {
      formVal.progressId = null;
    }

    return formVal;
  }

  private resetErrorsFlags() {
    this.duplicateActivity = false;
    this.activityTypeError = false;
    this.showErrorMessages = false;
  }

  updateRequest(formVal: Activity) {
    this.transitionService.updateActivity(this.activity.id, formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  addNewRequest(formVal: Activity) {
    this.transitionService.addNew(formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  private saveSuccessHelper(activity?: Activity) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    if (this.isNewActivity) {
      this.alertService.showMessage(
        'Success',
        `Activity \"${activity.name}\" was created successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to activity \"${activity.name}\" were saved successfully`,
        MessageSeverity.success
      );
    }

    this.resetForm();
    this.router.navigate(['/settings/activity']);
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    if (error == '409') {
      this.duplicateActivity = true;
      return;
    }
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occured whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  get isNonVoidActivity() {
    const isVoid = this.activityForm.get('isVoid').value;

    return !isVoid;
  }

  onVoidActivityChange(event: any) {
    const isChecked = (event.target as HTMLInputElement).checked;

    if (isChecked) {
      this.formsHelperService.addRequiredValidator(
        this.activityForm,
        'categoryId'
      );
    } else {
      this.formsHelperService.clearValidator(this.activityForm, 'categoryId');
    }
  }

  cancel() {
    this.resetForm();

    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/activity']);
  }

  resetForm() {
    this.activityForm.reset();
  }

  onChangeSelectedServices(index: number, event: any) {
    const formArray: UntypedFormArray = this.servicersFormArray;

    if (event.target.checked) {
      formArray.push(
        new UntypedFormControl({
          id: event.target.value,
          name: this.selectedServices.find(i => i.id == event.target.value)
            .name,
          isSelected: true
        })
      );
    } else {
      formArray.removeAt(index);
    }
  }

  isServiceSelected(service: SelectedServices): boolean {
    if (!this.activity.freePlServices) {
      return false;
    }
    return this.activity.freePlServices.findIndex(i => i.id == service.id) > -1;
  }

  private addSelectedServices() {
    if (this.activity.freePlServices) {
      const formArray: UntypedFormArray = this.servicersFormArray;

      this.activity.freePlServices.forEach(service => {
        formArray.push(
          new UntypedFormControl({
            id: service.id,
            name: service.name,
            isSelected: true
          })
        );
      });
    }
  }

  private getFreePlServices() {
    this.transitionService
      .getFreePlServices([ServiceTypes.DomesticTrucking])
      .pipe(
        map(response =>
          response.map(
            element => new SelectedServices(element.id, element.name)
          )
        )
      )
      .subscribe(selectedServices => {
        this.selectedServices.push(...selectedServices);
      });
  }
  get servicersFormArray(): UntypedFormArray {
    return this.activityForm.get('services') as UntypedFormArray;
  }
}
