<mat-expansion-panel
  hideToggle="true"
  #olderVersionsExpansionPanel
  class="older-version-list w-100 p-0"
  [class]="
    olderVersionsExpansionPanel.expanded ? '' : 'older-version-not-expanding'
  "
>
  <mat-expansion-panel-header class="older-version-list-header p-0 h-100">
    <mat-panel-description class="panel-description mr-0">
      <div class="uploaded-document-container d-flex w-100">
        <div
          class="document-type"
          [class]="
            isSupplierModule || isCompanyModule ? 'document-type-supplier' : ''
          "
        >
          <div
            [placement]="popOverPlacement"
            [ngbPopover]="
              isExceedDocumentTypeNameLimit ? documentTypeNameTemplate : ''
            "
            triggers="mouseenter:mouseleave"
          >
            {{
              isExceedDocumentTypeNameLimit
                ? (document?.documentTypeName | slice: 0:23) + "..."
                : document?.documentTypeName
            }}
            <span *ngIf="document.oldVersions?.length > 0">
              ({{ document.oldVersions?.length + 1 }})
            </span>
          </div>
        </div>

        <div
          class="file supplier-file"
          *ngIf="
            (!uploadedFile?.isUploading ||
              document.documentTypeName != uploadedFile?.typeName) &&
            !uploadedFile?.isCanceled
          "
        >
          <div
            [ngClass]="
              document?.isDeleted ? 'deleted-file-name' : 'existing-file-name'
            "
            [ngbPopover]="isExceedFileNameLimit ? documentFileNameTemplate : ''"
            triggers="mouseenter:mouseleave"
            (click)="viewFile(document)"
            class="file-name cursor-pointer"
          >
            {{
              isExceedFileNameLimit
                ? (document?.fileName | slice: 0:23) + "..."
                : document?.fileName
            }}
          </div>
          <div class="file-upload-date">
            <span *ngIf="isCompanyModule">
              {{ document.createdAt | fromutc | date: "dd/MM/YYYY hh:mm a" }}
            </span>
            <span *ngIf="!isCompanyModule">
              {{ document.createdAt | date: "dd/MM/YYYY hh:mm a" }}
            </span>
            -
            {{ document?.uploadedBy?.fullName }},
            {{ document?.uploadedBy?.companyName }}
          </div>
          <div class="file-upload-date" *ngIf="isCompanyModule">
            Expires on:
            {{ document.expirationDate | date: "dd/MM/YYYY hh:mm a" }}
          </div>

          <div class="document-deleted d-flex" *ngIf="document.isDeleted">
            <div class="deleted-text">Deleted</div>
            <span
              [placement]="popOverPlacement"
              [ngbPopover]="deletionReasonTemplate"
              triggers="mouseenter:mouseleave"
            >
              <svg-icon [name]="'red-info'"></svg-icon>
            </span>
          </div>
        </div>
        <ng-container
          *ngIf="
            (uploadedFile?.isUploading ||
              uploadedFile?.isCanceled ||
              isError) &&
            document.documentTypeName === uploadedFile?.typeName &&
            module != moduleName.Supplier
          "
        >
          <div class="file">
            <div
              class="uploadCard w-100 position-relative d-flex flex-column"
              [ngClass]="{
                'is-canceled':
                  uploadedFile?.isCanceled &&
                  document.documentTypeName === uploadedFile?.typeName,
                'is-error':
                  uploadedFile?.isError &&
                  document.documentTypeName === uploadedFile?.typeName
              }"
            >
              <div class="title">
                {{ document.documentTypeName }}
              </div>
              <div>
                <div class="d-flex align-items-end justify-content-between">
                  <span class="filename flex-grow-1">
                    <div class="d-flex align-items-end">
                      {{ document.fileName }}
                    </div>
                    <div
                      class="progressCon"
                      [ngClass]="{
                        'uploading-bar':
                          uploadedFile?.isUploading &&
                          document.documentTypeName === uploadedFile?.typeName,
                        'canceled-bar':
                          uploadedFile?.isCanceled &&
                          document.documentTypeName === uploadedFile?.typeName,
                        'error-bar': isError
                      }"
                    >
                      <mat-progress-bar
                        *ngIf="
                          (uploadedFile?.isUploading ||
                            uploadedFile?.isCanceled ||
                            isError) &&
                          document.documentTypeName === uploadedFile?.typeName
                        "
                        mode="determinate"
                        [value]="uploadedFile?.percentageValue"
                      ></mat-progress-bar>
                    </div>
                  </span>

                  <button
                    class="cancel"
                    *ngIf="
                      uploadedFile?.isUploading &&
                      document.documentTypeName === uploadedFile?.typeName
                    "
                    (click)="cancelUploadingFile(uploadedFile)"
                  >
                    <svg-icon [name]="'cancel'"></svg-icon>
                  </button>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                uploadedFile?.isCanceled &&
                document.documentTypeName === uploadedFile?.typeName
              "
              class="d-flex align-items-center"
            >
              <span class="pb -1">
                <svg-icon [name]="'alertMark'"></svg-icon>
              </span>
              <span class="text-alert mx-1 font-size-14">
                Upload has been canceled.</span
              >
              <span color="red">
                <svg-icon [name]="'refresh'"></svg-icon>
              </span>
              <span
                [class.cursor-disabled]="disableAnyAction"
                class="text-border font-size-14 mx-1 text-underline cursor-pointer"
                >Re-upload File
              </span>
            </div>
            <!-- is error case -->
            <!--  -->
          </div>
        </ng-container>

        <div class="document-notes" *ngIf="module !== moduleName.Supplier">
          <div (click)="toggleShowInput()" *ngIf="isShowInput === false">
            <div
              *ngIf="
                document.notes || document.description;
                else NoNotesAddedBlock
              "
              [placement]="popOverPlacement"
              [ngbPopover]="isExceedNoteLimit ? noteTemplate : ''"
              triggers="mouseenter:mouseleave"
            >
              {{
                document.notes || document.description
                  | slice: 0:documentNoteLimit
              }}
              {{ isExceedNoteLimit ? "..." : "" }}
            </div>
            <ng-template #NoNotesAddedBlock>
              <div class="document-no-notes-added pt-0 w-100">
                No added notes.
              </div>
            </ng-template>
            <ng-template #noteTemplate>
              <div class="notes-popover" *ngIf="module != moduleName.Approval">
                {{ document?.notes }}
              </div>
              <div class="notes-popover" *ngIf="module == moduleName.Approval">
                {{ document?.description }}
              </div>
            </ng-template>
          </div>
          <input
            (keydown)="$event.stopPropagation()"
            *ngIf="isShowInput === true"
            (change)="onValueChange()"
            [(ngModel)]="insertedNotes"
            maxlength="180"
          />
        </div>

        <div class="expiration-date" *ngIf="module === moduleName.Supplier">
          <div>
            <div
              [placement]="popOverPlacement"
              triggers="mouseenter:mouseleave"
            >
              {{ document.expirationDate | date: "dd/MM/YYYY" }}
            </div>
          </div>
        </div>

        <div
          class="actions action d-flex"
          [class]="document.isDeleted ? 'disabled' : ''"
        >
          <span
            class="action-btn download-btn d-flex justify-content-center align-items-center cursor-pointer"
            *ngIf="canDownloadDocument"
            (click)="downloadDocument()"
          >
            <svg-icon name="download"></svg-icon>
          </span>
          <span
            class="action-btn download-btn"
            *ngIf="canReplaceDocument"
            (click)="replace()"
          >
            <svg-icon name="replaceDocument"></svg-icon>
          </span>
          <span
            class="action-btn delete-btn mr-0"
            *ngIf="canDeleteDocument"
            (click)="delete()"
          >
            <svg-icon name="shipmentDetailsActionDelete"></svg-icon>
          </span>
          <div class="expansion-arrow-content">
            <svg-icon
              *ngIf="document.oldVersions?.length > 0"
              class="expansion-arrow d-inline-block"
              name="ExpansionArrow"
              [class]="
                olderVersionsExpansionPanel.expanded &&
                document.oldVersions?.length > 0
                  ? 'expansion-arrow-expanded'
                  : ''
              "
            >
            </svg-icon>
          </div>
        </div>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent>
    <app-uploaded-documents-details-older-version
      *ngIf="document.oldVersions?.length > 0"
      [oldVersions]="document.oldVersions"
      [module]="module"
      [parentDocumentSqlId]="document.documentSqlId"
      [canDownloadDocument]="canDownloadDocument"
      [canReplaceDocument]="canReplaceDocument"
      [canDeleteDocument]="canDeleteDocument"
    ></app-uploaded-documents-details-older-version>
  </ng-template>
</mat-expansion-panel>

<ng-template #documentTypeNameTemplate>
  <div>{{ document.documentTypeName }}</div>
</ng-template>
<ng-template #documentFileNameTemplate>
  <div>{{ document.fileName }}</div>
</ng-template>
<ng-template #deletionReasonTemplate>
  <div class="deletion-popOver">
    <div class="deletion-reason-con row">
      <div class="reason-title">Deleted on</div>
      <div class="reason-info col-auto">
        <span *ngIf="isCompanyModule">{{
          document.deletedAt | fromutc | date: "dd/MM/YYYY hh:mm a"
        }}</span>
        <span *ngIf="!isCompanyModule">
          {{ document.deletedAt | date: "dd/MM/YYYY hh:mm a" }}
        </span>
      </div>
    </div>
    <div class="deletion-reason-con row">
      <div class="reason-title">Deleted by</div>
      <div class="reason-info col-auto">
        {{ document.deletedBy?.fullName }},
        {{ document.deletedBy?.companyName }}
      </div>
    </div>
    <div class="deletion-reason-con row">
      <div class="deletion-reason-title reason-title">Reason</div>
      <div class="deletion-reason reason-info col-auto">
        {{ document.deletionReason }}
      </div>
    </div>
  </div>
</ng-template>
