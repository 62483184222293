export enum NonMedicalProductInformationSummary {
  ProductCategory = 'Product Category',
  ActualManufacturer = 'Actual Manufacturer',
  LegalManufacturer = 'Legal Manufacturer',
  ArticleNumber = 'Part Number',
  GTIN = 'GTIN',
  HSCode = 'HS Code(s)',
  SerialNumber = 'Serial Number',
  ISBN = 'ISBN',
  UPC = 'UPC',
  COO = 'Country of Origin (COO)',
  DangerousGoods = 'Dangerous Goods (DG)'
}
