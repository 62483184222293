import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApprovalActivityTransition } from '../models/approval-activity-transition.model';
import { ApprovalActivityService } from '../services/approval-activity.service';

@Injectable()
export class ApprovalActivityTransitionListResolver
  implements Resolve<ApprovalActivityTransition[]> {
  constructor(public registrationActivityService: ApprovalActivityService) {}

  resolve(): Observable<ApprovalActivityTransition[]> {
    return this.registrationActivityService
      .getAllActivitiesWithTransitions()
      .pipe(
        catchError(error => {
          return of(null);
        })
      );
  }
}
