<div [formGroup]="dangerousGoodForm" class="dangerous-good-container">
  <!--Document-->
  <div class="field document-field">
    <div class="title">
      <span [ngbPopover]="DGDPopUpTemplate" triggers="mouseenter:mouseleave"
        >DGD&nbsp;</span
      >/
      <span [ngbPopover]="MSDSPopUpTemplate" triggers="mouseenter:mouseleave">
        MSDS</span
      >
      <span class="text-danger"> *</span>
    </div>
    <ng-template #DGDPopUpTemplate>
      <div class="row pop-over">
        Dangerous Goods Declaration
      </div>
    </ng-template>
    <ng-template #MSDSPopUpTemplate>
      <div class="row pop-over">
        Material Safety Data Sheet
      </div>
    </ng-template>
    <div
      class="upload-certificate-field"
      *ngIf="!uploadedDocument; else uploadedDocumentBody"
    >
      <svg-icon
        name="uploadDocument"
        (click)="showModal()"
        class="upload-document-icon"
      ></svg-icon>
      <app-input
        [placeholder]="'Upload certificate'"
        [classList]="
          dangerousGoodForm.controls['document']?.hasError('required') &&
          dangerousGoodForm.controls['document']?.touched
            ? 'validation-error field-bg'
            : 'field-bg'
        "
        (click)="showModal()"
        [disabled]="document"
        formControlName="document"
        onkeydown="return false"
      ></app-input>
    </div>
    <ng-template #uploadedDocumentBody>
      <div class="uploaded-document-container">
        <div class="title">
          {{ uploadedDocument?.documentTypeName }}
        </div>

        <span class="document-name">
          <div (click)="viewFile(uploadedDocument?.path)">
            {{ uploadedDocument?.fileName }}
          </div>
        </span>

        <button
          [class.cursor-disabled]="disableAnyAction"
          class="icon"
          *ngIf="isUploadedDocument"
          (click)="deleteUploadedDocument()"
        >
          <svg-icon [name]="'delete'"></svg-icon>
        </button>
        <button
          class="icon"
          *ngIf="isUploadingDocument || isExistError"
          (click)="cancelUploadingDocument()"
        >
          <svg-icon [name]="'cancel'"></svg-icon>
        </button>
        <div
          class="document-note"
          *ngIf="uploadedDocument?.note; else noAddedNote"
        >
          {{ uploadedDocument?.note }}
        </div>
        <ng-template #noAddedNote>
          <div class="document-note">
            No added notes.
          </div>
        </ng-template>
      </div>
    </ng-template>
  </div>
  <!--class-->
  <div class="field ">
    <div class="title">
      Class
    </div>
    <div class="dangerous-good-class">
      <ng-select
        [items]="dangerousMaterialClasses"
        [multiple]="true"
        [selectableGroup]="true"
        [closeOnSelect]="false"
        bindValue="name"
        [placeholder]="'Select shipment products’ class categories'"
        [formControl]="classesNamesFormControl"
      >
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <div class="item-class">
            <div (click)="clear(item)">
              Class {{ item.id }}
              <svg-icon name="close-icon" class="close-icon"></svg-icon>
            </div>
          </div>
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <div class="d-flex align-items-center">
            <div class="dangerous-good-class-item">
              <app-dangerous-good-class
                [classList]="item$.selected ? 'selected' : ''"
                [dangerousGoodClass]="item"
                [index]="index"
              >
              </app-dangerous-good-class>
            </div>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <!--UN Numbers-->
  <div class="field" *ngIf="classesFormArray?.controls?.length > 0">
    <div class="d-flex">
      <div><span class="un-number-border"></span></div>
      <div>
        <div class="un-number-title">
          <div class="title">
            UN Number(s)
          </div>
          <div class="sub-title">
            Enter the UN number(s) according to the classes chosen above.
          </div>
        </div>
      </div>
    </div>

    <div class="un-number-list">
      <div
        *ngFor="let classForm of classesFormArray?.controls"
        class="un-number-container"
      >
        <div [formGroup]="classForm">
          <div class="un-number-field">
            <div class="un-number-label">
              <span
                >Class
                {{ getDangerousGoodClassId(classForm?.get("name")?.value) }}
              </span>
            </div>

            <app-input
              [placeholder]="'e.g. 1234, 5678, 9012'"
              formControlName="unNumbers"
              [classList]="'field-bg'"
            ></app-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
