import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProductRegistrationViewColumn } from '../models/product-registration-view-column.model';
import { ProductRegistrationColumnsService } from '../services/product-registration-columns.service';

@Injectable({ providedIn: 'root' })
export class ProductRegistrationColumnsResolver
  implements Resolve<ProductRegistrationViewColumn[]> {
  constructor(
     private productRegistrationColumnsService: ProductRegistrationColumnsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<ProductRegistrationViewColumn[]> {
    return this.productRegistrationColumnsService.getColumns().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
