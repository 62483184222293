import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProductRegistrationLeadTime } from '../models/product-registration-lead-time.model';

@Injectable({
  providedIn: 'root'
})
export class ProductRegistrationKpisService {
  baseURL = environment.apiUrl + 'ProductRegistrationLeadTime';

  constructor(private http: HttpClient) {}

  getAll(): Observable<ProductRegistrationLeadTime[]> {
    return this.http.get<ProductRegistrationLeadTime[]>(`${this.baseURL}`);
  }

  getById(id: number): Observable<ProductRegistrationLeadTime> {
    return this.http.get<ProductRegistrationLeadTime>(`${this.baseURL}/${id}`);
  }

  deleteLeadTime(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseURL}/${id}`);
  }

  addNewLeadTime(leadTime: ProductRegistrationLeadTime): Observable<any> {
    return this.http.post<any>(`${this.baseURL}`, leadTime);
  }

  updateLeadTime(
    id: number,
    leadTime: ProductRegistrationLeadTime
  ): Observable<any> {
    return this.http.put<any>(`${this.baseURL}/${id}`, leadTime);
  }
}
