import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmployeeNotificationSetting } from '../models/user-notification-setting.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationSettingService {
  baseURL = environment.apiUrl + 'Notification';
  constructor(private http: HttpClient) {}

  getNotificationSettings(
    employeeId: number
  ): Observable<EmployeeNotificationSetting[]> {
    return this.http.get<EmployeeNotificationSetting[]>(
      this.baseURL + `/Settings/${employeeId}`
    );
  }

  activateNotificationSetting(
    employeeId: number,
    notificationTypeName: string
  ): Observable<any> {
    return this.http.put<any>(
      this.baseURL + `/Setting/${employeeId}/${notificationTypeName}/Activate`,
      {}
    );
  }

  activateAllNotificationSettings(employeeId: number): Observable<any> {
    return this.http.put<any>(
      this.baseURL + `/Setting/${employeeId}/ActivateAll`,
      {}
    );
  }

  deactivateNotificationSetting(
    employeeId: number,
    notificationTypeName: string
  ): Observable<any> {
    return this.http.put<any>(
      this.baseURL +
        `/Setting/${employeeId}/${notificationTypeName}/DeActivate`,
      {}
    );
  }

  deactivateAllNotificationSettings(employeeId: number): Observable<any> {
    return this.http.put<any>(
      this.baseURL + `/Setting/${employeeId}/DeActivateAll`,
      {}
    );
  }
}
