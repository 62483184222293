import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-admin-search-bar',
  templateUrl: './admin-search-bar.component.html',
  styleUrls: ['./admin-search-bar.component.scss']
})
export class AdminSearchBarComponent implements OnInit {
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() placeholder: string;
  constructor() {}

  ngOnInit(): void {}

  onValueChange(value: string) {
    this.searchChange.emit(value);
  }
}
