import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ApprovalLeadTime } from '../../../models/approval-lead-time.model';
import { AppTranslationService } from '../../../../shared/services/app-translation.service';
import { ActivatedRoute } from '@angular/router';
import { Utilities } from '../../../../shared/services/utilities';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from '../../../../shared/services/alert.service';
import { ApprovalKpiService } from '../../../services/approval-kpi.service';

@Component({
  selector: 'app-approval-kpis-list',
  templateUrl: './approval-kpis-list.component.html',
  styleUrls: ['./approval-kpis-list.component.scss']
})
export class ApprovalKpisListComponent implements OnInit {
  columns: any[] = [];
  rows: ApprovalLeadTime[] = [];
  rowsCache: ApprovalLeadTime[] = [];
  loadingIndicator: boolean;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  constructor(
    private authService: AuthService,
    private translationService: AppTranslationService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private approvalKPisHttpService: ApprovalKpiService
  ) {}

  ngOnInit(): void {
    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      {
        prop: 'name',
        name: gT('config.management.ApprovalLeadTime.KpiName'),
        width: 560
      },
      {
        prop: 'leadTime',
        name: gT('config.management.ApprovalLeadTime.LeadTime'),
        width: 560
      }
    ];

    if (this.isAdmin) {
      let optionsColumn = {
        prop: 'options',
        name: gT('config.management.ApprovalLeadTime.Manage'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      };
      this.columns.push(optionsColumn);
    }

    this.route.data.subscribe(data => {
      var approvalLeadTimes = data['approvalLeadTimes'];

      approvalLeadTimes = this.getIndecies(approvalLeadTimes);

      this.rowsCache = [...approvalLeadTimes];
      this.rows = approvalLeadTimes;
    });
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  deleteApprovalLeadTime(row: ApprovalLeadTime) {
    this.alertService.showDialog(
      `Are you sure you want to delete the ${row.name} Approval lead time ?`,
      DialogType.confirm,
      () => this.deleteApprovalLeadTimeHelper(row)
    );
  }

  deleteApprovalLeadTimeHelper(row: ApprovalLeadTime) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;
    this.approvalKPisHttpService.deleteApprovalLeadTime(row.id).subscribe(
      res => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.alertService.showStickyMessage(
          'Deleted Successfully',
          `The Approval lead time "${row.name}" Deleted Successfully`,
          MessageSeverity.success
        );
        this.rowsCache = this.rowsCache.filter(item => item !== row);
        this.rowsCache = this.getIndecies(this.rowsCache);
        this.rows = this.rowsCache;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the Approval lead time.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  getIndecies(array: any) {
    array.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }
}
