import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ContainerTypeEnum } from 'src/app/shared/models/enums/container-type.enum';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { ContainerTitle } from '../../models/enums/container-title.enum';

@Component({
  selector: 'app-container-details-1',
  templateUrl: './container-details-1.component.html',
  styleUrls: ['./container-details-1.component.scss']
})
export class ContainerDetails1Component implements OnInit, OnDestroy {
  @Input() containerDetailsForm: UntypedFormGroup;
  @Input() sectionSubTitle: string;
  @Input() containers20Data;
  @Input() containers40Data;
  @Input() containers40HCData;

  containerTypeEnum = ContainerTypeEnum;
  containerTitle = ContainerTitle;

  constructor(private formsHelperService: FormsHelperService) {}

  ngOnDestroy(): void {
    this.formsHelperService.clearValidator(
      this.containerDetailsForm,
      'has20Container'
    );
    this.formsHelperService.clearValidator(
      this.containerDetailsForm,
      'has40Container'
    );
    this.formsHelperService.clearValidator(
      this.containerDetailsForm,
      'has40HCContainer'
    );

    this.containerDetailsForm.reset();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    if (
      !(this.has20Container || this.has40Container || this.has40HcContainer)
    ) {
      this.formsHelperService.addRequiredValidator(
        this.containerDetailsForm,
        'has20Container'
      );
      this.formsHelperService.addRequiredValidator(
        this.containerDetailsForm,
        'has40Container'
      );
      this.formsHelperService.addRequiredValidator(
        this.containerDetailsForm,
        'has40HCContainer'
      );
    }
  }

  onContainerTypeSelection(containerType: string, value: boolean) {
    if (containerType === ContainerTypeEnum.Container20) {
      this.patchFormControlValue('has20Container', value);
      this.updateFormControlValidation(
        'has40Container',
        this.isContainer40Required
      );
      this.updateFormControlValidation(
        'has40HCContainer',
        this.isContainer40HcRequired
      );
      return;
    }

    if (containerType === ContainerTypeEnum.Container40) {
      this.patchFormControlValue('has40Container', value);
      this.updateFormControlValidation(
        'has20Container',
        this.isContainer20Required
      );
      this.updateFormControlValidation(
        'has40HCContainer',
        this.isContainer40HcRequired
      );
      return;
    }

    if (containerType === ContainerTypeEnum.Container40hc) {
      this.patchFormControlValue('has40HCContainer', value);
      this.updateFormControlValidation(
        'has20Container',
        this.isContainer20Required
      );
      this.updateFormControlValidation(
        'has40Container',
        this.isContainer40Required
      );
      return;
    }
  }

  patchFormControlValue(formControlName: string, value: boolean): void {
    this.containerDetailsForm.get(formControlName).patchValue(value);
  }

  getFormControlValue(formControlName: string): boolean {
    return this.containerDetailsForm.get(formControlName).value;
  }

  isContainerSelected(containerName: string): boolean {
    return this.getFormControlValue(containerName);
  }

  updateFormControlValidation(formControlName: string, isRequired: boolean) {
    if (isRequired) {
      this.formsHelperService.addRequiredValidator(
        this.containerDetailsForm,
        formControlName
      );
    } else {
      this.formsHelperService.clearValidator(
        this.containerDetailsForm,
        formControlName
      );
    }
  }

  get isContainer20Required(): boolean {
    let isContainer20Required =
      !this.getFormControlValue('has40Container') &&
      !this.getFormControlValue('has40HCContainer');

    return isContainer20Required;
  }

  get isContainer40Required(): boolean {
    let isContainer40Required =
      !this.getFormControlValue('has20Container') &&
      !this.getFormControlValue('has40HCContainer');

    return isContainer40Required;
  }

  get isContainer40HcRequired(): boolean {
    let isContainer40HcRequired =
      !this.getFormControlValue('has20Container') &&
      !this.getFormControlValue('has40Container');

    return isContainer40HcRequired;
  }

  get has20Container() {
    return this.getFormControlValue('has20Container');
  }

  get has40Container() {
    return this.getFormControlValue('has40Container');
  }

  get has40HcContainer() {
    return this.getFormControlValue('has40HCContainer');
  }
}
