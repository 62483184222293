import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
 import { IndustryService } from '../../admin-portal-configs/services/industry.service';
import { Industry } from '../models/industry.model';

@Injectable({
  providedIn: 'root'
})
export class IndustryListResolver implements Resolve<Industry[]> {
  constructor(private industryService: IndustryService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Industry[]> {
    return this.industryService.getAll().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
