import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProductRegistrationViewColumn } from '../models/product-registration-view-column.model';
import { ProductRegistrationColumnsService } from '../services/product-registration-columns.service';
import { LocalStoreManager } from 'src/app/shared/services/local-store-manager.service';

@Injectable({ providedIn: 'root' })
export class ProductRegistrationPreferredSelectedColumnsResolver
  implements Resolve<ProductRegistrationViewColumn[]> {
  constructor(
    private productRegistrationColumnsService: ProductRegistrationColumnsService,
    private localStorage: LocalStoreManager
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<ProductRegistrationViewColumn[]> {
    let selectedColumns: ProductRegistrationViewColumn[] = this.localStorage.getData(
      'productRegistrationSelectedColumns'
    );
    if (selectedColumns?.length > 0) {
      return of(selectedColumns);
    }

    if (!selectedColumns || selectedColumns.length <= 0) {
      this.productRegistrationColumnsService
        .getEmployeePreferredSelectedColumns()
        .subscribe(response => {
          selectedColumns = response;
          this.localStorage.saveSyncedSessionData(
            selectedColumns,
            'productRegistrationSelectedColumns'
          );
          if (selectedColumns) {
            return of(selectedColumns);
          }
        });
    }
  }
}
