import { Injectable } from '@angular/core';
import { CompanySelectedService } from '../models/company-selected-service.model';
import { ServiceTypes } from 'src/app/shared/models/shipment/service-types.enum';
import { CompanySideNavBarSubTabs } from 'src/app/shared/models/company-side-nav-bar-sub-tabs.model';
import { ApprovalServiceDescriptionEnum } from 'src/app/company2/enum/approval-service-description.enum';
import { ProductRegistrationServiceCustomer } from '../models/Product-Registration-Service-Customer';
import { CustomerIorOrEorService } from '../models/customer-ior-or-eor-service.model';
import { CustomerAccountManagementService } from '../models/customer-account-management-service.model';
import { AccountManagementPricingPlanTypesEnum } from '../enums/account-management-pricing-plan-types.enum';

@Injectable({
  providedIn: 'root'
})
export class CustomerServicesHelperService {
  constructor() {}
  accountManagementErrorMessage =
    'Select an Account Management option to proceed';
  bundleErrorMessage =
    'You need to select at least two services to start defining your bundle';

  calculatePercentageDifference(newValue: number, oldValue: number): string {
    if (!oldValue || oldValue == 0) {
      return '0.00';
    }
    let difference = newValue - oldValue;
    let percentageDifference = (difference / oldValue) * 100;
    return percentageDifference.toFixed(2);
  }

  isServicePerInvoice(description: string): boolean {
    return description === ApprovalServiceDescriptionEnum.Invoice;
  }

  isServicePerVistOrPerShipment(description): boolean {
    return (
      description === ApprovalServiceDescriptionEnum.Visit ||
      description === ApprovalServiceDescriptionEnum.Shipment
    );
  }

  processCustomerSelectedServicesOnSideNavBar(
    companySelectedServices: CompanySelectedService[]
  ): CompanySideNavBarSubTabs[] {
    let bundleServices = companySelectedServices?.filter(
      c => c.isBundleService
    );
    let accountManagementService = companySelectedServices?.find(
      c => c.freePlService.name === ServiceTypes.AccountManagement
    );
    let isAccountManagementSelected =
      accountManagementService?.isBundleService ||
      accountManagementService?.isIndividualService;

    companySelectedServices = companySelectedServices?.filter(
      c => c.isIndividualService
    );

    let subTabs: CompanySideNavBarSubTabs[] = [];

    companySelectedServices?.forEach(service => {
      let subTab = service.freePlService as CompanySideNavBarSubTabs;

      subTab.isActive = isAccountManagementSelected;
      subTab.errorMessage = !isAccountManagementSelected
        ? this.accountManagementErrorMessage
        : null;

      subTabs.push(subTab);
    });

    if (bundleServices?.length > 0) {
      let subTab = {
        name: 'Bundle',
        isActive: bundleServices?.length > 1 && isAccountManagementSelected,
        errorMessage: !isAccountManagementSelected
          ? this.accountManagementErrorMessage
          : bundleServices?.length == 1
          ? this.bundleErrorMessage
          : null
      } as CompanySideNavBarSubTabs;
      subTabs = [subTab, ...subTabs];
    }

    return subTabs;
  }

  getSterility(
    productRegistrationService: ProductRegistrationServiceCustomer
  ): string {
    return productRegistrationService?.productRegistrationServicePricing
      ?.sterility?.name;
  }

  getCategoryClassification(
    productRegistrationService: ProductRegistrationServiceCustomer
  ): string {
    return productRegistrationService?.productRegistrationServicePricing
      ?.categoryClassification?.name;
  }
  getServicePrice(
    productRegistrationService: ProductRegistrationServiceCustomer
  ): number {
    return productRegistrationService?.productRegistrationServicePricing?.price;
  }

  getServiceStandaredPrice(
    productRegistrationService: ProductRegistrationServiceCustomer
  ): number {
    return productRegistrationService?.issuancePrice;
  }

  getServiceRenewalPrice(
    productRegistrationService: ProductRegistrationServiceCustomer
  ): number {
    return productRegistrationService?.renewalPrice;
  }
  getClassificationType(
    productRegistrationService: ProductRegistrationServiceCustomer
  ): string {
    return productRegistrationService?.productRegistrationServicePricing
      ?.classificationType?.name;
  }

  getIorOrEorPricingPlan(iorOrEorService: CustomerIorOrEorService): string {
    return iorOrEorService?.pricingPlan;
  }

  isCommissionBasedPricingPlan(
    accountManagementService: CustomerAccountManagementService
  ):boolean {
    return (
      accountManagementService?.accountManagementPricingPlanType?.name ==
      AccountManagementPricingPlanTypesEnum.CommissionBased
    );
  }

  isFlatFeePerShipmentPricingPlan(
    accountManagementService: CustomerAccountManagementService
  ):boolean {
    return (
      accountManagementService?.accountManagementPricingPlanType?.name ==
      AccountManagementPricingPlanTypesEnum.FlatFeePerShipment
    );
  }
}
