import { ComplianceInformationSummary } from './compliance-information-summary';

export const MedicalComplianceInformationSummary = {
  Tax: ComplianceInformationSummary.Tax,
  VAT: ComplianceInformationSummary.VAT,
  CategoryClassification: ComplianceInformationSummary.CategoryClassification,
  Entity: ComplianceInformationSummary.Entity,
  Sterility: ComplianceInformationSummary.Sterility,
  Regulations: ComplianceInformationSummary.Regulations,
  ClassificationType: ComplianceInformationSummary.ClassificationType,
  CertificationType: ComplianceInformationSummary.CertificationType,
  RequiredRegistration: ComplianceInformationSummary.RequiredRegistration,
  RequiredApprovals: ComplianceInformationSummary.RequiredApprovals
};
