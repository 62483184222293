import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ModuleName } from '../../models/enums/module-name.enum';
import { ProductRegistrationService } from 'src/app/product-registration/services/product-registration.service';
import { QuoteService } from 'src/app/quote/services/quote.service';
import { ProductRegistrationSearchCriteria } from 'src/app/product-registration/models/product-registration-search-criteria.model';
import { CreateQuoteHelperService } from 'src/app/create-quote/services/create-quote-helper.service';
import { AuthService } from '../../services/auth.service';
import { WidgetHeaderTab } from '../../models/widget-header-tab.model';

@Component({
  selector: 'app-module-widget',
  templateUrl: './module-widget.component.html',
  styleUrls: ['./module-widget.component.scss']
})
export class ModuleWidgetComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() buttonTitle: string;
  @Input() module: string;
  @Input() tabsList: WidgetHeaderTab[];
  @Input() cardData: any;
  @Input() redirectionLinkAdd: string;
  @Input() redirectionLinklist: string;
  @Input() boldText: string[];
  @Input() normalText: string[];
  isLoading = false;

  moduleName = ModuleName;
  hasQuoteAboutToExpire: boolean = false;

  constructor(
    private quoteService: QuoteService,
    private productRegistrationService: ProductRegistrationService,
    private createQuoteHelperService: CreateQuoteHelperService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.cardData) {
      this.isLoading = false;
    }
  }

  getQuoteRouterLink(quoteId: string): string {
    return '../quotes/' + quoteId + '/details';
  }

  onTabChange(event: any) {
    let tab = event.tab.textLabel;
    this.isLoading = true;

    if (this.module == ModuleName.Quote) {
      this.quoteService.quoteTabChanged.emit(tab);
    } else if (this.module == ModuleName.Registration) {
      let searchCriteria = {
        viewTab: tab
      } as ProductRegistrationSearchCriteria;
      this.productRegistrationService.productRegistrationSearch.emit(
        searchCriteria
      );
    }
  }

  onScroll(event) {
    if (
      event.target.offsetHeight + event.target.scrollTop <
      event.target.scrollHeight - 1
    ) {
      return;
    }

    if (this.module == ModuleName.Quote) {
      this.quoteService.onQuoteScroll.emit(true);
    } else if (this.module === ModuleName.Registration) {
      this.productRegistrationService.onListScroll.emit();
    }
  }
  isShowAboutToExpireIcon(tab: string): boolean {
    let isShow =
      (tab === 'Pending Approval' || tab === 'Pending Customer Approval') &&
      this.module == ModuleName.Quote &&
      this.hasQuoteAboutToExpire;

    return isShow;
  }

  get isViewAllButtonDisabled(): boolean {
    if (this.module != ModuleName.Quote) {
      return false;
    }

    return !this.authService.canViewQuotes;
  }

  get isAddButtonEnabled(): boolean {
    if (this.module != ModuleName.Quote) {
      return true;
    }

    return this.authService.canCreateQuote;
  }
}
