import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { District } from 'src/app/shared/models/district.models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DistrictService {
  baseURL = environment.apiUrl + 'district';

  constructor(private http: HttpClient) {}

  getAll(): Observable<District[]> {
    return this.http.get<District[]>(`${this.baseURL}`);
  }

  getById(id: number): Observable<District> {
    return this.http.get<District>(`${this.baseURL}/${id}`);
  }

  getByCountry(countryId: number): Observable<District[]> {
    return this.http.get<District[]>(`${this.baseURL}/Country/${countryId}`);
  }

  getByCity(cityId: number): Observable<District[]> {
    return this.http.get<District[]>(`${this.baseURL}/City/${cityId}`);
  }

  getByNameAndCountryId(
    name: string,
    countryId: number
  ): Observable<District[]> {
    return this.http.get<District[]>(
      `${this.baseURL}/${name}/Country/${countryId}`
    );
  }

  getByOriginAndDestinationCitiesIds(
    originCityId: number,
    destinationCityId: number
  ): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Origin/${originCityId}/Destination/${destinationCityId}`
    );
  }

  deleteDistrict(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseURL}/${id}`);
  }

  addNewDistrict(district: any): Observable<District> {
    return this.http.post<District>(`${this.baseURL}`, district);
  }

  updateDistrict(id: number, district: any): Observable<District> {
    return this.http.put<District>(`${this.baseURL}/${id}`, district);
  }
}
