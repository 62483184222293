<div class="unSaved-data-con">
    <svg-icon name="removeDocument"></svg-icon>
    <div class="title" [innerHTML]="message"></div> 

    <div class="form-buttons">
        <button class="form-btn cancel" (click)="cancel()">Cancel</button>
        <button class="form-btn confirm" (click)="confirm()">Confirm</button>
    </div>
    <div class="confirmation-warning">
        <svg-icon [name]="'mark!'"></svg-icon>
        <div class="confirmation-warning-text">This action cannot be undone.</div>
    </div>
</div>