import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Progress } from '../models/shipment/progress.model';
import { ActivityService } from '../../admin-portal-configs/services/activity.service';
 
@Injectable({
  providedIn: 'root'
})
export class ShipmentActivityProgressesResolver implements Resolve<Progress[]> {
  constructor(
    private router: Router,
    private activityService: ActivityService
  ) {}
  resolve(route: ActivatedRouteSnapshot): Observable<Progress[]> {
    return this.activityService.getProgresses().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
