import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { Utilities } from 'src/app/shared/services/utilities';
import { ShipmentLeadTimeResponse } from '../../../models/shipment-lead-time-response.model';
import { ShipmentLeadTimeService } from '../../../services/shipment-lead-time.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-shipment-lead-time-list',
  templateUrl: './shipment-lead-time-list.component.html',
  styleUrls: ['./shipment-lead-time-list.component.scss']
})
export class ShipmentLeadTimeListComponent implements OnInit {
  columns: any[] = [];
  rows: ShipmentLeadTimeResponse[] = [];
  rowsCache: ShipmentLeadTimeResponse[] = [];
  loadingIndicator: boolean;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private shipmentLeadTimeService: ShipmentLeadTimeService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      var shipmentLeadTimes = data['shipmentLeadTimes'];

      shipmentLeadTimes = this.getIndecies(shipmentLeadTimes);

      this.rowsCache = [...shipmentLeadTimes];
      this.rows = shipmentLeadTimes;
    });

    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      {
        prop: 'name',
        name: gT('config.management.ShipmentLeadTime.KpiName'),
        width: 560
      },
      {
        prop: 'leadTime',
        name: gT('config.management.ShipmentLeadTime.LeadTime'),
        width: 560
      }
    ];

    if (this.canUpdateShipmentLeadTimes || this.canDeleteShipmentLeadTimes) {
      this.columns.push({
        prop: 'options',
        name: gT('config.management.ShipmentLeadTime.Manage'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      });
    }
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  deleteShipmentLeadTime(row: ShipmentLeadTimeResponse) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' +
        row.name +
        '" shipment lead time ?',
      DialogType.confirm,
      () => this.deleteShipmentLeadTimeHelper(row)
    );
  }

  deleteShipmentLeadTimeHelper(row: ShipmentLeadTimeResponse) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.shipmentLeadTimeService.deleteShipmentLeadTime(row.id).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter(item => item !== row);

        this.rowsCache = this.getIndecies(this.rowsCache);
        this.rows = this.rowsCache;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the shipment lead time.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  getIndecies(array: any) {
    array.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }

  get canUpdateShipmentLeadTimes(): boolean {
    return this.authService.canUpdateShipmentKPIs;
  }

  get canDeleteShipmentLeadTimes(): boolean {
    return this.authService.canDeleteShipmentKPIs;
  }

  get canAddShipmentLeadTimes(): boolean {
    return this.authService.canCreateShipmentKPIs;
  }
}
