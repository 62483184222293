<div class="row p-3">
  <div class="col-lg-3 col-md-1 col-sm-1"></div>
  <div class="col-lg-6 col-md-10 col-sm-10 Card pb-1">
    <form [formGroup]="districtForm" id="districtForm" (ngSubmit)="save()">
      <h4>
        {{
          !isNewDistrict
            ? ("config.management.District.Edit" | translate) +
              " " +
              district.name
            : ("config.management.District.New" | translate)
        }}
      </h4>
      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <fieldset class="nested-fieldset">
        <div *ngIf="duplicateDistrict" class="error-message">
          <app-error-badge
            [thinBar]="true"
            message="{{
              'config.management.District.Editor.DuplicateEntry' | translate
            }}"
            class="warning-badge"
          ></app-error-badge>
        </div>
        <div class="form-group mb-3">
          <label for="name">{{ "config.management.Name" | translate }}</label>
          <input
            formControlName="name"
            [ngClass]="{
              'is-invalid':
                districtForm.get('name').errors &&
                districtForm.get('name').touched
            }"
            type="text"
            class="form-control"
            maxlength="30"
            id="name"
            placeholder="{{
              'config.management.District.NamePlaceHolder' | translate
            }}"
          />
          <small
            *ngIf="
              districtForm.get('name').touched &&
              districtForm.get('name').hasError('required')
            "
            class="form-text text-danger"
            >{{
              "config.management.District.Editor.DistrictNameRequired"
                | translate
            }}</small
          >
        </div>
      </fieldset>

      <fieldset class="nested-fieldset">
        <legend>{{ "Location" | translate }}</legend>
        <div class="row">
          <div class="col-6 form-group">
            <label for="countryName">{{ "route.Country" | translate }}</label>
            <select
              class="form-control"
              id="countryName"
              (change)="onChangeCountry($event)"
            >
              <option [value]="null">
                {{ "route.SelectCountry" | translate }}
              </option>
              <option
                *ngFor="let item of countries"
                selected="{{
                  selectedCountry?.name == item.name ? 'selected' : ''
                }}"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="col-6 form-group">
            <label for="cityID">{{ "route.City" | translate }}</label>
            <select
              class="form-control"
              formControlName="cityId"
              id="cityId"
              [value]="district.cityId"
            >
              <option *ngIf="!selectedCountry">
                {{ "route.SelectCountryFirst" | translate }}
              </option>
              <option *ngIf="selectedCountry">
                {{ "route.SelectCity" | translate }}
              </option>
              <option
                *ngFor="let item of selectedCountry?.states"
                [value]="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
      </fieldset>

      <div class="form-group mb-3">
        <label for="lat">{{
          "config.management.City.Latitude" | translate
        }}</label>
        <input
          formControlName="lat"
          [ngClass]="{
            'is-invalid':
              districtForm.get('lat').errors && districtForm.get('lat').touched
          }"
          type="number"
          max="180"
          min="-180"
          class="form-control"
          id="lat"
          (change)="validateDigitLength($event)"
          placeholder="{{ 'config.management.City.Latitude' | translate }}"
        />
        <small
          *ngIf="
            districtForm.get('lat').touched &&
            districtForm.get('lat').hasError('required')
          "
          class="form-text text-danger"
          >{{
            "config.management.City.Editor.LatitudeRequired" | translate
          }}</small
        >
      </div>

      <div class="form-group mb-3">
        <label for="lng">{{
          "config.management.City.Longtitude" | translate
        }}</label>
        <input
          formControlName="lng"
          [ngClass]="{
            'is-invalid':
              districtForm.get('lng').errors && districtForm.get('lng').touched
          }"
          type="number"
          max="180"
          min="-180"
          class="form-control"
          id="lng"
          (change)="validateDigitLength($event)"
          placeholder="{{ 'config.management.City.Longtitude' | translate }}"
        />
        <small
          *ngIf="
            districtForm.get('lng').touched &&
            districtForm.get('lng').hasError('required')
          "
          class="form-text text-danger"
          >{{
            "config.management.City.Editor.LongtitudeRequired" | translate
          }}</small
        >
      </div>

      <div class="row">
        <div class="col-md-12">
          <hr class="edit-separator-hr" />
        </div>
      </div>

      <div class="col-sm-12 form-group">
        <div ngPreserveWhitespaces class="float-right">
          <button (click)="cancel()" class="btn btn-danger">
            <i class="fa fa-times"></i>
            {{ "config.management.District.Editor.Cancel" | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="isSaving || !districtForm.valid"
          >
            <i *ngIf="!isSaving" class="fa fa-save"></i>
            <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
            {{
              isSaving
                ? ("config.management.District.Editor.Saving" | translate)
                : ("config.management.District.Editor.Save" | translate)
            }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
