import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusChangeRequest } from 'src/app/shared/models/status-change-request.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ProductRegistrationService } from '../../services/product-registration.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-pause-product-registration-popup',
  templateUrl: './pause-product-registration-popup.component.html',
  styleUrls: ['./pause-product-registration-popup.component.css']
})
export class PauseProductRegistrationPopupComponent implements OnInit {
  notes: string;
  notesError: boolean;
  productId: string;
  isLoading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productRegistrationService: ProductRegistrationService,
    private alertService: AlertService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.productId = this.data['productId'];
  }

  closePopup() {
    this.dialogService.close();
  }

  pauseRequest() {
    if (this.isLoading) {
      return;
    }
    if (this.notes) {
      this.isLoading = true;
      let statusChangeRequest: StatusChangeRequest = {
        id: this.productId,
        reason: this.notes
      };
      this.productRegistrationService
        .pauseProductRegistration(statusChangeRequest)
        .subscribe(data => {
          this.alertService.success('Request successfully put on hold');
          this.productRegistrationService.productRegistrationRequestUpdated.emit();
          this.closePopup();
        });
    } else {
      this.notesError = true;
    }
  }
}
