<div class="section-container">
  <div class="compliance-details-container">
    <div class="title-section d-flex w-100 justify-content-between">
      <div class="title-container d-flex">
        <svg-icon name="shipmentProducts"></svg-icon>
        <span class="title">COMPLIANCE DETAILS</span>
      </div>
      <button
        class="edit-section-button cursor-pointer bg-transparent border-0"
        (click)="handleComplianceInformationRouting()"
        *ngIf="canEditComplianceDetailsManagement"
      >
        <svg-icon name="editQuote"></svg-icon>
        Edit
      </button>
    </div>
    <app-product-compliance-details-summary
      [product]="product"
    ></app-product-compliance-details-summary>
  </div>
  <div
    class="compliance-details-container"
    *ngIf="isHealthCareOrPharmaceuticalsCategory"
  >
    <div class="title-section">
      <div class="title-container d-flex">
        <svg-icon name="shipmentProducts"></svg-icon>
        <span class="title">REGISTRATIONS </span>
      </div>
    </div>
    <app-product-details-registration-summary
      [productDetailsProductRegistrationList]="
        productReference?.productDetailsProductRegistration
      "
    ></app-product-details-registration-summary>
  </div>

  <div
    class="compliance-details-container"
    *ngIf="isHealthCareOrPharmaceuticalsCategory"
  >
    <div class="title-section">
      <div class="title-container d-flex">
        <svg-icon name="shipmentProducts"></svg-icon>
        <span class="title">APPROVALS</span>
      </div>
    </div>
    <app-product-details-approvals-summary
      [productDetailsApprovelList]="productReference?.productDetailsApproval"
    ></app-product-details-approvals-summary>
  </div>
</div>
