import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-empty-data-msg',
  templateUrl: './add-empty-data-msg.component.html',
  styleUrls: ['./add-empty-data-msg.component.scss']
})
export class AddEmptyDataMsgComponent implements OnInit {
  @Input() boldText: string;
  @Input() normalText: string;

  constructor() {}

  ngOnInit(): void {}
}
