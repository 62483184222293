<div class="sort-by">
  <div class="form-title  mb-4">
    Sort By
  </div>
  <form [formGroup]="columnsForm" (ngSubmit)="save()" class="text-center">
    <div class="sort-by-list">
      <div class="form-group pr-3" *ngFor="let sortByColumn of sortFilters">
        <div class="row sort-by-row">
          <input
            formControlName="sortFilterColumn"
            type="radio"
            name="sortFilterColumn"
            [value]="sortByColumn"
          />
          <div class="sort-by-name">{{ sortByColumn }}</div>
        </div>
      </div>
    </div>
    <div class="btn-container">
      <button
        type="submit"
        class="btn btn-primary w-100 submit-btn mt-3"
        [disabled]="!columnsForm.valid"
      >
        <span class="submit-btn-text">Apply</span>
      </button>
    </div>
  </form>
</div>
