import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalCase'
})
export class CapitalCasePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    return value[0] + value.substring(1).toLowerCase();
  }
}
