import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThousandSeparatorDirective } from './thousandSeparator.directive';



@NgModule({
  declarations: [
    ThousandSeparatorDirective
  ],
  imports: [
    CommonModule
  ],
  exports:[
    ThousandSeparatorDirective
  ]
})
export class  ThousandSeparatorModule { }
