import { Component, OnInit } from '@angular/core';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentParentType } from 'src/app/shared/models/enums/document-parent-type.model';
import { ServiceTypes } from 'src/app/shared/models/shipment/service-types.enum';
import { DocumentTypeWithService } from '../../../models/document-type-with-service.models';
import { SelectedServices } from '../../../models/selected-services.model';
import { DocumentTypeService } from '../../../services/document-type.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { CertificationType } from 'src/app/shared/models/certification-type.model';
import { CertificationTypeEnum } from 'src/app/admin-portal-configs/enums/certification-type.enum';
import { ClassificationType } from 'src/app/shared/models/classification-type.model';
import { DocumentTypeConditions } from 'src/app/admin-portal-configs/constants/document-type-condition';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { ProductDocumentCategory } from 'src/app/shared/models/enums/product-document-category.enum';

@Component({
  selector: 'app-add-or-edit-document-type',
  templateUrl: './add-or-edit-document-type.component.html',
  styleUrls: ['./add-or-edit-document-type.component.scss']
})
export class AddOrEditDocumentTypeComponent implements OnInit {
  documentTypeForm: UntypedFormGroup;
  isNewDocumentType: boolean = true;
  isSaving: boolean;
  showValidationErrors = true;
  documentTypeEdit: DocumentTypeWithService;
  parentTypes: BasicDDL[] = [
    new BasicDDL('Shipment', DocumentParentType.Shipment),
    new BasicDDL('CompanyProfile', DocumentParentType.CompanyProfile),
    new BasicDDL('Product', DocumentParentType.Product)
  ];
  categoryTypes = [
    ProductDocumentCategory.Medical,
    ProductDocumentCategory.NonMedical
  ];
  serviceTypes: SelectedServices[] = [];
  serviceDocumentType: string;

  classificationTypes: ClassificationType[];
  certificationTypes: CertificationType[];
  documentTypeConditions = DocumentTypeConditions;

  constructor(
    private fb: UntypedFormBuilder,
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private documentTypeService: DocumentTypeService,
    private router: Router,
    private route: ActivatedRoute,
    private helperService: HelperService,
    private formsHelperService: FormsHelperService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.documentTypeEdit = data['documentType'];
      this.getServices(data['services']);
    });

    this.initForm();
    this.addServicesToForm();
    this.getClassificationAndCertificationTypes();
  }

  private initForm() {
    this.isNewDocumentType = this.documentTypeEdit == null;

    this.documentTypeEdit =
      this.documentTypeEdit || ({} as DocumentTypeWithService);

    this.documentTypeForm = this.fb.group({
      name: [this.documentTypeEdit.name, [Validators.required]],
      maxFileSize: [
        this.documentTypeEdit.maxFileSize,
        [Validators.min(0), Validators.required]
      ],
      parentType: [this.documentTypeEdit.parentType, [Validators.required]],
      isPinned: [this.documentTypeEdit.isPinned],
      isForAir: [this.documentTypeEdit.isForAir],
      isForOcean: [this.documentTypeEdit.isForOcean],
      isForLand: [this.documentTypeEdit.isForLand],
      isRequired: [this.documentTypeEdit?.isRequired],
      isNonExpiring: [this.documentTypeEdit?.isNonExpiring],
      services: new UntypedFormArray([]),
      fscClassificationTypes: new UntypedFormArray([]),
      cfgWithGmbClassificationTypes: new UntypedFormArray([]),
      cfgWithoutGmbClassificationTypes: new UntypedFormArray([]),
      category: [this.documentTypeEdit.category]
    });
  }

  private addServicesToForm() {
    if (this.documentTypeEdit.services) {
      const formArray: UntypedFormArray = this.documentTypeForm.get(
        'services'
      ) as UntypedFormArray;

      this.documentTypeEdit.services.forEach(service => {
        formArray.push(new UntypedFormControl(service.id));
      });
    }
  }

  private addClassificationTypesToForm() {
    const fscClassificationTypes: UntypedFormArray = this.documentTypeForm.get(
      'fscClassificationTypes'
    ) as UntypedFormArray;

    this.documentTypeEdit.fscClassificationTypes?.forEach(
      classificationType => {
        fscClassificationTypes.push(new UntypedFormControl(classificationType));
      }
    );

    const cfgWithGmbClassificationTypes: UntypedFormArray = this.documentTypeForm.get(
      'cfgWithGmbClassificationTypes'
    ) as UntypedFormArray;

    this.documentTypeEdit.cfgWithGmbClassificationTypes?.forEach(
      classificationType => {
        cfgWithGmbClassificationTypes.push(
          new UntypedFormControl(classificationType)
        );
      }
    );

    const cfgWithoutGmbClassificationTypes: UntypedFormArray = this.documentTypeForm.get(
      'cfgWithoutGmbClassificationTypes'
    ) as UntypedFormArray;

    this.documentTypeEdit.cfgWithoutGmbClassificationTypes?.forEach(
      classificationType => {
        cfgWithoutGmbClassificationTypes.push(
          new UntypedFormControl(classificationType)
        );
      }
    );
  }

  private checkIfPicked(service: any): boolean {
    if (!this.documentTypeEdit.services) return false;

    return (
      this.documentTypeEdit.services.findIndex(
        i => i.id == service.id && i.isSelected == true
      ) > -1
    );
  }

  private getServices(services: any) {
    services.forEach(element => {
      const service = new SelectedServices(element.id, element.name);
      this.serviceTypes.push(service);
    });
  }

  selectService(event: any) {
    const formArray: UntypedFormArray = this.documentTypeForm.get(
      'services'
    ) as UntypedFormArray;

    if (event.target.checked) {
      formArray.push(new UntypedFormControl(event.target.value));
    } else {
      let i: number = 0;

      formArray.controls.forEach((ctrl: UntypedFormControl) => {
        if (ctrl.value == event.target.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  changeParentType(event: any) {
    this.documentTypeEdit.parentType = event.target.value;
    this.selectedCompanyProfileOrProduct();
    this.addOrClearProductFieldsValidation();
  }

  addOrClearProductFieldsValidation() {
    if (!this.isProduct) {
      this.clearValidator('isRequired');
      this.clearValidator('category');
      this.documentTypeForm.get('isRequired').reset();
      this.documentTypeForm.get('category').reset();
      return;
    }
    this.addRequiredValidator('isRequired');
    this.addRequiredValidator('category');
  }

  addRequiredValidator(formControl: string) {
    this.formsHelperService.addRequiredValidator(
      this.documentTypeForm,
      formControl
    );
  }

  clearValidator(formControl: string) {
    this.formsHelperService.clearValidator(this.documentTypeForm, formControl);
  }

  save() {
    const formVal = this.documentTypeForm.value;
    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');

    this.clearDraftData();
    this.processForm(formVal);

    if (!this.validateForm(formVal)) {
      return;
    }

    if (this.isNewDocumentType) {
      this.addDocumentType(formVal);
    } else {
      this.editDocumentType(formVal);
    }
  }

  clearDraftData() {
    if (!this.isProduct) {
      this.documentTypeForm?.get('isRequired')?.setValue(null);
    }
  }

  isCertificationTypeSelected(certificationType: string): boolean {
    let controlName = this.getControlNameFromCertificationType(
      certificationType
    );

    const formArray: UntypedFormArray = this.documentTypeForm.get(
      controlName
    ) as UntypedFormArray;

    return formArray?.length === this.classificationTypes?.length;
  }

  isClassificationTypeSelected(
    classificationType: string,
    certificationType: string
  ): boolean {
    let controlName = this.getControlNameFromCertificationType(
      certificationType
    );

    const formArray: UntypedFormArray = this.documentTypeForm.get(
      controlName
    ) as UntypedFormArray;

    let classificationTypes: string[] = formArray?.value;

    let classificationTypeIndex = classificationTypes?.findIndex(
      c => c === classificationType
    );

    return classificationTypes?.length > 0 && classificationTypeIndex != -1;
  }

  getControlNameFromCertificationType(certificationType: string): string {
    if (certificationType === CertificationTypeEnum.FSC) {
      return 'fscClassificationTypes';
    }

    if (certificationType === CertificationTypeEnum.CFGWithGMB) {
      return 'cfgWithGmbClassificationTypes';
    }

    if (certificationType === CertificationTypeEnum.CFGWithoutGMB) {
      return 'cfgWithoutGmbClassificationTypes';
    }

    return null;
  }

  selectClassificationType(
    event: any,
    classificationType: string,
    certificationType: string
  ) {
    let controlName = this.getControlNameFromCertificationType(
      certificationType
    );

    const formArray: UntypedFormArray = this.documentTypeForm.get(
      controlName
    ) as UntypedFormArray;

    if (event.target.checked) {
      formArray?.push(new UntypedFormControl(classificationType));
    } else {
      let formValue: string[] = formArray.value;
      let indexOfUnchecked = formValue.findIndex(f => f === classificationType);
      formArray?.removeAt(indexOfUnchecked);
    }
  }

  selectCertificationType(event: any, certificationType: string) {
    let controlName = this.getControlNameFromCertificationType(
      certificationType
    );

    const formArray: UntypedFormArray = this.documentTypeForm.get(
      controlName
    ) as UntypedFormArray;

    formArray?.clear();

    if (event.target.checked) {
      this.classificationTypes?.forEach(classificationType => {
        formArray?.push(new UntypedFormControl(classificationType?.name));
      });
    }
  }

  private addDocumentType(documentType: DocumentTypeWithService) {
    this.documentTypeService
      .addNewDocumentType(documentType, this.documentTypeEdit.services)
      .subscribe(
        documentType => {
          this.saveSuccessHelper(documentType);
        },
        error => this.saveFailedHelper(error)
      );
  }

  private editDocumentType(documentType: DocumentTypeWithService) {
    this.documentTypeService
      .updateDocumentType(
        this.documentTypeEdit.id,
        this.documentTypeEdit.services,
        documentType
      )
      .subscribe(
        documentType => {
          this.saveSuccessHelper(documentType);
        },
        error => this.saveFailedHelper(error)
      );
  }

  private processForm(formVal: any) {
    this.documentTypeEdit.maxFileSize = Math.ceil(
      this.documentTypeEdit.maxFileSize
    );

    this.documentTypeEdit.services = this.serviceTypes.filter(
      i => formVal.services.findIndex(f => parseInt(f) == i.id) > -1
    );

    this.documentTypeEdit.services.forEach(service => {
      service.isSelected = true;
    });
  }

  private validateForm(formVal: any): boolean {
    if (!this.documentTypeForm.valid) {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage(
        'Error',
        'Invalid Form Submission',
        MessageSeverity.error
      );
      return false;
    }

    if (this.isCompanyProfileOrProduct) {
      this.documentTypeEdit.isForAir = true;
      this.documentTypeEdit.isForOcean = true;
      this.documentTypeEdit.isForLand = true;
      this.documentTypeEdit.services = [];
    } else if (!this.validateOptionShipment()) {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage(
        'Error',
        'Please Select a Service',
        MessageSeverity.error
      );
      return false;
    }

    if (!this.validateServiceType(formVal) || !this.validateOptionProduct()) {
      return false;
    }

    return true;
  }

  private validateServiceType(formVal: any): boolean {
    const isForAir = formVal.isForAir || false;
    const isForOcean = formVal.isForOcean || false;
    const isForLand = formVal.isForLand || false;

    const frieghtType =
      this.documentTypeEdit.services.findIndex(
        i => i.name == ServiceTypes.Freight
      ) > -1;

    const customsType =
      this.documentTypeEdit.services.findIndex(
        i => i.name == ServiceTypes.CustomsClearance
      ) > -1;

    if (frieghtType || customsType) {
      if (!isForAir && !isForOcean && !isForLand) {
        this.isSaving = false;
        this.alertService.stopLoadingMessage();

        if (frieghtType)
          this.alertService.showMessage(
            'Error',
            'Specifiy Frieght Type',
            MessageSeverity.error
          );

        if (customsType)
          this.alertService.showMessage(
            'Error',
            'Specifiy Customs Clearance Type',
            MessageSeverity.error
          );
        return false;
      }
    }
    return true;
  }

  private saveSuccessHelper(documentType?: DocumentTypeWithService) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.showValidationErrors = false;

    if (this.isNewDocumentType) {
      this.alertService.showMessage(
        'Success',
        `Document type \"${documentType.name}\" was created successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to document type \"${documentType.name}\" was saved successfully`,
        MessageSeverity.success
      );
    }

    this.resetForm();
    this.router.navigate(['/settings/document-type']);
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occured whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  cancel() {
    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/document-type']);
  }

  resetForm() {
    this.documentTypeForm.reset();
  }

  selectedCompanyProfileOrProduct() {
    if (this.isCompanyProfileOrProduct) {
      this.documentTypeForm.get('isForAir').setValue(false);
      this.documentTypeForm.get('isForOcean').setValue(false);
      this.documentTypeForm.get('isForLand').setValue(false);
    }

    if (!this.isProduct) {
      const fscClassificationTypes: UntypedFormArray = this.documentTypeForm.get(
        'fscClassificationTypes'
      ) as UntypedFormArray;
      fscClassificationTypes.clear();

      const cfgWithGmbClassificationTypes: UntypedFormArray = this.documentTypeForm.get(
        'cfgWithGmbClassificationTypes'
      ) as UntypedFormArray;
      cfgWithGmbClassificationTypes.clear();

      const cfgWithoutGmbClassificationTypes: UntypedFormArray = this.documentTypeForm.get(
        'cfgWithoutGmbClassificationTypes'
      ) as UntypedFormArray;
      cfgWithoutGmbClassificationTypes.clear();
    }
  }

  validateOptionShipment(): boolean {
    return (
      this.documentTypeEdit.parentType == DocumentParentType.Shipment &&
      this.documentTypeEdit.services.length > 0
    );
  }

  validateOptionProduct(): boolean {
    const formVal = this.documentTypeForm.value;
    if (this.isProductMedical) {
      return (
        formVal?.fscClassificationTypes?.length > 0 ||
        formVal?.cfgWithGmbClassificationTypes?.length > 0 ||
        formVal?.cfgWithoutGmbClassificationTypes?.length > 0
      );
    }
    return true;
  }

  get isCompanyProfileOrProduct(): boolean {
    let isCompanyProfileOrProduct =
      this.documentTypeEdit.parentType == DocumentParentType.CompanyProfile ||
      this.isProduct;

    return isCompanyProfileOrProduct;
  }

  get isProduct(): boolean {
    return this.documentTypeEdit.parentType === DocumentParentType.Product;
  }

  get isProductMedical(): boolean {
    return (
      this.documentTypeForm.get('category').value ===
      ProductDocumentCategory.Medical
    );
  }

  private getClassificationAndCertificationTypes() {
    this.helperService
      .getClassificationAndCertificationTypes()
      .subscribe(res => {
        this.classificationTypes = res?.classificationTypes;
        this.certificationTypes = res?.certificationTypes;
        this.addClassificationTypesToForm();
      });
  }
}

export class BasicDDL {
  public Name: string;
  public Value: string;

  constructor(private name: string, private value: string) {
    this.Name = name;
    this.Value = value;
  }
}
