import { Injectable } from '@angular/core';
import { FileViewerComponent } from './file-viewer.component';
import { DialogService } from '../../services/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class FileViewerService {
  constructor(private dialogService: DialogService) {}

  view(url: string) {
    this.dialogService.open(
      FileViewerComponent,
      {
        url: url
      },
      '95vw',
      '90vh'
    );
  }
}
