import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProductRegistration } from '../models/product-registration.model';
import { Observable } from 'rxjs';
import { ProductDocumentTypesCritirea } from '../models/product-document-types-criteria.model';
import { DocumentType } from 'src/app/shared/models/document-type.model';
import { ProductRegistrationDto } from '../models/product-registration-dto.model';

@Injectable({
  providedIn: 'root'
})
export class CreateProductRegistrationService {
  baseURL = environment.apiUrl + 'ProductRegistration';
  productRegistration: ProductRegistration;

  isProductRegistrationSubmitted = false;

  constructor(private http: HttpClient) {}

  addNewProductRegistration(
    product: ProductRegistration,
    companyId: string
  ): Observable<ProductRegistration> {
    return this.http.post<ProductRegistration>(
      `${this.baseURL}/${companyId}`,
      product
    );
  }

  getProductRegistrationById(id: string): Observable<ProductRegistrationDto> {
    return this.http.get<ProductRegistrationDto>(`${this.baseURL}/${id}`);
  }

  updateProductRegistrationDetails(
    productId: string,
    product: ProductRegistration
  ): Observable<ProductRegistration> {
    return this.http.put<ProductRegistration>(
      `${this.baseURL}/${productId}/ProductRegistrationDetails`,
      product
    );
  }

  // To do move to product library service
  getProductRegistartionDocumentTypes(
    productDocumentTypesCritirea: ProductDocumentTypesCritirea
  ): Observable<DocumentType[]> {
    const params = new HttpParams()
      .append(
        'ClassificationType',
        productDocumentTypesCritirea.classificationType
      )
      .append(
        'CertificationType',
        productDocumentTypesCritirea.certificationType
      )
      .append('DocumentType', productDocumentTypesCritirea.documentType);
    return this.http.get<DocumentType[]>(`${this.baseURL}/documentType`, {
      params
    });
  }

  submitProductRegistration(
    product: ProductRegistration,
    productId: string
  ): Observable<ProductRegistration> {
    return this.http.put<ProductRegistration>(
      `${this.baseURL}/${productId}/SubmitProduct`,
      product
    );
  }
}
