<div class="row p-3">
  <div class="col-lg-3 col-md-1 col-sm-1"></div>
  <div class="col-lg-6 col-md-10 col-sm-10 Card pb-1">
    <form [formGroup]="truckTypeForm" (ngSubmit)="saveTruckType()">
      <fieldset>
        <h4>
          {{
            !isForAdd
              ? ("config.management.TruckType.Edit" | translate) +
                " " +
                truckType.name
              : ("config.management.TruckType.New" | translate)
          }}
        </h4>
        <hr />
        <div *ngIf="isDuplicateTruckType" class="error-message">
          <app-error-badge
            [thinBar]="true"
            message="{{
              'config.management.TruckType.Editor.DuplicateEntry' | translate
            }}"
            class="warning-badge"
          ></app-error-badge>
        </div>
        <div class="form-group">
          <label for="name" class="col-form-label">{{
            "config.management.TruckType.TruckType" | translate
          }}</label>
          <input
            formControlName="name"
            [ngClass]="{
              'is-invalid':
                truckTypeForm.get('name').errors &&
                truckTypeForm.get('name').touched
            }"
            type="text"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-md-12">
              <hr />
            </div>
          </div>
          <label for="loadTypes" class="col-form-label"
            >Compatible Truck Loads</label
          >
          <div
            *ngFor="let item of loadTypes; let i = index"
            class="col-sm-6 form-check"
          >
            <input
              type="checkbox"
              [value]="item.id"
              (change)="selectLoadType($event)"
              class="form-check-input"
              name="{{ item.name }}"
              [checked]="checkIfSelected(item)"
            />
            <label class="form-check-label" [for]="item.name">{{
              item.name
            }}</label>
          </div>

          <div class="row">
            <div class="col-md-12">
              <hr />
            </div>
          </div>

          <label for="loadTypes" class="col-form-label"
            >Compatible Services</label
          >
          <div class="col-sm-6 form-check">
            <input
              formControlName="isPortTrucking"
              type="checkbox"
              class="form-check-input"
              id="isPortTrucking"
            />
            <label for="isPortTrucking" class="form-check-label">{{
              "config.management.TruckType.CompatibleServices.PortTrucking"
                | translate
            }}</label>
          </div>

          <div class="col-sm-6 form-check">
            <input
              formControlName="isDomesticTrucking"
              type="checkbox"
              class="form-check-input"
              id="isDomesticTrucking"
            />
            <label for="isDomesticTrucking" class="form-check-label">{{
              "config.management.TruckType.CompatibleServices.DomesticTrucking"
                | translate
            }}</label>
          </div>

          <div class="col-sm-6 form-check">
            <input
              formControlName="isLandTrucking"
              type="checkbox"
              class="form-check-input"
              id="isLandTrucking"
            />
            <label for="isLandTrucking" class="form-check-label">{{
              "config.management.TruckType.CompatibleServices.LandTrucking"
                | translate
            }}</label>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <hr class="edit-separator-hr" />
          </div>
        </div>

        <div class="col form-group">
          <div ngPreserveWhitespaces class="float-right">
            <button (click)="cancel()" class="btn btn-danger">
              <i class="fa fa-times"></i>
              {{ "config.management.TruckType.Editor.Cancel" | translate }}
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="isSaving || !truckTypeForm.valid"
            >
              <i *ngIf="!isSaving" class="fa fa-save"></i>
              <i *ngIf="isSaving" class="fa fa-circle-o-notch fa-spin"></i>
              {{
                isSaving
                  ? ("config.management.TruckType.Editor.Saving" | translate)
                  : ("config.management.TruckType.Editor.Save" | translate)
              }}
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>
