import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Status } from '../models/shipment/status.model';
 import { catchError } from 'rxjs/operators';
import { ActivityService } from '../../admin-portal-configs/services/activity.service';

@Injectable({
  providedIn: 'root'
})
export class ShipmentStatusesResolver implements Resolve<Status[]> {
  constructor(
    private router: Router,
    private activityService: ActivityService
  ) {}
  resolve(route: ActivatedRouteSnapshot): Observable<Status[]> {
    return this.activityService.getStatuses().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
