export const environment = {
  production: false,
  baseUrl: 'https://dev.freepl.app',
  tokenUrl: null,
  apiUrl: 'https://dev.freepl.app/api/',
  loginUrl: '/login',
  googleClientId:
    '439188608778-n9a4ulafkm5s8f2k4p8afglcrvohht3h.apps.googleusercontent.com',
  facebookClientId: '2356966407766363',
  googleMapsApiKey: 'AIzaSyBtGIh7wWBaHGTWZ5z1-6ucfeJ4wBvn8no'
};
