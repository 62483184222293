<div class="container">
  <app-page-header [title]="'Countries'"></app-page-header>
  <div class="search-filter-add-container">
    <div class="search-filter-container">
      <app-admin-search-bar
        class="search-bar d-block"
        [placeholder]="'Search for Country'"
        (searchChange)="onSearchChanged($event)"
      ></app-admin-search-bar>
      <button class="btn filter-btn">
        <svg-icon name="three-lines-filter" class="filtersIcon"></svg-icon>
      </button>
    </div>
    <div class="add-container">
      <button class="btn add-btn" [routerLink]="['./add']">
        <span>+</span>
        <span class="add-btn-title">Add New Country</span>
      </button>
    </div>
  </div>
  <app-add-empty-data-msg
    *ngIf="!countriesExist"
    [normalText]="'Countries will appear here once created.'"
    [boldText]="'There are no countries yet!'"
  ></app-add-empty-data-msg>

  <ngx-datatable
    *ngIf="countriesExist"
    class="material colored-header sm table table-striped table-hover"
    [rows]="this.paginatedCountries?.items"
    [rowHeight]="40"
    [headerHeight]="35"
    [footerHeight]="35"
    [columns]="columns"
    [scrollbarV]="true"
    [columnMode]="'force'"
    (scroll)="onScroll($event)"
  >
  </ngx-datatable>
  <ng-template #actionTemplate let-value="value">
    <svg-icon
      class="action-btn cursor-pointer"
      name="edit-circle"
      [routerLink]="['./' + value.country + '/edit']"
    ></svg-icon>
  </ng-template>
</div>
