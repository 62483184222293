import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-status-list',
  templateUrl: './status-list.component.html',
  styleUrls: ['./status-list.component.scss']
})
export class StatusListComponent implements OnInit {
  @Input() statusList: string[] = [];
  @Output() selectedStatusChange = new EventEmitter<string>();
  searchText: string = '';

  constructor() {}
  ngOnInit(): void {}

  get filteredStatusList(): string[] {
    let filteredlist;
    if (!this.searchText) {
      filteredlist = [...this.statusList]
    } else {
      filteredlist = this.statusList
        .filter(item =>
          item.toLowerCase().includes(this.searchText.toLowerCase())
        )
        .sort((a, b) => {
          // Custom sorting logic based on the search term occurrence
          const aIndex = a.toLowerCase().indexOf(this.searchText.toLowerCase());
          const bIndex = b.toLowerCase().indexOf(this.searchText.toLowerCase());
          return aIndex - bIndex;
        });
    }
    return filteredlist.sort((a,b)=>
    {
      if (a.toLowerCase().includes("draft") && !b.toLowerCase().includes("draft")) {
        return 1;
      } else if (!a.toLowerCase().includes("draft") && b.toLowerCase().includes("draft")) {
        return -1;
      }
    }
      );;
  }

  statusSelected(event) {
    this.selectedStatusChange.emit(event.value);
  }
}
