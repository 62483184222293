export enum NonAdminSidebarEnum {
  Dashboard = 'dashboard',
  Shipments = 'shipments',
  Quotes = 'quotes',
  Products = 'products',
  Registrations = 'registrations',
  Approvals = 'approvals',
  Form4Requests = 'form4requests',
  OperationalOversight = 'operationsoversight',
  Reports = 'reports',
}
