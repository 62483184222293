<div class="container-details-form">
  <div *ngIf="form?.controls?.length > 0" class="row container-padding">
    <div class="title record-number">{{ title }}</div>
    <div class="title field">Weight (kg)</div>
    <div class="title field">Quantity<span class="text-error">*</span></div>
  </div>
  <div *ngFor="let container of form?.controls; let i = index" class="mb-3">
    <div
      [formGroup]="container"
      class="row container-details-row  container-padding"
    >
      <div class="text-24 record-number">
        {{ i + 1 }}
      </div>

      <app-input-number
        class="field"
        [isGreyBorder]="true"
        [classList]="
          getControl(i, 'weight')?.errors && getControl(i, 'weight')?.touched
            ? 'validation-error'
            : ''
        "
        input
        formControlName="weight"
      ></app-input-number>

      <app-quantity-input
        class="field"
        [isGreyBorder]="true"
        [digitsOnly]="true"
        [classList]="
          getControl(i, 'quantity')?.errors &&
          getControl(i, 'quantity')?.touched
            ? 'validation-error'
            : ''
        "
        input
        formControlName="quantity"
      ></app-quantity-input>

      <div class="d-flex align-items-end py-1">
        <button
          (click)="deleteContainer(i)"
          class="btn"
          [disabled]="form.length == 1"
        >
          <svg-icon name="shipmentDetailsActionDelete"></svg-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="add-container">
    <button
      type="button"
      class="btn btn-link underlined"
      (click)="addContainer()"
    >
      + Add another {{ addContainerButtonTitle }}
    </button>
  </div>
</div>
