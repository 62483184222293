export class SelectedServices {
  id: number;
  name: string;
  isSelected: boolean;

  constructor(id: number, name: string, isSelected: boolean = false) {
    this.id = id;
    this.name = name;
    this.isSelected = isSelected;
  }
}
