import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryMaster } from 'src/app/shared/models/country.model';
import { District } from 'src/app/shared/models/district.models';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { Utilities } from 'src/app/shared/services/utilities';
import { DistrictService } from '../../../services/district.service';
import { CountryService } from 'src/app/shared/services/country.service';

@Component({
  selector: 'app-add-or-edit-districts',
  templateUrl: './add-or-edit-districts.component.html',
  styleUrls: ['./add-or-edit-districts.component.scss']
})
export class AddOrEditDistrictsComponent implements OnInit {
  districtForm: UntypedFormGroup;
  district: District;
  countries: CountryMaster[] = [];
  selectedCountry: CountryMaster;
  isSaving: boolean = false;
  duplicateDistrict: boolean = false;
  isNewDistrict: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private districtService: DistrictService,
    private route: ActivatedRoute,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.district = data['district'];
    });

    this.getCountries();
    this.initForm();
  }

  getCountries() {
    this.countryService.getCountries().subscribe(
      data => {
        this.countries = data;
        if (!this.isNewDistrict) {
          this.selectedCountry = this.countries.find(
            i => i.name == this.district.countryName
          );
        }
      },
      error => {
        this.alertService.showStickyMessage(
          'Error',
          `An error occured whilst retreiving a list of countries.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  initForm() {
    this.isNewDistrict = this.district == null;
    this.district = this.district || ({} as District);

    this.districtForm = this.fb.group({
      name: [this.district.name, [Validators.required]],
      cityId: [this.district.cityId, [Validators.required]],
      lat: [this.district.lat, [Validators.required]],
      lng: [this.district.lng, [Validators.required]]
    });
  }

  save() {
    const formVal = this.districtForm.value;

    if (!this.districtForm.valid) {
      this.alertService.error('Invalid Form!');
      return;
    }

    if (this.isNewDistrict) {
      this.addNewRequest(formVal);
    } else {
      this.updateRequest(formVal);
    }
  }

  updateRequest(formVal: District) {
    this.districtService.updateDistrict(this.district.id, formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  addNewRequest(formVal: District) {
    this.districtService.addNewDistrict(formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  private saveSuccessHelper(district?: District) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    if (this.isNewDistrict) {
      this.alertService.showMessage(
        'Success',
        `District \"${district.name}\" was created successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to district \"${district.name}\" were saved successfully`,
        MessageSeverity.success
      );
    }

    this.resetForm();
    this.router.navigate(['/settings/districts']);
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    if (error == '409') {
      this.duplicateDistrict = true;
      return;
    }
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occurred whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  cancel() {
    this.resetForm();

    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/districts']);
  }

  resetForm() {
    this.districtForm.reset();
  }

  onChangeCountry(event) {
    var name = event.target.value;
    this.selectedCountry = this.countries.find(e => e.name == name);
  }

  validateDigitLength(event) {
    const maxLatDigits = 12;
    const maxLngDigits = 13;

    let value = event.target.value.replace(/\D/g, '');

    if (value.length > maxLatDigits || value.length > maxLngDigits) {
      if (event.srcElement.id === 'lat' && value.length > maxLatDigits) {
        this.districtForm.get('lat').setErrors(Validators.maxLength);
      } else if (value.length > maxLngDigits) {
        this.districtForm.get('lng').setErrors(Validators.maxLength);
      }
    } else {
      if (event.srcElement.id === 'lat' && value.length > maxLatDigits) {
        this.districtForm.get('lat').clearValidators();
      } else if (value.length > maxLngDigits) {
        this.districtForm.get('lat').clearValidators();
      }
    }
  }
}
