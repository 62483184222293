import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MessageSeverity } from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { ActivityTransition } from '../../../models/activity-transition.model';
import { ShipmentLeadTimeResponse } from '../../../models/shipment-lead-time-response.model';
import { ActivityService } from '../../../services/activity.service';
import { ShipmentLeadTimeService } from '../../../services/shipment-lead-time.service';

@Component({
  selector: 'app-add-or-edit-shipment-lead-time',
  templateUrl: './add-or-edit-shipment-lead-time.component.html',
  styleUrls: ['./add-or-edit-shipment-lead-time.component.scss']
})
export class AddOrEditShipmentLeadTimeComponent implements OnInit {
  shipmentLeadTimeForm: UntypedFormGroup;
  shipmentLeadTime: ShipmentLeadTimeResponse;
  isSaving: boolean = false;
  isduplicateShipmentLeadTime: boolean = false;
  isNewShipmentLeadTime: boolean = false;
  activities: ActivityTransition[];
  endTriggers: ActivityTransition[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private shipmentLeadTimeService: ShipmentLeadTimeService,
    private route: ActivatedRoute,
    private activityService: ActivityService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.shipmentLeadTime = data['shipmentLeadTime'];
    });

    this.loadActivities();
    this.initForm();
  }

  loadActivities() {
    this.activityService.getAll().subscribe(res => {
      this.activities = res;
      if (this.shipmentLeadTime != null) {
        this.onChangingStartTrigger(null, this.shipmentLeadTime.startTriggerId);
      }
    });
  }

  initForm() {
    this.isNewShipmentLeadTime = this.shipmentLeadTime == null;
    this.shipmentLeadTime =
      this.shipmentLeadTime || ({} as ShipmentLeadTimeResponse);

    this.shipmentLeadTimeForm = this.fb.group({
      name: [this.shipmentLeadTime.name, [Validators.required]],
      leadTime: [this.shipmentLeadTime.leadTime, [Validators.required]],
      startTriggerId: [
        this.shipmentLeadTime.startTriggerId,
        [Validators.required]
      ],
      endTriggerId: [this.shipmentLeadTime.endTriggerId, [Validators.required]]
    });
  }

  save() {
    const formVal = this.shipmentLeadTimeForm.value;

    if (!this.validateForm(formVal)) {
      this.alertService.error('Invalid Form!');
      return;
    }

    if (this.isNewShipmentLeadTime) {
      this.addNewRequest(formVal);
    } else {
      this.updateRequest(formVal);
    }
  }

  private validateForm(formVal: any) {
    const validTriggers =
      formVal.startTriggerId != 'null' && formVal.endTriggerId != 'null';
    return validTriggers && this.shipmentLeadTimeForm.valid;
  }

  updateRequest(formVal: any) {
    this.shipmentLeadTimeService
      .updateShipmentLeadTime(this.shipmentLeadTime.id, formVal)
      .subscribe(
        data => {
          if(data?.statusCode === 409){
            this.saveFailedHelper('409');
          }
          else{
            this.saveSuccessHelper(formVal);
          }

        },
        error => {
          this.saveFailedHelper(error);
        }
      );
  }

  onChangingStartTrigger(event?: any, shipmentLeadTimeId?: number) {
    if (shipmentLeadTimeId) {
      this.endTriggers = this.activities.filter(
        a => a.id != shipmentLeadTimeId
      );
    } else if (event) {
      this.endTriggers = this.activities.filter(
        a => a.id != event.target?.value
      );
    }
  }

  addNewRequest(formVal: any) {
    this.shipmentLeadTimeService.addShipmentLeadTime(formVal).subscribe(
      data => {
        if(data?.statusCode === 409){
          this.saveFailedHelper('409');
        }
        else{
          this.saveSuccessHelper(formVal);
        }
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  private saveSuccessHelper(formVal: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    if (this.isNewShipmentLeadTime) {
      this.alertService.showMessage(
        'Success',
        `Shipment lead time \"${formVal.name}\" was created successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to shipment lead time \"${formVal.name}\" were saved successfully`,
        MessageSeverity.success
      );
    }

    this.resetForm();
    this.router.navigate(['/settings/shipment-kpis']);
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    if (error == '409') {
      this.isduplicateShipmentLeadTime = true;
      return;
    }
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occured whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  cancel() {
    this.resetForm();

    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/shipment-kpis']);
  }

  resetForm() {
    this.shipmentLeadTimeForm.reset();
  }
}
