import { OceanFreightOptionsEnum } from '../models/enums/ocean-freight.enum';
import { TruckingLoadType } from '../models/enums/trucking-load-type.enum';

export const loadTypesRenderingData: any[] = [
  {
    value: OceanFreightOptionsEnum.LCL,
    title: OceanFreightOptionsEnum.LCL,
    description: 'Less than Container Load',
    image: 'lcl-container',
    subTitleOne: 'Ideal for volumes less than 15 CBM',
    subTitleTwo: 'Might take more time in transit'
  },
  {
    value: OceanFreightOptionsEnum.FCL,
    title: OceanFreightOptionsEnum.FCL,
    description: 'Full Container Load',
    image: 'fcl-container',
    subTitleOne: 'Ideal for bulk and large-volume items',
    subTitleTwo: 'Rate is charged even if not fully filled'
  },
  {
    value: OceanFreightOptionsEnum.Breakbulk,
    title: OceanFreightOptionsEnum.Breakbulk,
    description: 'Boxes, crates, drums, barrels',
    image: 'assets/images/PNG/bread-bulk-container.png',
    subTitleOne: 'Ideal for oversized goods',
    subTitleTwo: 'More expensive than containers'
  },
  {
    value: TruckingLoadType.LTL,
    title: TruckingLoadType.LTL,
    description: 'Less Than Truckload',
    image: 'ltl-truck',
    subTitleOne: 'Ideal for volumes less than 15 CBM',
    subTitleTwo: 'Might take more time in transit'
  },
  {
    value: TruckingLoadType.FTL,
    title: TruckingLoadType.FTL,
    description: 'Full Truckload',
    image: 'ftl-truck',
    subTitleOne: 'Ideal for bulk and large-volume items',
    subTitleTwo: 'Rate is charged even if not fully filled'
  }
];
