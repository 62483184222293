import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CompanyService } from '../../company/services/company.service';
import { Route } from '../../company/models/route.model';

@Injectable()
export class RouteResolver implements Resolve<Route> {
  id: number;
  companyId: number;
  constructor(private companyService: CompanyService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Route> {
    this.id = JSON.parse(route.params.id) as number;
    this.companyId = JSON.parse(route.params.companyId) as number;
    return this.companyService.getRoute(this.companyId, this.id).pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }
}
