import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';
import { Role } from '../models/role.model';
import { CategoryAndPermissions } from 'src/app/admin-portal-configs/models/category-and-permissions';
import { environment } from 'src/environments/environment';
import { Employee } from 'src/app/company/models/employee.model';
import { BaseNamedEntity } from '../models/shipment/base-named-entity.model';

@Injectable()
export class AccountEndpoint extends EndpointBase {
  baseURL = environment.apiUrl + 'Account';

  get usersUrl() {
    return this.configurations.baseUrl + '/api/account/users';
  }

  get usersPublicUrl() {
    return this.configurations.baseUrl + '/api/account/public/users';
  }

  get userByUserNameUrl() {
    return this.configurations.baseUrl + '/api/account/users/username';
  }

  get userHasPasswordUrl() {
    return this.configurations.baseUrl + '/api/account/users/haspassword';
  }

  get currentUserUrl() {
    return this.configurations.baseUrl + '/api/account/users/me';
  }

  get currentUserPreferencesUrl() {
    return this.configurations.baseUrl + '/api/account/users/me/preferences';
  }
  get sendConfirmEmailUrl() {
    return (
      this.configurations.baseUrl + '/api/account/users/me/sendconfirmemail'
    );
  }

  get confirmEmailUrl() {
    return this.configurations.baseUrl + '/api/account/public/confirmemail';
  }

  get recoverPasswordUrl() {
    return this.configurations.baseUrl + '/api/account/public/recoverpassword';
  }

  get resetPasswordUrl() {
    return this.configurations.baseUrl + '/api/account/public/resetpassword';
  }
  get unblockUserUrl() {
    return this.configurations.baseUrl + '/api/account/users/unblock';
  }
  get rolesUrl() {
    return this.configurations.baseUrl + '/api/account/roles';
  }

  get roleByRoleNameUrl() {
    return this.configurations.baseUrl + '/api/account/roles/name';
  }

  get permissionsUrl() {
    return this.configurations.baseUrl + '/api/account/permissions';
  }
  get roleUrl() {
    return this.configurations.baseUrl + '/api/account/role';
  }
  get permissionCategoryUrl() {
    return this.configurations.baseUrl + '/api/account/PermissionCategories';
  }

  get permissionGroupsUrl() {
    return this.configurations.baseUrl + '/api/account/PermissionGroups';
  }

  constructor(
    private configurations: ConfigurationService,
    http: HttpClient,
    authService: AuthService
  ) {
    super(http, authService);
  }

  getUserHasPasswordEndpoint<T>(userId: string): Observable<T> {
    const endpointUrl = `${this.userHasPasswordUrl}/${userId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUserEndpoint(userId));
      })
    );
  }

  getUserEndpoint<T>(userId?: string): Observable<T> {
    const endpointUrl = userId
      ? `${this.usersUrl}/${userId}`
      : this.currentUserUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUserEndpoint(userId));
      })
    );
  }

  getUserByUserNameEndpoint<T>(userName: string): Observable<T> {
    const endpointUrl = `${this.userByUserNameUrl}/${userName}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () =>
          this.getUserByUserNameEndpoint(userName)
        );
      })
    );
  }

  getUsersEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const endpointUrl =
      page && pageSize ? `${this.usersUrl}/${page}/${pageSize}` : this.usersUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () =>
          this.getUsersEndpoint(page, pageSize)
        );
      })
    );
  }

  getNewUserEndpoint<T>(
    userObject: any,
    isPublicRegistration?: boolean
  ): Observable<T> {
    const endpointUrl = isPublicRegistration
      ? this.usersPublicUrl
      : this.usersUrl;

    return this.http
      .post<T>(endpointUrl, JSON.stringify(userObject), this.requestHeaders)
      .pipe<T>(
        catchError(error => {
          return this.handleError(error, () =>
            this.getNewUserEndpoint(userObject)
          );
        })
      );
  }

  getUpdateUserEndpoint<T>(userObject: any, userId?: string): Observable<T> {
    const endpointUrl = userId
      ? `${this.usersUrl}/${userId}`
      : this.currentUserUrl;

    return this.http
      .put<T>(endpointUrl, JSON.stringify(userObject), this.requestHeaders)
      .pipe<T>(
        catchError(error => {
          return this.handleError(error, () =>
            this.getUpdateUserEndpoint(userObject, userId)
          );
        })
      );
  }

  getUserPreferencesEndpoint<T>(): Observable<T> {
    return this.http
      .get<T>(this.currentUserPreferencesUrl, this.requestHeaders)
      .pipe<T>(
        catchError(error => {
          return this.handleError(error, () =>
            this.getUserPreferencesEndpoint()
          );
        })
      );
  }

  getUpdateUserPreferencesEndpoint<T>(configuration: string): Observable<T> {
    return this.http
      .put<T>(
        this.currentUserPreferencesUrl,
        JSON.stringify(configuration),
        this.requestHeaders
      )
      .pipe<T>(
        catchError(error => {
          return this.handleError(error, () =>
            this.getUpdateUserPreferencesEndpoint(configuration)
          );
        })
      );
  }

  getSendConfirmEmailEndpoint<T>(): Observable<T> {
    const endpointUrl = this.sendConfirmEmailUrl;

    return this.http.post<T>(endpointUrl, null, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () =>
          this.getSendConfirmEmailEndpoint()
        );
      })
    );
  }

  getConfirmUserAccountEndpoint<T>(
    userId: string,
    confirmationCode: string
  ): Observable<T> {
    const endpointUrl = `${this.confirmEmailUrl}?userid=${userId}&code=${confirmationCode}`;

    return this.http.put<T>(endpointUrl, null, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () =>
          this.getConfirmUserAccountEndpoint(userId, confirmationCode)
        );
      })
    );
  }

  getRecoverPasswordEndpoint<T>(usernameOrEmail: string): Observable<T> {
    const endpointUrl = this.recoverPasswordUrl;

    return this.http
      .post<T>(
        endpointUrl,
        JSON.stringify({ usernameOrEmail }),
        this.requestHeaders
      )
      .pipe<T>(
        catchError(error => {
          return this.handleError(error, () =>
            this.getRecoverPasswordEndpoint(usernameOrEmail)
          );
        })
      );
  }

  getResetPasswordEndpoint<T>(
    usernameOrEmail: string,
    newPassword: string,
    resetCode: string
  ): Observable<T> {
    const endpointUrl = this.resetPasswordUrl;

    return this.http
      .post<T>(
        endpointUrl,
        JSON.stringify({
          usernameOrEmail,
          password: newPassword,
          resetcode: resetCode
        }),
        this.requestHeaders
      )
      .pipe<T>(
        catchError(error => {
          return this.handleError(error, () =>
            this.getResetPasswordEndpoint(
              usernameOrEmail,
              newPassword,
              resetCode
            )
          );
        })
      );
  }

  getUnblockUserEndpoint<T>(userId: string): Observable<T> {
    const endpointUrl = `${this.unblockUserUrl}/${userId}`;

    return this.http.put<T>(endpointUrl, null, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () =>
          this.getUnblockUserEndpoint(userId)
        );
      })
    );
  }

  getDeleteUserEndpoint<T>(userId: string): Observable<T> {
    const endpointUrl = `${this.usersUrl}/${userId}`;

    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () =>
          this.getDeleteUserEndpoint(userId)
        );
      })
    );
  }

  getRoleEndpoint<T>(roleId: string): Observable<T> {
    const endpointUrl = `${this.rolesUrl}/${roleId}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getRoleEndpoint(roleId));
      })
    );
  }
  getRoleAndPermissionsByIdEndpoint(roleId: string): Observable<Role> {
    return this.http.get<Role>(this.roleUrl + `/${roleId}`);
  }

  getCategoriesAndPermissions(): Observable<CategoryAndPermissions[]> {
    return this.http.get<CategoryAndPermissions[]>(this.permissionCategoryUrl);
  }

  getPermissionGroups(): Observable<BaseNamedEntity[]> {
    return this.http.get<BaseNamedEntity[]>(this.permissionGroupsUrl);
  }

  getRoleByRoleNameEndpoint<T>(roleName: string): Observable<T> {
    const endpointUrl = `${this.roleByRoleNameUrl}/${roleName}`;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () =>
          this.getRoleByRoleNameEndpoint(roleName)
        );
      })
    );
  }

  getRolesEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const endpointUrl =
      page && pageSize ? `${this.rolesUrl}/${page}/${pageSize}` : this.rolesUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () =>
          this.getRolesEndpoint(page, pageSize)
        );
      })
    );
  }

  getNewRoleEndpoint<T>(roleObject: any): Observable<T> {
    return this.http
      .post<T>(this.rolesUrl, JSON.stringify(roleObject), this.requestHeaders)
      .pipe<T>(
        catchError(error => {
          return this.handleError(error, () =>
            this.getNewRoleEndpoint(roleObject)
          );
        })
      );
  }

  getUpdateRoleEndpoint<T>(roleObject: any, roleId: string): Observable<T> {
    const endpointUrl = `${this.rolesUrl}/${roleId}`;

    return this.http
      .put<T>(endpointUrl, JSON.stringify(roleObject), this.requestHeaders)
      .pipe<T>(
        catchError(error => {
          return this.handleError(error, () =>
            this.getUpdateRoleEndpoint(roleObject, roleId)
          );
        })
      );
  }

  getDeleteRoleEndpoint<T>(roleId: string): Observable<T> {
    const endpointUrl = `${this.rolesUrl}/${roleId}`;

    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () =>
          this.getDeleteRoleEndpoint(roleId)
        );
      })
    );
  }

  getPermissionsEndpoint<T>(): Observable<T> {
    return this.http.get<T>(this.permissionsUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPermissionsEndpoint());
      })
    );
  }

  getUsersByRole<FreePLUser>(role: string): Observable<FreePLUser[]> {
    return this.http.get<FreePLUser[]>(this.baseURL + `/User/${role}`);
  }

  updateUserAndEmployee(employee: Employee): Observable<Employee> {
    return this.http.put<Employee>(
      this.baseURL + `/Employee/${employee.id}/Company/${employee.companyId}`,
      employee
    );
  }
}
