import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Permission } from 'src/app/shared/models/permission.model';
import { Role } from 'src/app/shared/models/role.model';
import { AccountService } from 'src/app/shared/services/account.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { BaseNamedEntity } from '../../../shared/models/shipment/base-named-entity.model';
import { PermissionGroupsEnum } from '../../enums/permission-groups.enum';
import { CategoryAndPermissions } from '../../models/category-and-permissions';
import { ParentPermissionCategory } from '../../models/parent-permission-category.model';
import { RoleAndPermissionsDTO } from '../../models/role-and-permissions-dto';

@Component({
  selector: 'app-add-edit-role',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.scss']
})
export class AddEditRoleComponent implements OnInit {
  roleAndPermissions: RoleAndPermissionsDTO;
  categoryPermissions: CategoryAndPermissions[];
  originalParentPermissionCategories: ParentPermissionCategory[];
  selectedPermissions: Permission[];
  permissionForm: UntypedFormGroup;
  isSaving: boolean;
  isFailedToSave: boolean;
  isSearching: boolean;
  permissionGroups: string[] = [
    PermissionGroupsEnum.Administrative,
    PermissionGroupsEnum.Operational
  ];
  parentPermissionCategories: ParentPermissionCategory[] = [];
  selectedTabIndex: number;
  currentGroup: BaseNamedEntity;

  constructor(
    private router: Router,
    private accountService: AccountService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private formHelperService: FormsHelperService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data => {
      if (this.isEditRoute) {
        this.roleAndPermissions = data['roleAndPermissions'];
      }
      this.categoryPermissions = data['categoryPermissions'];
      this.restructPerentCategoryAndGroupsPermission();
      this.currentGroup = this.getCurrentGroup(this.categoryPermissions);
      this.createForm();
      this.selectPermissions();
      if (this.currentGroup) {
        this.filterPermissionCategoriesByPermissionGroup();
      }
    });
  }

  restructPerentCategoryAndGroupsPermission() {
    const parentCategories = this.categoryPermissions
      .map(
        categoryPermissions =>
          categoryPermissions.permissionCategory?.parentPermissionCategory
      )
      .filter(Boolean);

    parentCategories.forEach(parentCategory => {
      if (
        !this.parentPermissionCategories.some(
          cat => cat.id === parentCategory.id
        )
      ) {
        this.parentPermissionCategories.push(parentCategory);
        this.addCategoriesToParentCategory(parentCategory);
      }
    });

    this.parentPermissionCategories.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    this.parentPermissionCategories.forEach(parentCategory => {
      if (parentCategory.categoryPermissions) {
        parentCategory.categoryPermissions.sort((a, b) =>
          a.permissionCategory.name.localeCompare(b.permissionCategory.name)
        );

        parentCategory.categoryPermissions.forEach(category => {
          if (category.permissions) {
            category.permissions.sort((a, b) => a.name.localeCompare(b.name));
          }
        });
      }
    });

    this.originalParentPermissionCategories = this.getDeepCopyOfParentPermissionCategories(
      this.parentPermissionCategories
    );
  }

  filterPermissionCategoriesByPermissionGroup() {
    var groupName = this.permissionForm?.value?.permissionGroup;
    this.parentPermissionCategories = [];
    for (var i = 0; i < this.originalParentPermissionCategories.length; i++) {
      var categories = this.getCategoriesByGroup(groupName, [
        ...this.originalParentPermissionCategories[i].categoryPermissions
      ]);
      if (categories?.length) {
        this.parentPermissionCategories.push({
          ...this.originalParentPermissionCategories[i]
        });
        this.parentPermissionCategories[
          this.parentPermissionCategories.length - 1
        ].categoryPermissions = categories;
      }
    }
  }

  private getCategoriesByGroup(
    groupName: string,
    categoryPermissions: CategoryAndPermissions[]
  ): CategoryAndPermissions[] {
    return categoryPermissions.filter(
      x => x.permissionCategory.permissionGroup?.name == groupName
    );
  }

  private getDeepCopyOfParentPermissionCategories(
    parentPermissionCategoriesArr: ParentPermissionCategory[]
  ) {
    return parentPermissionCategoriesArr.map(parentCategory => ({
      ...parentCategory,
      categoryPermissions: [...parentCategory.categoryPermissions]
    }));
  }

  private getCurrentGroup(
    categoryPermissions: CategoryAndPermissions[]
  ): BaseNamedEntity {
    var categoriesHaveGroup = categoryPermissions.filter(
      x => x.permissionCategory?.permissionGroup
    );
    var group = categoriesHaveGroup.find(x =>
      x.permissions.find(
        y => y.value == this.roleAndPermissions?.permissions[0]?.value
      )
    )?.permissionCategory.permissionGroup;
    return group;
  }

  selectAllTab() {
    this.selectedTabIndex = 0;
  }

  addCategoriesToParentCategory(parentCategory: ParentPermissionCategory) {
    parentCategory.categoryPermissions = this.categoryPermissions.filter(
      g =>
        g.permissionCategory?.parentPermissionCategory?.id == parentCategory?.id
    );
  }

  createForm() {
    this.permissionForm = this.fb.group({
      name: [
        this.roleAndPermissions?.role?.name,
        [Validators.required, Validators.minLength(2), Validators.maxLength(30)]
      ],
      description: [
        this.roleAndPermissions?.role?.description,
        [Validators.maxLength(100)]
      ],
      permissionGroup: [this.currentGroup?.name, [Validators.required]]
    });
  }

  get isEditRoute(): boolean {
    return this.router.url.includes('edit');
  }

  addNewRole(newRole: Role) {
    this.accountService.newRole(newRole).subscribe(res => {
      this.handleSaveResponse();
      this.isSaving = false;
    });
  }

  editRole(editedRole: Role) {
    this.accountService.updateRole(editedRole).subscribe(res => {
      this.handleSaveResponse();
      this.isSaving = false;
    });
  }

  save() {
    this.mergedPermissions();
    this.permissionForm.markAllAsTouched();

    if (this.permissionForm.invalid || this.isSaving) {
      this.isFailedToSave = true;
      return;
    }

    this.isSaving = true;
    this.isFailedToSave = false;

    if (this.isEditRoute) {
      this.roleAndPermissions.role.name = this.permissionForm?.value?.name;
      this.roleAndPermissions.role.description = this.permissionForm?.value?.description;
      this.roleAndPermissions.role.permissions = this.mergedPermissions();
      this.editRole(this.roleAndPermissions.role);
    } else {
      let role: Role = {
        name: this.permissionForm?.value?.name,
        description: this.permissionForm?.value?.description,
        permissions: this.mergedPermissions()
      };

      this.addNewRole(role);
    }
  }

  mergedPermissions(): Permission[] {
    var selectedPermissions = [];
    this.originalParentPermissionCategories.forEach(x => {
      x.categoryPermissions.forEach(y => {
        y.permissions.forEach(z => {
          if (z.isSelected) {
            selectedPermissions.push(z);
          }
        });
      });
    });
    return selectedPermissions;
  }

  handleSaveResponse(): void {
    this.alertService.success(
      this.isEditRoute
        ? 'Role updated successfully!'
        : 'Role added successfully!'
    );
    this.router.navigate(['/settings'], { fragment: 'roles' });
  }

  get errorMesage(): string {
    return `Required fields are either left empty or filled incorrectly
            . Check all fields highlighted in red and try again.`;
  }

  isInvalidFormControl(formControlName: string): boolean {
    return this.formHelperService?.isInvalidFormControl(
      this.permissionForm,
      formControlName
    );
  }

  selectPermissions(): void {
    this.parentPermissionCategories?.forEach(parentPermissionCategory => {
      this.selectParentPermissionCategoryPermissions(parentPermissionCategory);
    });
  }

  selectParentPermissionCategoryPermissions(
    parentPermissionCategory: ParentPermissionCategory
  ) {
    parentPermissionCategory?.categoryPermissions?.forEach(x => {
      x?.permissions?.forEach(x => {
        if (
          this.roleAndPermissions?.permissions?.find(y => y.value == x.value)
        ) {
          x.isSelected = true;
        }
      });
      if (x?.permissions?.every(y => y.isSelected)) {
        x.isSelected = true;
        x.selectionType = '';
      } else if (x?.permissions?.some(y => y.isSelected)) {
        x.isSelected = true;
        x.selectionType = 'dashmark';
      } else {
        x.isSelected = false;
        x.selectionType = '';
      }
    });
  }
}
