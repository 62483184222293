import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CountryDto } from '../models/country-dto';
import { CountryService } from 'src/app/shared/services/country.service';

@Injectable({
  providedIn: 'root'
})
export class CountryResolver implements Resolve<CountryDto> {
  constructor(private countryService: CountryService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<CountryDto> {
    let country = route.params.country;

    return this.countryService.getCountry(country).pipe(
      catchError(() => {
        return of(null);
      })
    );
  }
}
